import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { isEqual } from 'lodash';
import { Observable, Subscription, zip } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { CustomerData } from 'src/app/models/customer.model';
import { Statuses } from 'src/app/models/kn-br-draft-order.model';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import { KnBrDateHelper } from 'src/app/shared/kn-br-date.helper';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import { KnBrInvoiceService } from 'src/app/store/kn-br-invoice/kn-br-invoice.service';
import { KN_BR_CUSTOMER_CURRENT } from 'src/constants/api.endpoints.constant';
import { INVOICE_PAGESIZE } from 'src/constants/pagination.constant';

@Component({
  selector: 'app-kn-br-invoice-list-search',
  templateUrl: './kn-br-invoice-list-search.component.html',
  styleUrls: ['./kn-br-invoice-list-search.component.scss'],
})
export class KnBrInvoiceListSearchComponent implements OnInit, OnDestroy {
  invoiceSearchForm: UntypedFormGroup;
  invoiceStatusList$: Observable<Statuses[]> = this.knBrCommonService.loadInvoiceStatusList$();
  invoiceFilterByList$: Observable<any[]> = this.knBrCommonService.loadInvoiceFilterByList$();
  invoiceDocumentTypeList$: Observable<Statuses[]> = this.knBrCommonService.loadInvoiceDocumentTypeList$();
  customerContextData$: Observable<CustomerData> = this.knBrCustomerContextService.get$;
  brandList$: Observable<Statuses[]> = this.knBrCommonService.loadActiveSalse$();
  status$: Observable<Statuses[]> = this.knBrCommonService.loadQuoteStatuses$();
  documentTypeList: Statuses[] = [];
  filterByList: Statuses[] = [];
  selectedCustomer: CustomerData;
  currentPage = 0;
  // selectedStatus = 'all';
  customerNumber = '';
  objectKeys = Object.keys;
  brandList = [];
  subscription = new Subscription();
  isSearchDisabled$: Observable<boolean> = this.knBrInvoiceService.isSearchDisabled$;
  todayDate = () => {
    const today = new Date();
    return ({ year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate() });
  };
  maxStartDate = () => this.todayDate();
  minEndDate = () => this.todayDate();
  fromDate: any;
  toDate: any;
  hasDates = false;
  pBrandName = [];
  pStatus = [];
  pDocumentType = [];
  // pStatus = [{
  //   "Text": "All",
  //   "Key": "all"
  // }];
  state$: Observable<unknown>;
  retrievalPending = true;
  combinedCallSubscription: Subscription;

  constructor(
    private fb: UntypedFormBuilder,
    private knBrInvoiceService: KnBrInvoiceService,
    private knBrCommonService: KnBrCommonService,
    public ngbCalendar: NgbCalendar,
    private knBrDateHelper: KnBrDateHelper,
    private knBrCustomerContextService: KnBrCustomerContextService,
    protected activatedRoute: ActivatedRoute

  ) {}

  ngOnInit(): void {
    this.state$ = this.activatedRoute.paramMap
    .pipe(map(() => window.history.state));
    this.subscription = this.customerContextData$.subscribe((value) => {
      if (value) {
        this.selectedCustomer = value;
      }
      this.knBrCommonService.getInvoiceStatusList();
      this.knBrCommonService.getInvoiceFilterByList();
      this.knBrCommonService.getInvoiceDocumentTypeList();
      this.knBrInvoiceService.setInvoiceInit(true);
      this.initForm();
    });

    this.invoiceFilterByList$.subscribe((filterByList) => {
      if (filterByList && filterByList.length > 0) {
        const selectedFilter = filterByList.find((item) => item.Key === 'DOCUMENT_TYPE');
        if (selectedFilter) {
          this.invoiceSearchForm.patchValue({ filterBy: selectedFilter.Key });
        }
      }
    });
    this.customerContextData$.subscribe((value) => {
      this.customerNumber = value?.customerNumber ? value?.customerNumber : KN_BR_CUSTOMER_CURRENT;
      this.knBrCommonService.getActiveSalse(this.customerNumber);
    });
    this.subscription = this.knBrCommonService.loadActiveSalse$().subscribe((res: any) => {
      let brandValues = [];
      res ? res.forEach(item => brandValues.push(item.value)) : [];
      if (res) {
        this.invoiceSearchForm.patchValue({
          brandList: brandValues.join(','),
        });
        this.state$.subscribe((data:any) => {
          if(data && data.displayPersistedValues) {
            // this.submitForm("persist");
          } else {
            this.submitForm()
          }
        })
      }
    });
  }

  initForm() {
    const persistedData = JSON.parse(sessionStorage.getItem("invoiceSearch"));
    let flagValue = false;
    this.state$.subscribe((data:any) => {
      if(data && data.displayPersistedValues) {
        flagValue = true;
      } else {
        flagValue = false;
      }
    })
    this.invoiceSearchForm = this.fb.group({
      purchaseOrderNumber: (flagValue && persistedData?.purchaseOrderNumber) ? persistedData.purchaseOrderNumber : null,
      documentNo: (flagValue && persistedData?.documentNo) ? persistedData.documentNo : null,
      status: null,
      filterBy: null,
      documentType: '',
      startDate: null,
      endDate: null,
      fromOpenAmount: null,
      toOpenAmount: null,
      fromOriginalAmount: null,
      toOriginalAmount: null,
      currentPage: this.currentPage,
      brandName:null,
      brandList: [],
      pageSize: INVOICE_PAGESIZE,
      jobName: (flagValue && persistedData?.jobName) ? persistedData.jobName : null,
      _startDate: (flagValue && persistedData?.startDate) ? this.knBrCommonService.formatDate(this.knBrDateHelper.getDate(persistedData.startDate)) : null,
      _endDate: (flagValue && persistedData?.endDate) ? this.knBrCommonService.formatDate(this.knBrDateHelper.getDate(persistedData.endDate)) : null
    });
    this.setDateValidators();

    this.state$.subscribe((data:any) => {
      if (data && data.displayPersistedValues && this.retrievalPending) {
        if (persistedData) {
          const sessionStorageCusNo = sessionStorage.getItem("sscustomerNumber");
          if (this.selectedCustomer.customerNumber != sessionStorageCusNo && this.retrievalPending) {
            this.retrievalPending = false;
            this.combinedCallSubscription = zip(this.status$).subscribe((res:any)=> {
              const combinedData = {
                statusData: res[0],
                bresp: res[1],
              }
              const { statusData, bresp } = combinedData;
                if (persistedData.status) {
                  persistedData.status = persistedData.status.filter(eachStatus => this.containsObject(eachStatus, statusData));
                  this.pStatus = persistedData.status;
                }
                if (persistedData.brandName) {
                  persistedData.brandName = persistedData.brandName.filter((eachbrnd) => this.containsObject(eachbrnd, bresp.brand) )
                  this.pBrandName = persistedData.brandName || [];
                }
                if (persistedData.documentType) {
                  persistedData.documentType = persistedData.documentType.filter((eachbrnd) => this.containsObject(eachbrnd, bresp.brand) )
                  this.pBrandName = persistedData.documentType || [];
                }
                this.submitForm("persist")
            })
          } else if(this.retrievalPending) {
            this.retrievalPending = false
            this.pBrandName = persistedData.brandName || [];
            this.pStatus = persistedData.status || [];
            this.pDocumentType = persistedData.documentType || [];
            this.submitForm("newpersist")
          }
        }
      }
    })
  }

  containsObject(obj, list) {
    var i;
    for (i = 0; i < list.length; i++) {
      if (isEqual(list[i], obj)) {
        return true;
      }
    }
    return false;
  }

  submitForm(shouldRetain?) {
    if (this.invoiceSearchForm.valid) {
      this.invoiceSearchForm.patchValue({
        startDate: this.knBrDateHelper.formatDate(this.invoiceSearchForm.controls._startDate.value),
        endDate: this.knBrDateHelper.formatDate(this.invoiceSearchForm.controls._endDate.value)
      })
    }``
    const { ...payload } = this.invoiceSearchForm.getRawValue();
    const updatedPayload = {...payload};
    const updatedSessionStorage = {...payload};
    if (shouldRetain) {
      updatedSessionStorage.status = this.pStatus;
      updatedSessionStorage.brandName = this.pBrandName;
      updatedSessionStorage.documentType = this.pDocumentType;
      updatedPayload.status = (this.pStatus && this.pStatus.length) ? this.pStatus.map(eachStatus => eachStatus.Key).join(",") : '';
      updatedPayload.brandName = (this.pBrandName && this.pBrandName.length) ? this.pBrandName.map(eachbrandName => eachbrandName.value).join(",") : '';
      updatedPayload.documentType = (this.pDocumentType && this.pDocumentType.length) ? this.pDocumentType.map(eachDocumentType => eachDocumentType.Key).join(",") : ''
    } else {
      updatedPayload.status = (payload.status && payload.status.length) ? payload.status.map(eachStatus => eachStatus.Key).join(",") : '';
      updatedPayload.brandName = (payload.brandName && payload.brandName.length) ? payload.brandName.map(eachbrand => eachbrand.value).join(",") : '';
      updatedPayload.documentType = (payload.documentType &&  payload.documentType.length) ? payload.documentType.map(eachDocumentType => eachDocumentType.Key).join(",") : '';

      // if (updatedPayload.status === "all") {
      //   updatedPayload.status = [{Text: 'All',Key: 'all'}];
      // }
    }
    window.sessionStorage.setItem("sscustomerNumber", this.selectedCustomer?.customerNumber)
    window.sessionStorage.setItem("invoiceSearch",JSON.stringify(updatedSessionStorage));
    this.knBrInvoiceService.search(updatedPayload);
    this.knBrInvoiceService.setInvoiceInit(false);
  }

  get filterBySelectedValue(): AbstractControl {
    return this.invoiceSearchForm.get('filterBy');
  }

  changeFilterBy() {
    const invoiceFormControls = this.invoiceSearchForm.controls;
    invoiceFormControls.fromOpenAmount.setValue(null);
    invoiceFormControls.toOpenAmount.setValue(null);
    invoiceFormControls.fromOriginalAmount.setValue(null);
    invoiceFormControls.toOriginalAmount.setValue(null);
    invoiceFormControls.startDate.setValue(null);
    invoiceFormControls.endDate.setValue(null);
    invoiceFormControls.documentType.setValue(null);
  }

  setDateValidators() {
    const startDate = this.invoiceSearchForm.get('_startDate');
    const enddate = this.invoiceSearchForm.get('_endDate');

    startDate.valueChanges.pipe(distinctUntilChanged()).subscribe(fromDate => {
      if (fromDate && enddate.value === null) {
        enddate.setValidators([Validators.required]);
      } else {
        enddate.setValidators(null);
      }
      enddate.updateValueAndValidity();

      if (enddate.value && startDate.value) {
        const { year: y2, month: m2, day: d2 } = enddate.value;
        const e = new Date(y2, m2 - 1, d2);

        const { year: y1, month: m1, day: d1 } = startDate.value;
        const s = new Date(y1, m1 - 1, d1);

        if (s > e) {
          enddate.setValue(null)
        }
      }
    });
    enddate.valueChanges.pipe(distinctUntilChanged()).subscribe(toDate => {
      if (toDate) {
        startDate.setValidators([Validators.required]);
      } else {
        startDate.setValidators(null);
      }
      startDate.updateValueAndValidity();
    });
  }

  resetForm() {
    this.invoiceSearchForm.reset();
    sessionStorage.removeItem('invoiceSearch');
    // this.selectedStatus = 'all';
    this.initForm();
    this.invoiceSearchForm.patchValue({
      brandList: this.brandList.join(','),
    });
    this.knBrCommonService.fromDate = null;
    this.knBrCommonService.toDate = null;
    this.submitForm();
    this.knBrCommonService.clearDateInfo();
  }

  onDateChange(value, param?) {
    this.knBrCommonService.onDateChange(value, param);
  }

  ngOnDestroy() {
    if (this.combinedCallSubscription) {
      this.combinedCallSubscription.unsubscribe();
    }
    this.knBrCommonService.clearDateInfo();
    this.subscription.unsubscribe();
  }
}
