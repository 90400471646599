import { Component, Input } from '@angular/core';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';

@Component({
  selector: 'kn-br-common-address',
  templateUrl: './kn-br-common-address.component.html'
})
export class KnBrCommonAddressComponent {
  @Input() address;
  @Input() isSoldTo;
  @Input() addressLabel;

  constructor(public knbrCommonService: KnBrCommonService) { }

  formatZipcode(value) {
    if (value?.length > 5) {
      return value.replace(/(\d{5})/, "$1-");
    } else {
      return value;
    }
  }

  formatState(state) {
    return this.knbrCommonService.formatState(state);
  }
}
