import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ComponentRef, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalMessageService, GlobalMessageType, OccEndpointsService, RoutingService } from '@spartacus/core';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { Observable, Subscription, zip } from 'rxjs';
import { KnBrCaseUpdateDialogComponent } from 'src/app/kn-br-case-update-dialog/kn-br-case-update-dialog.component';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import { RemoveLeadingZerosPipe } from 'src/app/shared/pipe/kn-br-custom-pipes/remove-leading-zeros.pipe';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import { KnBrQuotesService } from 'src/app/store/kn-br-quotes/kn-br-quotes.service';
import { KNBR_CASE_ATTACHMENT_DOWNLOAD, KNBR_GET_CASE_DETAILS } from 'src/constants/api.endpoints.constant';

@Component({
  selector: 'kn-br-case-detail-header',
  templateUrl: './kn-br-case-detail-header.component.html',
  styleUrls: ['./kn-br-case-detail-header.component.scss'],
  providers: [RemoveLeadingZerosPipe],
})
export class KnBrCaseDetailHeaderComponent implements OnInit, OnDestroy {
  public formGroup: FormGroup;
  currentDateValue = {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
    day: new Date().getDate(),
  };
  todayDate = () => {
    const today = new Date();
    this.currentDateValue = { year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate() };
    return { year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate() };
  };
  maxStartDate = () => this.todayDate();
  customerId: string;
  helperForm: UntypedFormGroup;
  submitted: boolean;
  caseUpdateModalRef: any;
  caseDescriptionText = ''
  showReadMore = true;
  caseDescTextToDisplay;
  caseDetailInfo;
  showSpinner: void | Observable<ComponentRef<any>>;
  combinedSubscription = new Subscription();
addCommentsToolTipText = `Attachments supported: HTML/HTM, Image (JPEG,PNG), Video (MP4,WebM), DOC (Word, Excel, Notepad), PDF`;

  constructor(
    protected fb: UntypedFormBuilder,
    protected knBrQuoteService: KnBrQuotesService,
    protected knBrCommonService: KnBrCommonService,
    protected knBrCustomerContextService: KnBrCustomerContextService,
    protected removeLeadingZerosPipe: RemoveLeadingZerosPipe,
    protected activatedRoute: ActivatedRoute,
    protected http: HttpClient,
    private endPointService: OccEndpointsService,
    protected cdr: ChangeDetectorRef,
    protected launchDialogService: LaunchDialogService,
    protected vcr: ViewContainerRef,
    protected routingService: RoutingService,
    protected globalMessageService: GlobalMessageService,
    protected modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.combinedSubscription.add(this.knBrCustomerContextService.getActiveCustomerNumber().subscribe((response) => {
      this.customerId = response ? response : 'current';
    }));

    this.onSpinnerLoading(true);
    this.combinedSubscription.add(zip(this.activatedRoute.params, this.activatedRoute.queryParams).subscribe((results) => {
      console.log("logging result", results);
      const svcCaseNumber = results[0].caseId;
      const ticketId = results[1].ticketID;
      this.getCaseDetailsInfo(svcCaseNumber, ticketId);
    }))

    this.initForm();
  }

  initForm() {
    this.helperForm = this.fb.group({
      caseOptionToCreate: [null, Validators.required],
      salesOrInvoiceNumber: [null, Validators.required],
    });

    console.log('printing current date', this.currentDateValue);
  }

  getCaseDetailsInfo(svcCaseNumber, ticketId) {
    const url = this.endPointService.buildUrl(KNBR_GET_CASE_DETAILS(this.customerId, svcCaseNumber), {
      queryParams: {
        ticketID: ticketId,
        fields: 'FULL'
      }
    });
    this.combinedSubscription.add(this.http.get(url).subscribe(
      (resData: any) => {
        this.onSpinnerLoading(false);
        console.log('logging the response data', resData);
        this.caseDetailInfo = resData;
        this.caseDescriptionText = this.caseDetailInfo.caseDescription;
        this.caseDescTextToDisplay = this.truncateString(this.caseDescriptionText, 200);
        this.cdr.detectChanges();
      },
      (error) => {
        this.onSpinnerLoading(false);
      }
    ));
  }

  handleCaseDetailUpdateClick() {
    this.caseUpdateModalRef = this.modalService.open(KnBrCaseUpdateDialogComponent);
    this.caseUpdateModalRef.componentInstance.ticketInfo = this.caseDetailInfo;
    this.caseUpdateModalRef.result.then((result) => {
      if (result) {
        console.log('logging the result here', result);
        this.caseDetailInfo = this.getCaseDetailsInfo(this.caseDetailInfo.svcCaseNumber, this.caseDetailInfo.ticketId)
        this.cdr.detectChanges();
      }
    });
  }

  downloadAttachment(fileInfo) {
    const fileData = {...fileInfo}
    delete fileData.timestamp;
    const downloadUrl = this.endPointService.buildUrl(KNBR_CASE_ATTACHMENT_DOWNLOAD(this.customerId), {
      queryParams: fileData
    });
    this.http.get(downloadUrl, { responseType: 'blob' }).subscribe((data: Blob) => {
      const url = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = url;
      link.download = fileData.fileName;

       // Trigger the download
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        link.remove();
      }, 500);
     }, error => {
      this.globalMessageService.add('Something went wrong, please try again later', GlobalMessageType.MSG_TYPE_ERROR);
     })
  }

  onSpinnerLoading(isLoading) {
    if (isLoading) {
      this.showSpinner = this.launchDialogService.launch(LAUNCH_CALLER.PLACE_ORDER_SPINNER, this.vcr);
    } else {
      if (this.showSpinner) {
        this.showSpinner
          .subscribe((component) => {
            this.launchDialogService.clear(LAUNCH_CALLER.PLACE_ORDER_SPINNER);
            component.destroy();
          })
          .unsubscribe();
      }
    }
  }

  submitForm() {
    this.submitted = true;
    console.log('form submission getting called');
  }

  truncateString(str, num) {
    if (str && str.length > num) {
      this.showReadMore = true;
      return str.slice(0, num) + '...';
    } else {
      this.showReadMore = false;
      return str;
    }
  }

  showFullcaseDescText() {
    this.showReadMore = false;
    this.caseDescTextToDisplay = this.caseDescriptionText;
  }

  resetTheForm() {
    this.helperForm.reset();
    this.submitted = false;
  }

  ngOnDestroy(): void {
    this.helperForm.reset();
    this.combinedSubscription.unsubscribe();
  }
}
