import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { KnBrCustomRoutes } from './config/kn-br-custom-route.config';

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot([], {
      anchorScrolling: 'enabled',
      relativeLinkResolution: 'corrected',
      initialNavigation: 'enabledNonBlocking',
      scrollPositionRestoration: 'top',
      scrollOffset: [0, 0],
    }),
    RouterModule.forChild(KnBrCustomRoutes),
  ],
})
export class AppRoutingModule {}
