import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, HostListener, Inject, OnInit } from '@angular/core';
import { WindowRef } from '@spartacus/core';
import { Observable } from 'rxjs';
import { knBrFactSheet } from 'src/app/models/kn-br-fact-sheet.model';
import { KnBrFactSheetService } from 'src/app/store/kn-br-fact-sheet/kn-br-fact-sheet.service';

@Component({
  selector: 'knbr-kn-br-fact-sheet-material',
  templateUrl: './kn-br-fact-sheet-material.component.html',
  styleUrls: ['./kn-br-fact-sheet-material.component.scss'],
})
export class KnBrFactSheetMaterialComponent implements OnInit {
  factSheetData$: Observable<any> = this.factSheetService.factSheetData$;
  paginate$: Observable<any> = this.factSheetService.paginate$;
  isLoader$: Observable<boolean> = this.factSheetService.loading$;
  brandName$: Observable<any> = this.factSheetService.brandName$;
  currentPage: any;
  brandId;

  constructor(
    protected factSheetService: KnBrFactSheetService,
    protected ref: ChangeDetectorRef,
    private winRef: WindowRef,
    @Inject(DOCUMENT) document) {
    }

  ngOnInit(): void {}

  loadNextPage(currentPage: number) {
    const req: knBrFactSheet = {
      param: this.winRef.localStorage.getItem('brandId'),
      currentPage: currentPage + 1,
      categories: JSON.parse(this.winRef.localStorage.getItem('selectedCategories'))
    };
    this.factSheetService.getFactSheetData(req);
  };

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
     if (window.scrollY > 900) {
       let element = document.getElementById('navbar');
       element.classList.add('sticky');
     } else {
      let element = document.getElementById('navbar');
       if(element) element.classList.remove('sticky');
     }
  }
}
