import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KnBrServiceTicketsComponent } from '../kn-br-service-tickets/kn-br-service-tickets.component';
import { RouterModule } from '@angular/router';
import { AuthGuard, CmsConfig, ConfigModule, I18nModule, RoutingConfig } from '@spartacus/core';
import { CmsPageGuard, FormErrorsModule, IconModule, PageLayoutComponent, SpinnerModule } from '@spartacus/storefront';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { FileUploadModule } from '@iplab/ngx-file-upload';

@NgModule({
  declarations: [KnBrServiceTicketsComponent],
  imports: [
    CommonModule,
    I18nModule,
    ReactiveFormsModule,
    FormsModule,
    FormErrorsModule,
    NgSelectModule,
    FileUploadModule,
    SpinnerModule,
    RouterModule.forChild([
      {
        data: { pageLabel: '/service-tickets', cxRoute: 'serviceTickets' },
        path: null,
        canActivate: [AuthGuard, CmsPageGuard],
        component: PageLayoutComponent,
      },
    ]),
    ConfigModule.withConfig({
      routing: {
        routes: {
          serviceTickets: {
            paths: ['service-tickets'],
          },
        },
      },
    } as RoutingConfig),
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrServiceTicketsComponent: {
          component: KnBrServiceTicketsComponent,
        },
      },
    } as CmsConfig),
  ],
  exports: [KnBrServiceTicketsComponent],
})
export class KnBrServiceTicketsModule {}
