import { KnBrQuotesComments } from '../../models/kn-br-quote.model';

export const KN_BR_COMMENT_QUOTES_FEATURE = 'kn-br-quotes-comments';

export interface KnBrQuotesCommentsState {
  quoteComments?: KnBrQuotesComments[];
  errors?: Error;
  quoteComment?: KnBrQuotesComments;
  entryNumber?: number;
}

export interface StateWithQuoteComments {
  [KN_BR_COMMENT_QUOTES_FEATURE]: KnBrQuotesCommentsState;
}
