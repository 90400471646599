import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CART_NORMALIZER } from '@spartacus/cart/base/root';
import { ConfigModule, I18nModule } from '@spartacus/core';

import { KnBrCartNormalizer } from '../converter/kn-br-cart.converter';
import { KnBrDraftOrderCreateModule } from './kn-br-draft-order-create/kn-br-draft-order-create.module';
import { KnBrDraftOrderDetailModule } from './kn-br-draft-order-detail/kn-br-draft-order-detail.module';
import { knBrDraftOrderEndpointsConfig } from './kn-br-draft-order-endpoints.config';
import { KnBrDraftOrderListModule } from './kn-br-draft-order-list/kn-br-draft-order-list.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    I18nModule,
    KnBrDraftOrderListModule,
    KnBrDraftOrderCreateModule,
    KnBrDraftOrderDetailModule,
    ConfigModule.withConfig(knBrDraftOrderEndpointsConfig),
  ],
  providers: [
    {
      provide: CART_NORMALIZER,
      useClass: KnBrCartNormalizer,
      multi: true,
    },
  ],
})
export class KnBrDraftOrderModule {}
