import { NgbCalendar, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { KnBrDateHelper } from 'src/app/shared/kn-br-date.helper';
import { KnBrAddRequestEntry } from './../../../models/kn-br-order.model';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { RoutingService } from '@spartacus/core';
import { ICON_TYPE } from '@spartacus/storefront';
import * as _ from 'lodash';
import { Observable, Subscription } from 'rxjs';

import { CustomerData } from '../../../models/customer.model';
import { KnBrQuote } from '../../../models/kn-br-quote.model';
import { KnBrCustomerContextService } from '../../../store/kn-br-customer-context/kn-br-customer-context.service';
import { KnBrQuoteContextService } from '../../../store/kn-br-quote-context/kn-br-quote-context.service';
import { KnBrQuoteEntryService } from '../../../store/kn-br-quote-entry/kn-br-quote-entry.service';
import { KnBrQuotesService } from '../../../store/kn-br-quotes/kn-br-quotes.service';

@Component({
  selector: 'kn-br-select-quote',
  templateUrl: './kn-br-select-quote.component.html',
  styleUrls: ['./kn-br-select-quote.component.scss'],
})
export class KnBrSelectQuoteComponent implements OnInit, OnDestroy {
  productCode: string;
  quantity: number;
  iconTypes = ICON_TYPE;
  quoteCode: string;
  quoteList$: Observable<any> = this.knBrQuotesService.list$;
  quoteList: any;
  customerContextData$: Observable<CustomerData> = this.knBrCustomerContextService.get$;
  subscription = new Subscription();
  productList: KnBrAddRequestEntry[] = [];
  constructor(
    protected modalService: NgbModal,
    protected routingService: RoutingService,
    protected knBrQuotesService: KnBrQuotesService,
    protected knBrQuoteContextService: KnBrQuoteContextService,
    protected knBrQuoteEntryService: KnBrQuoteEntryService,
    protected knBrCustomerContextService: KnBrCustomerContextService,
    protected knBrDateHelper: KnBrDateHelper,
    protected ngbCalendar: NgbCalendar
  ) {
    this.subscription.add(
      this.customerContextData$.subscribe((value) => {
        this.knBrQuotesService.reset();
        if (value.customerNumber) {
          this.knBrQuotesService.search({ accountNumber: value.customerNumber, pageSize: 1000 } as KnBrQuote);
        }
      })
    );
  }
  ngOnInit(): void {
    this.quoteList = null;
    this.subscription.add(
      this.quoteList$.subscribe((quoteList) => {
        this.quoteList = _.filter(quoteList, ['editable', true]);
      })
    );
  }
  goToCreateNewQuote() {
    if (this.productList && this.productList.length > 0) {
      const quoteCreateReq = {
        headerNotes: '',
        purchaseOrderNumber: '',
        quoteValidTo: this.knBrDateHelper.formatDate(this.ngbCalendar.getNext(this.ngbCalendar.getToday(), 'd', 30)),
        rdd: null,
        shipComplete: false,
        jobName: '',
        referenceQuoteId: null,
        orderToolsProducts: this.productList,
      };
      this.knBrQuotesService.create(quoteCreateReq);
    } else {
      this.routingService.go({ cxRoute: 'createQuote', params: { p: this.productCode, q: this.quantity } });
    }
    this.dismissModal();
  }
  saveQuote() {
    if (this.productList && this.productList.length > 0) {
      this.knBrQuoteContextService.set(this.quoteCode);
      const addEntriesReq = { request: this.productList, quoteId: this.quoteCode };
      this.knBrQuoteEntryService.addEntries(addEntriesReq);
      this.dismissModal();
    } else {
      const quoteEntry = {
        quoteId: this.quoteCode,
        code: this.productCode,
        quantity: this.quantity,
      };
      this.knBrQuoteEntryService.add(quoteEntry);
      this.dismissModal();
    }
  }

  dismissModal(reason?: any): void {
    this.modalService.dismissAll(reason);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
