import { Component, OnInit, Optional, OnDestroy } from '@angular/core';
import {
  CmsSearchBoxComponent,
  WindowRef,
  RoutingService,
  PageType,
  GlobalMessageService,
  GlobalMessageType,
} from '@spartacus/core';
import { SearchBoxProductSelectedEvent } from '@spartacus/storefront';
import { SearchBoxComponentService, CmsComponentData, SearchBoxComponent } from '@spartacus/storefront';
import { filter, map } from 'rxjs/operators';
import { KnBrCustomerContextService } from '../../../store/kn-br-customer-context/kn-br-customer-context.service';
import { CustomerData } from '../../../models/customer.model';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';

@Component({
  selector: 'cx-searchbox',
  templateUrl: './kn-br-search-box.component.html',
  styleUrls: ['./kn-br-search-box.component.scss'],
})
export class KnBrSearchBoxComponent extends SearchBoxComponent implements OnInit, OnDestroy {
  customerContext: CustomerData;
  isactive = false;
  isOpen = false;
  constructor(
    protected searchBoxComponentService: SearchBoxComponentService,
    private knBrCustomerContextService: KnBrCustomerContextService,
    private globalMessageService: GlobalMessageService,
    @Optional()
    protected componentData: CmsComponentData<CmsSearchBoxComponent>,
    protected winRef: WindowRef,
    protected routingService: RoutingService,
    private knBrCommonService: KnBrCommonService,
  ) {
    super(searchBoxComponentService, componentData, winRef, routingService);
  }

  ngOnInit(): void {
    // super.ngOnInit();
    this.subscription = this.routingService
      .getRouterState()
      .pipe(filter((data) => !data.nextState))
      .subscribe((data) => {
        data.state.params && data.state.params.query ? this.knBrCommonService.setSpinner(true) : this.knBrCommonService.setSpinner(false);
        if (
          !(data.state.context?.id === 'search' && data.state.context?.type === PageType.CONTENT_PAGE) ||
          (data.state.params && data.state.params.query && data.state.params && (data.state.params.query === '*' || data.state.params.query === 'active-brands'))
        ) {
          this.chosenWord = '';
        } else {
          this.chosenWord = data.state.params && data.state.params.query ? data.state.params.query : '';
        }
      });
    this.knBrCustomerContextService.get$.subscribe((response) => {
      this.customerContext = response;
    });
  }

  checkContext(e: Event) {
    if (this.customerContext && this.customerContext.customerNumber) {
      return true;
    } else {
      this.routingService.go({ cxRoute: 'switchAccount' });
      this.globalMessageService.add({ key: 'customer.context.required' }, GlobalMessageType.MSG_TYPE_INFO);
      return false;
    }
  }

  /**
   * Opens the type-ahead searchBox
   */
  open(): void {
    this.isactive = true;
    this.isOpen = true;
    this.searchBoxComponentService.toggleBodyClass('searchbox-is-active', true);
  }

  /**
   * Closes the type-ahead searchBox.
   */
  closeme(): void {
    this.isOpen = false;
  }

  clearIcon() {
    this.routingService.go({
      cxRoute: 'search',
      params: { query: '' },
    });
  }

  dispatchProductEvent(eventData: SearchBoxProductSelectedEvent): void {
    this.searchBoxComponentService.dispatchProductSelectedEvent(eventData);
    this.searchBoxComponentService.toggleBodyClass('has-searchbox-results', false);
  }
  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
