import { Component, OnInit } from '@angular/core';
import { CmsBannerComponentMedia, CmsComponent, CmsNavigationComponent, OccConfig } from '@spartacus/core';
import { CategoryNavigationComponent, CmsComponentData, ICON_TYPE } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { RoutingService } from '@spartacus/core';

export interface CmsImageMapComponent extends CmsComponent {
  title?: string;
  container?: string;
  imageMapHTML?: string;
  media?: CmsBannerComponentMedia
}

@Component({
  selector: 'knbr-kn-br-spotlight',
  templateUrl: './kn-br-spotlight.component.html',
  styleUrls: ['./kn-br-spotlight.component.scss']
})
export class KnBrSpotlightComponent {
  data$: Observable<CmsImageMapComponent> = this.componentData.data$;
  constructor( private componentData: CmsComponentData<CmsImageMapComponent>, private config: OccConfig, private routingService: RoutingService ) { }

  getImgUrl(url: string) {
    return `${this.config.backend.occ.baseUrl}${url}`;
  }

  navSpotlightPlp = function(url: string) {
    if (url) {
      this.routingService.goByUrl(url);
    }
  }
}
