import { KnBrInvoiceAccountSummaryObject } from 'src/app/models/kn-br-invoice-account-summary.model';
import { B2bDocuments, KnBrInvoiceSearchForm } from 'src/app/models/kn-br-invoice.model';

export const KN_BR_INVOICE_FEATURE = 'kn-br-invoice';

export interface KnBrInvoiceState {
  b2bDocuments?: B2bDocuments[];
  pagination?: any;
  sorts?: any;
  error?: Error;
  searchRequest?: KnBrInvoiceSearchForm;
  isLoading?: boolean;
  isSearchDisabled: boolean;
  accountSummary?: KnBrInvoiceAccountSummaryObject;
  duesAmount?: any
}

export interface StateWithKnBrInvoice {
  [KN_BR_INVOICE_FEATURE]: KnBrInvoiceState;
}
