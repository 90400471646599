import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { CmsService, ContentSlotData } from '@spartacus/core';
import { ActiveCartService } from '@spartacus/cart/base/core';
import { Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { KnBrCommonService } from '../../../services/kn-br-common.service';

@Component({
  selector: 'knbr-kn-br-draft-order-items',
  templateUrl: './kn-br-draft-order-items.component.html',
  styleUrls: ['./kn-br-draft-order-items.component.scss'],
})
export class KnBrDraftOrderItemsComponent implements OnInit, OnDestroy {
  entries$: Observable<any> = this.activeService.getActive().pipe(
    filter((order) => !order || (order && !!order.entries)),
    map((order) => order.entries)
  );
  allChecked = false;

  selectedProductSubscription = new Subscription();
  selectedProductCodes: number[];
  components: ContentSlotData;
  constructor(
    protected activeService: ActiveCartService,
    protected knBrCommonService: KnBrCommonService,
    protected ref: ChangeDetectorRef,
    protected cmsService: CmsService
  ) {
    this.selectedProductSubscription = this.knBrCommonService.loadCartEntry().subscribe((selectedProductCodes) => {
      if (selectedProductCodes) {
        this.selectedProductCodes = selectedProductCodes;
        let entryNumbers = [];
        this.entries$
          .subscribe((entries) => {
            entryNumbers = entries.map((entry) => entry.entryNumber);
          })
          .unsubscribe();
        this.allChecked = entryNumbers.length === this.selectedProductCodes.length;
        this.ref.markForCheck();
      }
    });
  }

  ngOnInit(): void {}

  selectAll(event) {
    let entryNumbers = [];
    if (event.currentTarget.checked) {
      this.entries$
        .subscribe((entries) => {
          entryNumbers = entries.map((entry) => entry.entryNumber);
        })
        .unsubscribe();
    }
    this.knBrCommonService.setCartEntry(entryNumbers);
  }

  isDisabled() {
    let editable = true;
    this.cmsService
      .getContentSlot('KnBrDraftOrderItemsSlot')
      .subscribe((value) => (this.components = value))
      .unsubscribe();
    this.activeService
      .getActive()
      .subscribe((value: any) => (editable = value.editable))
      .unsubscribe();
    if (
      (this.components &&
        this.components.components &&
        this.components.components.length &&
        this.components.components[0].flexType === 'KnBrDraftOrderItemsComponentReadOnly') ||
      !editable
    ) {
      return true;
    }

    return false;
  }

  ngOnDestroy(): void {
    if (this.selectedProductSubscription) {
      this.selectedProductSubscription.unsubscribe();
    }
  }
}
