import { CustomerData } from '../../models/customer.model';

export const KN_BR_CONTEXT_CUSTOMER_FEATURE = 'kn-br-customer-context';

export interface KnBrCustomerContextState {
  customerContext?: CustomerData;
}

export interface StateWithCustomerContext {
  [KN_BR_CONTEXT_CUSTOMER_FEATURE]: KnBrCustomerContextState;
}
