import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { I18nModule } from '@spartacus/core';
import { IconModule, KeyboardFocusModule } from '@spartacus/storefront';

import { KnBrActiveFacetComponent } from './kn-br-active-facet.component';

@NgModule({
  declarations: [KnBrActiveFacetComponent],
  imports: [CommonModule, RouterModule, I18nModule, IconModule, KeyboardFocusModule],
  exports: [KnBrActiveFacetComponent],
})
export class KnBrActiveFacetModule {}
