import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActiveCartService } from '@spartacus/cart/base/core';
import { ContentSlotData } from '@spartacus/core';
import { Subscription } from 'rxjs';

import { KnBrCartContextService } from '../../../store/kn-br-cart-context/kn-br-cart-context.service';
import { KnBrQuoteContextService } from '../../../store/kn-br-quote-context/kn-br-quote-context.service';
import { KnBrQuotesService } from '../../../store/kn-br-quotes/kn-br-quotes.service';
import { KnBrDraftOrder } from './../../../models/kn-br-draft-order.model';
import { KnBrProductQuickEntryPopupComponent } from './kn-br-product-quick-entry-popup/kn-br-product-quick-entry-popup.component';

@Component({
  selector: 'kn-br-product-quick-entry',
  templateUrl: './kn-br-product-quick-entry.component.html',
  styleUrls: ['./kn-br-product-quick-entry.component.scss'],
})
export class KnBrProductQuickEntryComponent implements OnInit {
  modalRef: any;
  subscriptions = new Subscription();
  isQuoteContext: boolean;
  isCartContext: boolean;
  components: ContentSlotData;
  constructor(
    protected modalService: NgbModal,
    private activeCartService: ActiveCartService,
    private knBrQuoteContextService: KnBrQuoteContextService,
    private knBrCartContextService: KnBrCartContextService,
    private knBrQuotesService: KnBrQuotesService
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.knBrQuoteContextService.get$.subscribe((value) => {
        if (value) {
          this.isQuoteContext = true;
          this.isCartContext = false;
        }
      })
    );

    this.subscriptions.add(
      this.knBrCartContextService.get$.subscribe((value) => {
        if (value) {
          this.isCartContext = true;
          this.isQuoteContext = false;
        }
      })
    );
  }

  open() {
    this.modalRef = this.modalService.open(KnBrProductQuickEntryPopupComponent, {
      centered: true,
      size: 'md',
    });
  }

  isDisabled() {
    let editable = true;
    if (this.isQuoteContext)
      this.knBrQuotesService.get$.subscribe((value) => (editable = value.editable)).unsubscribe();
    else
      this.activeCartService
        .getActive()
        .subscribe((value: KnBrDraftOrder) => (editable = value.editable))
        .unsubscribe();
    return editable;
  }
}
