import { NgModule } from '@angular/core';
import { CheckoutRootModule, CHECKOUT_FEATURE } from '@spartacus/checkout/base/root';
import { provideConfig } from '@spartacus/core';

@NgModule({
  imports: [CheckoutRootModule],
  providers: [
    provideConfig({
      featureModules: {
        [CHECKOUT_FEATURE]: {
          module: () => import('@spartacus/checkout/base').then((m) => m.CheckoutModule),
        },
      },
    }),
  ],
})
export class CheckoutFeatureModule {}
