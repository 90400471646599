<div class="whats-new">
  <h2>{{ name }}</h2>
  <div class="sub-heading">{{ title }}</div>
</div>

<div class="custom-carousel">
  <div class="carousel-container">
    <div class="carousel-slide" *ngFor="let slide of slides; let i = index" [ngClass]="{ active: isActiveSlide(i) }">
      <div class="carousel-items col-md-12">
        <div class="col-md-4" *ngFor="let item of slide">
          <a [attr.href] = "item.urlLink ? '/' + baseSite + item.urlLink : null" [target]="item.external === 'true' ? '_blank' : '_self'" >
            <div class="carousel-item card">
              <cx-media [container]="item.media" class="img-styles" loading="lazy"/>
              <div class="card-body">
                <strong class="card-title">{{ item.name }}</strong>
                <div class="card-content" [innerHTML]="item.imageMapHTML"></div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>

  <button class="carousel-control prev" (click)="prevSlide()" [ngClass]="{disable: isPrevDisabled()}" [disabled]="isPrevDisabled()">
    <cx-icon class="cx-icon fas fa-angle-left flip-at-rtl"></cx-icon>
  </button>
  <button class="carousel-control next" (click)="nextSlide()" [ngClass]="{disable: isNextDisabled()}" [disabled]="isNextDisabled()">
    <cx-icon class="cx-icon fas fa-angle-right flip-at-rtl"></cx-icon>
  </button>

  <div class="carousel-indicators">
    <span
      *ngFor="let slide of slides; let i = index"
      class="indicator"
      [ngClass]="{ active: isActiveSlide(i) }"
      (click)="activeSlideIndex = i"
    ></span>
  </div>
</div>
