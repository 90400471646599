import { Injectable } from '@angular/core';
import { Converter, Occ } from '@spartacus/core';
import { environment } from 'src/environments/environment';

import { KnBrDraftOrder } from '../models/kn-br-draft-order.model';

@Injectable()
export class KnBrCartNormalizer implements Converter<Occ.Cart, KnBrDraftOrder> {
  convert(source: Occ.Cart, target: KnBrDraftOrder): KnBrDraftOrder {
    if (target === undefined) {
      target = { ...(source as any) };
    }

    if (target && target.user.uid.toLowerCase().includes((environment.BASE_SITE[0] + '|').toLowerCase())) {
      target.user.uid = target.user.uid.split('|')[1];
    }

    return target;
  }
}
