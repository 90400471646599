<div class="col-lg-12 p-0 return-order" *ngIf="orderReturnListResult$ | async as orderReturnListResult">
  <ng-container *ngIf="sorts$ | async as sorts">
    <div class="row d-flex">
      <div class="col-sm-6 col-md-6 col-lg-7 d-flex align-items-center" *ngIf="paginate$ | async as paginate">
        <kn-br-browsing [paginate]="paginate" [recordsShown]="orderReturnListResult.length"
          recordType="{{ 'label.returnorder' | cxTranslate }}">
        </kn-br-browsing>
      </div>
      <div class="col-sm-6 p-sm-4 col-md-6 col-lg-5 d-flex justify-content-end">
        <div class="mt-2 pt-1 sortBy mr-2">Sort By</div>
        <cx-sorting class="w-40" [sortOptions]="sorts" [sortLabels]="getSortLabels() | async"
          (sortListEvent)="changeSortCode($event)" [selectedOption]="sortType"
          placeholder="{{ 'returnRequestList.sortByMostRecent' | cxTranslate }}"></cx-sorting>
          <button type="button" [disabled]="downloadProgress" class="w-40 btn btn-primary ml-3" (click)="downloadReturns('orderReturns', 'Returns', true, 'Return_Export')"> {{ 'invoiceResult.download' | cxTranslate }} &nbsp;<i class="fas fa-download pr-2" aria-hidden="true"></i></button>
      </div>
    </div>
  </ng-container>
  <div class="row counter-wrapper"
    *ngIf="orderReturnListResult && orderReturnListResult.length ">
    <div class="customersearch-list-table float-left col-lg-12">
      <div
        class="customersearch-list-titles border-top float-left w-100 p-3 d-none d-sm-none d-lg-block">
        <div class="col-md-2 padding-res-0 float-left font-text-600 pl-0 font-sm-size">
          {{ 'orderReturnResult.header.rma' | cxTranslate }}
        </div>
        <div class="col-md-2 padding-res-0 float-left font-text-600 pl-0 font-sm-size">
          {{ 'orderReturnResult.header.accountNumber' | cxTranslate }}
        </div>
        <div class="col-md-2 padding-res-0 float-left font-text-600 pl-0 font-sm-size">
          {{ 'orderReturnResult.header.accountName' | cxTranslate }}
        </div>
        <div class="col-md-2 padding-res-0 float-left font-text-600 pl-0 font-sm-size">
          {{ 'orderReturnResult.header.createdOn' | cxTranslate }}
        </div>
        <div class="col-md-2 float-left font-text-600 pl-0 font-sm-size">
          {{ 'orderReturnResult.header.purchaseOrderNumber' | cxTranslate }}
        </div>
        <div class="col-md-2 float-left font-text-600 pl-0 font-sm-size">
          {{ 'orderReturnResult.header.status' | cxTranslate }}
        </div>
        <!-- <div class="col-md-1 float-left font-text-600 pl-0 font-sm-size">
          {{ 'orderReturnResult.header.netValue' | cxTranslate }}
        </div> -->
      </div>

      <div class="bg-white customersearch-list-titles table-content border-top float-left w-100 p-2 font-weight-normal d-flex align-items-center
        "*ngFor="let order of orderReturnListResult" (click)="redirectToDetail(order)">
        <div class="col-md-2 text-align-center title-wrap pl-0 font-sm-size color-font">
          <span class="res-titles "> {{ 'orderReturnResult.header.rma' | cxTranslate }}:</span>
          <a class="cx-link"> {{ order.rma | removeLeadingZeros }}</a>
        </div>
        <div class="col-md-2 text-align-center title-wrap pl-0 font-sm-size color-font">
          <span class="res-titles"> {{ 'orderReturnResult.header.accountNumber' | cxTranslate }}:</span>
          {{ order.accountNumber | removeLeadingZeros }}
        </div>
        <div class="col-md-2 float-left title-wrap pl-0 font-sm-size color-font">
          <span class="res-titles"> {{ 'orderReturnResult.header.accountName' | cxTranslate }}:</span>
          {{ order.accountName }}
        </div>
        <div class="col-md-2 float-left title-wrap pl-0 font-sm-size color-font">
          <span class="res-titles">{{ 'orderReturnResult.header.createdOn' | cxTranslate }}:</span>{{
          order.returnRequestDate }}
        </div>
        <div class="col-md-2 float-left title-wrap pl-0 font-sm-size color-font">
          <span class="res-titles"> {{ 'orderReturnResult.header.purchaseOrderNumber' | cxTranslate }}:</span>
          {{ order.purchaseOrderNumber }}
        </div>
        <div class="col-md-2 float-left title-wrap pl-0 font-sm-size color-font">
          <span class="res-titles"> {{ 'orderReturnResult.header.status' | cxTranslate }}:</span>
          {{ order.statusDisplay }}
        </div>
        <!-- <div class="col-md-1 float-left title-wrap pl-0 font-sm-size color-font">
          <span class="res-titles"> {{ 'orderReturnResult.header.netValue' | cxTranslate }}:</span>
          {{ order.totalPrice.formattedValue }}
        </div> -->
      </div>
    </div>
    <div class="col-lg-12 d-flex">
      <ng-container *ngIf="paginate$ | async as paginate">
        <div class="col-lg-6 px-0 mt-3" *ngIf="paginate$ | async as paginate">
          <kn-br-browsing [paginate]="paginate" [recordsShown]="orderReturnListResult.length" recordType="{{ 'label.returnorder' | cxTranslate }}"></kn-br-browsing>
        </div>
        <div class="col-lg-6 w-100 mt-3">
          <div *ngIf="paginate.currentPage < paginate.totalPages - 1 && !(isLoader$ | async)"
            (click)="loadMore(paginate.currentPage)" class="btn btn-loadmore mt-0 mr-3">
            {{ 'orderReturnResult.showMore' | cxTranslate }}
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="alert-info p-3 rounded mt-3"
    *ngIf="orderReturnListResult && orderReturnListResult.length < 1 && !(isLoader$ | async)">
    {{ 'orderReturnResult.nodataMsg' | cxTranslate }}
  </div>
</div>
