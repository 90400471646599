import { KnBrDirectiveModule } from 'src/app/shared/directive/kn-br-directive.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { KnBrAddProductsComponent } from './../kn-br-add-products/kn-br-add-products.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbAccordionModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { I18nModule, ConfigModule, CmsConfig, UrlModule } from '@spartacus/core';
import { MediaModule, SpinnerModule } from '@spartacus/storefront';
import { KnBrOrderDetailsItemsComponent } from './kn-br-order-details-items.component';
import { KnBrEditOrderItemComponent } from './kn-br-edit-order-item/kn-br-edit-order-item.component';

@NgModule({
  declarations: [KnBrOrderDetailsItemsComponent, KnBrAddProductsComponent, KnBrEditOrderItemComponent],
  imports: [
    CommonModule,
    SpinnerModule,
    NgbTooltipModule,
    RouterModule,
    UrlModule,
    MediaModule,
    I18nModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    KnBrDirectiveModule,
    NgbAccordionModule,
    ConfigModule.withConfig({
      cmsComponents: {
        AccountOrderDetailsItemsComponent: {
          component: KnBrOrderDetailsItemsComponent,
        },
      },
    } as CmsConfig),
  ],
  exports: [KnBrEditOrderItemComponent, KnBrAddProductsComponent],
})
export class KnBrOrderDetailsItemsModule {}
