import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CartActions, MultiCartState } from '@spartacus/cart/base/core';
import { map } from 'rxjs/operators';

import { KnBrQuoteContextService } from '../kn-br-quote-context/kn-br-quote-context.service';
import {
  KnBrCartAddUpdateContext,
  KnBrCartContextActions,
  KnBrCartContextActionTypes,
  KnBrCartLoadSuccessContext,
  KnBrCartRemoveContext,
  KnBrCartResetContext,
} from './kn-br-cart-context.actions';
import { KnBrCartContextAdapter } from './kn-br-cart-context.adapter';

@Injectable()
export class KnBrCartContextEffects {
  @Effect()
  loadCartContext$ = this.actions$.pipe(
    ofType(KnBrCartContextActionTypes.KnBrCartContextLoadAction),
    map((x) => {
      const context = this.knBrCartContextAdapter.get();
      return new KnBrCartLoadSuccessContext(context);
    })
  );
  @Effect({ dispatch: false })
  addUpdateCartContext$ = this.actions$.pipe(
    ofType(KnBrCartContextActionTypes.KnBrCartContextAddUpdateAction),
    map((action: KnBrCartAddUpdateContext) => action.payload),
    map((cartCode: string) => {
      this.knBrQuoteContextService.delete();
      this.store.dispatch(new CartActions.SetActiveCartId(cartCode));
      this.knBrCartContextAdapter.set(cartCode);
    })
  );
  @Effect({ dispatch: false })
  removeCartContext$ = this.actions$.pipe(
    ofType(KnBrCartContextActionTypes.KnBrCartContextRemoveAction),
    map((action: KnBrCartRemoveContext) => action.payload),
    map((cartCode: any) => {
      this.store.dispatch(new CartActions.ClearCartState());
      this.knBrCartContextAdapter.remove();
    })
  );
  @Effect({ dispatch: false })
  resetCartContext$ = this.actions$.pipe(
    ofType(KnBrCartContextActionTypes.KnBrCartContextResetAction),
    map((action: KnBrCartResetContext) => action.payload),
    map((cartCode: any) => {
      this.knBrCartContextAdapter.remove();
    })
  );

  constructor(
    private actions$: Actions<KnBrCartContextActions>,
    private knBrCartContextAdapter: KnBrCartContextAdapter,
    private store: Store<MultiCartState>,
    private knBrQuoteContextService: KnBrQuoteContextService
  ) {}
}
