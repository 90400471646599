<ng-container *ngIf="facetList$ | async as facetList" class="">
  <div class="float-left w-100">
    <h4 class="filter-title color-knbr-primary p-3 text-white m-0" *ngIf="facetList?.activeFacets?.length > 0">
      {{ 'productList.appliedFilter' | cxTranslate }}
    </h4>

    <a class="color-light-bg color-font font-size p-2 rounded mr-2 mt-2 mb-2 float-left"
      *ngFor="let facet of facetList?.activeFacets" routerLink="./" [queryParams]="getLinkParams(facet)"
      [cxFocus]="getFocusKey(facetList, facet)" (click)="linkClick()">

      <span>
        {{
        facet.facetValueName === 'true' || facet.facetValueName === 'false'
        ? facet.facetValueName === 'true'
        ? 'Yes'
        : 'No'
        : facet.facetValueName
        }}
        <cx-icon aria-hidden="true" [type]="closeIcon" class="applied-icon d-inline ml-2"></cx-icon></span>
    </a>
    <a *ngIf="facetList?.activeFacets?.length > 0"
      class="font-size p-2 mr-2 mt-2 mb-2 float-left border-rounded" routerLink="./"
      [queryParams]="removeFacets(facetList?.activeFacets)" (click)="linkClick()">
      <span>
        Clear All
        <cx-icon aria-hidden="true" [type]="closeIcon" class="applied-icon d-inline ml-2"></cx-icon>
      </span>
    </a>
  </div>
</ng-container>
