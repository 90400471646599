export const WIDGET_PAGESIZE = 5;
export const INVOICE_PAGESIZE = 50;

export const CAST_TYPES_BACKUP = {
  ZSC_CT: 'Complaint',
  ZSC_IRI: 'Invoice Related Issue',
  ZSC_PD2: 'Product Defect',
  ZSC_TI2: 'Transportation Issue',
  ZSC_WQD2: 'Wrong Quantity',
  ZSC_WPD2: 'Wrong Product Delivered',
  ZSC_PD2_1: 'Product Damage',
};

export const caseDetailMockData = {
  attachments: [
    {
      fileName: 'sampleImage22.png',
      fileReferenceId: 'cd21bd65-0019-11ef-9956-cf8552c90ad8',
      timestamp: '2024-05-20T11:07:37+0000',
    },
    {
      fileName: 'SampleImage.png',
      fileReferenceId: 'cd11bd65-0719-11ef-8f56-cf8552c90ad9',
      timestamp: '2024-05-20T11:07:37+0000',
    },
    {
      fileName: 'TestImage22.png',
      fileReferenceId: 'cd21bd65-0d19-11ef-9956-cf8552c90ad8',
      timestamp: '2024-05-10T06:30:41+0000',
    },
    {
      fileName: 'TestImage.png',
      fileReferenceId: 'cd11bd65-0d19-11ef-8f56-cf8552c90ad9',
      timestamp: '2024-05-10T06:30:41+0000',
    },
    {
      fileName: 'image2.png',
      fileReferenceId: 'cd21bd65-0d19-11ef-9f56-cf8552c90ad8',
      timestamp: '2024-05-10T06:26:49+0000',
    },
    {
      fileName: 'image1.jpg',
      fileReferenceId: 'cd11bd65-0d19-11ef-9f56-cf8552c90ad9',
      timestamp: '2024-05-10T06:26:49+0000',
    },
    {
      fileName: 'HeaderImage.png',
      fileReferenceId: 'ad21bd65-0d19-11ef-9f56-cf8552c90ad8',
      timestamp: '2024-05-03T09:02:23+0000',
    },
  ],
  caseDescription: 'Case Description by PA',
  caseType: 'Product Defect',
  createdOn: '05/03/2024',
  dateOfIncident: '05/14/2024',
  defectDescription: 'Not a great Product.',
  jobName: 'Prathamesh Aras',
  lastUpdated: '05/22/2024',
  orderNumber: '2450019',
  products: ['339365 - Case Creation Test Product - 2 Qty'],
  status: 'New',
  svcCaseNumber: '193',
  ticketEvents: [
    {
      attachments: [
        {
          fileName: 'SampleImage.png',
          fileReferenceId: 'cd11bd65-0719-11ef-8f56-cf8552c90ad9',
          timestamp: '2024-05-20T11:07:37+0000',
        },
        {
          fileName: 'sampleImage22.png',
          fileReferenceId: 'cd21bd65-0019-11ef-9956-cf8552c90ad8',
          timestamp: '2024-05-20T11:07:37+0000',
        },
      ],
      author: 'Test customer 1',
      code: '00000334',
      startDateTime: 'May 20, 2024 16:37 PM',
      text: 'Comment by Sharma Sumit on 20th May',
    },
    {
      attachments: [
        {
          fileName: 'TestImage.png',
          fileReferenceId: 'cd11bd65-0d19-11ef-8f56-cf8552c90ad9',
          timestamp: '2024-05-10T06:30:41+0000',
        },
        {
          fileName: 'TestImage22.png',
          fileReferenceId: 'cd21bd65-0d19-11ef-9956-cf8552c90ad8',
          timestamp: '2024-05-10T06:30:41+0000',
        },
      ],
      author: 'Test customer 1',
      code: '000002BD',
      startDateTime: 'May 10, 2024 12:00 PM',
      text: 'Responded by PA',
    },
    {
      attachments: [
        {
          fileName: 'image1.jpg',
          fileReferenceId: 'cd11bd65-0d19-11ef-9f56-cf8552c90ad9',
          timestamp: '2024-05-10T06:26:49+0000',
        },
        {
          fileName: 'image2.png',
          fileReferenceId: 'cd21bd65-0d19-11ef-9f56-cf8552c90ad8',
          timestamp: '2024-05-10T06:26:49+0000',
        },
      ],
      author: 'Test customer 1',
      code: '000002BC',
      startDateTime: 'May 10, 2024 11:56 AM',
      text: 'Product is Defective',
    },
  ],
  ticketId: '00047000',
};
