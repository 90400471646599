import { AsmComponentService, AsmSessionTimerComponent } from '@spartacus/asm/components';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AsmConfig } from '@spartacus/asm/core';
import { RoutingService, UserIdService } from '@spartacus/core';

@Component({
  selector: 'cx-asm-session-timer',
  templateUrl: './kn-br-asm-session-timer.component.html',
  styleUrls: ['./kn-br-asm-session-timer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class KnBrAsmSessionTimerComponent extends AsmSessionTimerComponent implements OnInit, OnDestroy {
  constructor(
    protected config: AsmConfig,
    protected asmComponentService: AsmComponentService,
    protected routingService: RoutingService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected userIdService: UserIdService
  ) {
    super(config, asmComponentService, routingService, changeDetectorRef, userIdService);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
