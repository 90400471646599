import { KnBrQuotesActionTypes, KnBrQuotesSearchActions } from './kn-br-quotes.action';
import { KnBrQuotesState } from './kn-br-quotes.state';

export const initialState: KnBrQuotesState = {
  quotes: [],
  error: undefined,
  searchRequest: undefined,
  isLoading: false,
  pagination: undefined,
  quote: undefined,
  isSearchDisabled: false,
  isCreateDisabled: false,
  statuses: [],
  draftOrder: undefined,
  showSpinner: false,
  detailSpinner: true,
};

export function knBrQuoteReducer(state = initialState, action: KnBrQuotesSearchActions): KnBrQuotesState {
  switch (action.type) {
    case KnBrQuotesActionTypes.KnBrQuoteSearchAction:
      return { ...state, searchRequest: action.payload, isLoading: true, isSearchDisabled: true };
    case KnBrQuotesActionTypes.KnBrQuoteSearchSuccessAction:
      return {
        ...state,
        quotes: [...state.quotes, ...action.payload?.quotes],
        pagination: action.payload?.pagination,
        sorts: action.payload.sorts,
        isLoading: false,
        isSearchDisabled: false,
        quote: null,
      };
    case KnBrQuotesActionTypes.KnBrQuoteSearchFailureAction:
      return { ...state, error: action.payload, isLoading: false, isSearchDisabled: false };
    case KnBrQuotesActionTypes.KnBrQuoteSearchResetAction:
      return { ...state, quotes: [], isLoading: true };
    case KnBrQuotesActionTypes.KnBrQuoteCopyAction:
      return { ...state, quote: action.payload };
    case KnBrQuotesActionTypes.KnBrQuoteCopySuccessAction:
      return { ...state, quote: action.payload };
    case KnBrQuotesActionTypes.KnBrQuoteCopyFailureAction:
      return { ...state, error: action.payload };
    case KnBrQuotesActionTypes.KnBrQuoteUpdateAction:
      return { ...state, quote: action.payload, isLoading: true, showSpinner: true };
    case KnBrQuotesActionTypes.KnBrQuoteUpdateSuccessAction:
      return { ...state, quote: action.payload, isLoading: false, showSpinner: false };
    case KnBrQuotesActionTypes.KnBrQuoteUpdateFailureAction:
      return { ...state, error: action.payload, isLoading: false, showSpinner: false };
    case KnBrQuotesActionTypes.KnBrQuoteCreateAction:
      return { ...state, quote: action.payload, isCreateDisabled: true };
    case KnBrQuotesActionTypes.KnBrQuoteCreateSuccessAction:
      return { ...state, quote: action.payload, isCreateDisabled: false };
    case KnBrQuotesActionTypes.KnBrQuoteCreateFailureAction:
      return { ...state, quote: null, error: action.payload, isCreateDisabled: false };
    case KnBrQuotesActionTypes.KnBrQuoteLoadAction: {
      if (state.quote?.code === action.payload) {
        return { ...state, quote: state.quote, quoteId: action.payload, isLoading: true };
      } else {
        return { ...state, quote: null, quoteId: action.payload, isLoading: true };
      }
    }
    case KnBrQuotesActionTypes.KnBrQuoteLoadSuccessAction:
      return { ...state, quote: action.payload, isLoading: false, detailSpinner: false };
    case KnBrQuotesActionTypes.KnBrQuoteLoadFailureAction:
      return { ...state, error: action.payload, isLoading: false, detailSpinner: false };
    case KnBrQuotesActionTypes.KnBrQuoteLoadStatusesSuccessAction:
      return { ...state, statuses: action.payload };
    case KnBrQuotesActionTypes.KnBrQuoteLoadStatusesFailureAction:
      return { ...state, error: action.payload };
    case KnBrQuotesActionTypes.KnBrQuoteCheckoutAction:
      return { ...state, quote: action.payload, showSpinner: true };
    case KnBrQuotesActionTypes.KnBrQuoteCheckoutSuccessAction:
      return { ...state, draftOrder: action.payload, showSpinner:false };
    case KnBrQuotesActionTypes.KnBrQuoteCheckoutFailureAction:
      return { ...state, draftOrder: action.payload, showSpinner:false };
    default:
      return state;
  }
}
