import { KnBrSidebarService } from './../services/kn-br-sidebar.service';
import { KnBrMessageDialogService } from './../common/kn-br-message-dialog/kn-br-message-dialog.service';
import { KnBrCommonService } from './../services/kn-br-common.service';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CmsNavigationComponent, GlobalMessageService, GlobalMessageType, RoutingService } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { KnBrNavigationService } from '../services/kn-br-navigation.service';
import { KnBrCustomerContextService } from '../store/kn-br-customer-context/kn-br-customer-context.service';
import { CustomerData } from '../models/customer.model';

@Component({
  selector: 'kn-br-navigation',
  templateUrl: './kn-br-navigation.component.html',
  styleUrls: ['./kn-br-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnBrNavigationComponent {
  node$: Observable<any> = this.service.createNavigation(this.componentData.data$);
  styleClass$: Observable<string> = this.componentData.data$.pipe(map((d) => d?.styleClass));
  customerContext: string;
  istoggleOpen = false;
  constructor(
    protected componentData: CmsComponentData<CmsNavigationComponent>,
    protected service: KnBrNavigationService,
    protected knBrCommonService: KnBrCommonService,
    protected globalMessageService: GlobalMessageService,
    protected knBrMessageDialogService: KnBrMessageDialogService,
    private sidebarService: KnBrSidebarService,
    private knBrCustomerContextService: KnBrCustomerContextService,
    private routingService: RoutingService
  ) {
    this.knBrCustomerContextService.getActiveCustomerNumber().subscribe((response) => {
      this.customerContext = response ? response : null;
    });
  }

  toggle(node, index: number) {
    this.closeRemainingMenus(index);
    node.active = !node.active;
    this.istoggleOpen = !this.istoggleOpen;
    if (node && node.uid === 'MyAccountPriceListLink') {
      this.sidebarService.changeMenuState(false);
      this.globalMessageService.add({ key: 'myAccountPriceList.inprogress' }, GlobalMessageType.MSG_TYPE_INFO);
      if (this.customerContext) {
        this.knBrCommonService.sendMyAccountPriceList().subscribe(
          (response: any) => {
            this.knBrMessageDialogService.open(null, 'myAccountPriceList.successMsg', 'button.ok', 'lg');
          },
          (err) => {
            this.globalMessageService.add({ key: 'myAccountPriceList.errorMsg' }, GlobalMessageType.MSG_TYPE_ERROR);
          }
        );
      } else {
        this.routingService.go({ cxRoute: 'switchAccount' });
        this.globalMessageService.add({ key: 'customer.context.required' }, GlobalMessageType.MSG_TYPE_INFO);
      }
    }
  }

  closeRemainingMenus(index: number) {
    this.node$.subscribe((res) => {
      if (res && res.children && res.children.length > 0) {
        const nodes: any[] = res.children[0];
        Object.values(nodes)
          .filter((menu, i) => i !== index && menu.active)
          .forEach((menu) => (menu.active = !menu.active));
      }
    });
  }

  onMouseEnter(node, index: number) {
    this.closeRemainingMenus(index);
    node.active = true;
  }

  onMouseLeave(node, index: number) {
    this.closeRemainingMenus(index);
    node.active = false;
  }
}
