import { KnBrQuoteCommentsActions, KnBrQuoteCommentsActionTypes } from './kn-br-quotes-comments.action';
import { KnBrQuotesCommentsState } from './kn-br-quotes-comments.state';

export const initialState: KnBrQuotesCommentsState = {
  quoteComments: [],
  errors: undefined,
  quoteComment: undefined,
};

export function knBrQuotesCommentsReducer(
  state = initialState,
  action: KnBrQuoteCommentsActions
): KnBrQuotesCommentsState {
  switch (action.type) {
    case KnBrQuoteCommentsActionTypes.KnBrQuoteCommentsCreateAction:
      return { ...state, quoteComment: action.payload };
    case KnBrQuoteCommentsActionTypes.KnBrQuoteCommentsCreateSuccessAction:
      return { ...state, quoteComment: action.payload };
    case KnBrQuoteCommentsActionTypes.KnBrQuoteCommentsCreateFailureAction:
      return { ...state, errors: action.payload };
    case KnBrQuoteCommentsActionTypes.KnBrQuoteCommentsLoadAction:
      return { ...state, quoteComments: state.quoteComments };
    case KnBrQuoteCommentsActionTypes.KnBrQuoteCommentsLoadSuccessAction:
      return { ...state, quoteComments: action.payload };
    case KnBrQuoteCommentsActionTypes.KnBrQuoteCommentsLoadFailureAction:
      return { ...state, errors: action.payload };
    case KnBrQuoteCommentsActionTypes.KnBrQuoteCommentsDeleteAction:
      return { ...state, quoteComment: action.payload };
    default:
      return state;
  }
}
