import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { GenericLinkModule, IconModule, MediaModule, NavigationModule } from '@spartacus/storefront';
import { KnBrNavigationComponent } from './kn-br-navigation.component';

@NgModule({
    declarations: [KnBrNavigationComponent],
    imports: [
        CommonModule,
        NavigationModule,
        GenericLinkModule,
        IconModule,
        I18nModule,
        MediaModule,
        ConfigModule.withConfig({
            cmsComponents: {
                NavigationComponent: {
                    component: KnBrNavigationComponent,
                },
            },
        } as CmsConfig),
    ],
    exports: [KnBrNavigationComponent]
})
export class KnBrNavigationModule {}
