import { Injectable, isDevMode } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CheckoutStepService } from '@spartacus/checkout/base/components';
import {
  CheckoutStep,
  CheckoutStepType,
  CheckoutDeliveryAddressFacade,
  CheckoutDeliveryModesFacade,
  CheckoutPaymentFacade,
} from '@spartacus/checkout/base/root';
import { CheckoutCostCenterFacade, CheckoutPaymentTypeFacade } from '@spartacus/checkout/b2b/root';
import { RoutingConfigService } from '@spartacus/core';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class KnBrCheckoutStepsSetGuard implements CanActivate {
  constructor(
    protected paymentTypeService: CheckoutPaymentTypeFacade,
    protected checkoutPaymentFacade: CheckoutPaymentFacade,
    protected checkoutStepService: CheckoutStepService,
    protected checkoutDetailsService: CheckoutDeliveryAddressFacade,
    protected checkoutDeliveryModeFacade: CheckoutDeliveryModesFacade,
    protected routingConfigService: RoutingConfigService,
    protected checkoutCostCenterService: CheckoutCostCenterFacade,
    protected router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    let currentIndex = -1;
    const currentRouteUrl = '/' + route.url.join('/');

    // check whether the previous step is set
    return this.checkoutStepService.steps$.pipe(
      take(1),
      switchMap((steps) => {
        currentIndex = steps.findIndex((step) => {
          const stepRouteUrl = `/${this.routingConfigService.getRouteConfig(step.routeName)?.paths?.[0]}`;
          return stepRouteUrl === currentRouteUrl;
        });
        // get current step
        let currentStep;
        if (currentIndex >= 0) {
          currentStep = steps[currentIndex];
        }
        if (Boolean(currentStep)) {
          return this.isStepSet(steps[currentIndex - 1]);
        } else {
          if (isDevMode()) {
            console.warn(
              `Missing step with route '${currentRouteUrl}' in checkout configuration or this step is disabled.`
            );
          }
          return of(this.getUrl('checkout'));
        }
      })
    );
  }

  protected isStepSet(step: CheckoutStep): Observable<boolean | UrlTree> {
    if (step && !step.disabled) {
      switch (step.type[0]) {
        case CheckoutStepType.DELIVERY_ADDRESS: {
          return this.isDeliveryAddress(step);
        }
        case CheckoutStepType.DELIVERY_MODE: {
          return this.isDeliveryModeSet(step);
        }
        case CheckoutStepType.PAYMENT_DETAILS: {
          return this.isPaymentDetailsSet(step);
        }
        case CheckoutStepType.REVIEW_ORDER: {
          break;
        }
      }
    }
    return of(true);
  }

  protected isPaymentTypeSet(step: CheckoutStep): Observable<boolean | UrlTree> {
    return this.paymentTypeService.getSelectedPaymentTypeState().pipe(
      filter((state) => !state.loading),
      map((state) => state.data),
      map((paymentType) => {
        if (paymentType) {
          return true;
        } else {
          return this.getUrl(step.routeName);
        }
      })
    );
  }

  protected isDeliveryAddressAndCostCenterSet(
    step: CheckoutStep,
    isAccountPayment: boolean
  ): Observable<boolean | UrlTree> {
    return combineLatest([
      this.checkoutDetailsService.getDeliveryAddressState().pipe(
        filter((state) => !state.loading),
        map((state) => state.data)
      ),
      this.checkoutCostCenterService.getCostCenterState().pipe(
        filter((state) => !state.loading),
        map((state) => state.data)
      ),
    ]).pipe(
      map(([deliveryAddress, costCenter]) => {
        if (isAccountPayment) {
          if (deliveryAddress && Object.keys(deliveryAddress).length && !!costCenter) {
            return true;
          } else {
            return this.getUrl(step.routeName);
          }
        } else {
          if (deliveryAddress && Object.keys(deliveryAddress).length && costCenter === undefined) {
            return true;
          } else {
            return this.getUrl(step.routeName);
          }
        }
      })
    );
  }

  protected isDeliveryAddress(step: CheckoutStep): Observable<boolean | UrlTree> {
    return this.checkoutDetailsService.getDeliveryAddressState().pipe(
      filter((state) => !state.loading),
      map((state) => state.data),
      map((deliveryAddress) => {
        if (deliveryAddress && Object.keys(deliveryAddress).length) {
          return true;
        } else {
          return this.getUrl(step.routeName);
        }
      })
    );
  }

  protected isDeliveryModeSet(step: CheckoutStep): Observable<boolean | UrlTree> {
    return this.checkoutDeliveryModeFacade.getSelectedDeliveryModeState().pipe(
      filter((state) => !state.loading),
      map((state) => state.data),
      map((mode) => (mode ? true : this.getUrl(step.routeName)))
    );
  }

  protected isPaymentDetailsSet(step: CheckoutStep): Observable<boolean | UrlTree> {
    return this.checkoutPaymentFacade.getPaymentDetailsState().pipe(
      filter((state) => !state.loading),
      map((state) => state.data),
      map((paymentDetails) =>
        paymentDetails && Object.keys(paymentDetails).length !== 0 ? true : this.getUrl(step.routeName)
      )
    );
  }

  protected getUrl(routeName: string): UrlTree {
    return this.router.parseUrl(this.routingConfigService.getRouteConfig(routeName)?.paths?.[0] as string);
  }
}
