import { OccConfig } from '@spartacus/core';
import { ViewConfig } from '@spartacus/storefront';

export const knBrProductEndpointsConfig: OccConfig = {
  backend: {
    occ: {
      endpoints: {
        // tslint:disable:max-line-length
        product: {
          default:
            'knbr/customers/${customerId}/products/${productCode}?fields=DEFAULT,averageRating,images(FULL),classifications,manufacturer,numberOfReviews,categories(FULL),baseOptions,baseProduct,variantOptions,variantType,callForPricing,pricingText,moq,showInCatalog',
          list: 'knbr/customers/${customerId}/products/${productCode}/salesOrg/${salesOrg}?fields=code,name,summary,price(formattedValue),images(DEFAULT,galleryIndex),callForPricing,pricingText',
          listWithSalesOrg: 'knbr/customer/${customerId}/products/${productCode}/salesOrg/${salesOrg}?fields=code,name,summary,images(DEFAULT,galleryIndex)&isPricingRequired=false',
          details: 'knbr/customer/${customerId}/products/${productCode}/salesOrg/${salesOrg}?fields=code,name,summary,images(galleryIndex,FULL),stock(DEFAULT),moq,description,url,manufacturer,categories(FULL),priceRange,multidimensional,configuratorType,tags,knbrPrice,minOrderQuantity,uom,assetName,assetUrl,safetyAssetName,safetyAssetUrl,stockingLocation,numberOfPieces,hazmat,callForPricing,pricingText,baseUom,packageUnit,packageWeight,packageQty,palletQty,materialOverviewText,brandName,featuresAndBenefits,applications,panelCharacteristics,potentialLeedCredits,codesCompliances,assetNameEs,assetUrlEs,assetNameFr,assetUrlFr,safetyAssetNameEs,safetyAssetUrlEs,safetyAssetNameFr,safetyAssetUrlFr,productSellSheetName,productSellSheetUrl,productSellSheetNameEs,productSellSheetUrlEs,productSellSheetUrlEs,productSellSheetUrlFr,productSellSheetNameFr,',
          attributes: 'knbr/customers/${customerId}/products/${productCode}?fields=classifications',
          variants:
            'knbr/customers/${customerId}/products/${productCode}?fields=name,purchasable,baseOptions(DEFAULT),baseProduct,variantOptions(DEFAULT),variantType,moq',
        },
        productReviews: 'knbr/customers/${customerId}/products/${productCode}/reviews',
        productReferences:
          'knbr/customers/${customerId}/products/${productCode}/references?fields=DEFAULT,references(target(images(FULL)))',
        productSearch:
          'knbr/customers/${customerId}/products/search?fields=products(code,name,summary,price(FULL),salesOrg,images(DEFAULT),stock(FULL),averageRating,variantOptions,uom,moq,minOrderQuantity,callForPricing,pricingText,baseUom),facets,breadcrumbs,pagination(DEFAULT),sorts(DEFAULT),freeTextSearch,currentQuery',
        productSuggestions: 'knbr/customers/${customerId}/products/suggestions',
      },
    },
  },
};

export const knBrProductViewConfig: ViewConfig = {
  view: {
    infiniteScroll: {
      active: true,
      productLimit: 1,
      showMoreButton: true,
    },
  },
};
