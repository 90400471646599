<div class="float-left w-100">
  <div
    class="filter-title color-knbr-primary text-white"
    *ngIf="((facetList$ | async)? facetListValuesData.facets : []) as facets"
    [cxFocus]="isDialog ? dialogFocusConfig : {}"
    (esc)="close($event)"
    (click)="block($event)"
  >
    <div class="filter-title color-knbr-primary p-3 text-white float-left w-100">
      <h4 class="m-0 w-auto float-left">
        {{ 'productList.filterBy.label' | cxTranslate }}
      </h4>
      <button type="button" class="close d-none" aria-label="Close" (click)="close()">
        <cx-icon aria-hidden="true" [type]="iconTypes.CLOSE"></cx-icon>
      </button>
    </div>

    <div class="bg-white product-cate-options float-left col-lg-12 border-left border-right border-bottom">
      <div class="col-lg-12 p-0 float-left filter-by mt-1">
        <kn-br-facet
          *ngFor="let facet of facets"
          #facetRef
          [facet]="facet"
          [cxFocus]="{ lock: true, trap: true, autofocus: 'a' }"
          (unlock)="expandFacetGroup(facet, facetRef)"
          [class.expanded]="isExpanded(facet) | async"
          [class.collapsed]="isCollapsed(facet) | async"
        ></kn-br-facet>
      </div>
    </div>
  </div>
</div>
