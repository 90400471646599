import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KnBrReturnOrderDetailsOverviewComponent } from './kn-br-return-order-details-overview.component';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { KnBrCustomPipesModule } from 'src/app/shared/pipe/kn-br-custom-pipes/kn-br-custom-pipes.module';
import { KnBrCommonAddressModule } from "../../kn-br-common-address/kn-br-common-address.module";

@NgModule({
  declarations: [KnBrReturnOrderDetailsOverviewComponent],
  imports: [
    CommonModule,
    I18nModule,
    KnBrCustomPipesModule,
		KnBrCommonAddressModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ReturnRequestOverviewComponent: {
          component: KnBrReturnOrderDetailsOverviewComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class KnBrReturnOrderDetailsOverviewModule {}
