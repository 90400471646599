<cx-org-form i18nRoot="orgUser">
  <ng-container main *ngIf="form" [formGroup]="form">
    <!-- <label>
      <span class="label-content required">{{ 'orgUser.title' | cxTranslate }}</span>
      <ng-select
        formControlName="titleCode"
        [searchable]="false"
        [clearable]="false"
        [items]="titles$ | async"
        bindLabel="name"
        bindValue="code"
        appendTo="cx-org-list"
      >
      </ng-select>
      <cx-form-errors [control]="form.get('titleCode')"></cx-form-errors>
    </label> -->
    <label>
      <span class="label-content required">{{ 'orgUser.firstName' | cxTranslate }}</span>
      <input
        type="text"
        class="form-control"
        required
        placeholder="{{ 'orgUser.firstName' | cxTranslate }}"
        formControlName="firstName"
      />
      <cx-form-errors [control]="form.get('firstName')"></cx-form-errors>
    </label>

    <label>
      <span class="label-content required">{{ 'orgUser.lastName' | cxTranslate }}</span>
      <input
        type="text"
        class="form-control"
        required
        placeholder="{{ 'orgUser.lastName' | cxTranslate }}"
        formControlName="lastName"
      />
      <cx-form-errors [control]="form.get('lastName')"></cx-form-errors>
    </label>

    <label>
      <span class="label-content required">{{ 'orgUser.email' | cxTranslate }}</span>
      <input
        class="form-control"
        type="email"
        required
        placeholder="{{ 'orgUser.email' | cxTranslate }}"
        formControlName="email"
      />
      <cx-form-errors [control]="form.get('email')"></cx-form-errors>
    </label>

    <label>
      <span class="label-content">{{ 'orgUser.mobileNumber' | cxTranslate }}</span>
      <input
        class="form-control"
        type="text"
        placeholder="{{ 'orgUser.mobileNumber' | cxTranslate }}"
        formControlName="knbrMobileNumber"
      />
      <cx-form-errors [control]="form.get('knbrMobileNumber')"></cx-form-errors>
    </label>

    <label aria-required="true" class="full-width">
      <span class="label-content">{{ 'register.jobFunction.label' | cxTranslate }}</span>
      <ng-select
        formControlName="jobFunctionKey"
        [searchable]="true"
        [items]="jobFunctionList$ | async"
        bindLabel="Text"
        bindValue="Key"
        appendTo="cx-org-list"
        [placeholder]="'register.jobFunction.label' | cxTranslate"
      >
      </ng-select>
      <cx-form-errors [control]="form.get('jobFunctionKey')"></cx-form-errors>
    </label>

    <fieldset aria-required="true" class="full-width" formArrayName="roles">
      <legend class="label-content required">
        {{ 'orgUser.roles' | cxTranslate }}
      </legend>

      <label class="form-check" *ngFor="let role of availableRoles; let i = index">
        <input
          type="checkbox"
          class="form-check-input"
          [value]="role"
          [checked]="roles?.value?.includes(role)"
          (change)="updateRoles($event)"
          [disabled]="form?.status === 'DISABLED'"
        />
        <span class="form-check-label">
          {{ 'organization.userRoles.' + role | cxTranslate }}
        </span>
      </label>
    </fieldset>

    <label aria-required="true" [formGroup]="form.get('orgUnit')">
      <span class="label-content required">{{ 'orgUser.orgUnit' | cxTranslate }}</span>
      <ng-select
        formControlName="uid"
        [searchable]="true"
        [clearable]="false"
        [items]="units$ | async"
        bindLabel="name"
        bindValue="id"
        appendTo="cx-org-list"
        [placeholder]="'orgUser.orgUnit' | cxTranslate"
      >
      </ng-select>
      <cx-form-errors [control]="form.get('orgUnit.uid')"></cx-form-errors>
    </label>

    <!-- <div *ngIf="isAssignedToApprovers" class="full-width">
      <label class="form-check">
        <input type="checkbox" class="form-check-input" formControlName="isAssignedToApprovers" />
        <span class="form-check-label">{{ 'orgUser.assignApprover' | cxTranslate }}</span>
      </label>
    </div> -->
  </ng-container>
</cx-org-form>
