import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { GlobalMessageService, GlobalMessageType, RoutingService } from '@spartacus/core';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as _ from 'lodash';
import {
  KnBrReturnOrdersSearchSuccess,
  KnBrReturnOrdersSearchFailure,
  KnBrOrderReturnActions,
  KnBrOrderReturnActionTypes,
  KnBrReturnOrdersQuickSearchSuccess,
} from './kn-br-order-return.action';
import { KnBrOrderReturnAdapter } from './kn-br-order-return.adapter';

@Injectable()
export class KnBrOrderReturnEffects {
  @Effect()
  searchReturnOrders$ = this.actions$.pipe(
    ofType(KnBrOrderReturnActionTypes.KnBrReturnOrdersSearchAction),
    mergeMap((action) =>
      this.orderReturnAdapter.retrunOrdersSearch(action.payload).pipe(
        map((data: any) => {
          return action.payload?.isWidget
            ? new KnBrReturnOrdersQuickSearchSuccess(data)
            : new KnBrReturnOrdersSearchSuccess(data);
        }),
        catchError((error: Error) => {
          this.messageService.add({ key: 'knBrMessages.error' }, GlobalMessageType.MSG_TYPE_ERROR);
          return of(new KnBrReturnOrdersSearchFailure(error));
        })
      )
    )
  );

  @Effect({ dispatch: false })
  searchReturnOrdersFailure$ = this.actions$.pipe(
    ofType(KnBrOrderReturnActionTypes.KnBrReturnOrdersSearchFailureAction),
    map((action) => {
      this.messageService.add({ key: 'knBrMessages.error' }, GlobalMessageType.MSG_TYPE_ERROR);
    })
  );

  constructor(
    private actions$: Actions<KnBrOrderReturnActions>,
    private orderReturnAdapter: KnBrOrderReturnAdapter,
    private messageService: GlobalMessageService
  ) {}
}
