import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'kn-br-price-book-pop-up',
  templateUrl: './kn-br-price-book-pop-up.component.html',
  styleUrls: ['./kn-br-price-book-pop-up.component.scss']
})
export class KnBrPriceBookPopUpComponent implements OnInit {
  constructor(private activeModal: NgbActiveModal) { }
  header: string;
  message: string;

  ngOnInit(): void {}

  handleContinueClick(): void {
    this.activeModal.close(true);
  }

  dismissModal() {
    this.activeModal.close(false);
  }



}
