import { Component, OnInit } from '@angular/core';
import { CmsService, RoutingService } from '@spartacus/core';
import { ActiveCartService } from '@spartacus/cart/base/core';
import { ICON_TYPE } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';

import { KnBrCartContextService } from '../../store/kn-br-cart-context/kn-br-cart-context.service';
import { KnBrQuoteContextService } from '../../store/kn-br-quote-context/kn-br-quote-context.service';
import { KnBrQuotesService } from '../../store/kn-br-quotes/kn-br-quotes.service';
import { MultiCartFacade } from '@spartacus/cart/base/root';
import { Cart } from '@spartacus/cart/base/root/models';

@Component({
  selector: 'kn-br-mini-cart',
  templateUrl: './kn-br-mini-cart.component.html',
  styleUrls: ['./kn-br-mini-cart.component.scss'],
})
export class KnBrMiniCartComponent implements OnInit{
  iconTypes = ICON_TYPE;
  //code$: Observable<string> = this.activeCartService.getActive().pipe(
  //  startWith({ code: '' }),
   // map((cart) => cart.code || '')
 // );
  // quantity$: Observable<number> = this.activeCartService.getActive().pipe(
   // startWith({ totalItems: 0 }),
   // map((cart) => cart.totalItems || 0)
  // );
  // total$: Observable<string> = this.activeCartService.getActive().pipe(
   // filter((cart) => !!cart.totalPrice),
    // map((cart) => cart.totalPrice.formattedValue)
  // );
  miniCart$: Observable<Cart>;
  quoteCode$: Observable<string> = this.knBrQuoteService.get$.pipe(
    startWith({ code: '' }),
    map((quote) => (quote && quote.code) || '')
  );
  quoteQuantity$: Observable<number> = this.knBrQuoteService.get$.pipe(
    startWith({ totalItems: 0 }),
    map((quote) => (quote && quote.totalItems) || 0)
  );
  quoteTotal$: Observable<string> = this.knBrQuoteService.get$.pipe(
    filter((quote) => quote && !!quote.totalPrice),
    map((quote) => quote && quote.totalPrice.formattedValue)
  );
  cartContext$: Observable<string> = this.knBrCartContextService.get$;
  quoteContext$: Observable<string> = this.knBrQuoteContextService.get$;
  readonly isNotQuoteDetailPage$ = this.cmsService
    .getCurrentPage()
    .pipe(map((page) => page && page.template !== 'KnBrQuoteDetailPageTemplate'));
  readonly isNotCartDetailPage$ = this.cmsService
    .getCurrentPage()
    .pipe(
      map(
        (page) =>
          page &&
          page.template !== 'KnBrDraftOrderDetailPageTemplate' &&
          page.template !== 'MultiStepCheckoutSummaryPageTemplate'
      )
    );
  constructor(
    private cmsService: CmsService,
    protected activeCartService: ActiveCartService,
    private knBrCartContextService: KnBrCartContextService,
    private knBrQuoteContextService: KnBrQuoteContextService,
    private knBrQuoteService: KnBrQuotesService,
    private multiCartFacade: MultiCartFacade,
    private routeService: RoutingService
  ) {}
   ngOnInit(): void {
    this.quoteContext$
      .subscribe((code) => {
        if (code) {
          this.knBrQuoteService.get(code);
        }
      })
      .unsubscribe();
      this.cartContext$.subscribe((code) => {
      this.multiCartFacade.reloadCart(code);
      this.miniCart$ = this.multiCartFacade.getCart(code).pipe(
         filter((cart) => !!cart),
        map((cart) => cart)
           );
      });
  }

  redirectToQuoteDetail() {
    this.quoteContext$
      .subscribe((code) => {
        if (code) {
          this.knBrQuoteService.get(code);
          this.routeService.go({ cxRoute: 'quoteDetails', params: { code } });
        }
      })
      .unsubscribe();
  }
  removeQuoteContext() {
    this.knBrQuoteContextService.delete();
  }

  removeCartContext() {
    this.knBrCartContextService.delete();
  }
}
