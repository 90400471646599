import { AuthGuard, CmsConfig, ParamsMapping, RoutingConfig } from '@spartacus/core';
import {
  OrganizationTableType,
  ActiveLinkCellComponent,
  StatusCellComponent,
  RolesCellComponent,
  CellComponent,
  ItemService,
  ListComponent,
  ListService,
  UserChangePasswordFormComponent,
  UserItemService,
  UserListService,
  UserDetailsComponent,
} from '@spartacus/organization/administration/components';
import { AdminGuard } from '@spartacus/organization/administration/core';

import { TableConfig } from '@spartacus/storefront';
import { KnBrUserFormComponent } from './add-user-form/kn-br-user-form.component';
import { KnBrUserDetailsComponent } from './user-details/kn-br-user-details.component';

const listPath = `users/:userCode`;
const paramsMapping: ParamsMapping = {
  userCode: 'customerId',
};

export const knBrUserRoutingConfig: RoutingConfig = {
  routing: {
    routes: {
      orgUser: {
        paths: ['users'],
      },
      orgUserCreate: {
        paths: ['users/create'],
      },
      orgUserDetails: {
        paths: [listPath],
        paramsMapping,
      },
      orgUserEdit: {
        paths: [`${listPath}/edit`],
        paramsMapping,
      },
      orgUserChangePassword: {
        paths: [`${listPath}/change-password`],
        paramsMapping,
      },
    },
  },
};

export const knBrUserTableConfig: TableConfig = {
  table: {
    [OrganizationTableType.USER]: {
      cells: ['name', 'uid', 'roles', 'active'],
      options: {
        cells: {
          name: {
            dataComponent: ActiveLinkCellComponent,
          },
          uid: {
            dataComponent: CellComponent,
          },
          roles: {
            dataComponent: RolesCellComponent,
          },
          active: {
            dataComponent: StatusCellComponent,
          },
        },
      },
    },
  },
};

export const knBrUserCmsConfig: CmsConfig = {
  cmsComponents: {
    ManageUsersListComponent: {
      component: ListComponent,
      providers: [
        {
          provide: ListService,
          useExisting: UserListService,
        },
        {
          provide: ItemService,
          useExisting: UserItemService,
        },
      ],
      childRoutes: {
        children: [
          {
            path: 'create',
            component: KnBrUserFormComponent,
          },
          {
            path: `:userCode`,
            component: KnBrUserDetailsComponent,
            data: {
              cxPageMeta: { breadcrumb: 'orgUser.breadcrumbs.details' },
            },
            children: [
              {
                path: `edit`,
                component: KnBrUserFormComponent,
              },
              {
                path: `change-password`,
                component: UserChangePasswordFormComponent,
              },
            ],
          },
        ],
      },
      guards: [AuthGuard, AdminGuard],
    },
  },
};
