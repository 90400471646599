import { switchMap, take } from 'rxjs/operators';
import { CsAgentAuthService } from '@spartacus/asm/root';
import { Component, OnInit } from '@angular/core';
import { AsmComponentService, AsmMainUiComponent } from '@spartacus/asm/components';
import { AuthService, UserService, GlobalMessageService, RoutingService, GlobalMessageType } from '@spartacus/core';
import { AsmService } from '@spartacus/asm/core';
import { of } from 'rxjs';
import { KnBrAuthRedirectService } from '../services/kn-br-auth-redirect.service';
import { UserAccountFacade } from '@spartacus/user/account/root';

@Component({
  selector: 'cx-asm-main-ui',
  templateUrl: './kn-br-asm-main-ui.component.html',
  styleUrls: ['./kn-br-asm-main-ui.component.scss'],
})
export class KnBrAsmMainUiComponent extends AsmMainUiComponent implements OnInit {
  startingCustomerSession = false;
  constructor(
    protected authService: AuthService,
    protected csAgentAuthService: CsAgentAuthService,
    protected userService: UserAccountFacade,
    protected asmComponentService: AsmComponentService,
    protected globalMessageService: GlobalMessageService,
    protected routingService: RoutingService,
    protected asmService: AsmService,
    protected knBrAuthRedirectService: KnBrAuthRedirectService
  ) {
    super(
      authService,
      csAgentAuthService,
      asmComponentService,
      globalMessageService,
      routingService,
      asmService,
      userService
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.customer$ = this.authService.isUserLoggedIn().pipe(
      switchMap((isLoggedIn) => {
        if (isLoggedIn) {
          this.handleCustomerSessionStartRedirection();
          return this.userService.get();
        } else {
          return of(undefined);
        }
      })
    );
  }

  protected handleCustomerSessionStartRedirection(): void {
    this.asmComponentService
      .isCustomerEmulationSessionInProgress()
      .pipe(take(1))
      .subscribe((isCustomerEmulated) => {
        if (this.startingCustomerSession && isCustomerEmulated) {
          this.startingCustomerSession = false;
          this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_ERROR);
          this.knBrAuthRedirectService.redirect();
          // this.routingService.go('/');
        }
      });
  }

  startCustomerEmulationSession({ customerId }: { customerId: string }): void {
    if (customerId) {
      this.startingCustomerSession = true;
      this.csAgentAuthService.startCustomerEmulationSession(customerId);
    } else {
      this.globalMessageService.add({ key: 'asm.error.noCustomerId' }, GlobalMessageType.MSG_TYPE_ERROR);
    }
  }
}
