import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CartValidationGuard } from '@spartacus/cart/base/core';
import {
  CartNotEmptyGuard,
  CheckoutAuthGuard,
  CheckoutProgressMobileBottomModule,
  CheckoutProgressMobileTopModule,
} from '@spartacus/checkout/base/components';

import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { AddressFormModule, CardModule, SpinnerModule } from '@spartacus/storefront';
import { KnBrCustomerContextGuard } from 'src/app/shared/guards/kn-br-customer-context.guard';
import { KnBrCustomPipesModule } from 'src/app/shared/pipe/kn-br-custom-pipes/kn-br-custom-pipes.module';
import { KnBrShippingAddressComponent } from './kn-br-shipping-address.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    AddressFormModule,
    CardModule,
    SpinnerModule,
    I18nModule,
    CheckoutProgressMobileTopModule,
    CheckoutProgressMobileBottomModule,
    KnBrCustomPipesModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CheckoutShippingAddress: {
          component: KnBrShippingAddressComponent,
          guards: [CheckoutAuthGuard, CartNotEmptyGuard, CartValidationGuard],
        },
      },
    } as CmsConfig),
  ],
  declarations: [KnBrShippingAddressComponent],
  exports: [KnBrShippingAddressComponent],
})
export class KnBrShippingAddressModule {}
