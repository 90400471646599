import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { KnBrQuotesEffects } from './kn-br-quotes.effects';
import { knBrQuoteReducer } from './kn-br-quotes.reducer';
import { KN_BR_SEARCH_QUOTES_FEATURE } from './kn-br-quotes.state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(KN_BR_SEARCH_QUOTES_FEATURE, knBrQuoteReducer),
    EffectsModule.forFeature([KnBrQuotesEffects]),
  ],
})
export class KnBrQuotesStateModule {}
