<ng-container>
  <div class="product-main-grid col-md-12 p-0 d-flex f-flow-col"
    *ngIf="quoteSearchResult$ | async as quoteSearchResult">
    <ng-container *ngIf="sorts$ | async as sorts">
      <div class="row mt-3">
        <div class="col-sm-6 col-md-6 col-lg-7 d-flex align-items-center">
          <ng-container *ngIf="paginate$ | async as paginate">
            <div class="p-2 mt-2 font-text-400 font-md-size text-muted">
              {{ 'quoteResult.showingDetail' | cxTranslate:{ current: (quoteSearchResult.length), total: paginate.totalResults} }}
            </div>
          </ng-container>
        </div>
        <div class="col-sm-6 col-md-6 col-lg-5 d-flex justify-content-end">
          <div class="mt-2 pt-1 sortBy">Sort By</div>
          <cx-sorting class="w-60" [sortOptions]="sorts" [sortLabels]="getSortLabels() | async"
            (sortListEvent)="changeSortCode($event)" [selectedOption]="sortType"
            placeholder="{{ 'orderHistory.sortByMostRecent' | cxTranslate }}">
          </cx-sorting>
            <button type="button" [disabled]="downloadProgress" class="w-40 btn btn-primary" (click)="downloadQuotes('quotes', 'Quotes', true, 'Quotes_Export')"> {{ 'invoiceResult.download' | cxTranslate }} &nbsp;<i class="fas fa-download pr-2" aria-hidden="true"></i></button>
        </div>
      </div>
    </ng-container>
    <div class="col-lg-12 product-list-view p-0">
      <div class="row counter-wrapper" *ngIf="quoteSearchResult && quoteSearchResult.length ">
        <div class="customersearch-list-table float-left col-lg-12 mt-3">
          <div class="customersearch-list-titles border-top float-left w-100 pl-2 pr-2 pt-3 pb-3 d-none d-sm-none d-lg-block">
            <div class="col-md-1 float-left font-text-400 pl-0 font-md-size text-muted">
              {{ 'quoteResult.header.brand' | cxTranslate }}
            </div>
            <div class="col-md-1 padding-res-0 float-left font-text-400 pl-0 font-md-size text-muted">
              <!-- <div class="text-center float-left font-text-400 pl-0 font-md-size text-muted pr-0"
                *ngIf="enableCheckboxFlag">
                <div class="text-center padding-res-0 float-left w-100">
                  <div class="remember-me float-left mb-3 d-flex justify-content-center p-0">
                    <div class="form-check custom-checkbox m-0">
                      <input type="checkbox" class="custom-control-input" id="delet" [checked]="allChecked"
                        (change)="selectAll($event)" />
                      <label class="custom-control-label color-font" for="delet"></label>
                    </div>
                  </div>
                </div>
              </div> -->
              {{ 'quoteResult.header.quoteNumber' | cxTranslate }}
            </div>
            <div class="col-md-1 float-left font-text-400 pl-0 font-md-size text-muted">
              {{ 'quoteResult.header.createdOn' | cxTranslate }}
            </div>
            <div class="col-md-1 float-left font-text-400 pl-0 font-md-size text-muted">
              {{ 'quoteResult.header.accountNo' | cxTranslate }}
            </div>
            <div class="col-md-1 float-left font-text-400 pl-0 font-md-size text-muted">
              {{ 'quoteResult.header.accountName' | cxTranslate }}
            </div>
            <div class="col-md-1 float-left font-text-400 pl-0 font-md-size text-muted">
              {{ 'quoteResult.header.soldToCity' | cxTranslate }}
            </div>
            <div class="col-md-1 float-left font-text-400 pl-0 font-md-size text-muted text-wrap">
              {{ 'quoteResult.header.customerPO' | cxTranslate }}
            </div>
            <div class="col-md-1 float-left font-text-400 pl-0 font-md-size text-muted text-wrap">
              {{ 'quoteResult.header.validity' | cxTranslate }}
            </div>
            <div class="col-md-1 float-left font-text-400 pl-0 font-md-size text-muted">
              {{ 'quoteResult.header.status' | cxTranslate }}
            </div>
            <div class="col-md-1 float-left font-text-400 pl-0 font-md-size text-muted">
              {{ 'quoteResult.header.validFrom' | cxTranslate }}
            </div>
            <div class="col-md-1 float-left font-text-400 pl-0 font-md-size text-muted">
              {{ 'quoteResult.header.validTo' | cxTranslate }}
            </div>
            <div class="col-md-1 float-left font-text-400 pl-0 font-md-size text-muted">
              {{ 'quoteResult.header.total' | cxTranslate }}
            </div>
            <!-- <div *ngIf="copyAction && !enableCheckboxFlag"
              class="col-md-1 text-center float-left font-text-400 pl-0 font-md-size text-muted">
              {{ 'quoteResult.header.actions' | cxTranslate }}
            </div> -->
          </div>

          <div class="bg-white customersearch-list-titles table-content border-top float-left w-100 p-2 pl-4 font-weight-normal d-flex align-items-center"
            *ngFor="let quote of quoteSearchResult" (click)="redirectToDetail($event, quote)">
            <div class="col-md-1 float-left title-wrap pl-0 font-md-size color-font text-muted">
              {{ quote.brandName }}
            </div>
            <div class="col-md-1 text-align-center title-wrap pl-0 font-md-size color-font text-muted">
              <!-- <div class="text-center float-left font-text-400 pl-0 font-md-size text-muted pr-0" *ngIf="enableCheckboxFlag">
                <div class="text-center padding-res-0 float-left w-100">
                  <div class="remember-me float-left mb-3 d-flex justify-content-center p-0">
                    <div class="form-check custom-checkbox m-0">
                      <input type="checkbox" [id]="quote.code" class="custom-control-input rounded cursor-pointer"
                        [value]="quote.code" [checked]="isChecked(quote.code)" (change)="changeCheckBox($event)" />
                      <label class="custom-control-label color-font" [for]="quote.code"
                        (click)="$event.stopPropagation()">
                      </label>
                    </div>
                  </div>
                </div>
              </div> -->
              <span class="res-titles"> {{ 'quoteResult.header.quoteNumber' | cxTranslate }}</span>
              {{ quote.code | removeLeadingZeros }}
            </div>

            <div class="col-md-1 float-left title-wrap pl-0 font-md-size color-font text-muted">
              <span class="res-titles"> {{ 'quoteResult.header.createdOn' | cxTranslate }}</span>
              {{ quote.creationDate }}
            </div>
            <div class="col-md-1 text-align-center title-wrap pl-0 font-md-size color-font text-muted">
              <span class="res-titles"> {{ 'quoteResult.header.accountNo' | cxTranslate }} </span>
              {{ quote.accountNumber | removeLeadingZeros }}
            </div>
            <div class="float-left title-wrap product-name pl-0 font-md-size color-font text-muted">
              <span class="res-titles"> {{ 'quoteResult.header.accountName' | cxTranslate }}</span>
              {{ quote.accountName }}
            </div>
            <div class="col-md-1 float-left title-wrap pl-0 font-md-size color-font text-muted">
              <span class="res-titles"> {{ 'quoteResult.header.job' | cxTranslate }}</span>
              {{ quote.soldToCity }}
            </div>
            <div class="col-md-1 float-left title-wrap pl-0 font-md-size color-font text-muted text-wrap">
              <span class="res-titles"> {{ 'quoteResult.header.customerPO' | cxTranslate }}</span>
              {{ quote.purchaseOrderNumber }}
            </div>
            <div class="col-md-1 float-left title-wrap pl-0 font-md-size color-font text-muted text-wrap">
              <span class="res-titles"> {{ 'quoteResult.header.validity' | cxTranslate }}</span>
              {{ quote.validity }}
            </div>
            <div class="col-md-1 float-left title-wrap pl-0 font-md-size color-font text-muted">
              <span class="res-titles"> {{ 'quoteResult.header.status' | cxTranslate }}</span> {{ quote.status }}
            </div>
            <div class="col-md-1 float-left title-wrap pl-0 font-md-size color-font text-muted">
              <span class="res-titles"> {{ 'quoteResult.header.validFrom' | cxTranslate }}</span>
              {{ quote.quoteValidFrom }}
            </div>
            <div class="col-md-1 float-left title-wrap pl-0 font-md-size color-font text-muted">
              <span class="res-titles"> {{ 'quoteResult.header.validTo' | cxTranslate }}</span>
              {{ quote.quoteValidTo }}
            </div>
            <div class="col-md-1 text-align-right title-wrap pl-0 font-md-size color-font text-muted pr-0">
              <span class="res-titles"> {{ 'quoteResult.header.total' | cxTranslate }}</span>
              {{ quote.totalPrice?.formattedValue }}
            </div>
            <!-- <div (click)="copyQuoteConent(quote)"
              class="col-md-1 text-center float-left title-wrap pl-0 font-md-size color-font text-muted copy-action"
              [ngbTooltip]="copyButtonTip" *ngIf="copyAction && !enableCheckboxFlag">
              <span class="res-titles"> {{ 'quoteResult.header.actions' | cxTranslate }}</span>
              <i class="far fa-copy fa-lg"></i>
            </div> -->
          </div>
        </div>
        <div class="col-lg-12 d-flex">
          <ng-container *ngIf="paginate$ | async as paginate">
            <div class="col-lg-6 p-4 mt-2 font-text-400 font-md-size text-muted">
              {{ 'quoteResult.showingDetail' | cxTranslate:{ current: (quoteSearchResult.length), total: paginate.totalResults} }}
            </div>
            <div class="col-lg-6 w-100 mt-3">
              <div *ngIf="paginate.currentPage < paginate.totalPages - 1 && !(isLoading$ | async)"
                (click)="loadMore(paginate.currentPage)" class="btn btn-loadmore mt-0 mr-3">
                {{ 'cartResult.showMore' | cxTranslate }}
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="alert-info p-3 rounded mt-3"
        *ngIf="quoteSearchResult && quoteSearchResult.length < 1 && !(isLoading$ | async)">
        {{ 'quoteResult.nodataMsg' | cxTranslate }}

      </div>
    </div>
  </div>
</ng-container>
<ng-template #copyButtonTip>{{ 'toolTip.copy' | cxTranslate }}</ng-template>
