import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  Renderer2,
} from '@angular/core';
import { Facet } from '@spartacus/core';
import { FacetGroupCollapsedState, FacetList, FacetService, FocusConfig, ICON_TYPE } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { KnBrFacetComponent } from '../kn-br-facet/kn-br-facet.component';

@Component({
  selector: 'kn-br-facet-list',
  templateUrl: './kn-br-facet-list.component.html',
  styleUrls: ['./kn-br-facet-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnBrFacetListComponent {
  facetListValuesData: any= []
  private _isDialog: boolean;
  /**
   * Indicates that the facet navigation is rendered in dialog.
   */
  @Input()
  set isDialog(value: boolean) {
    this._isDialog = value;
    if (value) {
      this.renderer.addClass(document.body, 'modal-open');
    }
  }
  get isDialog(): boolean {
    return this._isDialog;
  }
  /** Emits when the list must close */
  @Output() closeList = new EventEmitter();
  /** The list of all facet and values related to the products in the list */
  facetList$: Observable<FacetList> = this.facetService.facetList$;
  iconTypes = ICON_TYPE;
  dialogFocusConfig: FocusConfig = {
    trap: true,
    block: true,
    focusOnEscape: true,
    autofocus: 'kn-br-facet',
  };
  @HostListener('click') handleClick() {
    this.close();
  }
  constructor(protected facetService: FacetService, protected elementRef: ElementRef, protected renderer: Renderer2) {}

  ngOnInit(): void {
    const facetNamesToBeSeparated = ['EPDM System', 'PVC System', 'TPO System'];
    this.facetList$.subscribe(resp => {
      this.facetListValuesData = resp;
      let arrayPosition = 0
      const separatedList = []
      if(this.facetListValuesData && this.facetListValuesData.facets) {
        const modifiedFacets = this.facetListValuesData.facets.filter((eachFacet, index) => {
          if(facetNamesToBeSeparated.includes(eachFacet.name) === false) {
            return eachFacet
          } else {
            if(arrayPosition == 0) {
              arrayPosition = index;
            }
            separatedList.push(eachFacet);
          }
        });
        if(separatedList.length) {
          const combinedFacet = {...separatedList[0]};
          separatedList.forEach((eachFacet, index) => {
            if(index > 0) {
              combinedFacet.values = [...combinedFacet.values, ...eachFacet.values];
              combinedFacet.name = "Membrane System";
            }
          });
          modifiedFacets.splice(arrayPosition, 0,combinedFacet );
        }
        this.facetListValuesData.facets = modifiedFacets;
      }
    })
  }
  /**
   * Toggles the facet group in case it is not expanded.
   */
  expandFacetGroup(facet: Facet, ref: KnBrFacetComponent) {
    if (!ref.isExpanded) {
      this.facetService.toggle(facet, ref.isExpanded);
    }
  }
  /**
   * Indicates that the facet group has been expanded.
   */
  isExpanded(facet: Facet): Observable<boolean> {
    return this.facetService.getState(facet).pipe(map((value) => value.toggled === FacetGroupCollapsedState.EXPANDED));
  }
  /**
   * Indicates that the facet group has been collapsed.
   */
  isCollapsed(facet: Facet): Observable<boolean> {
    return this.facetService.getState(facet).pipe(map((value) => value.toggled === FacetGroupCollapsedState.COLLAPSED));
  }
  close(event?: boolean): void {
    this.renderer.removeClass(document.body, 'modal-open');
    this.closeList.emit(event);
  }
  block(event?: MouseEvent) {
    event.stopPropagation();
  }
}
