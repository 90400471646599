<ng-container *ngIf="caseDetailInfo">
  <div class="mx-5 case-detail">
    <div class="case-info-styles">
      <div class="row mb-4">
        <div class="col-lg-4">
          <div class="label-heading">Case ID</div>
          <div>{{caseDetailInfo?.svcCaseNumber}}</div>
        </div>
        <div class="col-lg-4">
          <ng-container *ngIf="caseDetailInfo.orderNumber">
            <div class="label-heading">Sales Order #</div>
            <div>{{caseDetailInfo?.orderNumber}}</div>
          </ng-container>
        </div>
        <div class="col-lg-4">
            <div class="label-heading">Document #</div>
            <div>{{caseDetailInfo?.documentNumber || '-'}}</div>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col-lg-4">
          <div class="label-heading">Created On</div>
          <div>{{caseDetailInfo?.createdOn}}</div>
        </div>
        <div class="col-lg-4">
          <div class="label-heading">Case Type</div>
          <div>{{caseDetailInfo?.caseType}}</div>

        </div>
        <div class="col-lg-4">
          <div class="label-heading">Job Name</div>
          <div>{{caseDetailInfo?.jobName || '-'}}</div>

        </div>
      </div>

      <div class="row mb-4">
        <div class="col-lg-4">
          <div class="label-heading">Last Updated</div>
          <div>{{caseDetailInfo?.lastUpdated}}</div>

        </div>
        <div class="col-lg-4">
          <div class="label-heading">Date of Incident</div>
          <div>{{caseDetailInfo?.dateOfIncident}}</div>

        </div>
        <div class="col-lg-4">
          <div class="label-heading">Status</div>
          <div class="status">{{caseDetailInfo?.status | uppercase}}</div>

        </div>
      </div>

      <div class="row mb-4">
        <div class="col-lg-4">
          <ng-container *ngIf="(caseDetailInfo?.products && caseDetailInfo.products.length)">
            <div class="label-heading">Additional Details Product List</div>
            <ng-container *ngFor="let eachProduct of caseDetailInfo.products">
              <div>
                {{eachProduct}}
              </div>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="(caseDetailInfo?.deliveredSku && caseDetailInfo?.requestedSku)">
            <div class="label-heading">SKU</div>
            <div>Requested - {{caseDetailInfo.requestedSku}}</div>
            <div>Delivered - {{caseDetailInfo.deliveredSku}}</div>
          </ng-container>

        </div>
        <div class="col-lg-4">
          <div class="label-heading">Case Description</div>
          <div class="case-desc-styles">{{caseDescTextToDisplay}} <a *ngIf="showReadMore"
              (click)="showFullcaseDescText()">Read More</a></div>
        </div>
      </div>


    </div>
    <div class="attachment-section-styles">
      <div class="label-heading">Attachments</div>
      <div class="attachment">
        <ng-container *ngFor="let eachAttachment of caseDetailInfo?.attachments">
          <div (click)="downloadAttachment(eachAttachment)">{{eachAttachment.fileName}}</div>
        </ng-container>
      </div>
    </div>
    <div>
      <hr class="mt-3 col-12 float-left">
    </div>

    <div class="conversation-styles">
      <div class="col-12 d-flex justify-content-between pl-0 pr-0 mb-3">
        <div class="label-heading sub-header pl-0 pt-2">Conversation History</div>
        <div class="label-heading sub-header pl-0 button-tooltip" *ngIf="caseDetailInfo?.status !== 'Closed'">
          <button class="btn btn-primary" (click)="handleCaseDetailUpdateClick()">Add Comments or Attachments</button>
          <span ngbTooltip="{{addCommentsToolTipText}}" triggers="hover" placement="top-right">
            <img src="assets/images/info_icon.svg" class="pb-1 pl-1">
            </span>
        </div>
      </div>
      <ng-container *ngFor="let commentItem of caseDetailInfo.ticketEvents">
        <div class="comment-styles">
          <div class="d-flex justify-content-between pt-2">
            <div class="label-heading">{{commentItem.author}}</div>
            <div class="date-styles">{{commentItem.startDateTime}}</div>
          </div>
          <div class="comment-notes-styles">
            <div class="pb-2">
              {{commentItem.text}}
            </div>
          </div>
          <div class="attachment">
            <ng-container *ngIf="commentItem.attachments && commentItem.attachments.length">
              <ng-container *ngFor="let eachAttachment of commentItem.attachments">
                <div (click)="downloadAttachment(eachAttachment)">{{eachAttachment.fileName}}</div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
