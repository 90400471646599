import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { KnBrQuoteReference } from 'src/app/models/kn-br-quote.model';

import { KnBrDraftOrderEntry } from '../../../../models/kn-br-draft-order.model';
import { KnBrQuoteReferenceService } from '../../../../store/kn-br-quote-reference/kn-br-quote-reference.service';

@Component({
  selector: 'knbr-kn-br-quote-reference',
  templateUrl: './kn-br-quote-reference.component.html',
  styleUrls: ['./kn-br-quote-reference.component.scss'],
})
export class KnBrQuoteReferenceComponent implements OnInit {
  showWarningMessage = false;
  cartEntry: KnBrDraftOrderEntry;
  selectedQuoteReference: KnBrQuoteReference;
  quoteReference$: Observable<KnBrQuoteReference[]> = this.knBrQuoteReferenceService.get$;
  constructor(public activeModal: NgbActiveModal, private knBrQuoteReferenceService: KnBrQuoteReferenceService) {}

  ngOnInit(): void {}
  add() {
    const selectedQuoteReference = _.cloneDeep(this.selectedQuoteReference);
    selectedQuoteReference.entryNumber = this.cartEntry.entryNumber;
    this.knBrQuoteReferenceService.add(selectedQuoteReference);
    this.activeModal.dismiss();
  }
  quoteReferenceChange(value) {
    this.showWarningMessage = false;
    if (
      this.selectedQuoteReference.referencedQty > 0 &&
      this.selectedQuoteReference.knbrQuoteReference.remainingQuantity - this.selectedQuoteReference.referencedQty <
        this.cartEntry.quantity
    ) {
      this.showWarningMessage = true;
    } else if (
      this.selectedQuoteReference.referencedQty <= 0 &&
      this.selectedQuoteReference.knbrQuoteReference.remainingQuantity < this.cartEntry.quantity
    ) {
      this.showWarningMessage = true;
    }
  }
}
