<div class="col-md-12 d-flex contact-heading">
  <p>Ask Us</p>
  <p>We are here to help!</p>
  <p>Complete the information below and we will get back to you soon.</p>
</div>
<div class="col-md-12 d-flex">
  <form [formGroup]="contactUsForm">
    <div class="form-group">
      <label for=""> Name *</label>
      <input type="text" class="form-control" formControlName="name"/>
    </div>
    <div class="form-group">
      <label for="">Company *</label>
      <input
        type="text"
        class="form-control"
        placeholder="Company"
        formControlName="company"
        maxlength="256"
        [class.valid]="contactUsForm.get('company').valid && submitted"
        [class.invalid]="contactUsForm.get('company').invalid && submitted"
      />
      <div *ngIf="contactUsForm.get('company').invalid && submitted && contactUsForm.get('company').errors">
        <small class="text-danger" *ngIf="contactUsForm.get('company').hasError('required')">
          Company is required.
        </small>
      </div>
    </div>
    <div class="form-group">
      <label for="">Email address *</label>
      <input type="text" class="form-control" formControlName="emailId"/>
    </div>
    <div class="form-group">
      <label for="">Phone number {{ contactUsForm.value.contactPhone ? '*' : '' }}</label>
      <input
        type="text"
        id="tel"
        class="form-control"
        phoneMask
        [phoneControl]="contactUsForm.controls['phnNo']"
        [preValue]="contactUsForm.value.phnNo"
        placeholder="(XXX)-XXX-XXXX"
        maxlength="14"
        formControlName="phnNo"
      />
      <div *ngIf="contactUsForm.get('phnNo').invalid && submitted && contactUsForm.get('phnNo').errors">
        <small class="text-danger" *ngIf="contactUsForm.get('phnNo').hasError('required')">
          Phone number is required.
        </small>
        <small class="text-danger" *ngIf="contactUsForm.get('phnNo').hasError('pattern')">
          Please enter a valid phone number.
        </small>
      </div>
    </div>
    <div class="form-group">
      <label for="">Country *</label>
      <select
        class="form-control"
        formControlName="country"
        [class.valid]="contactUsForm.get('country').valid && submitted"
        [class.is-invalid]="contactUsForm.get('country').invalid && submitted"
      >
        <option value="" disabled="true" [selected]="true">Country</option>
        <option *ngFor="let country of objectKeys(countries)" [value]="country">{{ countries[country] }}</option>
      </select>
      <div *ngIf="contactUsForm.get('country').invalid && submitted && contactUsForm.get('country').errors">
        <small class="text-danger" *ngIf="contactUsForm.get('country').hasError('required')">
          Country is required.
        </small>
      </div>
    </div>
    <div class="form-group"></div>
    <div class="form-group">
      <label for="">State / Province *</label>
      <select
        class="form-control"
        formControlName="region"
        [class.valid]="contactUsForm.get('region').valid && submitted"
        [class.is-invalid]="contactUsForm.get('region').invalid && submitted"
      >
        <option value="" disabled="true" [selected]="true">State</option>
        <option *ngFor="let state of objectKeys(states).sort()" [value]="state">{{ states[state] }}</option>
      </select>
      <div *ngIf="contactUsForm.get('region').invalid && submitted && contactUsForm.get('region').errors">
        <small class="text-danger" *ngIf="contactUsForm.get('region').hasError('required')"> State is required. </small>
      </div>
    </div>
    <div class="form-group">
      <label for="">City *</label>
      <input
        type="text"
        class="form-control"
        placeholder="City"
        formControlName="city"
        maxlength="48"
        [class.valid]="contactUsForm.get('city').valid && submitted"
        [class.invalid]="contactUsForm.get('city').invalid && submitted"
      />
      <div *ngIf="contactUsForm.get('city').invalid && submitted && contactUsForm.get('city').errors">
        <small class="text-danger" *ngIf="contactUsForm.get('city').hasError('required')">
          City is required.
        </small>
      </div>
    </div>
    <div class="row col-md-10">
      <label for="">Tell us what is going on *</label>
      <select
        class="form-control"
        formControlName="contactReason"
        [class.valid]="contactUsForm.get('contactReason').valid && submitted"
        [class.is-invalid]="contactUsForm.get('contactReason').invalid && submitted"
      >
        <option value="" disabled="true" [selected]="true">Tell us what is going on</option>
        <option *ngFor="let contactReason of contactReasons; let i = index" [value]="contactReason">
          {{ contactReason }}
        </option>
      </select>
      <div *ngIf="contactUsForm.get('contactReason').invalid && submitted && contactUsForm.get('contactReason').errors">
        <small class="text-danger" *ngIf="contactUsForm.get('contactReason').hasError('required')">
          What's going on is required.
        </small>
      </div>
    </div>
    <div class="form-group"></div>
    <div class="form-group full-width mb-0" *ngIf="getBrands(contactUsForm.value.country)">
      <label for="">Tell us the brand related to your inquiry *</label>
      <small>Select all that apply</small>
      <div class="form-group full-width mb-0">
        <div class="form-check form-check-inline mb-0" *ngFor="let brand of getBrands(contactUsForm.value.country)">
          <input
            class="form-check-input"
            type="checkbox"
            [value]="brand"
            (change)="onBrandChange(brand, $event.target.checked)"
          />
          <label class="form-check-label" for="inlineCheckbox">{{ brand }}</label>
        </div>
        <div *ngIf="submitted && selectedBrands.length === 0" class="mb-4">
          <small class="text-danger">
            Brand is required.
          </small>
        </div>
      </div>
    </div>

    <div class="form-group full-width">
      <label for="">Please provide more details *</label>
      <textarea
        class="form-control"
        formControlName="inquiryText"
        maxlength="1024"
        placeholder="Please provide more details about your inquiry so we an better assist you"
      ></textarea>
      <div *ngIf="contactUsForm.get('inquiryText').invalid && submitted && contactUsForm.get('inquiryText').errors">
        <small class="text-danger" *ngIf="contactUsForm.get('inquiryText').hasError('required')">
          More details is required.
        </small>
      </div>
    </div>

    <div class="form-group full-width">
      <label for="">How would you like to be connected? *</label>
      <div class="form-check form-check-inline mb-0">
        <input class="form-check-input" type="checkbox" formControlName="contactPhone" />
        <label class="form-check-label" for="inlineCheckbox1">Phone</label>
      </div>
      <div class="form-check form-check-inline mb-0">
        <input class="form-check-input" type="checkbox" formControlName="contactEmail" />
        <label class="form-check-label" for="inlineCheckbox2">Email</label>
      </div>
      <div *ngIf="submitted" class="mb-4">
        <small [hidden]="!contactUsForm.hasError('phnemail')" class="text-danger">
          How do you like us to connect is required.
        </small>
      </div>
    </div>

    <div class="form-group">
      <button type="button" class="w-100 mt-1 mt-sm-0 btn btn-primary" (click)="submit()">Submit</button>
    </div>
  </form>
</div>
