import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KnBrSpotlightComponent } from './kn-br-spotlight/kn-br-spotlight.component';
import { CmsConfig, ConfigModule, provideConfig, provideDefaultConfig } from '@spartacus/core';
import { IconModule, OutletModule, OutletPosition, PageComponentModule, provideOutlet } from '@spartacus/storefront';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    KnBrSpotlightComponent
  ],
  imports: [
    CommonModule,
    OutletModule,
    RouterModule,
    IconModule,
    PageComponentModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ImageMapComponent: {
          component: KnBrSpotlightComponent,
        },
      },
    } as CmsConfig),
  ],
  exports: [
    KnBrSpotlightComponent
  ],
  providers: [


  ]
})
export class KnBrSpotlightModule { }
