import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KnBrContactComponent } from './kn-br-contact/kn-br-contact.component';
import { ConfigModule, CmsConfig, AuthGuard } from '@spartacus/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PhoneMaskDirective } from './kn-br-phone-mask.directive';
import { KnBrMessageDialogModule } from '../common/kn-br-message-dialog/kn-br-message-dialog.module';


@NgModule({
  declarations: [
    KnBrContactComponent,
    PhoneMaskDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    KnBrMessageDialogModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CarlisleCotactUsFormComponent: {
              component: KnBrContactComponent,
              guards: [AuthGuard],
          },
      },
  } as CmsConfig),
  ]
})
export class KnBrContactModule { }
