import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, queueScheduler } from 'rxjs';
import { observeOn } from 'rxjs/operators';

import { KnBrQuotesComments } from '../../models/kn-br-quote.model';
import { KnBrQuoteContextService } from '../kn-br-quote-context/kn-br-quote-context.service';
import {
  KnBrQuoteCommentsCreate,
  KnBrQuoteCommentsDelete,
  KnBrQuoteCommentsLoad,
  KnBrQuoteCommentsUpdate,
} from './kn-br-quotes-comments.action';
import { getQuotesComments } from './kn-br-quotes-comments.selector';
import { StateWithQuoteComments } from './kn-br-quotes-comments.state';

@Injectable({
  providedIn: 'root',
})
export class KnBrQuotesCommentsService {
  constructor(private store: Store<StateWithQuoteComments>, private knBrQuoteContextService: KnBrQuoteContextService) {}

  list$: Observable<KnBrQuotesComments[]> = this.store.pipe(select(getQuotesComments), observeOn(queueScheduler));

  // reset() {
  //   this.store.dispatch(new KnBrQuoteSearchReset(null));
  // }
  // copy(payload: KnBrQuote) {
  //   this.store.dispatch(new KnBrQuoteCopy(payload));
  // }

  create(payload) {
    this.store.dispatch(new KnBrQuoteCommentsCreate(payload));
  }
  update(payload) {
    this.store.dispatch(new KnBrQuoteCommentsUpdate(payload));
  }
  get(entryNumber) {
    this.store.dispatch(new KnBrQuoteCommentsLoad(entryNumber));
  }

  delete(payload) {
    this.store.dispatch(new KnBrQuoteCommentsDelete(payload));
  }
}
