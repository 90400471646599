import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { CmsService, ContentSlotData, RoutingService } from '@spartacus/core';
import { Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { KnBrQuotesService } from '../../../store/kn-br-quotes/kn-br-quotes.service';

@Component({
  selector: 'kn-br-quote-items',
  templateUrl: './kn-br-quote-items.component.html',
  styleUrls: ['./kn-br-quote-items.component.scss'],
})
export class KnBrQuoteItemsComponent implements OnInit, OnDestroy {
  quote$: Observable<any> = this.knBrQuoteService.get$.pipe(
    filter((quote) => !quote || (quote && !!quote.entries)),
    map((quote) => quote)
  );
  isLoading$: Observable<any> = this.knBrQuoteService.loading$;
  allChecked = false;
  entries: any;
  private selectedProductSubscription = new Subscription();
  selectedProductCodes: string[];
  components: ContentSlotData;
  knbrOrderType: any;
  status: any;
  constructor(
    protected knBrQuoteService: KnBrQuotesService,
    protected ref: ChangeDetectorRef,
    protected cmsService: CmsService,
    protected routingService: RoutingService
  ) {
    this.selectedProductSubscription.add(
      this.knBrQuoteService.loadSelectedProducts$().subscribe((selectedProductCodes) => {
        this.selectedProductCodes = selectedProductCodes;
        let entryNumbers = [];
        this.quote$
          .subscribe((quote) => {
            entryNumbers = quote.entries.map((entry) => entry.entryNumber);
            this.knbrOrderType = quote?.knbrOrderType;
            this.status = quote?.status;
          })
          .unsubscribe();
        this.allChecked =
          entryNumbers && entryNumbers.length && entryNumbers.length === this.selectedProductCodes.length;
        this.ref.markForCheck();
      })
    );
  }

  ngOnInit(): void {}

  selectAll(event) {
    let entryNumbers = [];
    if (event.currentTarget.checked) {
      this.quote$
        .subscribe((quote) => {
          entryNumbers = quote.entries.map((entry) => entry.entryNumber);
        })
        .unsubscribe();
    }
    this.knBrQuoteService.setSelectedProducts(entryNumbers);
  }
  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    this.selectedProductSubscription.unsubscribe();
  }

  isDisabled() {
    let editable = true;
    this.cmsService
      .getContentSlot('KnBrQuoteHeaderSlot')
      .subscribe((value) => (this.components = value))
      .unsubscribe();
    this.knBrQuoteService.get$.subscribe((value) => (editable = value.editable)).unsubscribe();
    if (
      (this.components &&
        this.components.components &&
        this.components.components.length &&
        this.components.components[0].flexType === 'KnBrQuoteHeaderComponentReadOnly') ||
      !editable
    ) {
      return true;
    }

    return false;
  }

  goBack() {
    this.routingService.go({ cxRoute: 'quotes' }, { state: { displayPersistedValues: true }});
  }
}
