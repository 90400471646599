import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { KnBrQuoteItemComponent } from 'src/app/kn-br-quote/kn-br-quote-detail/kn-br-quote-items/kn-br-quote-item/kn-br-quote-item.component';

@Component({
  selector: 'knbr-kn-br-common-pop-up',
  templateUrl: './kn-br-common-pop-up.component.html',
  styleUrls: ['./kn-br-common-pop-up.component.scss']
})
export class KnBrCommonPopUpComponent implements OnInit {
  constructor(protected modalService: NgbModal, protected cdr: ChangeDetectorRef, public knBrQuoteItemComponent: KnBrQuoteItemComponent) {}
  header: string;
  message: string;
  hasImage: boolean;
  image;
  data;
  materialNum;

  ngOnInit(): void {
    console.log(this.image);

    this.hasImage = this.image == undefined ? false : true;
    console.log(this.hasImage);
    this.cdr.detectChanges();
  }

  dismissModal(reason?: any): void {
    this.modalService.dismissAll(reason);
  }

  open(orderNumber) {
    this.knBrQuoteItemComponent.navigateToOrderDetails(orderNumber);
    this.dismissModal();
  };
}
