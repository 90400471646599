<ng-container *ngIf="product$ | async as product">
  <div class="col-12 pl-2">
    <ng-template [cxOutlet]="outlets.SUMMARY" [cxOutletContext]="{ product: product }">
      <div class="d-flex">
        <p [innerHTML]="product?.name" class="summary w-100 mb-2"></p>
        <button class="btn reset p-1 float-right" (click)="back()">
          <i class="fas fa-level-down-alt fa-rotate-90" aria-hidden="true"></i>
        </button>
      </div>
      <p *ngIf="product?.description" [innerHTML]="product?.description" class="w-100 font-size-18 text-muted mb-3"></p>
      <h4 *ngIf="product?.materialOverviewText" [innerHTML]="product?.materialOverviewText" class="w-100 font-size-20 text-muted mb-3"></h4>
    </ng-template>
  </div>

</ng-container>
