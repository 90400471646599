import { Action } from '@ngrx/store';

import { CustomerData } from './../../models/customer.model';

export enum KnBrCustomerContextActionTypes {
  KnBrCustomerContextLoadAction = '[CustomerContext] Load ',
  KnBrCustomerContextLoadSuccessAction = '[CustomerContext] Load Success',
  KnBrCustomerContextAddUpdateAction = '[CustomerContext] ADD|UPDATE',
  KnBrCustomerContextRemoveAction = '[CustomerContext] Remove',
  KnBrCustomerContextResetAction = '[CustomerContext] Reset',
}

export class KnBrCustomerLoadContext implements Action {
  readonly type = KnBrCustomerContextActionTypes.KnBrCustomerContextLoadAction;
}
export class KnBrCustomerLoadSuccessContext implements Action {
  readonly type = KnBrCustomerContextActionTypes.KnBrCustomerContextLoadSuccessAction;
  constructor(public payload: CustomerData) {}
}

export class KnBrCustomerAddUpdateContext implements Action {
  readonly type = KnBrCustomerContextActionTypes.KnBrCustomerContextAddUpdateAction;
  constructor(public payload: CustomerData) {}
}
export class KnBrCustomerRemoveContext implements Action {
  readonly type = KnBrCustomerContextActionTypes.KnBrCustomerContextRemoveAction;
  constructor(public payload?: CustomerData) {}
}

export class KnBrCustomerResetContext implements Action {
  readonly type = KnBrCustomerContextActionTypes.KnBrCustomerContextResetAction;
  constructor(public payload?: CustomerData) {}
}
export type KnBrCustomerContextActions =
  | KnBrCustomerAddUpdateContext
  | KnBrCustomerRemoveContext
  | KnBrCustomerResetContext
  | KnBrCustomerLoadContext
  | KnBrCustomerLoadSuccessContext;
