import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KnBrFactSheetMaterialComponent } from './kn-br-fact-sheet-material.component';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [KnBrFactSheetMaterialComponent],
  imports: [
    CommonModule,
    I18nModule,
    RouterModule,
    UrlModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrFactSheetMaterialComponent: {
          component: KnBrFactSheetMaterialComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class KnBrFactSheetMaterialModule {}
