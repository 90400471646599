import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { KnBrOrderReturnRequestEffect } from './kn-br-order-return-request.effects';
import { knBrOrderReturnRequestReducer } from './kn-br-order-return-request.reducer';
import { KN_BR_ORDER_RETURN_REQUEST_FEATURE } from './kn-br-order-return-request.state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(KN_BR_ORDER_RETURN_REQUEST_FEATURE, knBrOrderReturnRequestReducer),
    EffectsModule.forFeature([KnBrOrderReturnRequestEffect]),
  ]
})
export class KnBrOrderReturnRequestStateModule {}
