import { LayoutConfig } from '@spartacus/storefront';
import { KnBrAsmMainUiComponent } from './kn-br-asm-main-ui.component';

export const knBrDefaultAsmLayoutConfig: LayoutConfig = {
  launch: {
    ASM: {
      outlet: 'cx-storefront',
      component: KnBrAsmMainUiComponent,
    },
  },
};
