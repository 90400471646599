import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthConfigService, GlobalMessageService, GlobalMessageType, RoutingService } from '@spartacus/core';
import { CustomFormValidators } from '@spartacus/storefront';
import { UserRegisterService } from '@spartacus/user/profile/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Statuses } from '../models/kn-br-quote.model';
import { KnBrUserSignUp } from '../models/kn-br-user-signup.model';
import { KnBrCommonService } from '../services/kn-br-common.service';

@Component({
  selector: 'knbr-register',
  templateUrl: './kn-br-user-register.component.html',
})
export class KnBrUserRegisterComponent implements OnInit, OnDestroy {
  jobFunctionList$: Observable<Statuses[]> = this.knBrCommonService.loadJobFunctionList$();
  isLoading$ = new BehaviorSubject(false);
  private subscription = new Subscription();

  registerForm: UntypedFormGroup = this.fb.group(
    {
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, CustomFormValidators.emailValidator]],
      emailconf: ['', Validators.required],
      jobFunction: [''],
      contactNumber: [''],
      unit: ['', Validators.required],
    },
    {
      validators: CustomFormValidators.emailsMustMatch('email', 'emailconf'),
    }
  );

  constructor(
    protected userRegisterService: UserRegisterService,
    protected globalMessageService: GlobalMessageService,
    protected fb: UntypedFormBuilder,
    protected router: RoutingService,
    protected authConfigService: AuthConfigService,
    protected knBrCommonService: KnBrCommonService
  ) {}

  ngOnInit() {
    this.knBrCommonService.getJobFunctionList();
  }

  submitForm(): void {
    if (this.registerForm.valid) {
      this.registerUser();
    } else {
      this.registerForm.markAllAsTouched();
    }
  }

  registerUser(): void {
    this.isLoading$.next(true);
    this.userRegisterService
      .register(this.collectDataFromRegisterForm(this.registerForm.value))
      .subscribe((res: any) => {
        if (res?.userExists) {
          this.isLoading$.next(false);
          this.globalMessageService.add(res?.response, GlobalMessageType.MSG_TYPE_ERROR);
        } else {
          this.globalMessageService.add(res?.response, GlobalMessageType.MSG_TYPE_CONFIRMATION);
          this.onRegisterUserSuccess();
        }
      });
  }

  collectDataFromRegisterForm(formData: any): KnBrUserSignUp {
    const { firstName, lastName, email, unit, jobFunction, contactNumber } = formData;
    return {
      firstName,
      lastName,
      uid: email.toLowerCase(),
      unit,
      jobFunction,
      contactNumber,
    };
  }

  private onRegisterUserSuccess(): void {
    this.router.go('login');
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
