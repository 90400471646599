import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { Facet, FacetValue } from '@spartacus/core';
import { FacetCollapseState, FacetService, FocusDirective, ICON_TYPE } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';

@Component({
  selector: 'kn-br-facet',
  templateUrl: './kn-br-facet.component.html',
  styleUrls: ['./kn-br-facet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnBrFacetComponent {
  protected _facet: Facet;
  state$: Observable<FacetCollapseState>;
  /** configurable icon that is used to collapse the facet group  */
  @Input() expandIcon: ICON_TYPE = ICON_TYPE.EXPAND;
  @Input() collapseIcon: ICON_TYPE = ICON_TYPE.COLLAPSE;
  @HostBinding('class.multi-select') isMultiSelect: boolean;
  @ViewChildren('facetValue') values: QueryList<ElementRef<HTMLElement>>;
  @ViewChild(FocusDirective) keyboardFocus: FocusDirective;
  @Input()
  set facet(value: Facet) {
    this._facet = value;
    this.isMultiSelect = !!value.multiSelect;
    this.state$ = this.facetService.getState(value);
  }
  get facet(): Facet {
    return this._facet;
  }
  constructor(
    protected facetService: FacetService,
    protected elementRef: ElementRef<HTMLElement>,
    protected cd: ChangeDetectorRef,
    private knBrCommonService: KnBrCommonService,

  ) { }
  /**
   * Handles clicking the heading of the facet group, which means toggling
   * the visibility of the group (collapse / expand) and optionally focusing
   * the group.
   */
  toggleGroup(event: UIEvent) {
    const host: HTMLElement = this.elementRef.nativeElement;
    const isLocked = this.keyboardFocus?.isLocked;
    this.facetService.toggle(this.facet, this.isExpanded);
    if (!isLocked || this.isExpanded) {
      host.focus();
      // we stop propagating the event as otherwise the focus on the host will trigger
      // an unlock event from the LockFocus directive.
      event.stopPropagation();
    }
  }
  get isExpanded(): boolean {
    return this.values.first.nativeElement.offsetParent !== null;
  }
  openLink(event: KeyboardEvent) {
    (event.target as HTMLElement).click();
    event.preventDefault();
  }
  /**
   * Increases the number of visible values for the facet. This is delegated
   * to `facetService.increaseVisibleValues`.
   */
  increaseVisibleValues(): void {
    this.facetService.increaseVisibleValues(this.facet);
  }
  /**
   * Decreases the number of visible values for the facet. This is delegated
   * to `facetService.decreaseVisibleValues`.
   */
  decreaseVisibleValues(): void {
    this.facetService.decreaseVisibleValues(this.facet);
  }
  getLinkParams(value: FacetValue) {
    return this.facetService.getLinkParams(value.query?.query.value);
  }
  linkClick() {
    this.knBrCommonService.setSpinner(true)
  }
}
