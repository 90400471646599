import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { CmsConfig, ConfigModule, I18nModule, RoutingConfig, UrlModule } from '@spartacus/core';
import { FormErrorsModule, PageComponentModule, PageLayoutModule, PageSlotModule } from '@spartacus/storefront';
import { KnBrCustomPipesModule } from "../shared/pipe/kn-br-custom-pipes/kn-br-custom-pipes.module";
import { KnBrCaseDetailHeaderComponent } from './kn-br-case-detail-header/kn-br-case-detail-header.component';
import { KnBrCaseUpdateDialogModule } from '../kn-br-case-update-dialog/kn-br-case-update-dialog.module';


@NgModule({
    declarations: [KnBrCaseDetailHeaderComponent],
    exports: [KnBrCaseDetailHeaderComponent,],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        UrlModule,
        I18nModule,
        FormErrorsModule,
        KnBrCaseUpdateDialogModule,
        NgSelectModule,
        NgbTooltipModule,
        NgbModule,
        PageComponentModule,
        PageLayoutModule,
        PageSlotModule,
        ConfigModule.withConfig({
            cmsComponents: {
              CarlisleCaseDetailHeaderComponent: {
                    component: KnBrCaseDetailHeaderComponent,
                }
            },
        } as CmsConfig),
        KnBrCustomPipesModule
    ]
})
export class KnBrCaseDetailModule {}
