import { KnBrCartContextService } from 'src/app/store/kn-br-cart-context/kn-br-cart-context.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';

import {
  KNBR_ONE_TIME_ADDRESS_ORDER,
  KNBR_RETURN_ORDER_SEARCH,
  KN_BR_CUSTOMER_CURRENT,
} from '../../../constants/api.endpoints.constant';
import { KnBrReturnOrderSearchForm } from '../../models/kn-br-order.model';
import { KnBrCustomerContextService } from '../kn-br-customer-context/kn-br-customer-context.service';

@Injectable({
  providedIn: 'root',
})
export class KnBrAddressAdapter {
  customerNumber: string;
  cartId: string;
  constructor(
    private endPointService: OccEndpointsService,
    private httpClient: HttpClient,
    protected knBrCustomerContextService: KnBrCustomerContextService,
    private knBrCartContextService: KnBrCartContextService
  ) {
    this.knBrCustomerContextService.getActiveCustomerNumber().subscribe((response) => {
      this.customerNumber = response ? response : null;
    });
    this.knBrCartContextService.get$.subscribe((response) => {
      this.cartId = response ? response : null;
    });
  }

  oneTimeAdress(params) {
    const CUSTOMER_NUMBER = this.customerNumber ? this.customerNumber : KN_BR_CUSTOMER_CURRENT;
    const reObj = {
      ...params,
      cartId: this.cartId,
    };
    return this.httpClient.post(
      this.endPointService.buildUrl(KNBR_ONE_TIME_ADDRESS_ORDER, {
        urlParams: {
          customerId: CUSTOMER_NUMBER,
          cartId: this.cartId,
        },
      }),
      params,
      { params: { customerId: CUSTOMER_NUMBER, cartId: this.cartId } }
    );
  }
}
