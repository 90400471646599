import { KnBrOrderReturn, KnBrReturnOrderSearchForm } from '../../models/kn-br-order.model';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, queueScheduler } from 'rxjs';
import { observeOn } from 'rxjs/operators';
import { StateWithKnBrAddress } from './kn-br-address.state';

import { KnBrOneTimeAddress } from './kn-br-address.action';
import { getOneTimeAddress } from './kn-br-address.selector';

@Injectable({
  providedIn: 'root',
})
export class KnBrAddressService {
  constructor(private store: Store<StateWithKnBrAddress>) {}

  oneTimeAddress$: Observable<any> = this.store.pipe(select(getOneTimeAddress), observeOn(queueScheduler));

  createOneTimeAddress(params) {
    this.store.dispatch(new KnBrOneTimeAddress(params));
  }
}
