import { OccConfig } from '@spartacus/core';

export const knBrCheckoutEndpointsConfig: OccConfig = {
  backend: {
    occ: {
      endpoints: {
        // tslint:disable:max-line-length
        addresses: 'knbr/customer/${customerId}/addresses',
        addressDetail: 'knbr/customer/${customerId}/addresses/${addressId}',
        addressVerification: 'knbr/customer/${customerId}/addresses/verification',
      },
    },
  },
};
