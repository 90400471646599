<h4>{{ 'orderCost.orderSummary' | cxTranslate }}</h4>

<div class="cx-summary-partials" *ngIf="cart">
  <!-- Cart Header Details -->
  <div class="cx-summary-row">
    <div class="col-6 cx-summary-label">{{ 'cartCommon.label.requestedDeliveryDate' | cxTranslate }}:</div>
    <div class="col-6 cx-summary-amount">{{ cart?.requestedDeliveryDate }}</div>
  </div>
  <div class="cx-summary-row">
    <div class="col-6 cx-summary-label">{{ 'cartCommon.label.customerPO' | cxTranslate }}:</div>
    <div class="col-6 cx-summary-amount">{{ cart?.purchaseOrderNumber }}</div>
  </div>
  <div class="cx-summary-row">
    <div class="col-6 cx-summary-label">{{ 'cartCommon.label.jobNameNumber' | cxTranslate }}:</div>
    <div class="col-6 cx-summary-amount">{{ cart?.jobName }}</div>
  </div>
  <div class="cx-summary-row">
    <div class="col-6 cx-summary-label">{{ 'cartCommon.label.headerNotes' | cxTranslate }}:</div>
    <div class="col-6 cx-summary-amount">{{ cart?.headerComments }}</div>
  </div>
  <div class="cx-summary-row">
    <div class="col-6 cx-summary-label">{{ 'cartCommon.label.shipComplete' | cxTranslate }}:</div>
    <div class="col-6 cx-summary-amount">{{ cart?.shipComplete ? 'Yes' : 'No' }}</div>
  </div>

  <div *ngIf="paymentType$ | async" class="w-100 border-top mt-2 mb-2"></div>

  <!-- Checkout Steps -->
  <div *ngIf="paymentType$ | async" class="cx-summary-row">
    <div class="cx-review-summary-card">
      <cx-card [content]="getPaymentTypeCard(paymentType$ | async) | async"></cx-card>
    </div>
  </div>

  <ng-container *ngIf="deliveryAddress$ | async as deliveryAddress">
    <ng-container *ngIf="deliveryAddress && deliveryAddress.firstName">
      <div class="w-100 border-top mt-2 mb-2"></div>

      <div class="cx-summary-row">
        <div class="cx-review-summary-card cx-review-card-address">
          <cx-card [content]="getShippingAddressCard(deliveryAddress, countryName$ | async) | async"></cx-card>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <div *ngIf="deliveryMode$ | async" class="w-100 border-top mt-2 mb-2"></div>

  <div *ngIf="deliveryMode$ | async as deliveryMode" class="cx-summary-row">
    <div class="cx-review-summary-card cx-review-card-shipping">
      <cx-card [content]="getDeliveryModeCard(deliveryMode) | async"></cx-card>
    </div>
  </div>

  <div class="w-100 border-top mt-2 mb-2"></div>

  <!-- Cart Totals -->
  <div class="cx-summary-row">
    <div class="col-6 cx-summary-label">
      {{ 'orderCost.subtotal' | cxTranslate }}
    </div>
    <div class="col-6 cx-summary-amount">
      {{ cart.subTotal?.formattedValue }}
    </div>
  </div>
  <div class="cx-summary-row">
    <div class="col-6 cx-summary-label">
      {{ (cart.deliveryCost?.formattedValue ? 'orderCost.shipping' : 'orderCost.estimatedShipping') | cxTranslate }}
    </div>
    <div class="col-6 cx-summary-amount">
      {{
        cart.deliveryCost?.formattedValue
          ? cart.deliveryCost.formattedValue
          : ('orderCost.toBeDetermined' | cxTranslate)
      }}
    </div>
  </div>
  <div class="cx-summary-row" *ngIf="cart.net; else cartWithoutNet">
    <div class="col-6 cx-summary-label">
      {{ 'orderCost.salesTax' | cxTranslate }}
    </div>
    <div class="col-6 cx-summary-amount">
      {{ cart.totalTax?.formattedValue }}
    </div>
  </div>
  <div class="cx-summary-row cx-summary-total">
    <div class="col-6 cx-summary-label">
      {{ 'orderCost.total' | cxTranslate }}
    </div>
    <div class="col-6 cx-summary-amount">
      {{ cart.totalPriceWithTax?.formattedValue }}
    </div>
  </div>
  <div class="cx-summary-row" *ngIf="cart.totalDiscounts?.value > 0">
    {{ 'orderCost.discount' | cxTranslate }}
    {{ cart.totalDiscounts?.formattedValue }}
  </div>
  <ng-template #cartWithoutNet>
    <div class="cx-summary-row">
      {{
        cart.totalPriceWithTax?.value !== cart.totalPrice?.value
          ? ('orderCost.grossTax' | cxTranslate)
          : ('orderCost.grossIncludeTax' | cxTranslate)
      }}
      {{ cart.totalTax?.formattedValue }}.
    </div>
  </ng-template>

  <div class="w-100 border-top mt-2 mb-2"></div>

  <!-- CART ITEM SECTION -->
  <!-- <ng-container *ngIf="cart$ | async as cart">
    <div class="cx-review-cart-total d-none d-lg-block d-xl-block">
      {{ 'cartItems.cartTotal' | cxTranslate: { count: cart.deliveryItemsQuantity } }}:
      {{ cart.totalPrice?.formattedValue }}
    </div>
    <h4 class="cx-review-cart-heading d-block d-lg-none d-xl-none">
      {{ 'checkoutReview.placeOrder' | cxTranslate }}
    </h4>
    <div class="cx-review-cart-item col-md-12" *ngIf="entries$ | async as entries">
      <ng-container *ngIf="orderPromotions$ | async as orderPromotions">
        <cx-promotions [promotions]="orderPromotions"></cx-promotions>
      </ng-container>

      <cx-cart-item-list
        [items]="entries"
        [readonly]="true"
        [promotionLocation]="promotionLocation"
      ></cx-cart-item-list>
    </div>
  </ng-container> -->
</div>
