import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KnBrAddressBookComponent } from '../kn-br-address-book/kn-br-address-book.component';
import { AuthGuard, CmsConfig, ConfigModule, I18nModule, RoutingConfig } from '@spartacus/core';
import { AddressFormModule, CardModule, FormErrorsModule, IconModule, SpinnerModule } from '@spartacus/storefront';
import { KnBrAddressFormComponent } from './kn-br-address-form/kn-br-address-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { KnBrAddressCardComponent } from './kn-br-address-card/kn-br-address-card.component';
import { KnBrCustomerContextGuard } from 'src/app/shared/guards/kn-br-customer-context.guard';

@NgModule({
  declarations: [KnBrAddressBookComponent, KnBrAddressFormComponent, KnBrAddressCardComponent],
  imports: [
    CommonModule,
    I18nModule,
    CardModule,
    AddressFormModule,
    SpinnerModule,
    ReactiveFormsModule,
    FormsModule,
    FormErrorsModule,
    NgSelectModule,
    IconModule,
    ConfigModule.withConfig({
      routing: {
        routes: {
          AccountAddressBookComponent: {
            paths: ['customer-360/address-book'],
          },
        },
      },
    } as RoutingConfig),
    ConfigModule.withConfig({
      cmsComponents: {
        AccountAddressBookComponent: {
          component: KnBrAddressBookComponent,
          guards: [AuthGuard, KnBrCustomerContextGuard],
        },
      },
    } as CmsConfig),
  ],
  exports: [KnBrAddressBookComponent, KnBrAddressFormComponent, KnBrAddressCardComponent],
})
export class KnBrAddressBookModule {}
