import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { PageMeta, PageMetaService, ProductSearchPage, TranslationService } from '@spartacus/core';
import { ICON_TYPE, ProductListComponentService, ViewModes } from '@spartacus/storefront';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { KnBrProductSelectService } from './../../services/kn-br-product-select.service';
import { ProductViewModeService } from './../../services/product-view-mode-service';
import { Title } from '@angular/platform-browser';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';

@Component({
  selector: 'kn-br-product-action-bar',
  templateUrl: './kn-br-product-action-bar.component.html',
  styleUrls: ['./kn-br-product-action-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class KnBrProductActionBarComponent implements OnDestroy {
  title$: Observable<string>;
  iconTypes = ICON_TYPE;
  model$: Observable<ProductSearchPage> = this.productListComponentService.model$;
  viewMode$ = this.viewModeService.getViewMode$();
  ViewModes = ViewModes;
  selectedProductSubscription = new Subscription();

  productCodes: string[];
  selectedProductCodes: string[];
  constructor(
    protected pageMetaService: PageMetaService,
    protected productListComponentService: ProductListComponentService,
    protected viewModeService: ProductViewModeService,
    protected knBrProductService: KnBrProductSelectService,
    private translationService: TranslationService,
    protected ngTitle: Title,
    private knBrCommonService: KnBrCommonService
  ) {
    this.selectedProductSubscription = this.knBrProductService
      .loadSelectedProducts$()
      .subscribe((selectedProductCodes) => {
        this.selectedProductCodes = selectedProductCodes;
      });
    this.setProductCountText();
  }
  sortList(sortCode: string): void {
    this.knBrCommonService.setSpinner(true);
    this.productListComponentService.sort(sortCode);
  }
  setProductCountText(): void {
    this.title$ = this.pageMetaService.getMeta().pipe(
      filter(Boolean),
      map((meta: PageMeta) => meta.heading || meta.title)
    );
  }
  setViewMode(mode: string): void {
    this.knBrCommonService.setSpinner(true);
    this.knBrProductService.setSelectedProducts([]);
    const newMode = mode === ViewModes.Grid ? ViewModes.List : ViewModes.Grid;
    this.viewModeService.setViewMode(newMode);
  }
  getLabels(sorts: any[]): {} {
    const labels = {};
    sorts.forEach((sort) => (labels[sort.code] = sort.code));
    return labels;
  }

  getPageTitle(model: any) {
    return combineLatest([
      this.translationService.translate('knBrMessages.plpListMsg', {
        records: model.pagination.currentPage + 1 === model.pagination.totalPages ? model.pagination.totalResults : model.pagination.totalResults > model.pagination.pageSize ? model.pagination.pageSize * (model.pagination.currentPage + 1) : model.pagination.totalResults,
        total: model.pagination.totalResults,
      }),
    ]).pipe(
      map(([textTitle]) => {
        this.ngTitle.setTitle(textTitle);
        return textTitle;
      })
    );
  }

  ngOnDestroy(): void {
    this.selectedProductSubscription.unsubscribe();
  }
}
