import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CheckoutStepService, CheckoutStepsSetGuard, CheckoutAuthGuard } from '@spartacus/checkout/base/components';
import { CheckoutStepType } from '@spartacus/checkout/base/root';
// import { CheckoutAuthGuard, CheckoutStepService, CheckoutStepsSetGuard } from '@spartacus/checkout/components';
import {
  CheckoutAdapter,
  // CheckoutDeliveryAdapter,
  CheckoutDeliveryAddressAdapter,
  CheckoutPaymentAdapter,
  // PaymentTypeAdapter,
} from '@spartacus/checkout/base/core';
import { CheckoutPaymentTypeAdapter } from '@spartacus/checkout/b2b/core';
// import { CheckoutStepType } from '@spartacus/checkout/root';
import { ConfigModule, UserAddressAdapter } from '@spartacus/core';
import { KnBrCheckoutStepService } from '../services/kn-br-checkout-step.service';
import { KnBrCheckoutAuthGuard } from '../shared/guards/kn-br-checkout-auth.guard';
import { KnBrCheckoutStepsSetGuard } from '../shared/guards/kn-br-checkout-steps-set.guard';
import { KnBrOccCheckoutDeliveryAdapter } from '../store/kn-br-checkout/kn-br-occ-checkout-delivery.adapter';
import { KnBrOccCheckoutPaymentTypeAdapter } from '../store/kn-br-checkout/kn-br-occ-checkout-payment-type.adapter';
import { KnBrOccCheckoutPaymentAdapter } from '../store/kn-br-checkout/kn-br-occ-checkout-payment.adapter';
import { KnBrOccCheckoutAdapter } from '../store/kn-br-checkout/kn-br-occ-checkout.adapter';
import { KnBrOccUserAddressAdapter } from '../store/kn-br-checkout/kn-br-occ-user-address.adapter';
import { knBrCheckoutEndpointsConfig } from './kn-br-checkout-endpoints.config';
import { KnBrCheckoutOrderSummaryModule } from './kn-br-checkout-order-summary/kn-br-checkout-order-summary.module';
import { KnBrPlaceOrderModule } from './kn-br-place-order/kn-br-place-order.module';
import { KnBrReviewSubmitModule } from './kn-br-review-submit/kn-br-review-submit.module';
import { KnBrShippingAddressModule } from './kn-br-shipping-address/kn-br-shipping-address.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ConfigModule.withConfig({
      checkout: {
        steps: [
          {
            id: 'shippingAddress',
            name: 'checkoutProgress.shippingAddress',
            routeName: 'checkoutDeliveryAddress',
            type: [CheckoutStepType.DELIVERY_ADDRESS],
          },
          {
            id: 'deliveryMode',
            name: 'checkoutProgress.deliveryMode',
            routeName: 'checkoutDeliveryMode',
            type: [CheckoutStepType.DELIVERY_MODE],
          },
          {
            id: 'reviewOrder',
            name: 'checkoutProgress.reviewOrder',
            routeName: 'checkoutReviewOrder',
            type: [CheckoutStepType.REVIEW_ORDER],
          },
        ],
      },
    }),
    ConfigModule.withConfig(knBrCheckoutEndpointsConfig),
    KnBrShippingAddressModule,
    KnBrReviewSubmitModule,
    KnBrCheckoutOrderSummaryModule,
    KnBrPlaceOrderModule,
  ],
  providers: [
    {
      provide: CheckoutAdapter,
      useClass: KnBrOccCheckoutAdapter,
    },
    {
      // provide: CheckoutDeliveryAdapter,
      provide: CheckoutDeliveryAddressAdapter,
      useClass: KnBrOccCheckoutDeliveryAdapter,
    },
    {
      provide: CheckoutPaymentAdapter,
      useClass: KnBrOccCheckoutPaymentAdapter,
    },
    {
      provide: UserAddressAdapter,
      useClass: KnBrOccUserAddressAdapter,
    },
    {
      // provide: PaymentTypeAdapter,
      provide: CheckoutPaymentTypeAdapter,
      useClass: KnBrOccCheckoutPaymentTypeAdapter,
    },
    {
      provide: CheckoutStepService,
      useClass: KnBrCheckoutStepService,
    },
    {
      provide: CheckoutStepsSetGuard,
      useClass: KnBrCheckoutStepsSetGuard,
    },
    {
      provide: CheckoutAuthGuard,
      useClass: KnBrCheckoutAuthGuard,
    },
  ],
})
export class KnBrCheckoutModule {}
