import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { CartActions } from '@spartacus/cart/base/core';
import { GlobalMessageService, GlobalMessageType } from '@spartacus/core';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { KnBrQuoteReference } from '../../models/kn-br-quote.model';
import { KnBrQuoteLoad } from '../kn-br-quotes/kn-br-quotes.action';
import { KnBrQuotesService } from '../kn-br-quotes/kn-br-quotes.service';
import {
  KnBrQuoteAddReferenceFailure,
  KnBrQuoteLoadReferenceFailure,
  KnBrQuoteLoadReferenceSuccess,
  KnBrQuoteReferenceAction,
  KnBrQuoteReferenceActionTypes,
  KnBrQuoteRemoveReferenceFailure,
  KnBrQuoteUpdateReferenceFailure,
} from './kn-br-quote-reference.actions';
import { KnBrQuoteReferenceAdapter } from './kn-br-quote-reference.adapter';

@Injectable()
export class KnBrQuoteReferenceEffects {
  @Effect()
  addQuoteReference$ = this.actions$.pipe(
    ofType(KnBrQuoteReferenceActionTypes.KnBrQuoteAddReferenceAction),
    mergeMap((action) =>
      this.knBrQuoteReferenceAdapter.add(action.payload).pipe(
        mergeMap((data: KnBrQuoteReference) => {
          return [new CartActions.ResetCartDetails()];
        }),
        catchError((error: Error) => {
          return of(new KnBrQuoteAddReferenceFailure(error));
        })
      )
    )
  );

  @Effect()
  removeQuoteReference$ = this.actions$.pipe(
    ofType(KnBrQuoteReferenceActionTypes.KnBrQuoteRemoveReferenceAction),
    mergeMap((action) =>
      this.knBrQuoteReferenceAdapter.remove(action.payload).pipe(
        mergeMap((data: KnBrQuoteReference) => {
          return [new CartActions.ResetCartDetails()];
        }),
        catchError((error: Error) => {
          this.messageService.add({ key: 'knBrMessages.error' }, GlobalMessageType.MSG_TYPE_ERROR);
          return of(new KnBrQuoteRemoveReferenceFailure(error));
        })
      )
    )
  );

  @Effect()
  updateQuoteReference$ = this.actions$.pipe(
    ofType(KnBrQuoteReferenceActionTypes.KnBrQuoteUpdateReferenceAction),
    mergeMap((action) =>
      this.knBrQuoteReferenceAdapter.update(action.payload).pipe(
        map((data: any) => {
          //  return new KnBrQuoteLoadReferenceSuccess(data.entry);
          return new KnBrQuoteLoad({}, false);
        }),
        catchError((error: Error) => {
          this.messageService.add({ key: 'knBrMessages.error' }, GlobalMessageType.MSG_TYPE_ERROR);
          return of(new KnBrQuoteUpdateReferenceFailure(error));
        })
      )
    )
  );

  @Effect()
  loadQuoteReference$ = this.actions$.pipe(
    ofType(KnBrQuoteReferenceActionTypes.KnBrQuoteLoadReferenceAction),
    mergeMap((action) =>
      this.knBrQuoteReferenceAdapter.get(action.payload).pipe(
        map((data: any) => new KnBrQuoteLoadReferenceSuccess(data)),
        catchError((error: Error) => {
          this.messageService.add({ key: 'knBrMessages.error' }, GlobalMessageType.MSG_TYPE_ERROR);
          return of(new KnBrQuoteLoadReferenceFailure(error));
        })
      )
    )
  );
  @Effect()
  loadQuoteReferenceByProduct$ = this.actions$.pipe(
    ofType(KnBrQuoteReferenceActionTypes.KnBrQuoteLoadReferenceByProductAction),
    mergeMap((action) =>
      this.knBrQuoteReferenceAdapter.getByProduct(action.payload).pipe(
        map((data: any) => new KnBrQuoteLoadReferenceSuccess(data)),
        catchError((error: Error) => {
          this.messageService.add({ key: 'knBrMessages.error' }, GlobalMessageType.MSG_TYPE_ERROR);
          return of(new KnBrQuoteLoadReferenceFailure(error));
        })
      )
    )
  );
  constructor(
    private actions$: Actions<KnBrQuoteReferenceAction>,
    private knBrQuoteReferenceAdapter: KnBrQuoteReferenceAdapter,
    private messageService: GlobalMessageService,
    private knBrQuoteService: KnBrQuotesService
  ) {}
}
