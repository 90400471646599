import { KnBrQuote } from 'src/app/models/kn-br-quote.model';

import { Statuses } from '../../models/kn-br-quote.model';

export const KN_BR_SEARCH_QUOTES_FEATURE = 'kn-br-quotes';

export interface KnBrQuotesState {
  pagination?: any;
  sorts?: any;
  quotes?: KnBrQuote[];
  error?: Error;
  searchRequest?: KnBrQuote;
  isLoading?: boolean;
  quote?: KnBrQuote;
  quoteId?: KnBrQuote;
  isSearchDisabled: boolean;
  isCreateDisabled: boolean;
  statuses?: Statuses[];
  draftOrder?: any;
  showSpinner?: boolean;
  detailSpinner?: boolean;
}

export interface StateWithQuote {
  [KN_BR_SEARCH_QUOTES_FEATURE]: KnBrQuotesState;
}
