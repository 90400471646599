import { OccConfig } from '@spartacus/core';

export const knBrDraftOrderEndpointsConfig: OccConfig = {
  backend: {
    occ: {
      endpoints: {
        // tslint:disable:max-line-length
        carts:
          'knbr/customer/${customerId}/carts?fields=carts(DEFAULT,totalPrice(formattedValue),totalItems,totalPriceWithTax(formattedValue),totalDiscounts(value,formattedValue),subTotal(formattedValue),deliveryCost(formattedValue),totalTax(formattedValue, value),net,productDiscounts(formattedValue),user,saveTime,name,description)',
        cart: 'knbr/customer/${customerId}/carts/${cartId}?fields=DEFAULT,entries(totalPrice(formattedValue),product(images(FULL),stock(FULL)),basePrice(formattedValue,value),updateable),totalPrice(formattedValue),totalItems,totalPriceWithTax(formattedValue),totalDiscounts(formattedValue),subTotal(formattedValue),deliveryItemsQuantity,deliveryCost(formattedValue),totalTax(formattedValue),net,productDiscounts(formattedValue),user,saveTime,name,description,paymentType',
        createCart:
          'knbr/customer/${customerId}/carts?fields=DEFAULT,entries(totalPrice(formattedValue),product(images(FULL),stock(FULL)),basePrice(formattedValue,value),updateable),totalPrice(formattedValue),totalItems,totalPriceWithTax(formattedValue),totalDiscounts(value,formattedValue),subTotal(formattedValue),deliveryItemsQuantity,deliveryCost(formattedValue),totalTax(formattedValue, value),net,productDiscounts(formattedValue),user',
        addEntries: 'knbr/customer/${customerId}/carts/${cartId}/entries',
        updateEntries: 'knbr/customer/${customerId}/carts/${cartId}/entries/${entryNumber}',
        removeEntries: 'knbr/customer/${customerId}/carts/${cartId}/entries/${entryNumber}',
        addEmail: 'knbr/customer/${customerId}/carts/${cartId}/email',
        deleteCart: 'knbr/customer/${customerId}/carts/${cartId}',
        cartVoucher: 'knbr/customer/${customerId}/carts/${cartId}/vouchers',
        saveCart: 'knbr/customer/${customerId}/carts/${cartId}/save',
      },
    },
  },
};
