import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import {
  KnBrFactSheetActions,
  KnBrFactSheetActionTypes,
  KnBrFactSheetDetailsSuccess,
  KnBrFactSheetDetailsFailure,
} from './kn-br-fact-sheet.action';
import { KnBrFactSheetAdapter } from './kn-br-fact-sheet.adapter';
import { GlobalMessageService, GlobalMessageType } from '@spartacus/core';

@Injectable()
export class KnBrFactSheetEffects {
  constructor(private actions$: Actions<KnBrFactSheetActions>, private factSheetAdapter: KnBrFactSheetAdapter,  private messageService: GlobalMessageService,) {}

  @Effect()
  getFactSheetDetails$ = this.actions$.pipe(
    ofType(KnBrFactSheetActionTypes.KnBrFactSheetDetailsAction),
    mergeMap((action) =>
      this.factSheetAdapter.getFactSheetDetails(action.payload).pipe(
        map((data: any) => new KnBrFactSheetDetailsSuccess(data)),
        catchError((error: Error) => {
          this.messageService.add({ key: 'knBrMessages.error' }, GlobalMessageType.MSG_TYPE_ERROR);
          return of(new KnBrFactSheetDetailsFailure(error));
        })
      )
    )
  );
}
