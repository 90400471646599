import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CartEntryAdapter } from '@spartacus/cart/base/core';
import { CartModification, CART_MODIFICATION_NORMALIZER } from '@spartacus/cart/base/root';
import { ConverterService, OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';

import { KnBrCustomerContextService } from '../store/kn-br-customer-context/kn-br-customer-context.service';

@Injectable()
export class KnBrOccCartEntryAdapter implements CartEntryAdapter {
  customerId: string;
  constructor(
    protected http: HttpClient,
    protected occEndpointsService: OccEndpointsService,
    protected converterService: ConverterService,
    private customerContextService: KnBrCustomerContextService
  ) {
    this.customerContextService.get$.subscribe((response) => {
      this.customerId = response && response.customerNumber ? response.customerNumber : null;
    });
  }

  public add(userId: string, cartId: string, productCode: string, quantity: number = 1): Observable<CartModification> {
    const toAdd = JSON.stringify({});
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    const url = this.occEndpointsService.buildUrl('addEntries', {
      urlParams: {
        customerId: this.customerId || userId,
        cartId,
      },
      queryParams: { code: productCode, quantity },
    });

    return this.http
      .post<CartModification>(url, toAdd, { headers })
      .pipe(this.converterService.pipeable(CART_MODIFICATION_NORMALIZER));
  }

  public update(
    userId: string,
    cartId: string,
    entryNumber: string,
    qty: number,
    pickupStore?: string
  ): Observable<CartModification> {
    let params = {};
    if (pickupStore) {
      params = { pickupStore };
    }

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    const url = this.occEndpointsService.buildUrl('updateEntries', {
      urlParams: { customerId: this.customerId || userId, cartId, entryNumber },
      queryParams: { qty, ...params },
    });

    return this.http
      .patch<CartModification>(url, {}, { headers })
      .pipe(this.converterService.pipeable(CART_MODIFICATION_NORMALIZER));
  }

  public remove(userId: string, cartId: string, entryNumber: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    const url = this.occEndpointsService.buildUrl('removeEntries', {
      urlParams: {
        customerId: this.customerId || userId,
        cartId,
        entryNumber,
      },
    });

    return this.http.delete(url, { headers });
  }
}
