import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BaseSiteService } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';

@Component({
  selector: 'knbr-kn-br-whats-new',
  templateUrl: './kn-br-whats-new.component.html',
  styleUrls: ['./kn-br-whats-new.component.scss']
})

export class KnBrWhatsNewComponent implements OnInit{
  name: string;
  title: string;
  tiles: string;
  tileData: any = [];
  componentData$ = this.component.data$;
  subscriptions = new Subscription();
  items$ = this.knbrCommonService.loadComponentData$();
  sectionComponentsData;
  items: any[] = [];
  itemsPerSlide: number = 3; // Default items per slide
  responsiveConfig: any = {
    desktop: 3,
    tablet: 2,
    mobile: 1,
  };
  slides: any[] = [];
  activeSlideIndex: number = 0;
  baseSite:any


  constructor(
    public component: CmsComponentData<any>,
    public knbrCommonService: KnBrCommonService,
    private cdr: ChangeDetectorRef,
    private baseSiteService: BaseSiteService
  ) {

  }

  ngOnInit(): void {
     this.baseSiteService.get().subscribe(
      ((site) => this.baseSite = site?.uid || '')
    )
    this.component.data$.subscribe((data) => {
      this.name = data.name;
      this.title = data.title;
      let componentsList = data.tiles.trim().split(' ');
      if(componentsList.length > 6) {
        componentsList = componentsList.slice(0,6)
      }
      this.knbrCommonService.getWhatsNewDataForMultipleComponents(componentsList).subscribe(combineddata => {
        this.sectionComponentsData = [...combineddata];
        this.items = this.sectionComponentsData;
        this.adjustItemsPerSlide();
        this.cdr.detectChanges();
      })

    });
  }

  createSlides() {
    this.slides = [];
    for (let i = 0; i < this.items.length; i += this.itemsPerSlide) {
      this.slides.push(this.items.slice(i, i + this.itemsPerSlide));
    }
  }

  adjustItemsPerSlide() {
    const screenWidth = window.innerWidth;
    if (screenWidth < 768) {
      this.itemsPerSlide = this.responsiveConfig.mobile;
    } else if (screenWidth < 992) {
      this.itemsPerSlide = this.responsiveConfig.tablet;
    } else {
      this.itemsPerSlide = this.responsiveConfig.desktop;
    }
    this.createSlides();
  }

  prevSlide() {
    this.activeSlideIndex =
      (this.activeSlideIndex - 1 + this.slides.length) % this.slides.length;
  }

  nextSlide() {
    this.activeSlideIndex = (this.activeSlideIndex + 1) % this.slides.length;
  }

  isPrevDisabled() {
    if(this.slides && this.slides.length) {
      return this.activeSlideIndex === 0;
    }
  }

  isNextDisabled() {
    if(this.slides && this.slides.length) {
      return this.activeSlideIndex === this.slides.length -1;
    }
  }

  isActiveSlide(index: number): boolean {
    return this.activeSlideIndex === index;
  }


}
