import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { I18nModule } from '@spartacus/core';
import { KnBrCommonPopUpComponent } from './kn-br-common-pop-up.component';

@NgModule({
  declarations: [KnBrCommonPopUpComponent],
  imports: [CommonModule, FormsModule, I18nModule],
})
export class KnBrCommomPopUpModule {}
