import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard, ConfigModule, RoutingConfig } from '@spartacus/core';
import { CmsPageGuard, PAGE_LAYOUT_HANDLER, PageLayoutComponent } from '@spartacus/storefront';

import { KnBrDraftOrderDeleteItemsModule } from './kn-br-draft-order-delete-items/kn-br-draft-order-delete-items.module';
import { KnBrDraftOrderDetailHeaderModule } from './kn-br-draft-order-detail-header/kn-br-draft-order-detail-header.module';
import { KnBrDraftOrderDetailLayoutHandler } from './kn-br-draft-order-detail.layout.hadler';
import { KnBrDraftOrderDownloadLinkModule } from './kn-br-draft-order-download-link/kn-br-draft-order-download-link.module';
import { KnBrDraftOrderItemsModule } from './kn-br-draft-order-items/kn-br-draft-order-items.module';
import { KnBrDraftOrderCommentsPopupModule } from './kn-br-draft-order-comments-popup/kn-br-draft-order-comments-popup.module';

import { KnBrDraftOrderAttachmentLinkModule } from './kn-br-draft-order-attachment-link/kn-br-draft-order-attachment-link.module';
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    KnBrDraftOrderDeleteItemsModule,
    KnBrDraftOrderDownloadLinkModule,
    KnBrDraftOrderDetailHeaderModule,
    KnBrDraftOrderItemsModule,
    KnBrDraftOrderCommentsPopupModule,
    RouterModule.forChild([
      {
        data: { pageLabel: '/cart-detail', cxRoute: 'cartDetails' },
        path: null,
        canActivate: [AuthGuard, CmsPageGuard],
        component: PageLayoutComponent,
      },
    ]),
    ConfigModule.withConfig({
      routing: {
        routes: {
          cartDetails: {
            paths: ['cart-detail/:orderCode'],
            paramsMapping: { orderCode: 'code' },
          },
        },
      },
    } as RoutingConfig),
    KnBrDraftOrderAttachmentLinkModule,
  ],
  providers: [
    {
      provide: PAGE_LAYOUT_HANDLER,
      useExisting: KnBrDraftOrderDetailLayoutHandler,
      multi: true,
    },
  ],
})
export class KnBrDraftOrderDetailModule {}
