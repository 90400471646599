import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { GlobalMessageService, GlobalMessageType, UserAddressService } from '@spartacus/core';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import { KnBrCartContextService } from '../kn-br-cart-context/kn-br-cart-context.service';
import {
  KnBrAddressActionTypes,
  KnBrOneTimeAddressFailure,
  KnBrOneTimeAddressSuccess,
  KnBrOrderReturnActions,
} from './kn-br-address.action';
import { KnBrAddressAdapter } from './kn-br-address.adapter';

@Injectable()
export class KnBrAddressEffects {
  @Effect()
  onetimeAddress$ = this.actions$.pipe(
    ofType(KnBrAddressActionTypes.KnBrOneTimeAddressAction),
    mergeMap((action) =>
      this.knbrAddressAdapter.oneTimeAdress(action.payload).pipe(
        mergeMap((data: any) => {
          return [
            new KnBrOneTimeAddressSuccess(data),
            // new CheckoutActions.LoadCheckoutDetails({
            //   userId: this.customerNumber,
            //   cartId: this.cartId,
            // }),
          ];
        }),
        catchError((error: Error) => {
          this.messageService.add({ key: 'knBrMessages.error' }, GlobalMessageType.MSG_TYPE_ERROR);
          return of(new KnBrOneTimeAddressFailure(error));
        })
      )
    )
  );

  @Effect({ dispatch: false })
  oneTimeAddressSuccess$ = this.actions$.pipe(
    ofType(KnBrAddressActionTypes.KnBrOneTimeAddressSuccessAction),
    map((action) => {
      this.userAddressService.loadAddresses();
      this.messageService.add({ key: 'knbrAddressForm.onetime.successMsg' }, GlobalMessageType.MSG_TYPE_CONFIRMATION);
      // this.routeService.go({
      //   cxRoute: 'orderEditConfirmation',
      //   params: { code: action.payload.orderCode, type: 'EditOrder' },
      // });
    })
  );

  @Effect({ dispatch: false })
  onetimeAddressFailure$ = this.actions$.pipe(
    ofType(KnBrAddressActionTypes.KnBrOneTimeAddressFailureAction),
    map((action) => {
      this.messageService.add({ key: 'knBrMessages.error' }, GlobalMessageType.MSG_TYPE_ERROR);
    })
  );

  customerNumber;
  cartId;
  constructor(
    private actions$: Actions<KnBrOrderReturnActions>,
    private knbrAddressAdapter: KnBrAddressAdapter,
    private messageService: GlobalMessageService,
    private userAddressService: UserAddressService,
    protected knBrCustomerContextService: KnBrCustomerContextService,
    protected knBrCartContextService: KnBrCartContextService
  ) {
    this.knBrCustomerContextService.getActiveCustomerNumber().subscribe((response) => {
      this.customerNumber = response ? response : null;
    });
    this.knBrCartContextService.get$.subscribe((response) => {
      this.cartId = response ? response : null;
    });
  }
}
