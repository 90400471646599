<div [formGroup]="caseUpdateForm">
  <div class="common-up">
    <div class="modal-header d-flex align-items-center">
      <h4 class="modal-title">Update Case</h4>
      <button type="button" class="close" aria-label="Close" (click)="dismissModal()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body float-left w-100 pl-3 pr-3 pt-1" >
      <div class="col-12 float-left mt-3 mb-2 pl-0">
        <span class="label-heading">Ticket Id:</span>
        {{ticketInfo.svcCaseNumber}}
      </div>
      <div class="col-12 float-left customersearch-forms login-forms p-0 customer-home-forms">
        <div class="form-group mt-3 col-12 pl-0 float-left mb-3 p-res-0 custom-height">
          <textarea type="text" class="form-control w-100 font-sm-size" placeholder="Enter Comment" formControlName="updateComment"></textarea>
          <span class="highlight"></span>
          <label class="text-muted">Update Comment</label>
          <div *ngIf="caseUpdateForm.get('updateComment') && caseUpdateForm.get('updateComment').errors">
            <small class="text-danger">
              Can't be more than 512 characters
            </small>
          </div>
        </div>
      </div>
      <input type="text" class="hide-unknown"/>
      <div class="col-12 pl-0">
        <h5 class="font-text-bold mb-3">Upload Documents</h5>
      </div>
      <ng-container *ngIf="showSpinner">
        <cx-spinner class="cx-dialog-popover-center-backdrop"></cx-spinner>
      </ng-container>
      <div [ngClass]="uploadedFileObjects.length > 0 ? 'border' : '' ">
        <div class="col-12 d-flex upload-background" [ngClass]="uploadedFileObjects.length === 0 ? 'border' : ''">
          <div>
            <input type="file" class="file-input" formControlName="fileInput"
            (change)="onFileSelected($event)" #fileUpload>
            <button class="btn btn-outline-primary m-2 upload-file-styles" (click)="fileUpload.click()">Choose files</button>
          </div>
          <div *ngIf="uploadedFileObjects.length === 0" class="ml-5 font-sm-size d-flex"><span class="align-content-center">Select Files to Upload</span></div>
        </div>
        <div *ngIf="uploadedFileObjects.length" [ngClass]="uploadedFileObjects.length > 3 ? 'upload-container': ''">
          <div *ngFor="let eachFile of uploadedFileObjects" class="file-styles">
            <div class="col-12 d-flex ">
              <div class="d-flex">
                <div>
                  <img src="assets/images/document-icon.svg" class="pb-1 mr-4">
                </div>
                <div class="d-block">
                  <div class="font-sm-size">{{eachFile.fileName}}</div>
                  <div class="font-xs-size">{{eachFile.fileSize}} KB</div>
                </div>
              </div>
              <div class="ml-auto" (click)="removeSelectedFile(eachFile.fileReferenceId)">
                <img src="assets/images/close-icon-upload.svg" class="pb-1">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex">
        <div class="font-xs-size ml-auto mt-2"> Max file size 200 MB</div>
      </div>

      <div class="text-center">
        <button type="button" class="btn btn-outline-primary mr-3" (click)="resetTheDialogForm()">Reset</button>
        <button type="button" class="btn btn-primary" (click)="handleUpdateClick($event)" [disabled]="disableUpdateButton">Update</button>
      </div>
    </div>
    <!-- <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="dismissModal()">Ok</button>
      <button type="button" class="btn btn-outline-primary" (click)="dismissModal()">Cancel</button>
    </div> -->
  </div>
</div>
