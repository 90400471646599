import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { KnBrQuoteEntryEffects } from './kn-br-quote-entry.effects';
import { knBrQuoteEntryReducer } from './kn-br-quote-entry.reducer';
import { KN_BR_QUOTE_ENTRY_FEATURE } from './kn-br-quote-entry.state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(KN_BR_QUOTE_ENTRY_FEATURE, knBrQuoteEntryReducer),
    EffectsModule.forFeature([KnBrQuoteEntryEffects]),
  ],
})
export class KnBrQuoteEntryStateModule {}
