import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { KnBrAddressEffects } from './kn-br-address.effects';
import { knBrAddressReducer } from './kn-br-address.reducer';
import { KN_BR_ADDRESS_FEATURE } from './kn-br-address.state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(KN_BR_ADDRESS_FEATURE, knBrAddressReducer),
    EffectsModule.forFeature([KnBrAddressEffects]),
  ],
})
export class KnBrAddressStateModule {}
