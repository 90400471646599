import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { I18nModule, UrlModule } from '@spartacus/core';
import {
  GlobalMessageComponentModule,
  OutletRefModule,
  PageComponentModule,
  PageLayoutModule,
  PageSlotModule,
} from '@spartacus/storefront';
import { SidebarModule } from 'ng-sidebar';

import { KnBrOutletComponent } from './kn-br-outlet.component';
import { KnBrCustomPipesModule } from 'src/app/shared/pipe/kn-br-custom-pipes/kn-br-custom-pipes.module';

@NgModule({
  imports: [
    CommonModule,
    OutletRefModule,
    PageComponentModule,
    PageLayoutModule,
    PageSlotModule,
    RouterModule,
    UrlModule,
    NgbModule,
    GlobalMessageComponentModule,
    I18nModule,
    KnBrCustomPipesModule,
    SidebarModule.forRoot(),
  ],
  declarations: [KnBrOutletComponent],
  exports: [KnBrOutletComponent],
})
export class KnBrOutletModule {}
