import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, queueScheduler } from 'rxjs';
import { observeOn } from 'rxjs/operators';

import { KnBrQuoteEntry } from '../../models/kn-br-quote.model';
import {
  KnBrQuoteAddEntry,
  KnBrQuoteRemoveEntry,
  KnBrQuoteUpdateEntry,
  KnBrQuoteAddEntries,
} from './kn-br-quote-entry.actions';
import {
  getQuoteEntry,
  getQuoteEntryLoading,
  getQuoteEntrySaveDisabled,
  getQuoteQuickEntryLoader,
} from './kn-br-quote-entry.selector';
import { StateWithQuoteEntry } from './kn-br-quote-entry.state';

@Injectable({
  providedIn: 'root',
})
export class KnBrQuoteEntryService {
  constructor(private store: Store<StateWithQuoteEntry>) {}

  get$: Observable<any> = this.store.pipe(select(getQuoteEntry), observeOn(queueScheduler));

  isSaveDisabled$: Observable<any> = this.store.pipe(select(getQuoteEntrySaveDisabled), observeOn(queueScheduler));
  showLoadingWhileUpdate$: Observable<any> = this.store.pipe(select(getQuoteEntryLoading), observeOn(queueScheduler));
  quickEntryLoader$: Observable<boolean> = this.store.pipe(select(getQuoteQuickEntryLoader), observeOn(queueScheduler));
  add(quoteEntry: KnBrQuoteEntry) {
    this.store.dispatch(new KnBrQuoteAddEntry(quoteEntry));
  }

  delete(quoteEntry: any) {
    this.store.dispatch(new KnBrQuoteRemoveEntry(quoteEntry));
  }
  update(quoteEntry: KnBrQuoteEntry) {
    this.store.dispatch(new KnBrQuoteUpdateEntry(quoteEntry));
  }
  addEntries(payload) {
    this.store.dispatch(new KnBrQuoteAddEntries(payload));
  }
}
