<div class="bg-white customersearch-list-titles table-content border-top float-left w-100 px-2 py-3 d-flex flex-column quote-details" *ngIf="entry && entry?.showLineItem" [ngClass]="{'cursor-auto': entry.product?.showInCatalog !== 'Yes'}">
  <div class="d-flex align-items-center">
    <div class="col-md-6 title-wrap padding-res-0 pl-0 pr-0 float-left d-flex align-items-center">
      <div class="padding-res-0 float-left pr-2">
        <div class="remember-me col-md-12 float-left d-flex justify-content-start p-0">
          <div class="form-check custom-checkbox" *ngIf="!isDisabled()">
            <input type="checkbox" class="custom-control-input" [checked]="isChecked(entry.entryNumber)"
              [id]="entry.entryNumber" (change)="changeCheckBox($event)" />
            <label class="custom-control-label color-font" [for]="entry.entryNumber"></label>
          </div>
        </div>
      </div>
      <cx-media [container]="entry.product?.images" format="thumbnail"> </cx-media>
      <div class="m-res-0 ml-3 text-left float-left product-name" [routerLink]="{ cxRoute: 'product', params: entry.product } | cxUrl" *ngIf="entry.product?.showInCatalog === 'Yes'">
        <span class="color-font font-text-400">{{ entry.product?.code }}</span>&nbsp;
        <span class="color-font font-text-600">{{ entry.product?.name }}</span><br>
        <span hidden>{{ this.splitMaterialText(entry.materialSalesText) }}</span><br>
        <p *ngFor="let item of this.materialText" class="color-font">{{ item }}<br /></p>

        <span hidden>{{ this.splitSalesText(entry?.quoteItemText) }}</span>
        <p *ngFor="let item of this.salesText" class="color-font">{{ item }}<br /></p>
      </div>
      <div class="m-res-0 ml-3 text-left float-left product-name" *ngIf="entry.product?.showInCatalog !== 'Yes'">
        <span class="color-font font-text-400">{{ entry.product?.code }}</span>&nbsp;
        <span class="color-font font-text-600">{{ entry.product?.name }}</span><br>
        <span hidden>{{ this.splitMaterialText(entry?.materialSalesText) }}</span><br>
        <p *ngFor="let item of this.materialText" class="color-font">{{ item }}<br /></p>

        <span hidden>{{ this.splitSalesText(entry?.quoteItemText) }}</span>
        <p *ngFor="let item of this.salesText" class="color-font">{{ item }}<br /></p>
      </div>
    </div>

    <div class="col-md-1 text-center title-wrap float-left pl-2 pr-2 color-font">
      <span class="res-titles"> {{ 'quoteItems.header.netPrice' | cxTranslate }}</span>
      {{ this.netPriceUnit }}
    </div>

    <div class="col-md-1 text-left title-wrap float-left pl-2 pr-2">
      <span class="res-titles"> {{ 'quoteItems.header.quantity' | cxTranslate }}</span>

      <div class="form-group col-lg-12 pl-0 mb-0 pr-0 float-left valid-to">
        <input type="text" class="form-control w-100" [formControl]="quantity" (blur)="update($event)"
          knBrNumbersOnly />
        <span class="highlight"></span>
        <span class="col-md-12 float-left p-0 font-sm-size color-font">
          {{ 'cartCommon.label.uom' | cxTranslate }}: {{ entry.uom }}
        </span>
      </div>
    </div>
    <div class="col-md-1 title-wrap float-left pl-2 pr-2 pt-2 color-font font-size">
      <span class="res-titles"> {{ 'quoteItems.header.status' | cxTranslate }}</span>
      {{ entry.status }}
    </div>

    <div class="col-md-1 title-wrap text-align-center pl-1 pr-1 color-font">
      <span class="res-titles"> {{ 'quoteItems.header.total' | cxTranslate }}</span>
      {{ this.totalPrice }}
    </div>

    <div class="col-md-2 hyperlink float-left pl-1 pr-1">
      <span *ngIf="entry.orderRefData.length === 0">-</span>
      <span class="m-2" *ngFor="let item of entry.orderRefData | slice: 0:4; let i = index">
        <a (click)="navigateToOrderDetails(item?.orderNumber)">{{ item.orderNumber }} | {{ item.poNumber }}</a><br>
      </span>
      <a *ngIf="entry.orderRefData.length > 6" (click)="openPopup(entry.orderRefData, entry.product?.code)">Show More ({{entry.orderRefData.length - 4}})</a>
    </div>
  </div>

  <div class="item w-100 float-left align-items-center pt-3 pl-3 pr-3 pb-0"
    *ngIf="entry.orderRefEntries && entry.orderRefEntries.length">
    <app-kn-br-quote-partial-item [togglePartialOrder]="entry.togglePartialOrder"
      [orderRefEntries]="entry.orderRefEntries"></app-kn-br-quote-partial-item>
  </div>
</div>
