<ng-container *ngIf="order$ | async as order">
  <div class="col-md-12 float-right d-flex justify-content-end">
    <button class="btn back-btn p-1 font-lg-size" (click)="goBack()">
      <i class="fas fa-level-down-alt fa-rotate-90" aria-hidden="true"></i>
    </button>
  </div>
  <div class="float-left px-0 my-4">
    <div class="col-md-12 float-left px-0">
      <div class="col-md-3 float-left">
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrOrderDetails.label.orderNumber' | cxTranslate }}
        </label>
        <p class="text-muted mb-2">{{ order.code | removeLeadingZeros }}</p>

        <label class="color-font font-text-600 mb-1">
          {{ 'knbrOrderDetails.label.brand' | cxTranslate }}
        </label>
        <p class="text-muted mb-2">{{ order.brand }}</p>

        <kn-br-common-address [address]="order.soldTo" [isSoldTo]="true" [addressLabel]="'Sold To Address'"></kn-br-common-address>

        <label class="color-font font-text-600 mb-1">
          {{ 'knbrOrderDetails.label.accountPhone' | cxTranslate }}
        </label>
        <p class="text-muted mb-2">{{ this.formatPhone(order.deliveryAddress?.phone) }}</p>
      </div>

      <div class="col-md-3 float-left">
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrOrderDetails.label.poNumber' | cxTranslate }}
        </label>
        <p class="text-muted mb-2">{{ order.purchaseOrderNumber }}</p>

        <label class="color-font font-text-600 mb-1">
          {{ 'knbrOrderDetails.label.orderType' | cxTranslate }}
        </label>
        <p class="text-muted mb-2">{{ order.orderType }}</p>

        <kn-br-common-address [address]="order.deliveryAddress" [addressLabel]="'Ship To Address'"></kn-br-common-address>

        <div>
          <label class="color-font font-text-600 mb-1">
            {{ 'knbrOrderDetails.label.deliveryBlock' | cxTranslate }}
          </label>
          <p class="text-muted mb-2">{{ order.deliveryBlock ? order.deliveryBlock : '-' }}</p>
        </div>
      </div>

      <div class="col-md-3 float-left">
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrOrderDetails.label.accountNumber' | cxTranslate }}
        </label>
        <p class="text-muted mb-2">{{ order.soldTo?.customerNumber | removeLeadingZeros }}</p>

        <label class="color-font font-text-600 mb-1">
          {{ 'knbrOrderDetails.label.orderStatus' | cxTranslate }}
        </label>
        <p class="text-muted mb-2">{{ order.statusDisplay }}</p>

        <label class="color-font font-text-600 mb-1">
          {{ 'knbrOrderDetails.label.jobNumber' | cxTranslate }}
        </label>
        <p class="text-muted mb-2">{{ order.jobName ? order.jobName : '-' }}</p>

        <label class="color-font font-text-600 mb-1">
          {{ 'knbrOrderDetails.label.shippingNotes' | cxTranslate }}
        </label>
        <p class="text-muted mb-2">{{ order.notes ? order.notes : '-' }}</p>
      </div>

      <div class="col-md-3 float-left">
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrOrderDetails.label.orderCreationDate' | cxTranslate }}
        </label>
        <p class="text-muted mb-2">{{ order.creationDate }}</p>

        <label class="color-font font-text-600 mb-1">
          {{ 'knbrOrderDetails.label.orderTotal' | cxTranslate }}
        </label>
        <p class="text-muted mb-2">{{ order.totalPrice?.formattedValue }}</p>

        <label class="color-font font-text-600 mb-1">
          {{ 'knbrOrderDetails.label.creditStatus' | cxTranslate }}
        </label>
        <p class="text-muted mb-2">{{ order.creditStatus }}</p>

        <label *ngIf="order.carlisleSaleDocs" class="color-font font-text-600 mb-1">
          {{ 'knbrOrderDetails.label.downloadDocs' | cxTranslate }}
        </label>
        <p class="float-left mb-2">
          <a (click)="downloadPDF(doc)" class="text-muted cursor-pointer pt-2 w-100 float-left doc-link"
          *ngFor="let doc of order.carlisleSaleDocs">{{ doc?.documentName }}</a>
        </p>

       <div>
        <label class="color-font font-text-600 mb-1">
          Case Management
        </label>
        <button class="mt-3 btn btn-primary" (click)="navigateToCaseCreation()" ngbTooltip="{{createCasetoolTipText}}" triggers="hover" placement="top-right">
          <img src="assets/images/create-case-icon.svg" class="pb-1">
          Submit Case
        </button>
       </div>
      </div>
    </div>
    <div class="col-md-1 float-left">
      <cx-page-slot position="ActionSlot"></cx-page-slot>
    </div>
  </div>
</ng-container>
