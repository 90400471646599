import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigModule } from '@spartacus/core';
import { knBrUserCmsConfig, knBrUserRoutingConfig, knBrUserTableConfig } from './kn-br-organization-users.config';
import { KnBrB2BUserNormalizer } from '../converter/kn-br-b2b-user.converter';
import { KnBrB2BUserSerializer } from '../serializer/kn-br-b2b-user-serializer';
import {
  AdminGuard,
  B2B_USERS_NORMALIZER,
  B2B_USER_NORMALIZER,
  B2B_USER_SERIALIZER,
} from '@spartacus/organization/administration/core';
import { KnBrB2BUserListNormalizer } from '../converter/kn-br-b2b-user-list-normalizer';
import { KnBrUserFormModule } from './add-user-form/kn-br-user-form.module';
import { UserFormService } from '@spartacus/organization/administration/components';
import { KnBrUserFormService } from './add-user-form/kn-br-user-form.service';
import { KnBrUserDetailsModule } from './user-details/kn-br-user-details.module';
import { KnBrAdminGuard } from '../shared/guards/kn-br-admin.guard';

@NgModule({
  imports: [
    CommonModule,
    KnBrUserDetailsModule,
    KnBrUserFormModule,
    ConfigModule.withConfig(knBrUserRoutingConfig),
    ConfigModule.withConfig(knBrUserTableConfig),
    ConfigModule.withConfig(knBrUserCmsConfig),
  ],
  providers: [
    {
      provide: UserFormService,
      useClass: KnBrUserFormService,
    },
    {
      provide: AdminGuard,
      useClass: KnBrAdminGuard,
    },
    {
      provide: B2B_USER_NORMALIZER,
      useClass: KnBrB2BUserNormalizer,
      multi: true,
    },
    {
      provide: B2B_USER_SERIALIZER,
      useClass: KnBrB2BUserSerializer,
      multi: true,
    },
    {
      provide: B2B_USERS_NORMALIZER,
      useClass: KnBrB2BUserListNormalizer,
      multi: true,
    },
  ],
})
export class KnBrOrganizationUsersModule {}
