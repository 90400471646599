import { KnBrQuoteContextActions, KnBrQuoteContextActionTypes } from './kn-br-quote-context.actions';
import { KnBrQuoteContextState } from './kn-br-quote-context.state';

export const initialState: KnBrQuoteContextState = {
  quoteContext: undefined,
};

export function knBrQuoteContextReducer(state = initialState, action: KnBrQuoteContextActions): KnBrQuoteContextState {
  switch (action.type) {
    case KnBrQuoteContextActionTypes.KnBrQuoteContextLoadSuccessAction:
      return { ...state, quoteContext: action.payload };
    case KnBrQuoteContextActionTypes.KnBrQuoteContextAddUpdateAction:
      return { ...state, quoteContext: action.payload };
    case KnBrQuoteContextActionTypes.KnBrQuoteContextRemoveAction:
      return { ...state, quoteContext: null };
    case KnBrQuoteContextActionTypes.KnBrQuoteContextResetAction:
      return { quoteContext: null };
    default:
      return state;
  }
}
