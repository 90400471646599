import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { RoutingService } from '@spartacus/core';
import { KnBrCommonService } from '../services/kn-br-common.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'knbr-kn-br-profile',
  templateUrl: './kn-br-profile.component.html',
  styleUrls: ['./kn-br-profile.component.scss']
})
export class KnBrProfileComponent implements OnInit {
  profileDetails;
  notificationListData: [];
  subscription = new Subscription();
  profileDetailsForm: UntypedFormGroup;
  profileData$: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor(
    protected fb: UntypedFormBuilder,
    protected cdr: ChangeDetectorRef,
    protected routingService: RoutingService,
    public knBrCommonService: KnBrCommonService
  ) {
    this.knBrCommonService.getProfileDetails();
  }

  ngOnInit(): void {
    this.subscription.add(
      this.knBrCommonService.profileData$.subscribe(res => {
        if (res) {
          this.profileDetails = res;
          this.notificationListData = res.notificationListData;
          this.initForm();
          this.cdr.detectChanges();
        }
      })
    )
  }

  initForm() {
    this.profileDetailsForm = this.fb.group({
      firstName: this.profileDetails?.firstName,
      lastName: this.profileDetails?.lastName,
      preferredName: this.profileDetails?.preferredName,
      businessEmail: this.profileDetails?.emailId,
      role: this.profileDetails?.role,
      customerGroup: this.profileDetails?.userType,
      businessPhone: this.profileDetails?.businessMobile,
      mobileNumber: this.profileDetails?.mobileNumber,
      alternateMobileNumber: null,
    });
  }

  goBack() {
    this.routingService.go({ cxRoute: 'invoiceList' }, { state: { displayPersistedValues: true }});
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.knBrCommonService.profileData$.next(null);
    }
  }
}
