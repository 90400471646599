<div class="col-md-12 float-left customersearch-forms login-forms p-0 customer-home-forms mb-4">
  <form [formGroup]="invoiceSearchForm" (ngSubmit)="submitForm('', true)">
    <div class="form-group col-lg-6 pl-0 multi-select-styles mt-4">
      <ng-select *ngIf="!hasContext" [items]="customersDataList$ | async" [clearSearchOnAdd]="true"
        [closeOnSelect]="false" labelForId="state" [multiple]="true"  [placeholder]="'invoiceSearchForm.label.customersId' | cxTranslate"
        clearAllText="Clear" formControlName="customersId" [bindLabel]="'Text'" [ngModel]="pCustomersId">
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
          <div class="row">
            <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{standalone: true}"/>
              {{item['Text']}}
          </div>
        </ng-template>
        <ng-template ng-multi-label-tmp let-items="items">
          <div class="ng-value" *ngFor="let item of items | slice:0:1">
            {{item['Text']}}
          </div>
          <div class="ng-value" *ngIf="items.length > 1">
            <span class="ng-value-label">{{items.length - 1}} more...</span>
          </div>
        </ng-template>
      </ng-select>
      <input *ngIf="hasContext"
        type="text"
        class="form-control w-100"
        [placeholder]="'invoiceSearchForm.label.customersId' | cxTranslate"
        formControlName="customersId"
      />
      <span class="highlight"></span>
      <label class="text-muted">{{ 'invoiceSearchForm.label.customersId' | cxTranslate }}</label>
    </div>

    <div class="form-group mt-3 col-lg-3 pl-0 float-left mb-3 p-res-0">
      <input
        type="text"
        class="form-control w-100"
        [placeholder]="'invoiceSearchForm.label.purchaseOrder' | cxTranslate"
        formControlName="purchaseOrderNumber"
      />
      <span class="highlight"></span>
      <label class="text-muted">{{ 'invoiceSearchForm.label.purchaseOrder' | cxTranslate }}</label>
    </div>

    <div class="form-group mt-3 col-lg-3 pl-0 float-left mb-3 p-res-0 multi-select-styles">
      <ng-select  [items]="invoiceStatusList$  | async" [clearSearchOnAdd]="true"
      [closeOnSelect]="false" labelForId="state" [multiple]="true"  [placeholder]="'invoiceSearchForm.label.status' | cxTranslate"
      clearAllText="Clear" formControlName="status" [bindLabel]="'Text'" [ngModel]="pStatus">
      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
        <div class="row">
          <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"    [ngModelOptions]="{standalone: true}"/>
        {{item['Text']}}
      </div>
      </ng-template>
      <ng-template ng-multi-label-tmp let-items="items">
        <div class="ng-value" *ngFor="let item of items | slice:0:1">
          {{item['Text']}}
        </div>
        <div class="ng-value" *ngIf="items.length > 1">
          <span class="ng-value-label">{{items.length - 1}} more...</span>
        </div>
      </ng-template>
    </ng-select>

      <span class="highlight"></span>
      <label class="text-muted">{{ 'invoiceSearchForm.label.status' | cxTranslate }}</label>
    </div>

    <div class="form-group mt-3 col-lg-3 pl-0 float-left mb-3 p-res-0 multi-select-styles">
      <ng-select  [items]="invoiceDocumentTypeList$  | async" [clearSearchOnAdd]="true"
      [closeOnSelect]="false" labelForId="state" [multiple]="true"  [placeholder]="'invoiceSearchForm.label.documentType' | cxTranslate"
      clearAllText="Clear" formControlName="documentType" [bindLabel]="'Text'" [ngModel]="pDocumentType">
      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
        <div class="row">
          <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"    [ngModelOptions]="{standalone: true}"/>
        {{item['Text']}}
      </div>
      </ng-template>
      <ng-template ng-multi-label-tmp let-items="items">
        <div class="ng-value" *ngFor="let item of items | slice:0:1">
          {{item['Text']}}
        </div>
        <div class="ng-value" *ngIf="items.length > 1">
          <span class="ng-value-label">{{items.length - 1}} more...</span>
        </div>
      </ng-template>
    </ng-select>

      <span class="highlight"></span>
      <label class="text-muted">{{ 'invoiceSearchForm.label.documentType' | cxTranslate }}</label>
    </div>
    <div class="form-group mt-3 col-lg-3 pl-0 float-left mb-3 p-res-0">
      <input
        type="text"
        class="form-control w-100"
        [placeholder]="'invoiceSearchForm.label.document' | cxTranslate"
        formControlName="documentNo"
      />
      <span class="highlight"></span>
      <label class="text-muted">{{ 'invoiceSearchForm.label.document' | cxTranslate }}</label>
    </div>

    <div class="form-group mt-3 col-lg-3 pl-0 float-left mb-3 p-res-0 multi-select-styles" *ngIf="brandList$ | async as brands">
      <ng-select  [items]="brands" [clearSearchOnAdd]="true"
      [closeOnSelect]="false" labelForId="state" [multiple]="true"  [placeholder]="'invoiceSearchForm.label.brand' | cxTranslate"
      clearAllText="Clear" formControlName="brandName" [bindLabel]="'name'" [ngModel]="pBrandName">
      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
        <div class="row">
          <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"    [ngModelOptions]="{standalone: true}"/>
        {{item['name']}}
      </div>
      </ng-template>
      <ng-template ng-multi-label-tmp let-items="items">
        <div class="ng-value" *ngFor="let item of items | slice:0:1">
          {{item['name']}}
        </div>
        <div class="ng-value" *ngIf="items.length > 1">
          <span class="ng-value-label">{{items.length - 1}} more...</span>
        </div>
      </ng-template>
    </ng-select>

      <span class="highlight"></span>
      <label class="text-muted">{{ 'invoiceSearchForm.label.brand' | cxTranslate }}</label>
    </div>
    <!-- <div class="form-group mt-3 col-lg-3 pl-0 float-left mb-3 p-res-0" *ngIf="brandList$ | async as brands">
      <ng-select class="mb-1" formControlName="brandName" [items]="brands"
        [placeholder]="'invoiceSearchForm.label.brand' | cxTranslate" [searchable]="false" bindLabel="name" bindValue="value" [clearable]="false">
      </ng-select>
      <span class="highlight"></span>
      <label class="text-muted">{{ 'invoiceSearchForm.label.brand' | cxTranslate }}</label>
    </div> -->

    <div class="form-group mt-3 col-lg-3 pl-0 float-left mb-2 p-res-0 valid-to">
      <div class="input-group">
        <input class="form-control w-100 mb-0 dateControls" [placeholder]="'invoiceSearchForm.label.from' | cxTranslate"
          name="fromDate" ngbDatepicker #fromDate="ngbDatepicker" formControlName="_startDate" [readonly]="true" [maxDate]="maxStartDate()" (dateSelect)="onDateChange($event, 'fDate')"  />
        <span class="highlight"></span>
        <label class="text-muted">{{ 'invoiceSearchForm.label.from' | cxTranslate }}</label>
          <div class="input-group-append">
          <button class="btn btn calendar p-0 h-auto w-auto" container="body" (click)="fromDate.toggle()"
            type="button">
            <i class="far fa-calendar-alt color-font header-icon-color"></i>
          </button>
          </div>
      </div>
      <ng-container *ngIf="invoiceSearchForm.controls['_startDate'].errors as errors">
        <!-- <div *ngIf="errors.required">
          Please select from date
        </div> -->
      </ng-container>
    </div>

    <div class="form-group mt-3 col-lg-3 pl-0 float-left mb-3 p-res-0 valid-to">
      <div class="input-group">
        <input class="form-control w-100 mb-0 dateControls" [placeholder]="'invoiceSearchForm.label.to' | cxTranslate" name="endDate"
          ngbDatepicker #endDate="ngbDatepicker" formControlName="_endDate" [readonly]="true" [maxDate]="maxEndDate()" (dateSelect)="onDateChange($event)"  />
        <span class="highlight"></span>
        <label class="text-muted">{{ 'invoiceSearchForm.label.to' | cxTranslate }}</label>
        <div class="input-group-append d-inline-block">
          <button class="btn btn calendar p-0 h-auto w-auto" container="body" (click)="endDate.toggle()"
            type="button">
            <i class="far fa-calendar-alt color-font header-icon-color"></i>
          </button>
        </div>
      </div>
      <!-- <div *ngIf="invoiceSearchForm.controls['_endDate'].errors as errors" class="mt-25">
        Please select to date
      </div> -->
    </div>

    <div class="form-group mt-3 col-lg-3 pl-0 float-left mb-3 p-res-0">
      <input type="text" class="form-control w-100" [placeholder]="'invoiceSearchForm.label.jobName' | cxTranslate" formControlName="jobName"/>
      <span class="highlight"></span>
      <label class="text-muted">{{ 'invoiceSearchForm.label.jobName' | cxTranslate }}</label>
    </div>

    <div class="col-lg-12 p-0 float-left">
      <div class="col-lg-2 pl-0 p-res-0 float-left">
        <button type="submit" [disabled]="isSearchDisabled$ | async" class="w-100 btn btn-primary">
          {{ 'button.search' | cxTranslate }}
        </button>
      </div>
      <div class="col-lg-2 pl-0 p-res-0 float-left">
        <button type="button" (click)="resetForm()" class="w-100 btn btn-outline-primary">
          {{ 'button.reset' | cxTranslate }}
        </button>
      </div>
    </div>
  </form>
</div>
