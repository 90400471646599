import { Component, OnInit } from '@angular/core';
import { RoutingService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { KnBrCommonService } from '../services/kn-br-common.service';

@Component({
  selector: 'kn-br-login-approver',
  templateUrl: './kn-br-login-approver.component.html',
  styleUrls: ['./kn-br-login-approver.component.scss'],
})
export class KnBrLoginApproverComponent implements OnInit {
  successMessage$: Observable<string> = this.commonService.approverStatusSuccess$;
  failureMessage$: Observable<string> = this.commonService.approverStatusFailure$;

  constructor(protected routingService: RoutingService, protected commonService: KnBrCommonService) {
    this.routingService.getRouterState().subscribe((state) => {
      if (state?.state?.queryParams) {
        this.commonService.updateApproverStatus(state.state.queryParams);
      } else {
        this.routingService.go(['/']);
      }
    });
  }

  ngOnInit(): void {}
}
