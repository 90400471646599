import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActiveCartService, MultiCartService } from '@spartacus/cart/base/core';

import { GlobalMessageService, GlobalMessageType, OccEndpointsService, RoutingService } from '@spartacus/core';
import { FileSaverService } from 'ngx-filesaver';
import { BehaviorSubject, Observable } from 'rxjs';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import {
  KNBR_CANCEL_ORDER_STATUS_LIST,
  KNBR_GET_LANDING_PAGE,
  KNBR_INVOICE_DOCUMENT_TYPE_LIST,
  KNBR_INVOICE_FILTER_BY_LIST,
  KNBR_INVOICE_STATUS_LIST,
  KNBR_ORDER_ACTIONS,
  KNBR_ORDER_IMPORT,
  KNBR_ORDER_RETURN_REASON,
  KNBR_ORDER_STATUS_LIST,
  KN_BR_AUTHENTICATE,
  KN_BR_CUSTOMER_CURRENT,
  KNBR_SERVICE_TICKET_CATEGORY_LIST,
  KNBR_CREATE_SERVICE_TICKET,
  KNBR_GET_JOB_FUNCTIONS,
  KNBR_MY_ACCOUNT_PRICE_LIST,
  KN_BR_DRAFT_ORDER_CHECKOUT_VALIDATION,
  KNBR_ORDER_DELIVERY_BLOCK_LIST,
  KNBR_ORDER_TYPE_LIST,
  KNBR_ORDER_CREDIT_STATUS_LIST,
  KNBR_BRAND_SALES_SHIPTO_LIST,
  KNBR_BRAND_ACTIVE_SALES,
  KNBR_BRAND_LIST,
  KNBR_SEND_PRICE_EMAIL,
  KNBR_ORDER_DOCS_DOWNLOAD,
  GET_USER_PK,
  KNBR_MULTI_DOWNLOAD,
  KN_BR_VALIDITY_STATUSES,
  KNBR_CATEGORY_LIST_FOR_BRAND,
  KNBR_ORDER_DOCS_DOWNLOAD_SINGLE,
  KN_BR_BREAD_CRUMBS,
  KNBR_INVOICE_DETAILS,
  KNBR_PROFILE_DETAILS,
} from 'src/constants/api.endpoints.constant';

import {
  KN_BR_RESET_PASSWORD,
  KNBR_DRAFT_ORDER_ATTACHMENT_UPLOAD,
  KNBR_QUOTE_ATTACHMENT_UPLOAD,
  KNBR_QUOTE_DOWNLOAD,
  KNBR_RETURN_ORDER_STATUS_LIST,
} from '../../constants/api.endpoints.constant';
import { KnBrCartContextService } from '../store/kn-br-cart-context/kn-br-cart-context.service';
import { KnBrQuoteContextService } from '../store/kn-br-quote-context/kn-br-quote-context.service';
import { KnBrQuotesService } from '../store/kn-br-quotes/kn-br-quotes.service';
import {
  KN_BR_ORDER_STATUSES,
  KN_BR_QUOTE_APPROVER_ACTION,
  KN_BR_QUOTE_STATUSES,
  KN_BR_QUOTE_SOLD_TO_CITY
} from './../../constants/api.endpoints.constant';
import { Statuses } from './../models/kn-br-draft-order.model';
import { sortBy } from 'lodash';
import * as moment from 'moment';
import { KnBrDateHelper } from '../shared/kn-br-date.helper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { KnBrCommonPopUpComponent } from '../kn-br-customer360/kn-br-common-pop-up/kn-br-common-pop-up.component';

@Injectable({
  providedIn: 'root',
})
export class KnBrCommonService {
  orderStatusesResult$: BehaviorSubject<Statuses[]> = new BehaviorSubject(null);
  sharePDPQuantity$: BehaviorSubject<number> = new BehaviorSubject(0);
  quoteStatusesResult$: BehaviorSubject<Statuses[]> = new BehaviorSubject(null);
  validityStatusesResult$: BehaviorSubject<Statuses[]> = new BehaviorSubject(null);
  quoteSoldToCitiesResult$: BehaviorSubject<Statuses[]> = new BehaviorSubject(null);
  selectCartEntry$: BehaviorSubject<number[]> = new BehaviorSubject(null);
  approverStatusSuccess$: BehaviorSubject<string> = new BehaviorSubject(null);
  downloadFlagResult$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  quoteListSelectedProductResult$: BehaviorSubject<string[]> = new BehaviorSubject([]);
  approverStatusFailure$: BehaviorSubject<string> = new BehaviorSubject(null);
  orderStatusList$: BehaviorSubject<Statuses[]> = new BehaviorSubject(null);
  brandSalesShipToList$: BehaviorSubject<any> = new BehaviorSubject(null);
  brandList$: BehaviorSubject<any> = new BehaviorSubject(null);
  productActiveSalse$: BehaviorSubject<any> = new BehaviorSubject(null);
  cancelOrderStatusList$: BehaviorSubject<Statuses[]> = new BehaviorSubject(null);
  orderReturnReasonList$: BehaviorSubject<Statuses[]> = new BehaviorSubject(null);
  returnOrderStatusList$: BehaviorSubject<Statuses[]> = new BehaviorSubject(null);
  invoiceStatusList$: BehaviorSubject<Statuses[]> = new BehaviorSubject(null);
  invoiceFilterByList$: BehaviorSubject<Statuses[]> = new BehaviorSubject(null);
  invoiceDocumentTypeList$: BehaviorSubject<Statuses[]> = new BehaviorSubject(null);
  orderActionsList$: BehaviorSubject<string[]> = new BehaviorSubject(null);
  redirectionDetail$: BehaviorSubject<any> = new BehaviorSubject(null);
  serviceTicketCateogryList$: BehaviorSubject<Statuses[]> = new BehaviorSubject(null);
  jobFunctionList$: BehaviorSubject<Statuses[]> = new BehaviorSubject(null);
  showSpinner$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  checkoutValidationResponse$: BehaviorSubject<any> = new BehaviorSubject(null);
  orderTypeList$: BehaviorSubject<Statuses[]> = new BehaviorSubject(null);
  creditStatusList$: BehaviorSubject<Statuses[]> = new BehaviorSubject(null);
  deliveryBlockList$: BehaviorSubject<Statuses[]> = new BehaviorSubject(null);
  hasValidDates$: BehaviorSubject<any> = new BehaviorSubject(null);
  invoiceData$: BehaviorSubject<any> = new BehaviorSubject(null);
  profileData$: BehaviorSubject<any> = new BehaviorSubject(null);
  customerId: string;
  quoteId: string;
  cartId: string;
  fromDate: any;
  toDate: any;
  modalRef: any;
  startDownload = false;
  formattedFromDate: any;
  formattedToDate: any;

  constructor(
    private endPointService: OccEndpointsService,
    private httpClient: HttpClient,
    private globalMessageService: GlobalMessageService,
    private routingService: RoutingService,
    private fileSaver: FileSaverService,
    protected knBrCustomerContextService: KnBrCustomerContextService,
    protected knBrQuoteService: KnBrQuotesService,
    protected knBrQuoteContextService: KnBrQuoteContextService,
    protected activeCartService: ActiveCartService,
    protected multiCartService: MultiCartService,
    private knBrCartContextService: KnBrCartContextService,
    private knBrDateHelper: KnBrDateHelper,
    protected modalService: NgbModal
  ) {
    this.knBrCustomerContextService.getActiveCustomerNumber().subscribe((response) => {
      this.customerId = response ? response : KN_BR_CUSTOMER_CURRENT;
    });
    this.knBrQuoteContextService.get$.subscribe((response) => {
      this.quoteId = response ? response : null;
    });
    this.activeCartService.getActiveCartId().subscribe((response) => {
      this.cartId = response ? response : null;
    });
  }

  getOrderStatusesList(customerId: string) {
    this.httpClient.get(this.endPointService.buildUrl(KN_BR_ORDER_STATUSES(customerId))).subscribe((response: any) => {
      this.orderStatusesResult$.next(response as Statuses[]);
    });
  }

  loadOrderStatuses$(): Observable<Statuses[]> {
    return this.orderStatusesResult$.asObservable();
  }

  getQuoteStatusesList(customerId: string) {
    this.httpClient.get(this.endPointService.buildUrl(KN_BR_QUOTE_STATUSES(customerId))).subscribe((response: any) => {
      this.quoteStatusesResult$.next(response as Statuses[]);
    });
  }

  loadQuoteStatuses$(): Observable<Statuses[]> {
    return this.quoteStatusesResult$.asObservable();
  }

  getValidityList(customerId: string) {
    this.httpClient
      .get(this.endPointService.buildUrl(KN_BR_VALIDITY_STATUSES(customerId)))
      .subscribe((response: any) => {
        this.validityStatusesResult$.next(response as Statuses[]);
      });
  }

  loadValidityStatuses$(): Observable<Statuses[]> {
    return this.validityStatusesResult$.asObservable();
  }

  getQuoteSoldToCityList(customerId: string) {
    this.httpClient
      .get(this.endPointService.buildUrl(KN_BR_QUOTE_SOLD_TO_CITY(customerId)))
      .subscribe((response: any) => {
        const cities = [];
        for (const [key, value] of Object.entries(response)) {
          cities.push({ Key: key, Text: value });
        }
        const sortSoldCity = this.alphabeticalSort(cities, 'Text');
        this.quoteSoldToCitiesResult$.next(sortSoldCity as any[]);
      });
  }

  loadSoldToCities$(): Observable<Statuses[]> {
    return this.quoteSoldToCitiesResult$.asObservable();
  }

  setPDPQuantity(quantity: number) {
    this.sharePDPQuantity$.next(quantity);
  }

  loadPDPQuantity(): Observable<number> {
    return this.sharePDPQuantity$.asObservable();
  }

  setCartEntry(quantity: number[]) {
    this.selectCartEntry$.next(quantity);
  }

  loadCartEntry(): Observable<number[]> {
    return this.selectCartEntry$.asObservable();
  }

  updateApproverStatus(payload) {
    this.httpClient.post(this.endPointService.buildUrl(KN_BR_QUOTE_APPROVER_ACTION, null, payload), null).subscribe(
      (response: any) => {
        this.approverStatusSuccess$.next(response);
      },
      (error) => {
        this.approverStatusFailure$.next(error);
      }
    );
  }

  resetPassword(userId) {
    this.httpClient.get(this.endPointService.buildUrl(KN_BR_RESET_PASSWORD, { queryParams: { userId } })).subscribe(
      (response: any) => {
        this.globalMessageService.add({ raw: response?.message }, GlobalMessageType.MSG_TYPE_CONFIRMATION);

        this.routingService.go({ cxRoute: 'login' });
      },
      (error) => {
        this.globalMessageService.add({ key: 'knBrMessages.error' }, GlobalMessageType.MSG_TYPE_ERROR);
      }
    );
  }

  downloadQuote(quoteId, multiple = false) {
    this.globalMessageService.add({ key: 'knBrMessages.downloadInProgress' }, GlobalMessageType.MSG_TYPE_INFO);
    const HTTPOptions = {
      headers: new HttpHeaders({ Accept: 'application/pdf; charset=UTF-8' }),
      observe: 'response' as 'body', // to display the full response & as 'body' for type cast
      responseType: 'blob' as 'json',
    };
    this.httpClient
      .get(
        this.endPointService.buildUrl(KNBR_QUOTE_DOWNLOAD, {
          urlParams: {
            customerId: this.customerId,
            quoteId,
          },
        }),
        HTTPOptions
      )
      .subscribe(
        (response: HttpResponse<any>) => {
          if (multiple) {
            this.fileSaver.save(response.body as any, 'Quotes.zip');
          } else {
            let fileName: string;
            if (response.headers) {
              fileName = this.getFileNameFromResponse(response);
            }
            if (!fileName) {
              fileName = quoteId + '.pdf';
            }
            this.fileSaver.save(response.body as any, fileName);
          }
          this.globalMessageService.add(
            { key: 'knBrMessages.downloadIsCompleted' },
            GlobalMessageType.MSG_TYPE_CONFIRMATION
          );
        },
        (error) => {
          this.globalMessageService.add({ key: 'knBrMessages.downloadIsFailed' }, GlobalMessageType.MSG_TYPE_ERROR);
        }
      );
  }

  getFileNameFromResponse(response: HttpResponse<any>) {
    const contentDisposition = response.headers.get('Content-Disposition');
    if (contentDisposition) {
      const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = fileNameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        return matches[1].replace(/['"]/g, '');
      }
    }
  }

  setDownloadFlag(downloadFlag: boolean) {
    this.downloadFlagResult$.next(downloadFlag);
  }

  loadDownloadFlag$(): Observable<boolean> {
    return this.downloadFlagResult$.asObservable();
  }
  setQuoteListSelectedProducts(products: string[]) {
    this.quoteListSelectedProductResult$.next(products);
  }
  loadQuoteListSelectedProducts$(): Observable<string[]> {
    return this.quoteListSelectedProductResult$.asObservable();
  }

  uploadQuoteAttachment(formData) {
    this.globalMessageService.add({ key: 'knBrMessages.uploadInProgress' }, GlobalMessageType.MSG_TYPE_INFO);
    this.httpClient
      .post(
        this.endPointService.buildUrl(KNBR_QUOTE_ATTACHMENT_UPLOAD, {
          urlParams: {
            customerId: this.customerId,
            quoteId: this.quoteId,
          },
        }),
        formData
      )
      .subscribe(
        (response: any) => {
          this.knBrQuoteService.get(this.quoteId);
          this.globalMessageService.add(
            { key: 'knBrMessages.uploadIsCompleted' },
            GlobalMessageType.MSG_TYPE_CONFIRMATION
          );
        },
        (error) => {
          this.globalMessageService.add({ key: 'knBrMessages.uploadIsFailed' }, GlobalMessageType.MSG_TYPE_ERROR);
        }
      );
  }

  deleteAttachment(fileName) {
    this.httpClient
      .delete(
        this.endPointService.buildUrl(KNBR_QUOTE_ATTACHMENT_UPLOAD, {
          urlParams: {
            customerId: this.customerId,
            quoteId: this.quoteId,
          },
          queryParams: { fileName },
        })
      )
      .subscribe(
        (response: any) => {
          this.knBrQuoteService.get(this.quoteId);
          this.globalMessageService.add(
            { key: 'knBrMessages.attachmentRemovedSuccess' },
            GlobalMessageType.MSG_TYPE_CONFIRMATION
          );
        },
        (error) => {
          this.globalMessageService.add(
            { key: 'knBrMessages.attachmentRemovedFailed' },
            GlobalMessageType.MSG_TYPE_ERROR
          );
        }
      );
  }

  uploadDraftOrderAttachment(formData) {
    this.globalMessageService.add({ key: 'knBrMessages.uploadInProgress' }, GlobalMessageType.MSG_TYPE_INFO);
    this.httpClient
      .post(
        this.endPointService.buildUrl(KNBR_DRAFT_ORDER_ATTACHMENT_UPLOAD, {
          urlParams: {
            customerId: this.customerId,
            cartId: this.cartId,
          },
        }),
        formData
      )
      .subscribe(
        (response: any) => {
          this.multiCartService.loadCart({
            userId: null,
            cartId: this.cartId,
            extraData: {
              active: true,
            },
          });
          this.globalMessageService.add(
            { key: 'knBrMessages.uploadIsCompleted' },
            GlobalMessageType.MSG_TYPE_CONFIRMATION
          );
        },
        (error) => {
          this.globalMessageService.add({ key: 'knBrMessages.uploadIsFailed' }, GlobalMessageType.MSG_TYPE_ERROR);
        }
      );
  }

  deleteDraftOrderAttachment(fileName) {
    this.httpClient
      .delete(
        this.endPointService.buildUrl(KNBR_DRAFT_ORDER_ATTACHMENT_UPLOAD, {
          urlParams: {
            customerId: this.customerId,
            cartId: this.cartId,
          },
          queryParams: { fileName },
        })
      )
      .subscribe(
        (response: any) => {
          this.multiCartService.loadCart({
            userId: null,
            cartId: this.cartId,
            extraData: {
              active: true,
            },
          });
          this.globalMessageService.add(
            { key: 'knBrMessages.attachmentRemovedSuccess' },
            GlobalMessageType.MSG_TYPE_CONFIRMATION
          );
        },
        (error) => {
          this.globalMessageService.add(
            { key: 'knBrMessages.attachmentRemovedFailed' },
            GlobalMessageType.MSG_TYPE_ERROR
          );
        }
      );
  }

  checkIsValidUser(username) {
    const reqParams = {
      userId: username,
    };

    return this.httpClient.post(this.endPointService.buildUrl(KN_BR_AUTHENTICATE, { queryParams: reqParams }), null);
  }

  getOrderStatusList(customerId: string) {
    this.httpClient
      .get(this.endPointService.buildUrl(KNBR_ORDER_STATUS_LIST(customerId)))
      .subscribe((response: any) => {
        const sortedOrderStatus = this.alphabeticalSort(response, 'Text');
        this.orderStatusList$.next(sortedOrderStatus as Statuses[]);
      });
  }

  loadOrderStatusList$(): Observable<Statuses[]> {
    return this.orderStatusList$.asObservable();
  }

  getBrandSalesShipToList(customerId: string) {
    this.httpClient
      .get(this.endPointService.buildUrl(KNBR_BRAND_SALES_SHIPTO_LIST(customerId)))
      .subscribe((response: any) => {
        Object.keys(response).forEach((key) => {
          const data = response[key];
          const res = Object.keys(data).map((key) => ({ name: data[key], value: key }));
          response[key] = res;
        });
        const sortBrand = this.alphabeticalSort(response.brand, 'name');
        const sortShipto = this.alphabeticalSort(response.shipTo, 'name');
        response.brand = sortBrand;
        response.shipTo = sortShipto;
        response.salesGroup = this.customFormatWithValueAndName(this.alphabeticalSort(response.salesGroup, 'name'));
        response.salesOffice = this.customFormatWithValueAndName(this.alphabeticalSort(response.salesOffice, 'name'));
        this.brandSalesShipToList$.next(response as any);
      });
  }

  loadBrandSalesShipToList$(): Observable<Statuses[]> {
    return this.brandSalesShipToList$.asObservable();
  }

  getBrandList(customerId: string) {
    this.httpClient.get(this.endPointService.buildUrl(KNBR_BRAND_LIST(customerId))).subscribe((response: any) => {
      this.brandList$.next(response as any);
    });
  }

  getCategoryList(selectedBrands) {
    const categoryUrl = this.endPointService.buildUrl(KNBR_CATEGORY_LIST_FOR_BRAND(this.customerId)) + selectedBrands;
    return this.httpClient.get(categoryUrl);
  }

  loadBrandList$(): Observable<Statuses[]> {
    return this.brandList$.asObservable();
  }

  // downlaodBrandPriceListURL(customerId: string, barndId: string): Observable<HttpResponse<Blob>> {
  //   return this.httpClient.get<Blob>(this.endPointService.buildUrl(KNBR_DOWNLOAD_BRAND_LIST(customerId), {queryParams:{brandId: barndId}}), { observe: 'response', responseType: 'blob' as 'json'});
  // }

  sendPriceEmail(customerId: string, payload) {
    return this.httpClient.post(
      this.endPointService.buildUrl(KNBR_SEND_PRICE_EMAIL(customerId), { urlParams: { customerId: this.customerId } }),
      payload
    );
  }

  getActiveSalse(customerId: string) {
    this.httpClient
      .get(this.endPointService.buildUrl(KNBR_BRAND_ACTIVE_SALES(customerId)))
      .subscribe((response: any) => {
        const result = Object.keys(response).map((key) => ({ name: response[key], value: key }));
        const sortedBrandName = this.alphabeticalSort(result, 'name');
        this.productActiveSalse$.next(sortedBrandName as any);
      });
  }

  loadActiveSalse$(): Observable<Statuses[]> {
    return this.productActiveSalse$.asObservable();
  }

  loadInvoiceStatusList$(): Observable<Statuses[]> {
    return this.invoiceStatusList$.asObservable();
  }

  getInvoiceStatusList() {
    this.httpClient
      .get(this.endPointService.buildUrl(KNBR_INVOICE_STATUS_LIST(this.customerId)))
      .subscribe((response: any) => {
        const sortedStatus = this.alphabeticalSort(response, 'Text');
        this.invoiceStatusList$.next(sortedStatus as Statuses[]);
      });
  }

  loadInvoiceFilterByList$(): Observable<Statuses[]> {
    return this.invoiceFilterByList$.asObservable();
  }

  getInvoiceFilterByList() {
    this.httpClient

      .get(this.endPointService.buildUrl(KNBR_INVOICE_FILTER_BY_LIST(this.customerId)))
      .subscribe((response: any) => {
        this.invoiceFilterByList$.next(response as Statuses[]);
      });
  }

  loadInvoiceDocumentTypeList$(): Observable<Statuses[]> {
    return this.invoiceDocumentTypeList$.asObservable();
  }

  getInvoiceDocumentTypeList() {
    this.httpClient
      .get(this.endPointService.buildUrl(KNBR_INVOICE_DOCUMENT_TYPE_LIST(this.customerId)))
      .subscribe((response: any) => {
        const sortedDocType = this.alphabeticalSort(response, 'Text');
        this.invoiceDocumentTypeList$.next(sortedDocType as Statuses[]);
      });
  }
  getCreditStatusList(customerId: string) {
    this.httpClient
      .get(this.endPointService.buildUrl(KNBR_ORDER_CREDIT_STATUS_LIST(customerId)))
      .subscribe((response: any) => {
        const sortedCreditStatus = this.alphabeticalSort(response, 'Text');
        this.creditStatusList$.next(sortedCreditStatus as Statuses[]);
      });
  }
  getOrderTypeList(customerId: string) {
    this.httpClient.get(this.endPointService.buildUrl(KNBR_ORDER_TYPE_LIST(customerId))).subscribe((response: any) => {
      const sortedOrderType = this.alphabeticalSort(response, 'Text');
      this.orderTypeList$.next(sortedOrderType as Statuses[]);
    });
  }
  getDeliveryBlockList(customerId: string) {
    this.httpClient
      .get(this.endPointService.buildUrl(KNBR_ORDER_DELIVERY_BLOCK_LIST(customerId)))
      .subscribe((response: any) => {
        const sortedDeliveryBlock = this.alphabeticalSort(response, 'Text');
        this.deliveryBlockList$.next(sortedDeliveryBlock as Statuses[]);
      });
  }

  loadOrderTypeList$(): Observable<Statuses[]> {
    return this.orderTypeList$.asObservable();
  }

  loadCreditStatusList$(): Observable<Statuses[]> {
    return this.creditStatusList$.asObservable();
  }

  loadDeliveryBlockList$(): Observable<Statuses[]> {
    return this.deliveryBlockList$.asObservable();
  }
  getReturnOrderStatusList(customerId: string) {
    this.httpClient
      .get(
        this.endPointService.buildUrl(KNBR_RETURN_ORDER_STATUS_LIST, {
          urlParams: {
            customerId,
          },
        })
      )
      .subscribe((response: any) => {
        const sortedArray = this.alphabeticalSort(response, 'Text');
        this.returnOrderStatusList$.next(sortedArray as Statuses[]);
      });
  }

  loadReturnOrderStatusList$(): Observable<Statuses[]> {
    return this.returnOrderStatusList$.asObservable();
  }

  getCancelOrderStatusList() {
    this.httpClient

      .get(this.endPointService.buildUrl(KNBR_CANCEL_ORDER_STATUS_LIST(this.customerId)))
      .subscribe((response: any) => {
        this.cancelOrderStatusList$.next(response as Statuses[]);
      });
  }

  loadCancelOrderStatusList$(): Observable<Statuses[]> {
    return this.cancelOrderStatusList$.asObservable();
  }

  getOrderReturnReasonList() {
    this.httpClient

      .get(this.endPointService.buildUrl(KNBR_ORDER_RETURN_REASON(this.customerId)))
      .subscribe((response: any) => {
        this.orderReturnReasonList$.next(response as Statuses[]);
      });
  }

  loadOrderReturnReasonList$(): Observable<Statuses[]> {
    return this.orderReturnReasonList$.asObservable();
  }

  getOrderActionsList(orderCode: string) {
    this.httpClient
      .get(
        this.endPointService.buildUrl(KNBR_ORDER_ACTIONS, {
          urlParams: {
            customerId: this.customerId,
            code: orderCode,
          },
        })
      )
      .subscribe((response: any) => {
        this.orderActionsList$.next(response as string[]);
      });
  }

  loadOrderActionsList$(): Observable<string[]> {
    return this.orderActionsList$.asObservable();
  }

  uploadOrderExcel(formData) {
    this.globalMessageService.add({ key: 'knBrMessages.uploadInProgress' }, GlobalMessageType.MSG_TYPE_INFO, 15000);

    this.httpClient.post(this.endPointService.buildUrl(KNBR_ORDER_IMPORT(this.customerId), null), formData).subscribe(
      (response: any) => {
        if (response && response.code) {
          this.knBrCartContextService.set(response.code);
          this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_INFO);
          this.globalMessageService.add(
            { key: 'knBrMessages.uploadIsCompleted' },
            GlobalMessageType.MSG_TYPE_CONFIRMATION
          );
          if (response.errorMessage && response.errorMessage.length > 0) {
            response.errorMessage.forEach((error: any) => {
              if (error.message) {
                this.globalMessageService.add(
                  { raw: error.message },
                  error.type === 'ERROR' ? GlobalMessageType.MSG_TYPE_ERROR : GlobalMessageType.MSG_TYPE_WARNING
                );
              }
            });
          }
          this.routingService.go({ cxRoute: 'cartDetails', params: { code: response.code } });
        }
      },
      (errorres) => {
        this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_INFO);
        const errors = (errorres?.message || errorres?.details || []) as Array<any>;
        if (errors.length > 0) {
          errors.forEach((error) => {
            const type = error.type.toLowerCase();
            if (type.indexOf('warning') !== -1) {
              this.globalMessageService.add({ raw: error.message }, GlobalMessageType.MSG_TYPE_WARNING);
            } else if (type.indexOf('error') !== -1) {
              this.globalMessageService.add({ raw: error.message }, GlobalMessageType.MSG_TYPE_ERROR);
            }
          });
        } else {
          this.globalMessageService.add({ key: 'knBrMessages.uploadIsFailed' }, GlobalMessageType.MSG_TYPE_ERROR);
        }
      }
    );
  }

  getRedirectionDetail() {
    return this.httpClient.get(this.endPointService.buildUrl(KNBR_GET_LANDING_PAGE));
  }

  loadRedirectionDetail$(): Observable<any> {
    return this.redirectionDetail$.asObservable();
  }

  getServiceTicketCategoryList() {
    this.httpClient
      .get(
        this.endPointService.buildUrl(KNBR_SERVICE_TICKET_CATEGORY_LIST, {
          urlParams: {
            customerId: this.customerId,
          },
        })
      )
      .subscribe((response: any) => {
        this.serviceTicketCateogryList$.next(response as Statuses[]);
      });
  }

  loadServiceTicketCategoryList$(): Observable<Statuses[]> {
    return this.serviceTicketCateogryList$.asObservable();
  }

  createServiceTicket(formData, fileData) {
    return this.httpClient.post(
      this.endPointService.buildUrl(KNBR_CREATE_SERVICE_TICKET, {
        urlParams: {
          customerId: this.customerId,
        },
        queryParams: {
          message: formData.message,
          subject: formData.subject,
          ticketCategory: formData.ticketCategory,
        },
      }),
      fileData
    );
  }

  getJobFunctionList() {
    this.httpClient.get(this.endPointService.buildUrl(KNBR_GET_JOB_FUNCTIONS)).subscribe((response: any) => {
      this.jobFunctionList$.next(response);
    });
  }

  loadJobFunctionList$(): Observable<Statuses[]> {
    return this.jobFunctionList$.asObservable();
  }

  sendMyAccountPriceList() {
    return this.httpClient.get(
      this.endPointService.buildUrl(KNBR_MY_ACCOUNT_PRICE_LIST, {
        urlParams: {
          customerId: this.customerId,
        },
      })
    );
  }

  getQuantityBasedOnMoq(quantity, moq) {
    quantity = parseInt(quantity, 10);
    moq = parseInt(moq, 10);
    if (quantity < moq) {
      return moq;
    } else {
      return quantity;
    }
  }

  setSpinner(showLoading: boolean) {
    this.showSpinner$.next(showLoading);
  }

  loadSpinner$(): Observable<boolean> {
    return this.showSpinner$.asObservable();
  }

  loadCheckoutValidationResponse$(): Observable<any> {
    return this.checkoutValidationResponse$.asObservable();
  }

  clearCheckoutValidationResponse() {
    this.checkoutValidationResponse$.next(null);
  }

  validateOnCheckout() {
    this.httpClient
      .get(
        this.endPointService.buildUrl(KN_BR_DRAFT_ORDER_CHECKOUT_VALIDATION, {
          urlParams: {
            customerId: this.customerId,
            cartId: this.cartId,
          },
        })
      )
      .subscribe((response: any) => {
        this.checkoutValidationResponse$.next(response as any);
      });
  }

  downloadPDF(invoiceDetails) {
    const params = {
      documentNumber: invoiceDetails.documentNumber,
      documentReference: invoiceDetails.documentReference,
    };
    const urlValue = this.endPointService.buildUrl(KNBR_ORDER_DOCS_DOWNLOAD, {
      queryParams: params,
    });
    const options: any = {
      headers: new HttpHeaders({ 'Content-Type': 'file type of an particular document' }),
      responseType: 'arraybuffer',
    };
    this.httpClient.get(urlValue, options).subscribe((response: any) => {
      const blob = new Blob([response], {
        type: 'application/zip',
      });
      const url = window.URL.createObjectURL(blob);

      var a = document.createElement('a');
      document.body.appendChild(a);
      a.href = url;
      a.download = 'selectedpdf';
      a.click();
      window.URL.revokeObjectURL(url);
    });
  }

  downloadSinglePDF(invoiceDetails) {
    const fileName = invoiceDetails.documentName;
    const params = {
      documentNumber: invoiceDetails.documentNumber,
      documentReference: invoiceDetails.documentReference,
    };
    this.httpClient
      .get(
        this.endPointService.buildUrl(KNBR_ORDER_DOCS_DOWNLOAD_SINGLE, {
          queryParams: params,
        })
      )
      .subscribe((response: any) => {
        const linkSource = `data:application/pdf;base64,${response?.binaryData}`;
        const downloadLink = document.createElement('a');
        downloadLink.href = linkSource;
        downloadLink.download = fileName + '.pdf';
        downloadLink.click();
      });
  }

  getUserPk() {
    return this.httpClient.get(this.endPointService.buildUrl(GET_USER_PK));
  }

  alphabeticalSort(response, key) {
    return sortBy(response, key);
  }

  // currently being used only for Sales group and sales office dropdowns
  customFormatWithValueAndName(group) {
    const formattedData = group.map((eachItem: any) => {
      return {
        name: eachItem.value + ' ' + eachItem.name,
        value: eachItem.value,
      };
    });
    return formattedData;
  }

  multiDownload(payload, param1, param2, fileName?: any) {
    const customerId = payload.accountNumber;
    this.httpClient
      .get<Blob>(
        this.endPointService.buildUrl(KNBR_MULTI_DOWNLOAD(customerId, param1, param2), { queryParams: payload }),
        { observe: 'response', responseType: 'blob' as 'json' }
      )
      .subscribe((response) => {
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(new Blob([response.body], { type: response.body.type }));
        downloadLink.download = `${fileName}.xlsx`;
        downloadLink.click();
      });
  }

  dateCheck(data) {
    this.hasValidDates$.next(data);
  }

  clearDateInfo() {
    this.hasValidDates$.next(null);
  }

  dateInfo$(): Observable<any> {
    return this.hasValidDates$.asObservable();
  }

  onDateChange(value, param?) {
    let hasDates = false;
    if (param === 'fDate') {
      this.fromDate = moment(value.year + ',' + value.month + ',' + value.day);
      this.formattedFromDate = this.formatDate(value);
    } else {
      this.toDate = moment(value.year + ',' + value.month + ',' + value.day);
      this.formattedToDate = this.formatDate(value);
    }
    if (this.fromDate?.isValid() && this.toDate?.isValid()) {
      hasDates = true;
      const numberOfDays = this.toDate.diff(this.fromDate, 'days');
      const dateInfo = {
        startDate: this.knBrDateHelper.formatDate(this.formattedFromDate),
        endDate: this.knBrDateHelper.formatDate(this.formattedToDate),
        numberOfDays: numberOfDays,
        hasDates: hasDates,
        startDownload: false,
      };
      this.dateCheck(dateInfo);
    }
  }

  formatDate(date) {
    return {
      day: date.day,
      month: date.month,
      year: date.year,
    };
  }

  downloadItems(data) {
    this.startDownload = data.startDownload;
    this.dateInfo$().subscribe((res) => {
      if (this.startDownload && res === null) {
        this.startDownload = false;
        const header = 'Unable to download list';
        const message = `Please select 'From' and 'To' dates before proceeding to download the orders, the maximum allowed duration is 6 months.`;
        this.openPopup(header, message);
      } else if (res.numberOfDays < 0 || res.numberOfDays > 184) {
        this.startDownload = false;
        const header = 'Download Transaction Time Limit Exceeded';
        const message = 'Transactions can be downloaded for a maximum period of 6 months.';
        this.openPopup(header, message);
      } else if (this.startDownload && !res.startDownload && res.hasDates && res.numberOfDays < 184) {
        this.startDownload = false;
        data.searchCriteria.startDate = res.startDate;
        data.searchCriteria.endDate = res.endDate;
        data.searchCriteria.pageSize = '100000';
        this.multiDownload(data.searchCriteria, data.param1, data.param2, data.fileName);
      }
    });
  }

  openPopup(header, body) {
    let modalInstance: any;
    this.modalRef = this.modalService.open(KnBrCommonPopUpComponent, {
      centered: true,
      size: 'md',
    });
    modalInstance = this.modalRef.componentInstance;
    modalInstance.header = header;
    modalInstance.message = body;
    modalInstance.isImage = false;
  }

  getBreadCrumbs(customerId, productId, salesOrg) {
    return this.httpClient.get(
      this.endPointService.buildUrl(KN_BR_BREAD_CRUMBS(customerId, productId, salesOrg), {
        urlParams: { customerId, productId, salesOrg },
      })
    );
  }

  disableControls(ctrls) {
    ctrls.forEach((ctrl) => {
      ctrl.disable();
    });
  }

  enableControls(ctrls) {
    ctrls.forEach((ctrl) => {
      ctrl.enable();
    });
  }

  getInvoiceDetails(customerNum, invoiceNum) {
    return this.httpClient
      .get(this.endPointService.buildUrl(KNBR_INVOICE_DETAILS(customerNum, invoiceNum)))
      .subscribe((res) => {
        this.invoiceData$.next(res);
      });
  }

  toFixedAndThousandSeparator(value) {
    const numberToFormat = Number(value);
    return numberToFormat.toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  separator(value) {
    if (value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  }

  getProfileDetails() {
    this.httpClient.get(this.endPointService.buildUrl(KNBR_PROFILE_DETAILS)).subscribe(res => {
    return this.profileData$.next(res);
    })
  }

  formatState(state) {
    if (state?.startsWith('US-')) {
      return state.slice(3);
    } else if (state?.startsWith('CA-')) {
      return state.slice(3);
    } else {
      return state;
    }
  }
}
