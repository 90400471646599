import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CheckoutStepService } from '@spartacus/checkout/base/components';
import { CheckoutConfig } from '@spartacus/checkout/base/root';
import { RoutingConfigService, RoutingService } from '@spartacus/core';
import { ActiveCartService } from '@spartacus/cart/base/core';

@Injectable({
  providedIn: 'root',
})
export class KnBrCheckoutStepService extends CheckoutStepService {
  constructor(
    protected routingService: RoutingService,
    protected checkoutConfig: CheckoutConfig,
    protected routingConfigService: RoutingConfigService,
    protected activeCartService: ActiveCartService
  ) {
    super(routingService, checkoutConfig, routingConfigService);
  }

  back(activatedRoute: ActivatedRoute): void {
    const previousUrl = this.getPreviousCheckoutStepUrl(activatedRoute);
    this.routingService.go(previousUrl === null ? this.goToCartDetail() : previousUrl);
  }

  goToCartDetail() {
    this.activeCartService
      .getActiveCartId()
      .subscribe((cartId) => {
        this.routingService.go({ cxRoute: 'cartDetails', params: { code: cartId } });
      })
      .unsubscribe();
  }
}
