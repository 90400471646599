import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaymentType, Cart, CART_NORMALIZER } from '@spartacus/cart/base/root';
import { CheckoutPaymentTypeAdapter, CHECKOUT_PAYMENT_TYPE_NORMALIZER } from '@spartacus/checkout/b2b/core';
import { ConverterService, Occ, OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { KnBrCustomerContextService } from '../kn-br-customer-context/kn-br-customer-context.service';

const ENDPOINT_PAYMENT_TYPES = 'paymenttypes';

@Injectable()
export class KnBrOccCheckoutPaymentTypeAdapter implements CheckoutPaymentTypeAdapter {
  customerId: string;
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService,
    private customerContextService: KnBrCustomerContextService
  ) {
    this.customerContextService.get$.subscribe((response) => {
      this.customerId = response && response.customerNumber ? response.customerNumber : null;
    });
  }

  loadPaymentTypes(): Observable<PaymentType[]> {
    return this.http.get<Occ.PaymentTypeList>(this.occEndpoints.buildUrl(ENDPOINT_PAYMENT_TYPES)).pipe(
      map((paymentTypeList) => paymentTypeList.paymentTypes),
      this.converter.pipeableMany(CHECKOUT_PAYMENT_TYPE_NORMALIZER)
    );
  }

  setPaymentType(userId: string, cartId: string, paymentType: string, purchaseOrderNumber?: string): Observable<Cart> {
    let httpParams = new HttpParams().set('paymentType', paymentType);
    if (purchaseOrderNumber !== undefined) {
      httpParams = httpParams.set('purchaseOrderNumber', purchaseOrderNumber);
    }
    /* eslint-disable max-len */
    httpParams = httpParams.set(
      'fields',
      'DEFAULT,potentialProductPromotions,appliedProductPromotions,potentialOrderPromotions,appliedOrderPromotions,entries(totalPrice(formattedValue),product(images(FULL),stock(FULL)),basePrice(formattedValue,value),updateable),totalPrice(formattedValue),totalItems,totalPriceWithTax(formattedValue),totalDiscounts(value,formattedValue),subTotal(formattedValue),deliveryItemsQuantity,deliveryCost(formattedValue),totalTax(formattedValue, value),pickupItemsQuantity,net,appliedVouchers,productDiscounts(formattedValue),user'
    );

    return this.http
      .put(
        this.getCartEndpoint(userId) + cartId + '/paymenttype',
        {},
        {
          params: httpParams,
        }
      )
      .pipe(this.converter.pipeable(CART_NORMALIZER));
  }

  protected getCartEndpoint(userId: string): string {
    const cartEndpoint = 'knbr/customer/' + this.customerId + '/carts/';

    return this.occEndpoints.buildUrl(cartEndpoint);
  }
  getPaymentTypes(): Observable<PaymentType[]> {
    return;
  }
}
