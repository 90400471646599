import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Product, ProductScope, ProductService, RoutingService } from '@spartacus/core';
import { CurrentProductService, ProductSummaryComponent } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';

@Component({
  selector: 'kn-br-product-summary',
  templateUrl: './kn-br-product-summary.component.html',
  styleUrls: ['./kn-br-product-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnBrProductSummaryComponent extends ProductSummaryComponent {
  subscription: Subscription;
  pdpProductData;
  product$: Observable<Product | null> = this.currentProductService.getProduct([
    ProductScope.DETAILS
  ]).pipe(tap((_) => {
    this.pdpProductData = _;
    this.knBrCommonService.setSpinner(false)}));
  constructor(
    protected service: CurrentProductService,
    protected productService: ProductService,
    protected routingService: RoutingService,
    private knBrCommonService: KnBrCommonService
  ) {
    super(service);
    this.knBrCommonService.setSpinner(false);
  }

  navigateTo(panelId) {
    const techDocId:any = document.querySelectorAll(`[aria-controls=${panelId}]`);
    if(techDocId[0].getAttribute("aria-expanded") === "false") {
      techDocId[0].click();
    }
    window.scrollBy(0, 500);
  }

  shouldProductandTechnicalDocumentationDisplay() {
    if(this.pdpProductData) {
      const product = this.pdpProductData;
      if (
        product.assetName ||
        product.assetNameEs ||
        product.assetNameFr ||
        product.productSellSheetName ||
        product.productSellSheetNameEs ||
        product.productSellSheetNameFr ||
        product.safetyAssetName ||
        product.safetyAssetNameEs ||
        product.safetyAssetNameFr
      ) {
        return true;
      }
      return false;
    }
    return false;
  }
}
