import { NgModule } from '@angular/core';
import { AsmComponentsModule } from '@spartacus/asm/components';
import { AsmOccModule } from '@spartacus/asm/occ';

import { ProductVariantsModule } from '@spartacus/product/variants';
import { PersonalizationModule } from '@spartacus/tracking/personalization';
import {
  AnonymousConsentsModule,
  AuthModule,
  CostCenterOccModule,
  ExternalRoutesModule,
  ProductModule,
  ProductOccModule,
  UserModule,
  UserOccModule,
} from '@spartacus/core';
import { SmartEditModule } from '@spartacus/smartedit';
import {
  AddressBookModule,
  AnonymousConsentManagementBannerModule,
  AnonymousConsentsDialogModule,
  BannerCarouselModule,
  BannerModule,
  BreadcrumbModule,
  CategoryNavigationModule,
  CmsParagraphModule,
  ConsentManagementModule,
  FooterNavigationModule,
  HamburgerMenuModule,
  JsonLdBuilderModule,
  LinkModule,
  MyCouponsModule,
  MyInterestsModule,
  NavigationEventModule,
  NavigationModule,
  NotificationPreferenceModule,
  // PageEventModule,
  PaymentMethodsModule,
  ProductCarouselModule,
  ProductDetailsPageModule,
  ProductFacetNavigationModule,
  ProductImagesModule,
  ProductIntroModule,
  ProductListingPageModule,
  ProductListModule,
  ProductPageEventModule,
  ProductReferencesModule,
  ProductSummaryModule,
  ProductTabsModule,
  // ProductVariantsModule,
  SearchBoxModule,
  SiteContextSelectorModule,
  StockNotificationModule,
  TabParagraphContainerModule,
  UserComponentModule,
  // UserComponentModule,
} from '@spartacus/storefront';
import { UserAccountModule } from '@spartacus/user/account';
import { UserProfileModule } from '@spartacus/user/profile';
import {
  OrderCancellationModule,
  OrderComponentsModule,
  OrderConfirmationModule,
  OrderDetailsModule,
  OrderHistoryModule,
  OrderReturnModule,
  ReplenishmentOrderDetailsModule,
  ReplenishmentOrderHistoryModule,
  ReturnRequestDetailModule,
  ReturnRequestListModule,
} from '@spartacus/order/components';
import { OrderCoreModule } from '@spartacus/order/core';
import { OrderOccModule } from '@spartacus/order/occ';
import { OrderModule } from '@spartacus/order';
import {
  CloseAccountModule,
  ForgotPasswordModule,
  ResetPasswordModule,
  UpdateEmailModule,
  UpdatePasswordModule,
  UpdateProfileModule,
} from '@spartacus/user/profile/components';
import { AsmFeatureModule } from './features/asm/asm-feature.module';
import { OrganizationAdministrationFeatureModule } from './features/organization/organization-administration-feature.module';
import { SmartEditFeatureModule } from './features/smartedit/smart-edit-feature.module';
import { UserFeatureModule } from './features/user/user-feature.module';
import { CheckoutFeatureModule } from './features/checkout/checkout-feature.module';
import { CartBaseCoreModule, CartPageEventModule } from '@spartacus/cart/base/core';
import { CheckoutComponentsModule } from '@spartacus/checkout/base/components';
import { CheckoutCoreModule } from '@spartacus/checkout/base/core';
import { CheckoutOccModule } from '@spartacus/checkout/base/occ';
import { CartBaseOccModule } from '@spartacus/cart/base/occ';
import { WishListModule } from '@spartacus/cart/wish-list';
import { CartBaseFeatureModule } from './features/cart/checkout-feature.module';
import { CustomerTicketingFeatureModule } from './features/customer-ticketing/customer-ticketing-feature.module';
import { TagManagementFeatureModule } from './tag-management-feature.module';

@NgModule({
  imports: [
    // Auth Core
    AuthModule.forRoot(),

    // Basic Cms Components
    HamburgerMenuModule,
    SiteContextSelectorModule,
    LinkModule,
    BannerModule,
    CmsParagraphModule,
    TabParagraphContainerModule,
    BannerCarouselModule,
    CategoryNavigationModule,
    NavigationModule,
    FooterNavigationModule,
    BreadcrumbModule,

    // User Core
    UserModule,
    UserAccountModule,
    UserProfileModule,
    UserOccModule,
    // UserTransitional_4_2_Module,
    // UserOccTransitional_4_2_Module,
    // User UI
    UserComponentModule,
    AddressBookModule,
    UpdateEmailModule,
    UpdatePasswordModule,
    UpdateProfileModule,
    CloseAccountModule,
    ForgotPasswordModule,
    ResetPasswordModule,
    PaymentMethodsModule,
    NotificationPreferenceModule,
    MyInterestsModule,
    StockNotificationModule,
    ConsentManagementModule,
    MyCouponsModule,

    // Anonymous Consents Core
    AnonymousConsentsModule.forRoot(),
    // Anonymous Consents UI
    AnonymousConsentsDialogModule,
    AnonymousConsentManagementBannerModule,

    // Product Core
    ProductModule.forRoot(),
    ProductOccModule,

    // Product UI
    ProductDetailsPageModule,
    ProductListingPageModule,
    ProductListModule,
    SearchBoxModule,
    ProductFacetNavigationModule,
    ProductTabsModule,
    ProductCarouselModule,
    ProductReferencesModule,
    ProductImagesModule,
    ProductSummaryModule,
    ProductVariantsModule,
    ProductIntroModule,

    // Cart Core
    // CartModule.forRoot(),
    CartBaseFeatureModule,
    CartBaseOccModule,
    CartBaseCoreModule,

    // Cart UI
    // CartComponentModule,
    WishListModule,

    // Checkout Core
    CheckoutCoreModule,
    CheckoutOccModule,
    CostCenterOccModule,
    CheckoutFeatureModule,
    // Checkout UI
    CheckoutComponentsModule,
    OrderConfirmationModule,

    // Order
    OrderHistoryModule,
    OrderDetailsModule,
    OrderCancellationModule,
    OrderReturnModule,
    ReturnRequestListModule,
    ReturnRequestDetailModule,
    ReplenishmentOrderHistoryModule,
    ReplenishmentOrderDetailsModule,
    // ReplenishmentOrderConfirmationModule,
    OrderCoreModule,
    OrderOccModule,
    OrderComponentsModule,
    OrderModule,

    // SmartEdit
    SmartEditModule,
    // Personalization
    PersonalizationModule,

    // Asm Core
    AsmOccModule,
    // Asm UI
    AsmComponentsModule,

    // Page Events
    CartPageEventModule,
    // PageEventModule,
    ProductPageEventModule,
    NavigationEventModule,

    // Customer ticket support
    CustomerTicketingFeatureModule,

    /************************* Opt-in features *************************/
    ExternalRoutesModule.forRoot(), // to opt-in explicitly, added by default schematics
    JsonLdBuilderModule,
    SmartEditFeatureModule,
    UserFeatureModule,
    AsmFeatureModule,
    OrganizationAdministrationFeatureModule,
    TagManagementFeatureModule,

    /************************* External features *************************/
  ],
})
export class SpartacusFeaturesModule {}
