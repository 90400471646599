import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  CheckoutStepService,
  // ShippingAddressComponent
  CheckoutDeliveryAddressComponent,
} from '@spartacus/checkout/base/components';
// import { CheckoutCostCenterFacade, CheckoutDeliveryFacade, PaymentTypeFacade } from '@spartacus/checkout/root';
import {
  // ActiveCartService,
  Address,
  GlobalMessageService,
  QueryState,
  RoutingService,
  TranslationService,
  UserAddressService,
  UserCostCenterService,
} from '@spartacus/core';
import { ActiveCartService } from '@spartacus/cart/base/core';
import {
  Card,
  LaunchDialogService,
  // ModalService
} from '@spartacus/storefront';
import { CheckoutDeliveryAddressFacade, CheckoutDeliveryModesFacade } from '@spartacus/checkout/base/root';
import { B2BCheckoutDeliveryAddressComponent } from '@spartacus/checkout/b2b/components';
import { Observable, Subscription } from 'rxjs';
import { KnBrAddress } from 'src/app/models/kn-br-order.model';
import { KnBrAddressFormComponent } from './../../kn-br-customer360/kn-br-address-book/kn-br-address-form/kn-br-address-form.component';
import { KnBrAddressService } from './../../store/kn-br-address/kn-br-address.service';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { CheckoutCostCenterFacade, CheckoutPaymentTypeFacade } from '@spartacus/checkout/b2b/root';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'cx-shipping-address',
  templateUrl: './kn-br-shipping-address.component.html',
  styleUrls: ['./kn-br-shipping-address.component.scss'],
})
export class KnBrShippingAddressComponent extends B2BCheckoutDeliveryAddressComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  constructor(
    protected routingService: RoutingService,
    protected activeCartService: ActiveCartService,
    protected knBrAddressService: KnBrAddressService,
    protected userAddressService: UserAddressService,
    protected checkoutDeliveryAddressFacade: CheckoutDeliveryAddressFacade,
    protected activatedRoute: ActivatedRoute,
    protected translationService: TranslationService,
    protected activeCartFacade: ActiveCartFacade,
    protected checkoutStepService: CheckoutStepService,
    protected checkoutDeliveryModesFacade: CheckoutDeliveryModesFacade,
    protected globalMessageService: GlobalMessageService,
    protected checkoutCostCenterFacade: CheckoutCostCenterFacade,
    protected checkoutPaymentTypeFacade: CheckoutPaymentTypeFacade,
    protected userCostCenterService: UserCostCenterService,
    private modalService: NgbModal
  ) {
    super(
      userAddressService,
      checkoutDeliveryAddressFacade,
      activatedRoute,
      translationService,
      activeCartFacade,
      checkoutStepService,
      checkoutDeliveryModesFacade,
      globalMessageService,
      checkoutCostCenterFacade,
      checkoutPaymentTypeFacade,
      userCostCenterService
    );
    this.knBrAddressService.oneTimeAddress$.subscribe((oneTimeAddress) => {
      this.userAddressService.getAddresses();
    });
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.isAccountPayment = false;
    this.userAddressService.loadAddresses();
  }

  goToCartDetail() {
    this.activeCartService
      .getActiveCartId()
      .subscribe((cartId) => {
        this.routingService.go({ cxRoute: 'cartDetails', params: { code: cartId } });
      })
      .unsubscribe();
  }

  openAddressForm() {
    let modalInstance: any;
    const modalRef = this.modalService.open(KnBrAddressFormComponent, {
      centered: true,
      size: 'lg',
    });
    modalInstance = modalRef.componentInstance;
    modalInstance.isCheckOut = true;
    modalInstance.showCancelBtn = true;
    modalRef.result
      .then((address) => {
        if (address && address.isOneTime) {
          const onetimeaddress = {
            ...address.address,
            isDisposable: true,
          };
          this.knBrAddressService.createOneTimeAddress(onetimeaddress);
        } else {
          this.addAddress(address.address);
        }
      })
      .catch((error) => {});
  }

  get deliveryAddress$(): Observable<QueryState<Address>> {
    return this.checkoutDeliveryAddressFacade.getDeliveryAddressState();
  }
  get getOtshAddress(): any {
    let OtshAddress = {};
    this.checkoutDeliveryAddressFacade.getDeliveryAddressState().subscribe((address) => {
      OtshAddress['address'] = address?.data;
      OtshAddress['card'] = this.getCardContent(address?.data, { id: address?.data?.id }, '', '', 'Selected');
    });
    return OtshAddress;
  }

  getCardContent(
    address: KnBrAddress,
    selected: any,
    textDefaultShippingAddress: string,
    textShipToThisAddress: string,
    textSelected: string
  ): Card {
    let region = '';
    if (address.region && address.region.isocode) {
      region = address.region.isocode;
    }

    return {
      title: address.defaultAddress ? textDefaultShippingAddress : '',
      textBold: address.firstName + (address.lastName ? ' ' + address.lastName : ''),
      text: [
        (address.houseNumber ? address.houseNumber + ', ' : '') + address.line1,
        address.line2,
        address.town + ', ' + region,
        address.country.isocode + ', ' + address.postalCode,
        this.formatPhone(address.phone)
      ],
      actions: [{ name: textShipToThisAddress, event: 'send' }],
      header: selected && selected.id === address.id ? textSelected : '',
    };
  }

  formatPhone(value) {
    const trim = value?.substring(2);
    const cleaned = ('' + trim).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    } else {
      const removeSpaces = value?.replace(/\s/g, '');
      const trim = removeSpaces?.substring(1);
      const match = trim?.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
      }
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
