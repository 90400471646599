import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { AuthGuard, CmsConfig, ConfigModule, I18nModule, RoutingConfig, UrlModule } from '@spartacus/core';
import { CmsPageGuard, FormErrorsModule, PageLayoutComponent } from '@spartacus/storefront';

import { KnBrCustomerContextGuard } from './../../shared/guards/kn-br-customer-context.guard';
import { KnBrDraftOrderCreateComponent } from './kn-br-draft-order-create.component';

@NgModule({
  declarations: [KnBrDraftOrderCreateComponent],
  imports: [
    CommonModule,
    NgbModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    UrlModule,
    I18nModule,
    FormErrorsModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrDraftOrderCreateComponent: {
          component: KnBrDraftOrderCreateComponent,
          guards: [KnBrCustomerContextGuard],
        },
      },
    } as CmsConfig),
    RouterModule.forChild([
      {
        data: { pageLabel: '/create-cart', cxRoute: 'createCart' },
        path: null,
        canActivate: [AuthGuard, CmsPageGuard],
        component: PageLayoutComponent,
      },
    ]),
    ConfigModule.withConfig({
      routing: {
        routes: {
          createCart: {
            paths: ['copy-cart/:code/:name', 'create-cart'],
          },
        },
      },
    } as RoutingConfig),
  ],
})
export class KnBrDraftOrderCreateModule {}
