<div class="col-lg-12 p-0 order-history" *ngIf="shippingList$ | async as shippingListResult">
    <div class="row d-flex justify-content-end">
      <div class="col-sm-12 col-md-6 col-lg-5 justify-content-start" >
        <ng-container *ngIf="totalItems.length > 0">
          <div class="p-2 mt-2 font-text-400 font-md-size text-muted">
            {{ 'shipmentSearchResult.showingDetail' | cxTranslate:{ current: (currentItems.length), total: (totalItems.length)} }}
          </div>
        </ng-container>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-7 d-flex justify-content-end">
        <button type="button" class="w-40 btn btn-primary" (click)="downloadShipment()" [disabled]="">
          {{ 'shipmentSearchResult.download' | cxTranslate }}  &nbsp; <i class="fas fa-download pr-2" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  <div class="row counter-wrapper">
    <div class="customersearch-list-table float-left col-lg-12 mt-3">
      <div
        class="customersearch-list-titles border-top float-left w-100 pl-2 pr-2 pt-3 pb-3 d-none d-sm-none d-lg-block">
        <div class="col-md-1 padding-res-0 float-left font-text-600 pl-0">
          {{ 'shipmentSearchResult.header.shipment' | cxTranslate }}
        </div>
        <div class="col-md-1 padding-res-0 float-left font-text-600 pl-0">
          {{ 'shipmentSearchResult.header.delivery' | cxTranslate }}
        </div>
        <div class="col-md-1 padding-res-0 float-left font-text-600 pl-0">
          {{ 'shipmentSearchResult.header.shipmentStatus' | cxTranslate }}
        </div>
        <div class="col-md-1 padding-res-0 float-left font-text-600 pl-0">
          {{ 'shipmentSearchForm.label.shippingPoint' | cxTranslate }}
        </div>
        <div class="col-md-1 float-left font-text-600 pl-0">
          {{ 'shipmentSearchResult.header.shipmentDate' | cxTranslate }}
        </div>
        <div class="col-md-1 float-left font-text-600 pl-0">
          {{ 'shipmentSearchResult.header.deliveryDate' | cxTranslate }}
        </div>
        <div class="col-md-1 float-left font-text-600 pl-0">
          {{ 'shipmentSearchResult.header.shipToCity' | cxTranslate }}
        </div>
        <div class="col-md-1 float-left font-text-600 pl-0">
          {{ 'shipmentSearchResult.header.accountName' | cxTranslate }}
        </div>
        <div class="col-md-1 float-left font-text-600 pl-0">
          {{ 'shipmentSearchResult.header.order' | cxTranslate }}
        </div>
        <div class="col-md-1 float-left font-text-600 pl-0 pr-0">
          {{ 'shipmentSearchResult.header.purchaseOrderNumber' | cxTranslate }}
        </div>
        <div class="col-md-1 float-left font-text-600 pl-0">
          {{ 'shipmentSearchResult.header.jobName' | cxTranslate }}
        </div>
        <div class="col-md-1 float-left font-text-600 pl-0">
          {{ 'shipmentSearchResult.header.carrierType' | cxTranslate }}
        </div>
      </div>
      <div class="bg-white customersearch-list-titles table-content border-top float-left w-100 pl-2 pr-2 py-2 font-weight-normal align-items-center" *ngFor="let shippingItem of currentItems; let j = index">
        <div class="row mr-0 ml-0">
          <div class="col-md-1 title-wrap pl-0 font-sm-size color-font">
            <span class="res-titles"> {{ 'shipmentSearchResult.header.shipment' | cxTranslate }}:</span>
            {{ shippingItem.shippingId }}
          </div>
          <div class="col-md-1 float-left title-wrap pl-0 font-sm-size color-font">
            <span class="res-titles"> {{ 'shipmentSearchResult.header.delivery' | cxTranslate }}:</span>
            {{ shippingItem.deliveryId }}
          </div>
          <div class="col-md-1 float-left title-wrap pl-0 font-sm-size color-font">
            <span class="res-titles">{{ 'shipmentSearchResult.header.shipmentStatus' | cxTranslate }}:</span>
            {{ shippingItem.shippingStatus }}
          </div>
          <div class="col-md-1 title-wrap pl-0 font-sm-size color-font">
            <span class="res-titles"> {{ 'shipmentSearchForm.label.shippingPoint' | cxTranslate }}:</span>
            <ng-container *ngIf="shippingPointByIdData$ | async as shippingpointListResult">
              <ng-container *ngFor="let shippingItems of shippingpointListResult">
              <ng-container *ngIf="shippingItem.shippingPoint === shippingItems.Key">
                {{ shippingItems['Text'] }}
              </ng-container>
            </ng-container>
          </ng-container>
          </div>
          <div class="col-md-1 float-left title-wrap pl-0 font-sm-size color-font">
            <span class="res-titles"> {{ 'shipmentSearchResult.header.shipmentDate' | cxTranslate }}:</span>
            {{ shippingItem.shipmentDate | date:"MM/dd/YYYY" }}
          </div>
          <div class="col-md-1 float-left title-wrap pl-0 font-sm-size color-font">
            <span class="res-titles"> {{ 'shipmentSearchResult.header.deliveryDate' | cxTranslate }}:</span>
            {{ shippingItem.deliveryDate | date:"MM/dd/YYYY"}}
          </div>
          <div class="col-md-1 float-left title-wrap pl-0 font-sm-size color-font">
            <span class="res-titles"> {{ 'shipmentSearchResult.header.deliveryAddress' | cxTranslate }}:</span>
            {{ shippingItem.deliveryAddress.city }}, {{ shippingItem.deliveryAddress.state }}
          </div>
          <div class="col-md-1 title-wrap pl-0 font-sm-size color-font">
            <span class="res-titles">{{ 'shipmentSearchResult.header.accountName' | cxTranslate }}:</span>
            {{ shippingItem.customerName }}
          </div>
          <div class="col-md-1 float-left title-wrap pl-0 font-sm-size color-font">
            <span class="res-titles"> {{ 'shipmentSearchResult.header.order' | cxTranslate }}:</span>
            {{ shippingItem.salesOrder }}
          </div>
          <div class="col-md-1 float-left title-wrap pl-0 font-sm-size color-font">
            <span class="res-titles"> {{ 'shipmentSearchResult.header.purchaseOrderNumber' | cxTranslate }}:</span>
            {{ shippingItem.purchaseOrderNumber }}
          </div>
          <div class="col-md-1 float-left title-wrap pl-0 font-sm-size color-font">
            <span class="res-titles"> {{ 'shipmentSearchResult.header.jobName' | cxTranslate }}:</span>
            {{ shippingItem.jobName }}
          </div>
          <div class="col-md-1 float-left title-wrap pl-0 font-sm-size color-font">
            <span class="res-titles"> {{ 'shipmentSearchResult.header.carrierType' | cxTranslate }}:</span>
            {{ shippingItem.carrierType }}
          </div>
        </div>
        <div class="row col-md-12">
          <ng-container>
            <div class="bg-white table-content float-left w-100 font-weight-normal d-flex">
              <ngb-accordion #acc="ngbAccordion" class="scheduled" [closeOthers]="true" (panelChange)="showHide($event, acc)">
                <ngb-panel class="w-100 float-left mb-2 text-muted font-size-18">
                  <ng-template ngbPanelTitle="">
                    <div class="d-flex justify-content-between">
                      <div class="d-flex shipment-delivery-details">Shipment Delivery Details</div>
                      <div class="d-flex"><i class="fas ml-3 mt-1 font-text-600" [ngClass]="this.show && this.index == j ? 'fa-chevron-up' : 'fa-chevron-down'"></i></div>
                    </div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="row counter-wrapper">
                      <div class="customersearch-list-table float-left col-md-12 mt-1">
                        <div class="float-left w-100">
                          <div class="col-md-1 float-left pl-2 py-2 font-weight-bold color-font">{{ 'shipmentSearchResult.header.srno' | cxTranslate }}</div>
                          <div class="col-md-2 float-left pl-2 py-2 font-weight-bold color-font">{{ 'shipmentSearchForm.label.productNumber' | cxTranslate }}</div>
                          <div class="col-md-3 float-left pl-2 py-2 font-weight-bold color-font">{{ 'shipmentSearchForm.label.productName' | cxTranslate }}</div>
                          <div class="col-md-2 float-left pl-0 py-2 font-weight-bold color-font text-align-center" >{{ 'shipmentSearchForm.label.brand' | cxTranslate }}</div>
                          <div class="col-md-2 float-left pl-0 py-2 font-weight-bold color-font text-align-center">{{ 'shipmentSearchResult.header.qtyshipped' | cxTranslate }}</div>
                          <div class="col-md-2 float-left pl-0 pr-0 py-2 font-weight-bold color-font text-align-center">{{ 'shipmentSearchForm.label.shippmentType' | cxTranslate }}</div>
                        </div>
                        <div class="bg-white table-content float-left w-100 py-2 font-weight-normal d-flex" *ngFor="let item of shippingItem.deliveryAddress.deliveryItems; let i = index">
                          <div class="col-md-1 float-left title-wrap pl-2 font-md-size text-muted mb-1">
                            <span class="res-titles"> {{ 'shipmentSearchResult.header.srno' | cxTranslate }}:</span>
                          {{ i + 1 }}
                          </div>
                          <div class="col-md-2 float-left title-wrap pl-2 font-md-size text-muted mb-1">
                            <span class="res-titles">{{ 'shipmentSearchForm.label.productNumber' | cxTranslate }}:</span>
                            {{ item.productId }}
                          </div>
                          <div class="col-md-3 float-left title-wrap pl-2 font-md-size text-muted mb-1">
                            <span class="res-titles">{{ 'shipmentSearchForm.label.productName' | cxTranslate }}:</span>
                            {{ item.productName }}
                          </div>
                          <div class="col-md-2 float-left title-wrap pl-0 font-md-size text-muted mb-1 text-align-center">
                          <span class="res-titles">{{ 'shipmentSearchForm.label.brand' | cxTranslate }}:</span>
                          <ng-container *ngIf="brandList.length">
                            <ng-container *ngFor="let brands of brandList;">
                              <ng-container *ngIf="item.brand === brands.value">
                                {{ brands.name }}
                              </ng-container>
                            </ng-container>
                          </ng-container>
                          </div>
                          <div class="col-md-2 float-left title-wrap pl-0 font-md-size text-muted mb-1 text-align-center">
                            <span class="res-titles">{{ 'shipmentSearchResult.header.qtyshipped' | cxTranslate }}:</span>
                            {{ item.quantityShipped }} {{ item.quantityShipped ? item.quantityUoM : '' }}
                          </div>
                          <div class="col-md-2 float-left title-wrap pl-0 pr-0 font-md-size text-muted mb-1 text-align-center" *ngIf="shipmentTypeList$ | async as shipingTypeResult" >
                            <span class="res-titles">{{ 'shipmentSearchForm.label.shippmentType' | cxTranslate }}:</span>
                            <ng-container *ngIf="item.shippingType === 'Z3' || item.shippingType === 'LT' || item.shippingType === 'Y1'">
                              LTL
                            </ng-container>
                            <ng-container *ngFor="let shippingTypes of shipingTypeResult">
                              <ng-container *ngIf="item.shippingType === shippingTypes.value">
                                {{ shippingTypes['name'] }}
                              </ng-container>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
              <div class="col-md-5 bg-white table-content float-left w-100 p-2 font-weight-normal d-flex">
                <div class="col-md-3 pl-0 pr-0 float-left font-text-600 packing-list">Packing List :</div>
                <div class="col-md-4 pl-0 pr-0">
                  <a class="downloadPdf" (click)="downloadFile(shippingItem)"><u>Download PDF </u></a>
                </div>
                <div class="col-md-3 pl-0 pr-0 float-left font-text-600 tracking-id">Tracking ID :</div>
                <div class="col-md-3 pl-0 pr-0 float-left" *ngIf="shippingItem.shippingNotificationLink; else notTracking">
                  <a target="_blank" href="{{ shippingItem.shippingNotificationLink }}">
                    <u>Tracking</u>
                  </a>
                </div>
                <ng-template #notTracking>
                  <div class="col-md-3 float-left pl-0">Tracking not available</div>
                </ng-template>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <ng-container *ngIf="totalItems.length">
      <div class="col-lg-12 d-flex">
        <div class="col-6 px-0">
          <ng-container *ngIf="totalItems.length">
            <div class="p-2 mt-2 font-text-400 font-md-size text-muted">
              {{ 'shipmentSearchResult.showingDetail' | cxTranslate:{ current: (currentItems.length), total: (totalItems.length)} }}
            </div>
          </ng-container>
        </div>
        <div class="col-6">
          <div class=" w-100 mt-3">
            <div *ngIf="currentItems.length < totalItems.length"
              (click)="loadMore()" class="btn btn-loadmore mt-0 mr-3">
              {{ 'shipmentSearchResult.showMore' | cxTranslate }}
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>


<div class="alert-info p-3 rounded mt-3" *ngIf="totalItems.length === 0">
  {{ 'shipmentSearchResult.nodataMsg' | cxTranslate }}
</div>
