import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, ComponentRef, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbCalendar, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  CmsService,
  ContentSlotData,
  Currency,
  CurrencyService,
  CxDatePipe,
  GlobalMessageService,
  GlobalMessageType,
  RoutingService,
  UserService,
} from '@spartacus/core';
import { LaunchDialogService } from '@spartacus/storefront';
import { ActiveCartService, MultiCartService } from '@spartacus/cart/base/core';
import * as _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { KnBrQuoteRejectCommentsComponent } from 'src/app/kn-br-quote/kn-br-quote-detail/kn-br-quote-header/kn-br-quote-reject-comments/kn-br-quote-reject-comments.component';
import { KnBrCartContextService } from 'src/app/store/kn-br-cart-context/kn-br-cart-context.service';

import { KnBrCommonService } from '../../../services/kn-br-common.service';
import { KnBrDateHelper } from '../../../shared/kn-br-date.helper';
import { KnBrDraftOrdersService } from '../../../store/kn-br-draft-orders/kn-br-draft-orders.service';
import { KnBrDraftOrder } from './../../../models/kn-br-draft-order.model';
import { LAUNCH_CALLER } from '@spartacus/storefront';
import { RemoveLeadingZerosPipe } from 'src/app/shared/pipe/kn-br-custom-pipes/remove-leading-zeros.pipe';
import { UserAccountService } from '@spartacus/user/account/core';
import { CartAdapter } from '@spartacus/cart/base/core';
@Component({
  selector: 'knbr-kn-br-draft-order-detail-header',
  templateUrl: './kn-br-draft-order-detail-header.component.html',
  styleUrls: ['./kn-br-draft-order-detail-header.component.scss'],
  providers: [CxDatePipe, KnBrDateHelper, RemoveLeadingZerosPipe],
})
export class KnBrDraftOrderDetailHeaderComponent implements OnInit, OnDestroy {
  draftOrderEditForm: UntypedFormGroup;
  draftOrder: KnBrDraftOrder;
  userId: any;
  cartId: any;
  cart$ = this.activeCartService.getActive();
  subscription = new Subscription();
  components: ContentSlotData;

  isLoading$ = this.knBrDraftOrderService.loader$;
  dateFormat$: Observable<any> = this.currentUserService.get().pipe(
    filter((user: any) => user && !!user.dateFormat),
    map((user: any) => user.dateFormat)
  );
  activeCurrency: string;
  knBrHeaderDiscount: number;
  isHeaderDiscountUpdate = false;
  statuses$ = this.knBrDraftOrderService.draftOrderStatuses$;
  currencyData$: Observable<string> = this.currencyService.getActive();
  showAddCartEntryLoading$: Observable<boolean> = this.knBrDraftOrderService.loadAddCartEntryLoading$();
  spinnerLoading$: Observable<boolean> = this.knBrDraftOrderService.spinner$;
  showSpinner: void | Observable<ComponentRef<any>>;
  showDetailSpinner: void | Observable<ComponentRef<any>>;
  quoteRefErrorMsq: string;
  checkoutValidationResponse$ = this.knBrCommonService.loadCheckoutValidationResponse$();
  constructor(
    protected currentUserService: UserAccountService,
    protected activeCartService: ActiveCartService,
    protected fb: UntypedFormBuilder,
    protected ngbCalendar: NgbCalendar,
    protected cmsService: CmsService,
    protected knBrCommonService: KnBrCommonService,
    protected cdr: ChangeDetectorRef,
    protected knBrDraftOrderService: KnBrDraftOrdersService,
    protected location: Location,
    protected routingService: RoutingService,
    protected knBrDateHelper: KnBrDateHelper,
    protected multiCartService: MultiCartService,
    protected knBrCartContextService: KnBrCartContextService,
    protected modalService: NgbModal,
    protected currencyService: CurrencyService,
    protected launchDialogService: LaunchDialogService,
    protected vcr: ViewContainerRef,
    protected removeLeadingZerosPipe: RemoveLeadingZerosPipe,
    protected globalMessageService: GlobalMessageService,
    protected cartAdapter: CartAdapter
  ) {
    this.onSpinnerDetailLoading(true);
  }

  ngOnInit(): void {
    this.subscription.add(
      this.currencyData$.subscribe((currency) => {
        this.currencyService.getAll().subscribe((currencies) => {
          if (currency && currencies?.length) {
            const activeCurrencyObj: Currency = _.find(currencies, ['isocode', currency]);
            this.activeCurrency = activeCurrencyObj ? activeCurrencyObj.symbol : null;
          }
        });
      })
    );
    this.knBrCartContextService.get$.subscribe((response) => {
      this.cartId = response ? response : null;
      const cartId = response ? response : null;
      if (cartId) {
        this.currentUserService.get().subscribe((res) => {
          this.userId = res.customerId;
          const userId = res.customerId;
          this.multiCartService.loadCart({
            cartId,
            userId,
            extraData: {
              active: true,
            },
          });
        });
      }
    });
    this.cartAdapter.load(this.userId, this.cartId).subscribe((draftOrder: any) => {
      if (draftOrder && Object.keys(draftOrder).length && draftOrder?.code) {
        this.knBrCommonService.setCartEntry([]);
        this.draftOrder = _.cloneDeep(draftOrder);
        this.draftOrder.code = this.removeLeadingZerosPipe.transform(this.draftOrder.code);
        this.cdr.markForCheck();
      }
      if (this.draftOrder) {
        this.initForm();
        this.patchForm(this.draftOrder);
      }
      this.knBrDraftOrderService.getStatuses();
      this.cdr.markForCheck();
    });
    this.subscription.add(
      this.showAddCartEntryLoading$.subscribe((isLoading) => {
        this.onSpinnerLoading(isLoading);
      })
    );
    this.subscription.add(
      this.spinnerLoading$.subscribe((isLoading) => {
        this.onSpinnerLoading(isLoading);
      })
    );
    this.subscription.add(
      this.activeCartService.isStable().subscribe((isStable) => {
        if (isStable) {
          this.onSpinnerDetailLoading(false);
        }
      })
    );
  }

  onSpinnerDetailLoading(isLoading) {
    if (isLoading) {
      this.showDetailSpinner = this.launchDialogService.launch(LAUNCH_CALLER.PLACE_ORDER_SPINNER, this.vcr);
    } else {
      if (this.showDetailSpinner) {
        this.showDetailSpinner
          .subscribe((component) => {
            this.launchDialogService.clear(LAUNCH_CALLER.PLACE_ORDER_SPINNER);
            component.destroy();
          })
          .unsubscribe();
      }
    }
  }

  onSpinnerLoading(isLoading) {
    if (isLoading) {
      this.showSpinner = this.launchDialogService.launch(LAUNCH_CALLER.PLACE_ORDER_SPINNER, this.vcr);
    } else {
      if (this.showSpinner) {
        this.showSpinner
          .subscribe((component) => {
            this.launchDialogService.clear(LAUNCH_CALLER.PLACE_ORDER_SPINNER);
            component.destroy();
          })
          .unsubscribe();
      }
    }
  }

  initForm() {
    this.draftOrderEditForm = this.fb.group({
      headerComments: [{ value: null, disabled: this.isDisabled() }],
      jobName: [{ value: null, disabled: this.isDisabled() }],
      name: [{ value: null, disabled: this.isDisabled() }, Validators.required],
      purchaseOrderNumber: [{ value: null, disabled: this.isDisabled() }, Validators.required],
      requestedDeliveryDate: [{ value: null, disabled: this.isDisabled() }],
      shipComplete: [{ value: false, disabled: this.isDisabled() }],
      draftOrderStatus: null,
    });
  }

  patchForm(draftOrder: KnBrDraftOrder) {
    this.draftOrderEditForm.patchValue({
      headerComments: draftOrder.headerComments,
      jobName: draftOrder.jobName,
      name: draftOrder.name,
      purchaseOrderNumber: draftOrder.purchaseOrderNumber,
      requestedDeliveryDate: this.knBrDateHelper.getDate(draftOrder.requestedDeliveryDate),
      shipComplete: draftOrder.shipComplete,
      draftOrderStatus: draftOrder.cartStatus,
    });
  }

  onSubmit(isRedirect = false) {
    if (this.draftOrderEditForm?.valid && this.draftOrderEditForm?.dirty) {
      const formValues = _.cloneDeep(this.draftOrderEditForm.getRawValue());
      if (this.draftOrderEditForm.controls.requestedDeliveryDate.value) {
        formValues.requestedDeliveryDate = this.knBrDateHelper.formatDate(
          this.draftOrderEditForm.controls.requestedDeliveryDate.value
        );
      }
      if (this.isHeaderDiscountUpdate) {
        formValues.knbrHeaderDiscount = this.knBrHeaderDiscount ? this.knBrHeaderDiscount : '0';
      }
      formValues.isRedirect = isRedirect;
      if (!isRedirect) {
        this.submitOrder(formValues);
      } else {
        const draftOrderStatus = formValues.draftOrderStatus;
        if (draftOrderStatus === 'CART_SELLERAPPROVER_REJECTED' || draftOrderStatus === 'CART_BUYER_PENDING_APPROVAL') {
          this.openRejectQuotePopup(isRedirect, formValues);
        } else {
          this.updateCartStatus(formValues);
        }
      }
    } else {
      this.draftOrderEditForm.markAllAsTouched();
    }
  }

  openRejectQuotePopup(isRedirect, formValues) {
    let modalInstance: any;
    const modalRef = this.modalService.open(KnBrQuoteRejectCommentsComponent, {
      centered: true,
      size: 'lg',
    });
    modalInstance = modalRef.componentInstance;
    modalRef.result
      .then((comment) => {
        formValues.headerComments = comment;
        this.updateCartStatus(formValues);
      })
      .catch((error) => {});
  }

  updateCartStatus(formValues) {
    const payload = {
      headerNotes: formValues.headerComments,
      requestedDeliveryDate: formValues.requestedDeliveryDate
        ? this.knBrDateHelper.formatDate(formValues.requestedDeliveryDate)
        : null,
      jobName: formValues.jobName,
      shipComplete: formValues.shipComplete,
      cartStatus: formValues.draftOrderStatus,
      isRedirect: true,
    };
    this.knBrDraftOrderService.patch(payload);
  }

  submitOrder(formValues) {
    this.knBrDraftOrderService.update(formValues);
  }

  goBack() {
    if (this.draftOrderEditForm?.dirty) {
      this.onSubmit(true);
    } else {
      this.routingService.go({ cxRoute: 'carts' });
    }
  }
  isDisabled() {
    this.cmsService
      .getContentSlot('KnBrDraftOrderHeaderSlot')
      .subscribe((value) => (this.components = value))
      .unsubscribe();

    if (
      (this.components &&
        this.components.components &&
        this.components.components.length &&
        this.components.components[0].flexType === 'KnBrDraftOrderHeaderComponentReadOnly') ||
      !this.draftOrder.editable
    ) {
      return true;
    }
    return false;
  }

  navigate(url: string) {
    // On navigate to checkout page calling validatopn api and once the response is received calling the cart detail api
    // to reload the cart data, in case of quote reference we need to check this as some entries might be effected.
   // const cartId = this.draftOrder.code;
    this.knBrCommonService.validateOnCheckout();
    this.subscription.add(
      // this.knBrDraftOrderService.validateOnCheckout().subscribe((res: any) => {
      this.checkoutValidationResponse$.subscribe((res: any) => {
        this.knBrCartContextService.get$.subscribe((response) => {
          const cartId = response ? response : null;
          if (cartId) {
            this.currentUserService.get().subscribe((res) => {
                  // this.userId = res.customerId;
                  const userId = res.customerId;
                  this.multiCartService.loadCart({
                          cartId,
                          userId,
                          extraData: {
                                active: true,
                             },
                });
            });
          }
        });
        if (res?.errorMessage && res.errorMessage[0].error === 'QUOTE_REF_VALIDATE') {
          this.quoteRefErrorMsq = res.errorMessage[0].message;
          this.knBrCommonService.clearCheckoutValidationResponse();
        } else if (res) {
          this.knBrCommonService.clearCheckoutValidationResponse();
          this.routingService.goByUrl(url);
        }
      })
    );
    // this.routingService.goByUrl(url);
  }

  submitForApproval() {
    const formValues = _.cloneDeep(this.draftOrderEditForm.getRawValue());
    const payload = {
      headerNotes: formValues.headerComments,
      requestedDeliveryDate: formValues.requestedDeliveryDate
        ? this.knBrDateHelper.formatDate(formValues.requestedDeliveryDate)
        : null,
      jobName: formValues.jobName,
      shipComplete: formValues.shipComplete,
      cartStatus: 'DRAFT_SELLER_APPROVED',
    };
    this.knBrDraftOrderService.patch(payload);
  }

  ngOnDestroy(): void {
    this.launchDialogService.clear(LAUNCH_CALLER.PLACE_ORDER_SPINNER);
    this.subscription.unsubscribe();
    this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_INFO);
  }
}
