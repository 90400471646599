<form [formGroup]="quoteEditForm" (ngSubmit)="onSubmit()" *ngIf="knBrQuoteService.get$ | async">
  <fieldset [disabled]="isLoading$ | async">
    <div class="rounded w-100 float-left">
      <div class="float-right text-left p-0 justify-content-end d-flex align-items-center">
        <button class="btn back-btn pl-1 pr-1 pb-1 pt-1 font-lg-size" (click)="goBack()">
          <i class="fas fa-level-down-alt fa-rotate-90" aria-hidden="true"></i>
        </button>
      </div>
    </div>
    <div class="col-md-12 float-left customersearch-forms mt-3 login-forms p-0">
      <div class="col-lg-12 p-0 float-left">
        <div class="form-group mt-3 col-md-2 pl-0 float-left mb-3">
          <input
            type="text"
            class="form-control w-100"
            placeholder="{{ 'quoteCommon.label.quoteOrderNumber' | cxTranslate }}"
            [value]="quoteNumberUI"
            disabled
          />
          <span class="highlight"></span>
          <label class="text-muted">{{ 'quoteCommon.label.quoteOrderNumber' | cxTranslate }}</label>
        </div>

        <div class="form-group mt-3 col-md-2 pl-0 float-left mb-3">
          <input
            type="text"
            class="form-control w-100"
            placeholder="{{ 'quoteCommon.label.validity' | cxTranslate }}"
            formControlName="validity"
          />
          <span class="highlight"></span>
          <label class="text-muted">{{ 'quoteCommon.label.validity' | cxTranslate }}</label>
        </div>

        <div class="form-group mt-3 col-md-2 pl-0 float-left mb-3">
          <input
            type="text"
            class="form-control w-100"
            placeholder="{{ 'quoteCommon.label.status' | cxTranslate }}"
            formControlName="status"
          />
          <span class="highlight"></span>
          <label class="text-muted">{{ 'quoteCommon.label.status' | cxTranslate }}</label>
        </div>

        <div class="form-group mt-3 col-md-2 pl-0 float-left mb-4 valid-to p-res-0 position-relative">
          <label class="text-muted m-0"
            >{{ 'quoteCommon.label.validFrom' | cxTranslate }}
            <span class="text-danger font-size-18 float-right ml-2">*</span></label
          >
          <div class="input-group">
            <input
              class="form-control w-100 cursor-pointer"
              [placeholder]="dateFormat$ | async"
              [maxDate]="quoteEditForm.get('quoteValidTo').value"
              name="validFrom"
              ngbDatepicker
              #validFrom="ngbDatepicker"
              formControlName="quoteValidFrom"
              (blur)="onSubmit()"
              (dateSelect)="onSubmit()"
            />
            <div class="input-group-append">
              <button
                class="btn btn calendar p-0 h-auto w-auto"
                container="body"
                (click)="validFrom.toggle()"
                type="button"
                [disabled]="isDisabled()"
              >
                <i class="far fa-calendar-alt color-font"></i>
              </button>
            </div>
          </div>
          <cx-form-errors
            *ngIf="
              quoteEditForm.get('quoteValidFrom')?.errors?.required ||
              quoteEditForm.get('quoteValidFrom')?.errors?.ngbDate?.invalid
            "
            [control]="quoteEditForm.get('quoteValidFrom')"
          ></cx-form-errors>
          <span
            class="kn-br-form-errors"
            *ngIf="
              quoteEditForm.get('quoteValidFrom')?.errors?.ngbDate?.minDate ||
              quoteEditForm.get('quoteValidFrom')?.errors?.ngbDate?.maxDate
            "
          >
            <p>{{ 'formErrors.invalidDateRange' | cxTranslate }}</p>
          </span>
        </div>
        <div class="form-group mt-3 col-md-2 pl-0 float-left mb-4 valid-to p-res-0 position-relative">
          <label class="text-muted m-0"
            >{{ 'quoteCommon.label.validTo' | cxTranslate }}
            <span class="text-danger font-size-18 float-right ml-2">*</span></label
          >
          <div class="input-group">
            <input
              class="form-control w-100 cursor-pointer"
              [placeholder]="dateFormat$ | async"
              name="quoteValidTo"
              [minDate]="quoteEditForm.get('quoteValidFrom').value"
              ngbDatepicker
              #validTo="ngbDatepicker"
              formControlName="quoteValidTo"
              (blur)="onSubmit()"
              (dateSelect)="onSubmit()"
            />
            <div class="input-group-append">
              <button
                class="btn btn calendar p-0 h-auto w-auto"
                container="body"
                (click)="validTo.toggle()"
                type="button"
                [disabled]="isDisabled()"
              >
                <i class="far fa-calendar-alt color-font"></i>
              </button>
            </div>
          </div>
          <cx-form-errors
            *ngIf="
              quoteEditForm.get('quoteValidTo')?.errors?.required ||
              quoteEditForm.get('quoteValidTo')?.errors?.ngbDate?.invalid
            "
            [control]="quoteEditForm.get('quoteValidTo')"
          ></cx-form-errors>
          <span
            class="kn-br-form-errors"
            *ngIf="
              quoteEditForm.get('quoteValidTo')?.errors?.ngbDate?.minDate ||
              quoteEditForm.get('quoteValidTo')?.errors?.ngbDate?.maxDate
            "
          >
            <p>{{ 'formErrors.invalidDateRange' | cxTranslate }}</p>
          </span>
        </div>

        <div class="form-group mt-3 col-md-2 pl-0 float-left mb-4 valid-to p-res-0 position-relative">
          <label class="text-muted m-0">{{ 'quoteCommon.label.createdOn' | cxTranslate }} </label>
          <input
            type="text"
            class="form-control w-100"
            placeholder="{{ 'quoteCommon.label.creationDate' | cxTranslate }}"
            formControlName="creationDate"
          />
        </div>
      </div>

      <div class="form-group mt-3 col-md-2 pl-0 float-left mb-3">
        <input
          type="text"
          class="form-control w-100"
          placeholder="{{ 'quoteCommon.label.customerPO' | cxTranslate }}"
          formControlName="purchaseOrderNumber"
          (blur)="onSubmit()"
        />
        <span class="highlight"></span>
        <label class="text-muted">{{ 'quoteCommon.label.customerPO' | cxTranslate }} </label>
      </div>

      <div class="form-group mt-3 col-md-2 pl-0 float-left mb-3 p-res-0">
        <ng-select
        [items]="soldToCities$ | async"
        formControlName="soldToCity"
        bindLabel="Text"
        bindValue="Key"
        placeholder="{{ 'quoteSearchForm.label.soldToCity' | cxTranslate }}"
        [searchable]="false"
        [clearable]="false"
        >
        </ng-select>
        <span class="highlight"></span>
        <label class="text-muted">{{ 'quoteSearchForm.label.soldToCity' | cxTranslate }}</label>

      </div>


      <div class="form-group mt-3 col-md-2 pl-0 float-left mb-3">
        <input
          type="text"
          class="form-control w-100"
          placeholder="{{ 'quoteCommon.label.brandName' | cxTranslate }}"
          formControlName="brandName"
          (blur)="onSubmit()"
        />
        <span class="highlight"></span>
        <label class="text-muted">{{ 'quoteCommon.label.brandName' | cxTranslate }} </label>
      </div>

      <div *ngIf="quote.headerText" class="col-lg-12 p-0 float-left mt-2">
        <span hidden>{{this.transformText(quote.headerText)}}</span>
        <span>Notes:</span><br>
        <span *ngFor="let item of noteStr" class="color-font">{{ item }}<br></span>
      </div>

      <div class="col-lg-12 p-0 float-left mt-4">
          <div class="col-lg-3 float-left pl-1 color-font font-size-17 font-text-400 text-muted">
          <label class="float-left  mr-2"> {{ 'quoteCommon.label.totalItems' | cxTranslate }} : </label>
          {{ quote.totalItems }}
        </div>
         <div class="col-lg-3 float-left pl-1 color-font font-size-17 font-text-400 text-muted">
          <label class="float-left  mr-2"> {{ 'quoteCommon.label.totalPrice' | cxTranslate }} : </label>
          {{ quote.totalPrice?.formattedValue }}
        </div>
        <div class="col-lg-6 float-left pl-1 color-font font-size-17 font-text-400 text-muted flex-right">
          <knbr-kn-br-quote-download-link [downloadInfo]="quote?.carlisleSaleDocs" ></knbr-kn-br-quote-download-link>
        </div>
      </div>
    </div>

  </fieldset>
</form>
