import { KnBrCartContextActions, KnBrCartContextActionTypes } from './kn-br-cart-context.actions';
import { KnBrCartContextState } from './kn-br-cart-context.state';

export const initialState: KnBrCartContextState = {
  cartContext: undefined,
};

export function knBrCartContextReducer(state = initialState, action: KnBrCartContextActions): KnBrCartContextState {
  switch (action.type) {
    case KnBrCartContextActionTypes.KnBrCartContextLoadSuccessAction:
      return { ...state, cartContext: action.payload };
    case KnBrCartContextActionTypes.KnBrCartContextAddUpdateAction:
      return { ...state, cartContext: action.payload };
    case KnBrCartContextActionTypes.KnBrCartContextRemoveAction:
      return { ...state, cartContext: null };
    case KnBrCartContextActionTypes.KnBrCartContextResetAction:
      return { cartContext: null };
    default:
      return state;
  }
}
