import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActiveCartService } from '@spartacus/cart/base/core';
import { RoutingService } from '@spartacus/core';
import * as _ from 'lodash';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { KnBrQuoteReference } from 'src/app/models/kn-br-quote.model';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import { KnBrCartContextService } from 'src/app/store/kn-br-cart-context/kn-br-cart-context.service';
import { KnBrQuoteContextService } from 'src/app/store/kn-br-quote-context/kn-br-quote-context.service';

import { KnBrDraftOrderEntry } from '../../models/kn-br-draft-order.model';
import { KnBrQuoteReferenceService } from '../../store/kn-br-quote-reference/kn-br-quote-reference.service';

@Component({
  selector: 'knbr-kn-br-product-quote-reference',
  templateUrl: './kn-br-product-quote-reference.component.html',
  styleUrls: ['./kn-br-product-quote-reference.component.scss'],
})
export class KnBrProductQuoteReferenceComponent implements OnInit, OnDestroy {
  showWarningMessage = false;
  cartEntry: KnBrDraftOrderEntry = { product: {} };
  selectedQuoteReference: KnBrQuoteReference;
  quoteReference$: Observable<KnBrQuoteReference[]> = this.knBrQuoteReferenceService.get$;
  cartContext: any;
  subscription = new Subscription();
  quoteContext: any;
  quoteReference: KnBrQuoteReference[];
  productCode: string;
  quantity: number;
  constructor(
    private knBrQuoteReferenceService: KnBrQuoteReferenceService,
    private knBrQuoteContextService: KnBrQuoteContextService,
    private knBrCartContextService: KnBrCartContextService,
    private activeCartService: ActiveCartService,
    private knBrCommonService: KnBrCommonService,
    private routingService: RoutingService
  ) {
    this.subscription.add(
      this.routingService.getRouterState().subscribe((state) => {
        if (state.state.params.productCode) {
          this.productCode = state.state.params.productCode;
        }
      })
    );
  }

  ngOnInit(): void {
    this.knBrQuoteReferenceService.getQuoteByProduct(this.productCode);
    this.subscription.add(
      combineLatest([this.knBrCartContextService.get$, this.knBrQuoteContextService.get$]).subscribe((data: any) => {
        this.cartContext = data[0];
        this.quoteContext = data[1];
        // if (!this.selectedQuoteReference) {
        this.updateSelectedRef();
        // }
      })
    );
    this.subscription.add(
      this.quoteReference$.subscribe((data) => {
        this.quoteReference = data;
      })
    );
    this.subscription.add(
      this.knBrCommonService.loadPDPQuantity().subscribe((data) => {
        if (data) {
          this.quantity = Number(data);
        }
        if (this.quantity && this.selectedQuoteReference) {
          this.quoteReferenceChange(this.selectedQuoteReference);
        }
      })
    );
  }
  updateSelectedRef() {
    if (this.quoteContext) {
      if (this.selectedQuoteReference) {
        this.deleteQuoteReference();
      }
    }
    //  else {
    //   if (this.quoteReference && this.quoteReference.length && this.quantity) {
    //     const selRef = this.quoteReference.find(
    //       (ref: KnBrQuoteReference) =>
    //         ref.knbrQuoteReference && this.quantity <= ref.knbrQuoteReference.remainingQuantity
    //     );
    //     this.quoteReferenceChange(selRef || this.quoteReference[0]);
    //   }
    // }
  }

  quoteReferenceChange(ref: KnBrQuoteReference) {
    this.showWarningMessage = false;
    this.selectedQuoteReference = ref;
    if (this.quantity > this.selectedQuoteReference.knbrQuoteReference.remainingQuantity) {
      this.showWarningMessage = true;
    }
    this.knBrQuoteReferenceService.pdpQuoteRef = _.cloneDeep(this.selectedQuoteReference);
  }

  deleteQuoteReference() {
    // this.knBrQuoteReferenceService.delete(0);
    this.selectedQuoteReference = undefined;
    this.knBrQuoteReferenceService.pdpQuoteRef = undefined;
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.knBrQuoteReferenceService.pdpQuoteRef = undefined;
    this.selectedQuoteReference = undefined;
    this.quoteReference = undefined;
  }
}
