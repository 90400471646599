import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserAccountFacade } from '@spartacus/user/account/root';
// import { ModalService } from '@spartacus/storefront';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { KnBrDraftOrderComments } from 'src/app/models/kn-br-draft-order.model';
import { KnBrDraftOrdersCommentsService } from 'src/app/store/kn-br-draft-order-comments/kn-br-draft-orders-comments.service';

@Component({
  selector: 'knbr-kn-br-draft-order-comments-popup',
  templateUrl: './kn-br-draft-order-comments-popup.component.html',
  styleUrls: ['./kn-br-draft-order-comments-popup.component.scss'],
})
export class KnBrDraftOrderCommentsPopupComponent implements OnInit {
  comment: KnBrDraftOrderComments;
  entryNumber: number;
  comments$: Observable<KnBrDraftOrderComments[]> = this.knBrDraftOrdersCommentsService.list$;
  text: string;

  dateFormat$: Observable<any> = this.currentUserService.get().pipe(
    filter((user: any) => user && !!user.dateFormat),
    map((user: any) => user.dateFormat)
  );
  editable: boolean;
  constructor(
    protected currentUserService: UserAccountFacade,
    protected knBrDraftOrdersCommentsService: KnBrDraftOrdersCommentsService,
    protected modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.knBrDraftOrdersCommentsService.get(this.entryNumber);
    this.comment = {
      comment: '',
      entryNumber: this.entryNumber,
    };
  }

  saveComment() {
    const comment = _.cloneDeep(this.comment);
    comment.comment = this.text;
    comment.entryNumber = this.entryNumber;
    if (comment.code) {
      this.knBrDraftOrdersCommentsService.update(comment);
    } else {
      this.knBrDraftOrdersCommentsService.create(comment);
    }
    this.resetComment();
  }
  editComment(comment) {
    this.comment = comment;
    this.text = comment.text;
  }

  resetComment() {
    this.comment = {
      text: '',
      entryNumber: this.entryNumber,
    };
    this.text = '';
  }

  deleteComment(comment) {
    const commentObj = _.cloneDeep(comment);
    commentObj.entryNumber = this.entryNumber;
    this.knBrDraftOrdersCommentsService.delete(commentObj);
  }

  dismissModal(reason?: any): void {
    this.modalService.dismissAll(reason);
  }
}
