import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
// import { CartNotEmptyGuard, CheckoutAuthGuard } from "@spartacus/checkout/components";
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import {
  CardModule,
  //   CartSharedModule,
  IconModule,
  PromotionsModule,
} from '@spartacus/storefront';
import { CartSharedModule } from '@spartacus/cart/base/components';
import { KnBrReviewSubmitComponent } from './kn-br-review-submit.component';
import { CheckoutAuthGuard, CartNotEmptyGuard } from '@spartacus/checkout/base/components';

@NgModule({
  imports: [
    CommonModule,
    CardModule,
    CartSharedModule,
    I18nModule,
    UrlModule,
    RouterModule,
    PromotionsModule,
    IconModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CheckoutReviewOrder: {
          component: KnBrReviewSubmitComponent,
          // TODO(#8880): Shouldn't we keep ShippingAddressSetGuard and others here?
          guards: [CheckoutAuthGuard, CartNotEmptyGuard],
        },
      },
    } as CmsConfig),
  ],
  declarations: [KnBrReviewSubmitComponent],
  exports: [KnBrReviewSubmitComponent],
})
export class KnBrReviewSubmitModule {}
