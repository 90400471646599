import { Location } from '@angular/common';
import {
  KnBrCancelOrReturnRequestEntryInput,
  KnBrCancellationRequestEntryInputList,
} from './../../../models/kn-br-order.model';
import { KnBrOrderHistoryService } from './../../../store/kn-br-order-history/kn-br-order-history.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { Statuses } from './../../../models/kn-br-draft-order.model';
import { KnBrCommonService } from './../../../services/kn-br-common.service';
import { Observable, Subscription } from 'rxjs';
import { RoutingService, GlobalMessageService, GlobalMessageType } from '@spartacus/core';
import { Component, OnInit } from '@angular/core';
import { OrderDetailsService } from '@spartacus/order/components';
import { OrderHistoryFacade } from '@spartacus/order/root';
import * as _ from 'lodash';

@Component({
  selector: 'knbr-order-return',
  templateUrl: './kn-br-order-return.component.html',
  styleUrls: ['./kn-br-order-return.component.scss'],
})
export class KnBrOrderReturnComponent implements OnInit {
  isLoading$: Observable<any>;
  reason$: Observable<Statuses[]>;
  isReasonsLoading = false;
  orderCode: string;
  returnOrderForm: UntypedFormGroup;
  order$: Observable<any>;
  returnedItems: KnBrCancelOrReturnRequestEntryInput[] = [];
  subscriprion = new Subscription();
  returnType = 'RMA';
  constructor(
    protected knBrCommonService: KnBrCommonService,
    protected fb: UntypedFormBuilder,
    protected knBrOrderHistoryService: KnBrOrderHistoryService,
    protected routingService: RoutingService,
    protected orderDetailsService: OrderDetailsService,
    protected messageService: GlobalMessageService,
    protected location: Location,
    protected userOrderService: OrderHistoryFacade
  ) {
    this.routingService
      .getRouterState()
      .subscribe((state) => {
        if (state && state.state && state.state.params && state.state.params.orderCode) {
          this.orderCode = state.state.params.orderCode;
          this.returnType = state.state.params.returnType;
          this.userOrderService.loadOrderDetails(this.orderCode);
        }
      })
      .unsubscribe();
    this.order$ = this.orderDetailsService.getOrderDetails();
    this.order$.subscribe((orderDetails) => {
      if (orderDetails && orderDetails.consignments && orderDetails.consignments.length < 1) {
        this.routingService.go({ cxRoute: 'orderDetails', params: { code: this.orderCode } });
      }
    });
    this.isLoading$ = this.knBrOrderHistoryService.loader$;
  }

  initForm() {
    this.returnOrderForm = this.fb.group({
      reason: ['', Validators.required],
      notes: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    this.initForm();
    this.getStatuses();
  }

  getStatuses() {
    this.isReasonsLoading = true;
    this.knBrCommonService.getOrderReturnReasonList();
    this.reason$ = this.knBrCommonService.loadOrderReturnReasonList$().pipe(tap(() => (this.isReasonsLoading = false)));
    this.subscriprion.add(
      this.reason$.subscribe((res) => {
        if (res && res.length > 0 && this.returnType === 'ZRRE') {
          const defective = res.find((reason) => reason.Key === 'DEFECTIVE');
          if (defective) {
            this.returnOrderForm.patchValue({ reason: defective.Key });
          } else {
            this.returnOrderForm.patchValue({ reason: res[0].Key });
          }
        }
      })
    );
  }

  onQuantityChange(eventData: KnBrCancelOrReturnRequestEntryInput) {
    if (eventData.quantity > 0) {
      const index = _.findIndex(this.returnedItems, { orderEntryNumber: eventData.orderEntryNumber });

      // Replace item at index using native splice
      if (index >= 0) {
        this.returnedItems.splice(index, 1, eventData);
      } else {
        this.returnedItems.push(eventData);
      }
    } else {
      _.remove(this.returnedItems, {
        orderEntryNumber: eventData.orderEntryNumber,
      });
    }
  }

  onSubmit() {
    if (this.returnOrderForm.valid) {
      const reqobject: KnBrCancellationRequestEntryInputList = {
        orderCode: this.orderCode,
        returnRequestEntryInputs: this.prepareReturnItems(),
        repReturn: this.returnType === 'ZRRE' ? true : false,
      };
      this.knBrOrderHistoryService.createReturnOrder(reqobject);
    } else {
      this.returnOrderForm.markAllAsTouched();
      this.messageService.add({ key: 'knBrOrderReturn.noteserrorMsg' }, GlobalMessageType.MSG_TYPE_WARNING);
    }
  }

  prepareReturnItems(): KnBrCancelOrReturnRequestEntryInput[] {
    return this.returnedItems.map((returnitem) => ({
      ...returnitem,
      reason: this.returnOrderForm.value.reason,
      notes: this.returnOrderForm.value.notes,
    }));
  }

  goBack() {
    this.location.back();
  }
}
