import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActiveCartService } from '@spartacus/cart/base/core';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import { B2BCheckoutReviewSubmitComponent } from '@spartacus/checkout/b2b/components';
import { CheckoutCostCenterFacade, CheckoutPaymentTypeFacade } from '@spartacus/checkout/b2b/root';
import { CheckoutReviewSubmitComponent, CheckoutStepService } from '@spartacus/checkout/base/components';
import {
  CheckoutDeliveryAddressFacade,
  CheckoutDeliveryModesFacade,
  CheckoutPaymentFacade,
} from '@spartacus/checkout/base/root';
// import {
//   CheckoutCostCenterFacade,
//   CheckoutDeliveryFacade,
//   CheckoutPaymentFacade,
//   PaymentTypeFacade,
// } from '@spartacus/checkout/root';
import { TranslationService, UserAddressService, UserCostCenterService } from '@spartacus/core';
import { Card } from '@spartacus/storefront';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { KnBrAddress } from 'src/app/models/kn-br-order.model';

@Component({
  selector: 'cx-review-submit',
  templateUrl: './kn-br-review-submit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnBrReviewSubmitComponent extends B2BCheckoutReviewSubmitComponent {
  countryName$;
  constructor(
    protected checkoutDeliveryModesFacade: CheckoutDeliveryModesFacade,
    protected checkoutDeliveryService: CheckoutDeliveryAddressFacade,
    protected checkoutPaymentService: CheckoutPaymentFacade,
    protected userAddressService: UserAddressService,
    protected activeCartService: ActiveCartService,
    protected translation: TranslationService,
    protected checkoutStepService: CheckoutStepService,
    protected checkoutPaymentTypeFacade: CheckoutPaymentTypeFacade,
    protected checkoutCostCenterFacade: CheckoutCostCenterFacade,
    protected userCostCenterService: UserCostCenterService
  ) {
    super(
      checkoutDeliveryService,
      checkoutPaymentService,
      activeCartService,
      translation,
      checkoutStepService,
      checkoutDeliveryModesFacade,
      checkoutPaymentTypeFacade,
      checkoutCostCenterFacade,
      userCostCenterService
    );
    this.countryName$ = checkoutDeliveryService?.getDeliveryAddressState().pipe(map((res) => res.data.country));
  }

  getShippingAddressCard(deliveryAddress: KnBrAddress, countryName: string): Observable<Card> {
    return combineLatest([this.translationService.translate('addressCard.shipTo')]).pipe(
      map(([textTitle]) => {
        if (!countryName) {
          countryName = deliveryAddress?.country?.isocode;
        }

        let region = '';
        if (deliveryAddress && deliveryAddress.region && deliveryAddress.region.isocode) {
          region = deliveryAddress.region.isocode;
        }

        return {
          title: textTitle,
          textBold: deliveryAddress.firstName + (deliveryAddress.lastName ? ' ' + deliveryAddress.lastName : ''),
          text: [
            (deliveryAddress.houseNumber ? deliveryAddress.houseNumber + ', ' : '') + deliveryAddress.line1,
            deliveryAddress.line2,
            deliveryAddress.town + ', ' + region,
            countryName + ', ' + deliveryAddress.postalCode,
            deliveryAddress.phone,
          ],
        };
      })
    );
  }
}
