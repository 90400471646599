import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CurrentProductService, ProductImagesComponent } from '@spartacus/storefront';
import { KnBrCommonPopUpComponent } from 'src/app/kn-br-customer360/kn-br-common-pop-up/kn-br-common-pop-up.component';

@Component({
  selector: 'kn-br-product-images',
  templateUrl: './kn-br-product-images.component.html',
  styleUrls: ['./kn-br-product-images.component.scss'],
})
export class KnBrProductImagesComponent extends ProductImagesComponent implements OnInit {
  constructor(private service: CurrentProductService, protected modalService: NgbModal) {
    super(service);
  }
  modalRef: any;
  productName;
  ngOnInit(): void {
    this.service.getProduct().subscribe(res => this.productName = res.name)
  }

  enlargeImg(img) {
    let modalInstance: any;
    this.modalRef = this.modalService.open(KnBrCommonPopUpComponent, { centered: true, size: 'xl' });
    modalInstance = this.modalRef.componentInstance;
    modalInstance.image = img;
    modalInstance.header = this.productName;
  };
}
