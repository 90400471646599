import { KnBrOrderReturn, KnBrReturnOrderSearchForm } from '../../models/kn-br-order.model';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, queueScheduler } from 'rxjs';
import { observeOn } from 'rxjs/operators';
import { StateWithKnBrOrderReturn } from './kn-br-order-return.state';

import { KnBrReturnOrdersSearch, KnBrReturnOrdersSearchReset } from './kn-br-order-return.action';
import {
  getOrderReturn,
  getOrderReturnLoader,
  getOrderReturnPaginate,
  getOrderReturnSearchCriteria,
  getOrderReturnSearchDisabled,
  getOrderRetrunSorts,
} from './kn-br-order-return.selector';

@Injectable({
  providedIn: 'root',
})
export class KnBrOrderReturnService {
  constructor(private store: Store<StateWithKnBrOrderReturn>) {}

  list$: Observable<KnBrOrderReturn[]> = this.store.pipe(select(getOrderReturn), observeOn(queueScheduler));

  loader$: Observable<boolean> = this.store.pipe(select(getOrderReturnLoader), observeOn(queueScheduler));

  paginate$: Observable<any> = this.store.pipe(select(getOrderReturnPaginate), observeOn(queueScheduler));

  sorts$: Observable<any> = this.store.pipe(select(getOrderRetrunSorts), observeOn(queueScheduler));

  searchCriteria$: Observable<any> = this.store.pipe(select(getOrderReturnSearchCriteria), observeOn(queueScheduler));

  isSearchDisabled$: Observable<boolean> = this.store.pipe(
    select(getOrderReturnSearchDisabled),
    observeOn(queueScheduler)
  );

  returnOrderListSearch(searchForm: KnBrReturnOrderSearchForm) {
    if ((searchForm && searchForm.currentPage === 0) || !searchForm) {
      this.returnOrderFormReset();
    }
    this.store.dispatch(new KnBrReturnOrdersSearch(searchForm));
  }

  returnOrderFormReset() {
    this.store.dispatch(new KnBrReturnOrdersSearchReset(null));
  }
}
