import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { I18nModule } from '@spartacus/core';
import {
  SpinnerModule,
  PageComponentModule,
  PageLayoutModule,
  PageSlotModule
} from '@spartacus/storefront';
import { SloanSSOLayoutConfigModule } from './knbr-sso-layout.config';
import { KnbrSsoComponent } from './knbr-sso.component';

const staticRoutes: Routes = [{
  path: 'carlisle/sso',
  component: KnbrSsoComponent
}];


@NgModule({
  declarations: [KnbrSsoComponent],
  imports: [
    CommonModule,
    I18nModule,
    SpinnerModule,
    PageComponentModule,
    PageLayoutModule,
    PageSlotModule,
    SloanSSOLayoutConfigModule,
    RouterModule.forChild(staticRoutes)
  ],
  exports: [KnbrSsoComponent],
})
export class KnbrSsoModule { }
