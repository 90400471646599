import { Statuses } from './../../models/kn-br-quote.model';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, queueScheduler, BehaviorSubject } from 'rxjs';
import { observeOn } from 'rxjs/operators';
import { DraftOrder } from 'src/app/models/draft-order.model';

import { DraftSearchForm } from '../../models/draft-order.model';
import {
  KnBDraftOrderStatusesLoad,
  KnBrDraftOrderCopy,
  KnBrDraftOrderCreate,
  KnBrDraftOrderEntryUpdate,
  KnBrDraftOrderPatch,
  KnBrDraftOrderSearch,
  KnBrDraftOrderSearchReset,
  KnBrDraftOrderUpdate,
  KnBrDraftOrderQuickEntry,
  KnBrQuickOrderResetForm,
  KnBrDraftOrderWarningMessage,
  KnBrDraftOrderDelete,
} from './kn-br-draft-orders.action';
import {
  getDraftOrders,
  getDraftOrderSearchCriteria,
  getDraftOrdersLoader,
  getDraftOrdersPaginate,
  getDraftSearchDisabled,
  getDraftCreateDisabled,
  getDraftOrderDetailStatuses,
  getResetForm,
  getDraftOrdersSpinner,
  getDraftOrderCheckOutDisable,
  getDraftOrderWarningMessage,
} from './kn-br-draft-orders.selector';
import { StateWithDraftOrder } from './kn-br-draft-orders.state';
import { KnBrDraftOrdersAdapter } from './kn-br-draft-orders.adapter';

@Injectable({
  providedIn: 'root',
})
export class KnBrDraftOrdersService {
  constructor(private store: Store<StateWithDraftOrder>, private knBrDraftOrdersAdapter: KnBrDraftOrdersAdapter) {}

  list$: Observable<DraftOrder[]> = this.store.pipe(select(getDraftOrders), observeOn(queueScheduler));

  loader$: Observable<boolean> = this.store.pipe(select(getDraftOrdersLoader), observeOn(queueScheduler));

  paginate$: Observable<any> = this.store.pipe(select(getDraftOrdersPaginate), observeOn(queueScheduler));

  searchCriteria$: Observable<any> = this.store.pipe(select(getDraftOrderSearchCriteria), observeOn(queueScheduler));

  isSearchDisabled$: Observable<boolean> = this.store.pipe(select(getDraftSearchDisabled), observeOn(queueScheduler));

  isCreateDisabled$: Observable<boolean> = this.store.pipe(select(getDraftCreateDisabled), observeOn(queueScheduler));

  spinner$: Observable<boolean> = this.store.pipe(select(getDraftOrdersSpinner), observeOn(queueScheduler));

  warningMessage$: Observable<string> = this.store.pipe(select(getDraftOrderWarningMessage), observeOn(queueScheduler));

  isCheckoutDisabled$: Observable<boolean> = this.store.pipe(
    select(getDraftOrderCheckOutDisable),
    observeOn(queueScheduler)
  );

  showAddCartEntryLoading$: BehaviorSubject<boolean> = new BehaviorSubject(null);

  draftOrderStatuses$: Observable<Statuses[]> = this.store.pipe(
    select(getDraftOrderDetailStatuses),
    observeOn(queueScheduler)
  );

  quickResetForm$: Observable<boolean> = this.store.pipe(select(getResetForm), observeOn(queueScheduler));

  draftHeaderDiscountType$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  search(searchForm: DraftSearchForm) {
    if ((searchForm && searchForm.currentPage === 0) || !searchForm) {
      this.reset();
    }
    this.store.dispatch(new KnBrDraftOrderSearch(searchForm));
  }

  reset() {
    this.store.dispatch(new KnBrDraftOrderSearchReset(null));
  }

  copy(payload) {
    this.store.dispatch(new KnBrDraftOrderCopy(payload));
  }

  create(payload) {
    this.store.dispatch(new KnBrDraftOrderCreate(payload));
  }
  update(payload) {
    this.store.dispatch(new KnBrDraftOrderUpdate(payload));
  }

  delete(payload) {
    this.store.dispatch(new KnBrDraftOrderDelete(payload));
  }

  patch(payload) {
    this.store.dispatch(new KnBrDraftOrderPatch(payload));
  }
  updateEntry(payload) {
    this.store.dispatch(new KnBrDraftOrderEntryUpdate(payload));
  }
  validateOnCheckout() {
    // this.store.dispatch(new KnBrDraftOrderChekoutValidation(null));
    return this.knBrDraftOrdersAdapter.validateOnCheckout();
  }
  getStatuses() {
    this.store.dispatch(new KnBDraftOrderStatusesLoad());
  }
  quickEntry(payload) {
    this.store.dispatch(new KnBrDraftOrderQuickEntry(payload));
  }

  quickFormReset(payload) {
    this.store.dispatch(new KnBrQuickOrderResetForm(payload));
  }

  setAddCartEntryLoading(showLoading: boolean) {
    this.showAddCartEntryLoading$.next(showLoading);
  }

  loadAddCartEntryLoading$(): Observable<boolean> {
    return this.showAddCartEntryLoading$.asObservable();
  }

  resetWarningMsg() {
    this.store.dispatch(new KnBrDraftOrderWarningMessage(null));
  }
}
