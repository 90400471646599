<ng-container *ngIf="accountSummaryResult$ | async as accSummaryResult">
  <div class="col-md-12 float-left p-3 mb-4">
    <div class="col-lg-3 p-0 float-left">
      <label class="color-font font-text-600 mb-1">
        {{ 'knbrAccountSummary.label.accountNumber' | cxTranslate }}
      </label>
      <p class="text-muted mb-2">
        {{ accSummaryResult?.b2bUnitData?.uid | removeLeadingZeros }}
      </p>
      <label class="color-font font-text-600 mb-1">
        {{ 'knbrAccountSummary.label.address' | cxTranslate }}
      </label>
      <p class="text-muted mb-0">
        {{ accSummaryResult.b2bUnitData.name }}
      </p>
      <p class="text-muted mb-0">
        {{ accSummaryResult.billingAddress.line1 }}
      </p>
      <p class="text-muted mb-0">
        {{ accSummaryResult.billingAddress.line2 }}
      </p>
      <p class="text-muted mb-0">
        {{ accSummaryResult.billingAddress.town? accSummaryResult.billingAddress.town : '' }} {{ formatState(accSummaryResult.billingAddress?.region?.isocode) }} {{ accSummaryResult.billingAddress.postalCode }}
      </p>
      <p class="text-muted mb-0">
        {{ formatCountry(accSummaryResult.billingAddress?.country?.isocode) }}
      </p>

      <label class="color-font font-text-600 pt-2 mb-0">
        {{ 'knbrAccountSummary.label.accountPhone' | cxTranslate }}
      </label>
      <p class="text-muted mb-0">
        {{ this.formatPhone(accSummaryResult.billingAddress?.phone) }}
      </p>
    </div>
    <div class="col-lg-3 p-0 float-left">
      <label class="color-font font-text-400 text-muted mb-1">
        {{ 'knbrAccountSummary.label.accountTodaysBalance' | cxTranslate }}
      </label>
      <p class="mb-2">
        {{ this.totalBalance[0]?.value }}
      </p>
    </div>
    <div class="col-lg-3 p-0 float-left">
      <table class="table dueList">
        <thead>
          <tr>
            <th></th>
            <th class="text-left pt-0">
              <label class="color-font font-text-600 mb-1">
                {{ 'knbrAccountSummary.label.accountDueItems' | cxTranslate }}
              </label>
            </th>
          </tr>
        </thead>
        <tbody *ngIf="this.duelist">
          <tr *ngFor="let due of this.duelist; let i = index">
            <td><a (click)="search(due.name)">{{ due.name }}</a></td>
            <td class="dueValues">{{ formatValue(due?.value) }}</td>
          </tr>
        </tbody>
      </table>
    </div>

  </div>
</ng-container>
