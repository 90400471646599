import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CmsBreadcrumbsComponent, CmsService, PageMetaService, RoutingService, TranslationService } from '@spartacus/core';
import { BreadcrumbComponent, CmsComponentData, CurrentProductService } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { CustomerData } from 'src/app/models/customer.model';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import { KnBrProductSalesOrgService } from 'src/app/services/kn-br-product-sales-org.service';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import { KN_BR_CUSTOMER_CURRENT } from 'src/constants/api.endpoints.constant';

@Component({
  selector: 'kn-br-bread-crumb',
  templateUrl: './kn-br-bread-crumb.component.html',
  styleUrls: ['./kn-br-bread-crumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnBrBreadCrumbComponent extends BreadcrumbComponent implements OnInit {
  componentData$: Observable<CmsBreadcrumbsComponent> = this.componentData.data$;
  customerContextData$: Observable<CustomerData> = this.knBrCustomerContextService.get$;
  hideBreadcrumbs = false;
  subscription: Subscription;
  selectedCustomer;
  breadCrumbs = [];
  salesOrg: string;
  productId: string;
  constructor(
    private componentData: CmsComponentData<CmsBreadcrumbsComponent>,
    pageMetaService: PageMetaService,
    translation: TranslationService,
    private cmsService: CmsService,
    protected commonService: KnBrCommonService,
    protected cdr: ChangeDetectorRef,
    private routingService: RoutingService,
    protected currentProductService: CurrentProductService,
    protected knBrCustomerContextService: KnBrCustomerContextService,
    protected knBrProductSalesOrgService: KnBrProductSalesOrgService,
  ) {
    super(componentData, pageMetaService, translation);
    this.getSalesOrg().subscribe((res) => {
      this.salesOrg = res;
    });
    this.getProductId().subscribe((res) => {
      this.productId = res;
    });
  }
  ngOnInit(): void {
    this.subscription = this.customerContextData$.subscribe((value) => {
      if (value) {
        this.selectedCustomer = value?.customerNumber ? value?.customerNumber : KN_BR_CUSTOMER_CURRENT;
      }
    });

    this.cmsService.getCurrentPage().subscribe((page) => {
      if (page.pageId === 'productDetails') {
        this.hideBreadcrumbs = true;
        this.commonService.getBreadCrumbs(this.selectedCustomer, this.productId, this.salesOrg).subscribe(res => {
          this.breadCrumbs = Object.keys(res).map((key) => [key, res[key]]);
          this.cdr.detectChanges();
        })
      } else {
        this.hideBreadcrumbs = false;
      }
    });

    super.ngOnInit();
  }

  getLabel(label: string) {
    if(!label) return;
     return label.toLowerCase().indexOf('syntec') > -1
           ? label.replace('syntec','SynTec')
           : label.toLowerCase().indexOf('partner') > -1
           ? label.replace('partner','Business Partner')
           : label;
  }

  protected getSalesOrg(): Observable<any> {
    return this.routingService.getRouterState().pipe(map((state) => state.state.params['salesOrg']));
  }

  protected getProductId(): Observable<any> {
    return this.routingService.getRouterState().pipe(map((state) => state.state.params['productCode']));
  }

  openLink(url) {
    const isCanada = window.location.href.includes("carlisle-customer-ca");
    if (isCanada) {
      const baseSite = 'carlisle-customer-ca';
      const replaceAmpersandUrl = url.replace('&', '%26');
      return baseSite + replaceAmpersandUrl;
    } else {
      const replaceAmpersandUrl = url.replace('&', '%26');
      return replaceAmpersandUrl
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
