import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActiveCartService } from '@spartacus/cart/base/core';
import { ProductSearchPage, TranslationService } from '@spartacus/core';
import { ProductListComponentService, ViewConfig, ViewModes } from '@spartacus/storefront';
import { Subscription, combineLatest } from 'rxjs';

import { KnBrDraftOrder } from '../../../models/kn-br-draft-order.model';
import { KnBrProductSelectService } from '../../../services/kn-br-product-select.service';
import { KnBrCartContextService } from '../../../store/kn-br-cart-context/kn-br-cart-context.service';
import { KnBrQuoteContextService } from '../../../store/kn-br-quote-context/kn-br-quote-context.service';
import { KnBrQuotesService } from '../../../store/kn-br-quotes/kn-br-quotes.service';
import { map } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'kn-br-product-scroll',
  templateUrl: './kn-br-product-scroll.component.html',
  styleUrls: ['./kn-br-product-scroll.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnBrProductScrollComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  selectedProductSubscription = new Subscription();
  productCodes: string[];
  selectedProductCodes: string[];
  allChecked = false;
  @Input('scrollConfig')
  set setConfig(inputConfig: ViewConfig) {
    this.setComponentConfigurations(inputConfig);
  }
  model: ProductSearchPage;
  @Input('model')
  set setModel(inputModel: ProductSearchPage) {
    this.infiniteScrollOperations(inputModel);
  }
  inputViewMode: ViewModes;
  @Input('inputViewMode')
  set setViewMode(inputViewMode: ViewModes) {
    this.inputViewMode = inputViewMode;
    // If viewMode is already set (meaning it is not the first load)
    // Reset the product list
    if (this.viewMode) {
      this.resetListOnViewModeChange();
    } else {
      // If viewMode is not set (meaning it is the first load)
      // Set the viewMode
      this.viewMode = inputViewMode;
    }
  }
  viewMode: ViewModes;
  productLimit: number;
  maxProducts: number;
  ViewModes = ViewModes;
  appendProducts = false;
  resetList = false;
  isMaxProducts = false;
  isLastPage = false;
  isEmpty = false;
  subscriptions = new Subscription();
  isQuoteContext = false;
  isCartContext = false;
  editable = true;
  constructor(
    protected productListComponentService: ProductListComponentService,
    protected ref: ChangeDetectorRef,
    protected knBrProductService: KnBrProductSelectService,
    protected activeCartService: ActiveCartService,
    protected knBrQuoteContextService: KnBrQuoteContextService,
    protected knBrCartContextService: KnBrCartContextService,
    protected knBrQuotesService: KnBrQuotesService,
    private translationService: TranslationService,
    protected ngTitle: Title,
    private router: Router
  ) {
    this.knBrProductService.setSelectedProducts([]);
  }
  ngOnInit(): void {
    this.selectedProductCodes = [];
    this.selectedProductSubscription = this.knBrProductService
      .loadSelectedProducts$()
      .subscribe((selectedProductCodes) => {
        this.selectedProductCodes = selectedProductCodes;
        this.productCodes = this.model.products.map((product) => product.code);
        this.allChecked = this.productCodes.length === this.selectedProductCodes.length;
        this.ref.markForCheck();
      });
    this.subscriptions.add(
      this.knBrQuoteContextService.get$.subscribe((value) => {
        this.isQuoteContext = false;
        if (value) {
          this.isQuoteContext = true;
          this.isCartContext = false;
        }
        this.ref.markForCheck();
        this.setEditable();
      })
    );

    this.subscriptions.add(
      this.knBrCartContextService.get$.subscribe((value) => {
        this.isCartContext = false;
        if (value) {
          this.isCartContext = true;
          this.isQuoteContext = false;
        }
        this.ref.markForCheck();

        this.setEditable();
      })
    );
  }
  scrollPage(pageNumber: number): void {
    this.appendProducts = true;
    this.ref.markForCheck();
    this.productListComponentService.getPageItems(pageNumber);
  }
  loadNextPage(pageNumber: number): void {
    this.isMaxProducts = false;
    this.scrollPage(pageNumber);
  }
  scrollToTop(): void {
    window.scroll(0, 0);
    document.body.scrollTop = 0;
  }
  setComponentConfigurations(scrollConfig: ViewConfig): void {
    const isButton = scrollConfig.view.infiniteScroll.showMoreButton;
    const configProductLimit = scrollConfig.view.infiniteScroll.productLimit;
    // Display "show more" button every time when button configuration is true
    // Otherwise, only display "show more" when the configuration product limit is reached
    this.productLimit = isButton ? 1 : configProductLimit;
  }
  infiniteScrollOperations(inputModel: ProductSearchPage): void {
    if (this.appendProducts) {
      this.model = {
        ...inputModel,
        products: this.model.products.concat(inputModel.products),
      };
    } else {
      this.model = inputModel;
      this.maxProducts = this.productLimit;
    }
    this.setConditions();
    this.ref.markForCheck();
  }
  resetListOnViewModeChange(): void {
    this.scrollToTop();
    this.resetList = true;
    this.productListComponentService.getPageItems(0);
  }
  // Set booleans after model has been retrieved
  setConditions(): void {
    this.isEmpty = !this.model.products || this.model.products.length === 0;
    this.isLastPage = this.model.pagination.currentPage === this.model.pagination.totalPages - 1;
    this.isMaxProducts = this.productLimit && this.productLimit !== 0 && this.model.products.length >= this.maxProducts;
    // Add the productLimit to the current number of products to determine the next max number of products
    if (this.isMaxProducts) {
      this.maxProducts = this.model.products.length + this.productLimit;
    }
    // Only change viewMode once the new model is set
    // This prevents flickering issues
    if (this.viewMode !== this.inputViewMode) {
      this.viewMode = this.inputViewMode;
    }
    this.resetList = false;
    this.appendProducts = false;
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.selectedProductSubscription.unsubscribe();
    this.subscriptions.unsubscribe();
  }
  selectAll(event) {
    const selectedProductCodes = event.currentTarget.checked ? this.productCodes : [];
    this.knBrProductService.setSelectedProducts(selectedProductCodes);
  }
  setEditable() {
    if (this.isQuoteContext) {
      this.subscriptions.add(
        this.knBrQuotesService.get$.subscribe((value) => {
          this.editable = value.editable === undefined || value.editable === null ? true : value.editable;
          this.ref.markForCheck();
        })
      );
    } else if (this.isCartContext) {
      this.subscriptions.add(
        this.activeCartService.getActive().subscribe((value: KnBrDraftOrder) => {
          this.editable = value.editable === undefined || value.editable === null ? true : value.editable;
          this.ref.markForCheck();
        })
      );
    } else {
      this.editable = true;
    }
  }

  getPageTitle(model: any) {
    return combineLatest([
      this.translationService.translate('knBrMessages.plpListMsg', {
        records: model.pagination.currentPage + 1 === model.pagination.totalPages ? model.pagination.totalResults : model.pagination.totalResults > model.pagination.pageSize ? model.pagination.pageSize * (model.pagination.currentPage + 1) : model.pagination.totalResults,
        total: model.pagination.totalResults,
      }),
    ]).pipe(
      map(([textTitle]) => {
        this.ngTitle.setTitle(textTitle);
        return textTitle;
      })
    );
  }

  hasQueryParams() {
    return this.router.url.includes('?')
  }

}
