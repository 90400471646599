import { KnBrQuoteReference, KnBrQuoteReferenceResponse } from '../../models/kn-br-quote.model';

export const KN_BR_QUOTE_REFERENCE_FEATURE = 'kn-br-quote-reference';

export interface KnBrQuoteReferenceState {
  reference?: KnBrQuoteReference;
  error?: Error;
  referenceResponse?: KnBrQuoteReferenceResponse;
}

export interface StateWithQuoteReference {
  [KN_BR_QUOTE_REFERENCE_FEATURE]: KnBrQuoteReferenceState;
}
