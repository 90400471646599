import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { GlobalMessageService, GlobalMessageType, OccEndpointsService, RoutingService } from '@spartacus/core';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import { RemoveLeadingZerosPipe } from 'src/app/shared/pipe/kn-br-custom-pipes/remove-leading-zeros.pipe';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import { KnBrQuotesService } from 'src/app/store/kn-br-quotes/kn-br-quotes.service';
import { KNBR_INVOICE_DETAILS } from 'src/constants/api.endpoints.constant';
@Component({
  selector: 'kn-br-case-listing',
  templateUrl: './kn-br-case-listing-header.component.html',
  styleUrls: ['./kn-br-case-listing-header.component.scss'],
  providers: [RemoveLeadingZerosPipe],
})
export class KnBrCaseListingHeaderComponent implements OnInit, OnDestroy {
  public formGroup: FormGroup;
  currentDateValue = {
     year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate()
  }
  todayDate = () => {
    const today = new Date();
    this.currentDateValue = { year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate() }
    return ({ year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate() });
  };
  maxStartDate = () => this.todayDate();
  customerId: string;
  helperForm: UntypedFormGroup;
  submitted: boolean;
  createcaseToolTipText = `To create a case, enter the Sales Order or Invoice # and click "Submit Case."`


  constructor(
    protected fb: UntypedFormBuilder,
    protected knBrQuoteService: KnBrQuotesService,
    protected knBrCommonService: KnBrCommonService,
    protected knBrCustomerContextService: KnBrCustomerContextService,
    protected endPointService: OccEndpointsService,
    protected removeLeadingZerosPipe: RemoveLeadingZerosPipe,
    protected activatedRoute: ActivatedRoute,
    private http: HttpClient,
    protected cdr: ChangeDetectorRef,
    protected routingService: RoutingService,
    private globalMessageService: GlobalMessageService,
  ) {
  }

  ngOnInit(): void {

    this.knBrCustomerContextService.getActiveCustomerNumber().subscribe((response) => {
      this.customerId = response ? response : 'current'
    });
    this.initForm();
  }

  initForm() {
    this.helperForm = this.fb.group({
      caseOptionToCreate: [null, Validators.required],
      salesOrInvoiceNumber: [null, Validators.required]
    });
    console.log("printing current date", this.currentDateValue);
  }

  submitForm() {
    this.submitted = true;
    const formValues = this.helperForm.getRawValue();
    if(formValues.caseOptionToCreate === 'salesOrder') {
      const orderdetailsUrl = this.endPointService.buildUrl(`knbr/customers/${this.customerId}/orders/${formValues.salesOrInvoiceNumber}`)
      this.http.get(orderdetailsUrl).subscribe((resp:any) => {
      this.routingService.goByUrl(`/customer-360/order/${formValues.salesOrInvoiceNumber}`);
    }, (error:any) => {
      this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_ERROR);
      this.globalMessageService.add('Requested Order is not found', GlobalMessageType.MSG_TYPE_ERROR)
    })
    } else {
      const invoiceDetailsUrl = this.endPointService.buildUrl(KNBR_INVOICE_DETAILS(this.customerId, formValues.salesOrInvoiceNumber));
      this.http.get(invoiceDetailsUrl).subscribe(resp => {
        this.routingService.goByUrl(`/customer-360/invoice-list/invoice?invoiceNumber=${formValues.salesOrInvoiceNumber}`);
      }, error => {
      this.globalMessageService.remove(GlobalMessageType.MSG_TYPE_ERROR);
      this.globalMessageService.add('Requested Invoice is not found', GlobalMessageType.MSG_TYPE_ERROR)
      })
    }
  }

  isRequiredFieldsFilled() {
    const caseOption = this.helperForm.get('caseOptionToCreate');
    const numberValue = this.helperForm.get('salesOrInvoiceNumber');
    if(caseOption.value && numberValue.value) {
      return false;
    }
    return true;
  }

  getPlaceHolderValue(){
    const caseOption = this.helperForm.get('caseOptionToCreate');
    if(caseOption && caseOption.value === 'invoice') {
      return 'Enter Invoice #'
    } else if (caseOption && caseOption.value === 'salesOrder') {
      return 'Enter Sales Order #'
    }
    return 'Enter Value'
  }

  resetTheForm() {
    this.helperForm.reset();
    this.submitted = false;
  }

  ngOnDestroy(): void {
    this.helperForm.reset();
  }
}
