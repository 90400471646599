import { KnBrFactSheetData } from 'src/app/models/kn-br-fact-sheet.model';

export const KNBR_FACT_SHEET_FEATURE = 'knbr-fact-sheet';

export interface KnBrFactSheetState {
  factSheetData: KnBrFactSheetData;
  error?: Error;
  isLoading?: boolean;
  pagination: any;
}

export interface StateWithKnBrFactSheet {
  [KNBR_FACT_SHEET_FEATURE]: KnBrFactSheetState;
}
