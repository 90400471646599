import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KnBrProfileComponent } from './kn-br-profile.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MediaModule } from '@spartacus/storefront';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';

@NgModule({
  declarations: [KnBrProfileComponent],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    MediaModule,
    FormsModule,
    I18nModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CarlisleAccountDetailsHeaderComponent: {
          component: KnBrProfileComponent
        }
      }
    } as CmsConfig)
  ]
})
export class KnBrProfileModule { }
