import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, I18nConfig, I18nModule, provideConfig, UrlModule } from '@spartacus/core';
import { FormErrorsModule } from '@spartacus/storefront';
import { userAccountTranslations, userAccountTranslationChunksConfig } from '@spartacus/user/account/assets';
import { LoginFormComponentService } from '@spartacus/user/account/components';

import { KnBrLoginFormComponent } from './kn-br-login-form.component';

@NgModule({
    declarations: [KnBrLoginFormComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        UrlModule,
        I18nModule,
        FormErrorsModule,
        ConfigModule.withConfig({
            cmsComponents: {
                KnBrLoginFormComponent: {
                    component: KnBrLoginFormComponent,
                },
            },
        } as CmsConfig),
    ],
    exports: [KnBrLoginFormComponent],
    providers: [
        LoginFormComponentService,
        provideConfig({
            i18n: {
                resources: userAccountTranslations,
                chunks: userAccountTranslationChunksConfig,
            },
        } as I18nConfig),
    ]
})
export class KnBrLoginFormModule {}
