
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  StateWithProcess,
  UserIdService,
} from '@spartacus/core';
import {  StateWithOrder } from './kn-br-order-return-request.state';
import { OrderReturnRequestService } from '@spartacus/order/core';

@Injectable({
  providedIn: 'root',
})
export class KnBrOrderReturnRequestService extends OrderReturnRequestService {
  constructor(
    protected store: Store<StateWithOrder>,
    protected processStateStore: Store<StateWithProcess<void>>,
    protected userIdService: UserIdService
  ) {
    super(store, processStateStore, userIdService);
  } 
}
