<div class="col-lg-12 selected-pages p-2 float-left">
  <ul *ngIf="!hideBreadcrumbs" class="p-0 m-0">
    <li *ngFor="let crumb of crumbs$ | async">
      <a [routerLink]="crumb.link" [innerHTML]="getLabel(crumb.label)"></a>
    </li>
  </ul>

  <ul *ngIf="hideBreadcrumbs">
    <li *ngFor="let crumb of breadCrumbs">
      <a [href]="openLink(crumb[1])">{{ crumb[0] }}</a>
    </li>
  </ul>
</div>
