import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { KnBrInvoiceEffects } from './kn-br-invoice.effects';
import { knBrInvoiceReducer } from './kn-br-invoice.reducer';
import { KN_BR_INVOICE_FEATURE } from './kn-br-invoice.state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(KN_BR_INVOICE_FEATURE, knBrInvoiceReducer),
    EffectsModule.forFeature([KnBrInvoiceEffects]),
  ],
})
export class KnBrInvoiceStateModule {}
