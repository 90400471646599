import { KN_BR_QUOTE_QUICK_ENTRY } from './../../../constants/api.endpoints.constant';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { KnBrQuoteEntry } from 'src/app/models/kn-br-quote.model';

import { KN_BR_QUOTE_ENTRIES, KN_BR_QUOTE_UPDATE_ENTRY } from '../../../constants/api.endpoints.constant';
import { KnBrCustomerContextService } from '../kn-br-customer-context/kn-br-customer-context.service';
import { KnBrQuoteContextService } from '../kn-br-quote-context/kn-br-quote-context.service';

@Injectable({
  providedIn: 'root',
})
export class KnBrQuoteEntryAdapter {
  customerId: string;
  quoteId: string;
  constructor(
    protected http: HttpClient,
    protected occEndpointsService: OccEndpointsService,
    protected knBrCustomerContextService: KnBrCustomerContextService,
    protected knBrQuoteContextService: KnBrQuoteContextService
  ) {
    this.knBrCustomerContextService.getActiveCustomerNumber().subscribe((response) => {
      this.customerId = response ? response : null;
    });
    this.knBrQuoteContextService.get$.subscribe((response) => {
      this.quoteId = response ? response : null;
    });
  }

  public add(
    customerId: string,
    quoteId: string,
    productCode: string,
    quantity: number = 1
  ): Observable<KnBrQuoteEntry> {
    const toAdd = JSON.stringify({});

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    const url = this.occEndpointsService.buildUrl(KN_BR_QUOTE_ENTRIES, {
      urlParams: {
        customerId: this.customerId,
        quoteId: quoteId ? quoteId : this.quoteId,
      },
      queryParams: { code: productCode, quantity },
    });

    return this.http.post<KnBrQuoteEntry>(url, toAdd, { headers });
  }

  public update(entry: KnBrQuoteEntry): Observable<KnBrQuoteEntry> {
    const params = {
      quantity: entry.quantity,
      priceAdjusted: entry.knbrTotalPrice?.priceAdjusted?.value,
      requestedShipDate: entry.requestedShipDate,
    };

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    const url = this.occEndpointsService.buildUrl(KN_BR_QUOTE_UPDATE_ENTRY, {
      urlParams: { customerId: this.customerId, quoteId: this.quoteId, entryNumber: entry.entryNumber },
      queryParams: { ...params },
    });

    return this.http.patch<KnBrQuoteEntry>(url, {}, { headers });
  }

  public remove(customerId: string, quoteId: string, entryNumber: string): Observable<KnBrQuoteEntry> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    const url = this.occEndpointsService.buildUrl(KN_BR_QUOTE_ENTRIES, {
      urlParams: {
        customerId: this.customerId,
        quoteId: quoteId ? quoteId : this.quoteId,
      },
      queryParams: { entryNumbers: entryNumber },
    });
    return this.http.delete<KnBrQuoteEntry>(url, { headers });
  }
  addEntries(payload) {
    if (payload && payload.quoteId) {
      this.quoteId = payload.quoteId;
      payload = payload.request;
    }
    const reobj = {
      addProductEntryInputs: payload,
    };
    return this.http.post(
      this.occEndpointsService.buildUrl(KN_BR_QUOTE_QUICK_ENTRY, {
        urlParams: {
          customerId: this.customerId,
          quoteId: this.quoteId,
        },
      }),
      reobj
    );
  }
}
