import { KnBrDraftOrdersService } from 'src/app/store/kn-br-draft-orders/kn-br-draft-orders.service';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  GlobalMessageService,
  GlobalMessageType,
  normalizeHttpError,
  OCC_USER_ID_CURRENT,
  RoutingService,
} from '@spartacus/core';
import { noop, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { KnBrCartContextService } from '../kn-br-cart-context/kn-br-cart-context.service';
import {
  KnBrDraftOrderCopyFailure,
  KnBrDraftOrderCopySuccess,
  KnBrDraftOrderCreateFailure,
  KnBrDraftOrderCreateSuccess,
  KnBrDraftOrderLoadStatusesFailure,
  KnBrDraftOrderLoadStatusesSuccess,
  KnBrDraftOrderQuickEntryFailure,
  KnBrDraftOrderQuickEntrySuccess,
  KnBrDraftOrdersActionTypes,
  KnBrDraftOrderSearchFailure,
  KnBrDraftOrderSearchSuccess,
  KnBrDraftOrdersSearchActions,
  KnBrDraftOrderUpdateFailure,
  KnBrDraftOrderUpdateSuccess,
  KnBrQuickOrderResetForm,
  KnBrDraftOrderEntryUpdateFailure,
  KnBrDraftOrderEntryUpdateSuccess,
  KnBrDraftOrderDeleteSuccess,
  KnBrDraftOrderDeleteFailure,
} from './kn-br-draft-orders.action';
import { KnBrDraftOrdersAdapter } from './kn-br-draft-orders.adapter';
import { KnBrQuoteReferenceService } from '../kn-br-quote-reference/kn-br-quote-reference.service';
import * as _ from 'lodash';
import { CartActions, MultiCartService } from '@spartacus/cart/base/core';

@Injectable()
export class KnBrDraftOrdersEffects {
  @Effect()
  searchOrders$ = this.actions$.pipe(
    ofType(KnBrDraftOrdersActionTypes.KnBrDraftOrderSearchAction),
    mergeMap((action) =>
      this.draftOrdersAdapter.search(action.payload).pipe(
        map((data: any) => {
          return new KnBrDraftOrderSearchSuccess(data);
        }),
        catchError((error: Error) => {
          this.messageService.add({ key: 'knBrMessages.error' }, GlobalMessageType.MSG_TYPE_ERROR);
          return of(new KnBrDraftOrderSearchFailure(error));
        })
      )
    )
  );

  @Effect({ dispatch: false })
  searchOrdersFailure$ = this.actions$.pipe(
    ofType(KnBrDraftOrdersActionTypes.KnBrDraftOrderSearchFailureAction),
    map((action) => {
      this.messageService.add({ key: 'knBrMessages.error' }, GlobalMessageType.MSG_TYPE_ERROR);
    })
  );

  @Effect()
  copyOrder$ = this.actions$.pipe(
    ofType(KnBrDraftOrdersActionTypes.KnBrDraftOrderCopyAction),
    mergeMap((action) =>
      this.draftOrdersAdapter.copy(action.payload).pipe(
        map((data: any) => new KnBrDraftOrderCopySuccess(data)),
        catchError((error: Error) => {
          return of(new KnBrDraftOrderCopyFailure(error));
        })
      )
    )
  );
  @Effect({ dispatch: false })
  copySuccess$ = this.actions$.pipe(
    ofType(KnBrDraftOrdersActionTypes.KnBrDraftOrderCopySuccessAction),
    map((action) => {
      this.knBrCartContextService.set(action.payload.code);
      this.messageService.add({ key: 'cartCommon.copyMessage.success' }, GlobalMessageType.MSG_TYPE_CONFIRMATION);
      this.routeService.go({ cxRoute: 'cartDetails', params: { code: action.payload.code } });
    })
  );

  @Effect({ dispatch: false })
  copyFailure$ = this.actions$.pipe(
    ofType(KnBrDraftOrdersActionTypes.KnBrDraftOrderCopyFailureAction),
    map((action) => this.messageService.add({ key: 'cartCommon.copyMessage.error' }, GlobalMessageType.MSG_TYPE_ERROR))
  );

  @Effect()
  createOrder$ = this.actions$.pipe(
    ofType(KnBrDraftOrdersActionTypes.KnBrDraftOrderCreateAction),
    mergeMap((action) =>
      this.draftOrdersAdapter.create(action.payload).pipe(
        map((data: any) => {
          data.orderToolsProducts = action.payload.orderToolsProducts;
          return new KnBrDraftOrderCreateSuccess(data);
        }),
        catchError((error: Error) => {
          return of(new KnBrDraftOrderCreateFailure(error));
        })
      )
    )
  );
  @Effect({ dispatch: false })
  createSuccess$ = this.actions$.pipe(
    ofType(KnBrDraftOrdersActionTypes.KnBrDraftOrderCreateSuccessAction),
    map((action) => {
      this.knBrCartContextService.set(action.payload.code);
      if (this.knBrCartContextService.pdpQuoteRef) {
        this.knBrQuoteReferenceService.pdpQuoteRef = _.cloneDeep(this.knBrCartContextService.pdpQuoteRef);
        this.knBrCartContextService.pdpQuoteRef = undefined;
      }

      this.multiCartService.loadCart({
        userId: null,
        cartId: action.payload.code,
        extraData: {
          active: true,
        },
      });
      const productList = this.activatedRoute.snapshot.queryParamMap.get('productList');
      const orderToolsProducts = action.payload.orderToolsProducts;
      if (productList || orderToolsProducts) {
        const parsedProducts = JSON.parse(productList);
        if (parsedProducts && parsedProducts.length > 0) {
          const parsedProductsCopy = { request: parsedProducts, cartId: action.payload.code };
          this.knBrDraftOrderService.quickEntry(parsedProductsCopy);
        } else if (orderToolsProducts && orderToolsProducts.length > 0) {
          const orderToolsReq = { request: orderToolsProducts, cartId: action.payload.code };
          this.knBrDraftOrderService.quickEntry(orderToolsReq);
        }
      } else {
        const productCode = this.activatedRoute.snapshot.queryParamMap.get('p');
        const quantity = this.activatedRoute.snapshot.queryParamMap.get('q');
        if (productCode) {
          this.multiCartService.addEntry(
            OCC_USER_ID_CURRENT,
            action.payload.code,
            productCode,
            quantity ? parseInt(quantity, 10) : 1
          );
        }
        this.routeService.go({ cxRoute: 'cartDetails', params: { code: action.payload.code } });
      }

      this.messageService.add({ key: 'cartCommon.createMessage.success' }, GlobalMessageType.MSG_TYPE_CONFIRMATION);
    })
  );

  @Effect({ dispatch: false })
  createFailure$ = this.actions$.pipe(
    ofType(KnBrDraftOrdersActionTypes.KnBrDraftOrderCreateFailureAction),
    map((action) =>
      this.messageService.add({ key: 'cartCommon.createMessage.error' }, GlobalMessageType.MSG_TYPE_ERROR)
    )
  );

  @Effect()
  updateOrder$ = this.actions$.pipe(
    ofType(KnBrDraftOrdersActionTypes.KnBrDraftOrderUpdateAction),
    mergeMap((action) =>
      this.draftOrdersAdapter.update(action.payload).pipe(
        map((data: any) => {
          data.isRedirect = action.payload.isRedirect;
          return new KnBrDraftOrderUpdateSuccess(data);
        }),
        catchError((error) => {
          return of(new KnBrDraftOrderUpdateFailure(normalizeHttpError(error)));
        })
      )
    )
  );

  @Effect()
  patchOrder$ = this.actions$.pipe(
    ofType(KnBrDraftOrdersActionTypes.KnBrDraftOrderPatchAction),
    mergeMap((action) =>
      this.draftOrdersAdapter.patch(action.payload).pipe(
        map((data: any) => {
          data.isRedirect = action.payload.isRedirect;
          return new KnBrDraftOrderUpdateSuccess(data);
        }),
        catchError((error) => {
          return of(new KnBrDraftOrderUpdateFailure(normalizeHttpError(error)));
        })
      )
    )
  );

  @Effect({ dispatch: false })
  updateSuccess$ = this.actions$.pipe(
    ofType(KnBrDraftOrdersActionTypes.KnBrDraftOrderUpdateSuccessAction),
    map((action) => {
      this.knBrCartContextService.set(action.payload.code);
      if (action.payload.isRedirect) {
        this.routeService.goByUrl('carts');

        // To remove cart context if the cart is submitted for approval.
        if (action.payload.statusKey === 'CART_SELLER_SUBMITTED') {
          this.knBrCartContextService.delete();
        }
      } else {
        this.multiCartService.loadCart({
          userId: null,
          cartId: action.payload.code,
          extraData: {
            active: true,
          },
        });
      }
      this.messageService.add({ key: 'cartCommon.updateMessage.success' }, GlobalMessageType.MSG_TYPE_CONFIRMATION);
    })
  );

  @Effect({ dispatch: false })
  updateFailure$ = this.actions$.pipe(
    ofType(KnBrDraftOrdersActionTypes.KnBrDraftOrderUpdateFailureAction),
    map((action) => {
      const errors = (action.payload?.message || action.payload?.details || []) as Array<any>;
      if (errors.length > 0) {
        errors.forEach((error) => {
          const type = error.type.toLowerCase();
          if (type.indexOf('warning') !== -1) {
            this.messageService.add({ raw: error.message }, GlobalMessageType.MSG_TYPE_WARNING);
          } else if (type.indexOf('error') !== -1) {
            this.messageService.add({ raw: error.message }, GlobalMessageType.MSG_TYPE_ERROR);
          }
        });
      } else {
        this.messageService.add({ key: 'cartCommon.updateMessage.error' }, GlobalMessageType.MSG_TYPE_ERROR);
      }
    })
  );

  @Effect()
  updateEntry$ = this.actions$.pipe(
    ofType(KnBrDraftOrdersActionTypes.KnBrDraftOrderEntryUpdateAction),
    mergeMap((action) =>
      this.draftOrdersAdapter.updateEntry(action.payload).pipe(
        mergeMap((data: any) => {
          return [
            new KnBrDraftOrderEntryUpdateSuccess(data),
            new CartActions.LoadCart({ userId: null, cartId: action.payload.cartCode }),
          ];
        }),
        catchError((error: Error) => {
          return [new KnBrDraftOrderEntryUpdateFailure(normalizeHttpError(error)), new CartActions.ResetCartDetails()];
        })
      )
    )
  );

  @Effect({ dispatch: false })
  updateEntryFailure$ = this.actions$.pipe(
    ofType(KnBrDraftOrdersActionTypes.KnBrDraftOrderEntryUpdateFailureAction),
    map((action: any) => {
      const errors = (action.payload?.message || action.payload?.details || []) as Array<any>;
      if (errors.length > 0) {
        errors.forEach((error) => {
          const type = error.type.toLowerCase();
          if (type.indexOf('warning') !== -1) {
            this.messageService.add({ raw: error.message }, GlobalMessageType.MSG_TYPE_WARNING);
          } else if (type.indexOf('error') !== -1) {
            this.messageService.add({ raw: error.message }, GlobalMessageType.MSG_TYPE_ERROR);
          }
        });
      } else {
        this.messageService.add({ key: 'cartCommon.updateMessage.error' }, GlobalMessageType.MSG_TYPE_ERROR);
      }
    })
  );

  @Effect()
  chekoutValidationAction$ = this.actions$.pipe(
    ofType(KnBrDraftOrdersActionTypes.KnBrDraftOrderChekoutValidationAction),
    mergeMap((action) =>
      this.draftOrdersAdapter.validateOnCheckout().pipe(
        map((data: any) => {
          // return new CartActions.LoadCart({ userId: null, cartId: action.payload.cartCode });
          return of(new noop());
        }),
        catchError((error: Error) => {
          this.messageService.add({ key: 'cartCommon.updateMessage.error' }, GlobalMessageType.MSG_TYPE_ERROR);
          return of(new CartActions.ResetCartDetails());
        })
      )
    )
  );

  @Effect()
  getStatuses$ = this.actions$.pipe(
    ofType(KnBrDraftOrdersActionTypes.KnBrDraftOrderStatusesLoadAction),
    mergeMap((action) =>
      this.draftOrdersAdapter.getCartStatuses().pipe(
        map((data: any) => new KnBrDraftOrderLoadStatusesSuccess(data)),
        catchError((error: Error) => {
          return of(new KnBrDraftOrderLoadStatusesFailure(error));
        })
      )
    )
  );

  @Effect()
  quickEntry$ = this.actions$.pipe(
    ofType(KnBrDraftOrdersActionTypes.KnBrDraftOrderQuickEntryAction),
    mergeMap((action) =>
      this.draftOrdersAdapter.quickEntry(action.payload).pipe(
        mergeMap((data: any) => {
          this.knBrDraftOrderService.quickFormReset(true);
          this.routeService.go({ cxRoute: 'cartDetails', params: { code: action.payload.cartId } });
          return [new KnBrDraftOrderQuickEntrySuccess(data)];
        }),
        catchError((error: Error) => {
          return of(new KnBrDraftOrderQuickEntryFailure(normalizeHttpError(error)));
        })
      )
    )
  );

  @Effect({ dispatch: false })
  quickEntrySuccess$ = this.actions$.pipe(
    ofType(KnBrDraftOrdersActionTypes.KnBrDraftOrdeQuickEntrySuccessAction),
    map((action) => {
      this.messageService.add({ key: 'addToCartMessage.success' }, GlobalMessageType.MSG_TYPE_CONFIRMATION);
      const addedEntries = action.payload.knbrCartModificationList;
      if (addedEntries.length > 0) {
        if (addedEntries && addedEntries[0].statusCode && addedEntries[0].statusCode !== 'success') {
          this.messageService.add({ raw: addedEntries[0].statusMessage }, GlobalMessageType.MSG_TYPE_WARNING);
        }
      }
    })
  );

  @Effect({ dispatch: false })
  quickEntryFailure$ = this.actions$.pipe(
    ofType(KnBrDraftOrdersActionTypes.KnBrDraftOrderQuickEntryFailureAction),
    map((action) => {
      const errors = (action.payload?.message || action.payload?.details || []) as Array<any>;
      if (errors.length > 0) {
        errors.forEach((error) => {
          const type = error.type.toLowerCase();
          if (type.indexOf('warning') !== -1) {
            this.messageService.add({ raw: error.message }, GlobalMessageType.MSG_TYPE_WARNING);
          } else if (type.indexOf('error') !== -1) {
            this.messageService.add({ raw: error.message }, GlobalMessageType.MSG_TYPE_ERROR);
          }
        });
      } else {
        this.messageService.add({ key: 'addToCartMessage.failure' }, GlobalMessageType.MSG_TYPE_ERROR);
      }
    })
  );

  @Effect()
  deleteOrder$ = this.actions$.pipe(
    ofType(KnBrDraftOrdersActionTypes.KnBrDraftOrderDeleteAction),
    mergeMap((action) =>
      this.draftOrdersAdapter.delete(action.payload).pipe(
        map((data: any) => {
          // data.isRedirect = action.payload.isRedirect;
          return new KnBrDraftOrderDeleteSuccess(data);
        }),
        catchError((error) => {
          return of(new KnBrDraftOrderDeleteFailure(normalizeHttpError(error)));
        })
      )
    )
  );

  @Effect({ dispatch: false })
  deleteSuccess$ = this.actions$.pipe(
    ofType(KnBrDraftOrdersActionTypes.KnBrDraftOrderDeleteSuccessAction),
    map((action) => {
      this.routeService.goByUrl('carts');
      this.knBrCartContextService.delete();
      this.messageService.add({ key: 'cartCommon.deleteMessage.success' }, GlobalMessageType.MSG_TYPE_CONFIRMATION);
    })
  );

  @Effect({ dispatch: false })
  deleteFailure$ = this.actions$.pipe(
    ofType(KnBrDraftOrdersActionTypes.KnBrDraftOrderDeleteFailureAction),
    map((action) => {
      const errors = (action.payload?.message || action.payload?.details || []) as Array<any>;
      if (errors.length > 0) {
        errors.forEach((error) => {
          const type = error.type.toLowerCase();
          if (type.indexOf('warning') !== -1) {
            this.messageService.add({ raw: error.message }, GlobalMessageType.MSG_TYPE_WARNING);
          } else if (type.indexOf('error') !== -1) {
            this.messageService.add({ raw: error.message }, GlobalMessageType.MSG_TYPE_ERROR);
          }
        });
      } else {
        this.messageService.add({ key: 'cartCommon.deleteMessage.error' }, GlobalMessageType.MSG_TYPE_ERROR);
      }
    })
  );

  constructor(
    private actions$: Actions<KnBrDraftOrdersSearchActions>,
    private draftOrdersAdapter: KnBrDraftOrdersAdapter,
    private messageService: GlobalMessageService,
    private routeService: RoutingService,
    private activatedRoute: ActivatedRoute,
    private multiCartService: MultiCartService,
    private knBrCartContextService: KnBrCartContextService,
    private knBrQuoteReferenceService: KnBrQuoteReferenceService,
    private knBrDraftOrderService: KnBrDraftOrdersService
  ) {}
}
