import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KnBrFactSheetHeaderModule } from './kn-br-fact-sheet-header/kn-br-fact-sheet-header.module';
import { KnBrFactSheetGroupModule } from './kn-br-fact-sheet-group/kn-br-fact-sheet-group.module';
import { KnBrFactSheetMaterialModule } from './kn-br-fact-sheet-material/kn-br-fact-sheet-material.module';
import { RouterModule } from '@angular/router';
import { AuthGuard, ConfigModule, RoutingConfig } from '@spartacus/core';
import { CmsPageGuard, PageLayoutComponent } from '@spartacus/storefront';
import { KnBrCustomerContextGuard } from 'src/app/shared/guards/kn-br-customer-context.guard';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    KnBrFactSheetHeaderModule,
    KnBrFactSheetMaterialModule,
    KnBrFactSheetGroupModule,
    RouterModule.forChild([
      {
        data: { pageLabel: '/customer-360/fact-sheet', cxRoute: 'factSheet' },
        path: null,
        canActivate: [AuthGuard, CmsPageGuard, KnBrCustomerContextGuard],
        component: PageLayoutComponent,
      },
    ]),
    ConfigModule.withConfig({
      routing: {
        routes: {
          factSheet: {
            paths: ['customer-360/fact-sheet'],
          },
        },
      },
    } as RoutingConfig),
  ],
})
export class KnBrFactSheetModule {}
