import { KnBrCommonService } from './../../../../services/kn-br-common.service';
import { Product } from '@spartacus/core';
import { UntypedFormControl } from '@angular/forms';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { KnBrAddRequestEntry } from 'src/app/models/kn-br-order.model';

@Component({
  selector: 'kn-br-order-form-item',
  templateUrl: './kn-br-order-form-item.component.html',
  styleUrls: ['./kn-br-order-form-item.component.scss'],
})
export class KnBrOrderFormItemComponent implements OnInit {
  @Input() item: any;
  quantity = new UntypedFormControl(0);
  selectProduct = new UntypedFormControl(false);
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onChangeProduct = new EventEmitter<{ productCode: string; quantity: number; checked: boolean }>();
  @Input() isOrderForm = true;
  @Output() quantityChange = new EventEmitter<{ entry: KnBrAddRequestEntry; price: number }>();

  constructor(public knbrCommonService: KnBrCommonService) {}

  ngOnInit(): void {}

  onchageQuantity(event) {
    const quantity = parseInt(this.quantity.value, 10);
    let quantityvalue = quantity;
    if (quantity > 0) {
       quantityvalue =  this.knbrCommonService.
      getQuantityBasedOnMoq(quantity, this.item.minOrderQuantity);
       this.quantity.setValue(quantityvalue);
    }
    const quantitychange: KnBrAddRequestEntry = {
      productCode: this.item.code,
      quantity: quantityvalue,
    };
    const req = {
      entry: quantitychange,
      price: this.item.price.value,
    };
    this.quantityChange.emit(req);
  }

  onSelectProduct(event) {
    const obj = {
      productCode: this.item.code,
      checked: event,
      quantity: this.item.minOrderQuantity,
    };
    this.onChangeProduct.emit(obj);
  }
}
