<div class="common-up">
  <div class="modal-header d-flex align-items-center">
    <div>
      <h3 class="modal-title pb-3">{{header}}</h3>
      <h5 *ngIf="materialNum">Material # {{materialNum}}</h5>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="dismissModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div *ngIf="!data" class="modal-body float-left w-100 p-0">
    <div *ngIf="!this.hasImage" class="col-md-12 float-left mt-3">
      <p>{{message}}</p>
    </div>
    <img *ngIf="this.hasImage" [src]='image' class="pdp-img" alt="Product image"/>
  </div>

  <div *ngIf="data?.length" class="hyperlink modal-body float-left w-100 p-0 mb-3">
    <span *ngFor="let item of data">
      <a (click)="open(item?.orderNumber)" class="p-3">{{ item.orderNumber }} | {{ item.poNumber }}</a><br>
    </span>
  </div>
  <div *ngIf="!this.hasImage" class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="dismissModal()">Ok</button>
    <button type="button" class="btn btn-outline-primary" (click)="dismissModal()">Cancel</button>
  </div>
</div>
