
import { Injectable } from '@angular/core';
import { OrderHistoryConnector } from '@spartacus/order/core';
import { KnBrOrderHistoryAdapter } from './kn-br-order-return-request.adapter';

@Injectable({
  providedIn: 'root',
})
export class KnBrOrderHistoryConnector extends OrderHistoryConnector {
  constructor(protected adapter: KnBrOrderHistoryAdapter) {
    super(adapter);
  }
}
