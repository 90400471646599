import { Injectable } from '@angular/core';
import { Converter, Occ } from '@spartacus/core';
import { KnBrAddress } from '../models/kn-br-order.model';

@Injectable()
export class KnBrAddressSerializer implements Converter<KnBrAddress, Occ.Address> {
  convert(source: KnBrAddress, target: Occ.Address): Occ.Address {
    if (target === undefined) {
      target = { ...(source as any) };
    }
    return target;
  }
}
