import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { knBrSalesOrgReducer } from "./kn-br-salesorg.reducer";
import { KN_BR_SALES_ORG_FEATURE } from "./kn-br-salesorg.state";

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature(KN_BR_SALES_ORG_FEATURE, knBrSalesOrgReducer)
    ]
})
export class KnBrSalesOrgStateModule {}