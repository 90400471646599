import { Component, Input } from '@angular/core';
import { CheckoutStepService } from '@spartacus/checkout/base/components';

import { TranslationService, UserAddressService, UserCostCenterService } from '@spartacus/core';
import { ActiveCartService } from '@spartacus/cart/base/core';
import { KnBrReviewSubmitComponent } from 'src/app/kn-br-checkout/kn-br-review-submit/kn-br-review-submit.component';
import { KnBrDraftOrder } from 'src/app/models/kn-br-draft-order.model';
import { ActiveCartFacade } from '@spartacus/cart/base/root';
import {
  CheckoutPaymentFacade,
  CheckoutDeliveryModesFacade,
  CheckoutDeliveryAddressFacade,
} from '@spartacus/checkout/base/root';
import { CheckoutCostCenterFacade, CheckoutPaymentTypeFacade } from '@spartacus/checkout/b2b/root';
import { map } from 'rxjs/operators';

@Component({
  selector: 'cx-order-summary',
  templateUrl: './kn-br-order-summary.component.html',
})
export class KnBrOrderSummaryComponent extends KnBrReviewSubmitComponent {
  @Input()
  cart: KnBrDraftOrder;

  constructor(
    protected checkoutDeliveryModesFacade: CheckoutDeliveryModesFacade,
    protected checkoutDeliveryService: CheckoutDeliveryAddressFacade,
    protected checkoutPaymentService: CheckoutPaymentFacade,
    protected userAddressService: UserAddressService,
    protected activeCartService: ActiveCartService,
    protected translation: TranslationService,
    protected checkoutStepService: CheckoutStepService,
    protected checkoutPaymentTypeFacade: CheckoutPaymentTypeFacade,
    protected checkoutCostCenterFacade: CheckoutCostCenterFacade,
    protected userCostCenterService: UserCostCenterService
  ) {
    super(
      checkoutDeliveryModesFacade,
      checkoutDeliveryService,
      checkoutPaymentService,
      userAddressService,
      activeCartService,
      translation,
      checkoutStepService,
      checkoutPaymentTypeFacade,
      checkoutCostCenterFacade,
      userCostCenterService
    );
  }
}
