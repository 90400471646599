import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { I18nModule, UrlModule } from '@spartacus/core';
import { ToggleStatusModule, DisableInfoModule, CardModule } from '@spartacus/organization/administration/components';
import { KeyboardFocusModule, SplitViewModule } from '@spartacus/storefront';
import { ItemExistsModule } from '../item-exists/item-exists.module';
import { KnBrUserDetailsComponent } from './kn-br-user-details.component';

@NgModule({
  imports: [
    CommonModule,
    SplitViewModule,
    CardModule,
    RouterModule,
    UrlModule,
    I18nModule,
    ToggleStatusModule,
    ItemExistsModule,
    DisableInfoModule,
    KeyboardFocusModule,
  ],
  declarations: [KnBrUserDetailsComponent],
  exports: [KnBrUserDetailsComponent],
})
export class KnBrUserDetailsModule {}
