<ng-container *ngIf="(node$ | async)?.children[0] as nodes">
  <div class="col-4 pr-0 p-lg-0 float-right order-tools" ngbDropdown>
    <a id="dropdownBasic1" class="cursor-pointer quick-order ml-3" ngbDropdownToggle
      ><i class="fas fa-cogs mr-2"></i><span class="d-none d-sm-inline">{{ 'miniCart.orderTools' | cxTranslate }}</span>
    </a>
    <div ngbDropdownMenu class="dropdown-menu knbr-dropdown" aria-labelledby="dropdownBasic1">
      <ng-container *ngFor="let menu of nodes.children">
        <ng-container *ngIf="menu.title && menu.url">
          <button [routerLink]="menu.url" ngbDropdownItem>
            {{ menu.title }}
          </button>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-container>
