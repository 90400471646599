import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { KnBrOrderReturnEffects } from './kn-br-order-return.effects';
import { knBrOrderReturnReducer } from './kn-br-order-return.reducer';
import { KN_BR_ORDER_RETURN_FEATURE } from './kn-br-order-return.state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(KN_BR_ORDER_RETURN_FEATURE, knBrOrderReturnReducer),
    EffectsModule.forFeature([KnBrOrderReturnEffects]),
  ],
})
export class KnBrOrderReturnStateModule {}
