import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import { KNBR_FACT_SHEET_DETAILS, KN_BR_CUSTOMER_CURRENT } from 'src/constants/api.endpoints.constant';

@Injectable({
  providedIn: 'root',
})
export class KnBrFactSheetAdapter {
  customerNumber: string;
  params;
  constructor(
    private endPointService: OccEndpointsService,
    private httpClient: HttpClient,
    protected knBrCustomerContextService: KnBrCustomerContextService
  ) {
    this.knBrCustomerContextService.getActiveCustomerNumber().subscribe((response) => {
      this.customerNumber = response ? response : null;
    });
  }

  getFactSheetDetails(payload) {
    const CUSTOMER_NUMBER = this.customerNumber ? this.customerNumber : KN_BR_CUSTOMER_CURRENT;
    payload === null ? this.params = { brandId:payload, pageSize: 24 } : this.params = { brandId:payload.param, pageSize: 24, currentPage:payload.currentPage, categories: payload.categories };
    return this.httpClient.get(
      this.endPointService.buildUrl(
        KNBR_FACT_SHEET_DETAILS(CUSTOMER_NUMBER), {
        queryParams: this.params
      })
    );
  }
}
