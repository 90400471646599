import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CheckoutOrderSummaryComponent } from '@spartacus/checkout/base/components';
// import { ActiveCartService } from '@spartacus/core';
import { ActiveCartService } from '@spartacus/cart/base/core';

@Component({
  selector: 'cx-checkout-order-summary',
  templateUrl: './kn-br-checkout-order-summary.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnBrCheckoutOrderSummaryComponent extends CheckoutOrderSummaryComponent {
  constructor(protected activeCartService: ActiveCartService) {
    super(activeCartService);
  }
}
