import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { KnBrQuoteContextEffects } from './kn-br-quote-context.effects';
import { knBrQuoteContextReducer } from './kn-br-quote-context.reducer';
import { KN_BR_CONTEXT_QUOTE_FEATURE } from './kn-br-quote-context.state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(KN_BR_CONTEXT_QUOTE_FEATURE, knBrQuoteContextReducer),
    EffectsModule.forFeature([KnBrQuoteContextEffects]),
  ],
})
export class KnBrQuoteContextStateModule {}
