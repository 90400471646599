import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CmsNavigationComponent } from '@spartacus/core';
import { CategoryNavigationComponent, CmsComponentData, NavigationService } from '@spartacus/storefront';

import { KnBrResponsiveCategoryNavigationComponent } from './kn-br-responsive-category-navigation/kn-br-responsive-category-navigation.component';

@Component({
  selector: 'kn-br-category-navigation',
  templateUrl: './kn-br-category-navigation.component.html',
  styleUrls: ['./kn-br-category-navigation.component.scss'],
})
export class KnBrCategoryNavigationComponent extends CategoryNavigationComponent implements OnInit {
  constructor(
    protected componentData: CmsComponentData<CmsNavigationComponent>,
    protected service: NavigationService,
    protected modalService: NgbModal
  ) {
    super(componentData, service);
  }
  ngOnInit(): void {}

  open() {
    let modalInstance: any;
    const modalRef = this.modalService.open(KnBrResponsiveCategoryNavigationComponent, {
      centered: true,
      size: 'lg',
    });
    modalInstance = modalRef.componentInstance;
    modalInstance.node$ = this.node$;
    modalInstance.data$ = this.data$;
  }

  getMenuSlice(menu) {
    return Math.ceil(menu.length/2)
  }
}
