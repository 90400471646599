import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { KN_BR_DRAFT_ORDERS__COMMENT_FEATURE } from './kn-br-draft-orders-comments.state';
import { KnBrDraftOrdersCommentsEffects } from './kn-br-draft-orders-comments.effects';
import { knBrDraftOrdersCommentsReducer } from './kn-br-draft-orders-comments.reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(KN_BR_DRAFT_ORDERS__COMMENT_FEATURE, knBrDraftOrdersCommentsReducer),
    EffectsModule.forFeature([KnBrDraftOrdersCommentsEffects]),
  ],
})
export class KnBrDraftOrdersCommentsStateModule {}
