import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, queueScheduler } from 'rxjs';
import { observeOn, tap } from 'rxjs/operators';
import { KnBrQuoteReference } from 'src/app/models/kn-br-quote.model';

import {
  KnBrCartAddUpdateContext,
  KnBrCartLoadContext,
  KnBrCartRemoveContext,
  KnBrCartResetContext,
} from './kn-br-cart-context.actions';
import { getCartContext } from './kn-br-cart-context.selector';
import { StateWithCartContext } from './kn-br-cart-context.state';

@Injectable({
  providedIn: 'root',
})
export class KnBrCartContextService {
  pdpQuoteRef: KnBrQuoteReference;
  constructor(private store: Store<StateWithCartContext>) {
    this.store.dispatch(new KnBrCartLoadContext());
  }

  get$: Observable<string> = this.store.pipe(
    select(getCartContext),
    observeOn(queueScheduler),
    tap((context) => {
      if (!context) {
        this.store.dispatch(new KnBrCartLoadContext());
      }
    })
  );
  set(context: string) {
    this.store.dispatch(new KnBrCartAddUpdateContext(context));
  }

  delete() {
    this.store.dispatch(new KnBrCartRemoveContext());
  }
  reset() {
    this.store.dispatch(new KnBrCartResetContext());
  }
}
