import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConverterService, Occ, OccEndpointsService, OCC_USER_ID_CURRENT } from '@spartacus/core';
import { OrderHistoryAdapter } from '@spartacus/order/core';
import { OccOrderHistoryAdapter } from '@spartacus/order/occ';
import {
  Order,
  ORDER_NORMALIZER,
  OrderHistoryList,
  ORDER_HISTORY_NORMALIZER,
  ConsignmentTracking,
  CONSIGNMENT_TRACKING_NORMALIZER,
  CancellationRequestEntryInputList,
  ReturnRequestEntryInputList,
  ReturnRequest,
  ORDER_RETURN_REQUEST_INPUT_SERIALIZER,
  ORDER_RETURN_REQUEST_NORMALIZER,
  ReturnRequestList,
  ORDER_RETURNS_NORMALIZER,
  ReturnRequestModification,
} from '@spartacus/order/root';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { KN_BR_CUSTOMER_CURRENT } from 'src/constants/api.endpoints.constant';
import { KnBrCustomerContextService } from '../store/kn-br-customer-context/kn-br-customer-context.service';

@Injectable()
export class KnBrOccUserOrderAdapter extends OccOrderHistoryAdapter {
  customerNumber: string;
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService,
    protected knBrCustomerContextService: KnBrCustomerContextService
  ) {
    super(http, occEndpoints, converter);
    this.knBrCustomerContextService.getActiveCustomerNumber().subscribe((response) => {
      this.customerNumber = response ? response : null;
    });
  }

  public load(userId: string, orderCode: string): Observable<Order> {
    const customerNumber = this.customerNumber ? this.customerNumber : KN_BR_CUSTOMER_CURRENT;
    return this.http

      .get<Occ.Order>(
        this.occEndpoints.buildUrl('orderDetail', { urlParams: { customerId: customerNumber, orderId: orderCode } })
      )
      .pipe(this.converter.pipeable(ORDER_NORMALIZER));
  }

  public loadHistory(
    userId: string,
    pageSize?: number,
    currentPage?: number,
    sort?: string
  ): Observable<OrderHistoryList> {
    const params = {};
    if (pageSize) {
      params['pageSize'] = pageSize.toString();
    }
    if (currentPage) {
      params['currentPage'] = currentPage.toString();
    }
    if (sort) {
      params['sort'] = sort.toString();
    }

    const url = this.occEndpoints.buildUrl('orderHistory', { urlParams: { userId }, queryParams: { params } });

    return this.http.get<Occ.OrderHistoryList>(url).pipe(this.converter.pipeable(ORDER_HISTORY_NORMALIZER));
  }

  public getConsignmentTracking(
    orderCode: string,
    consignmentCode: string,
    userId: string = OCC_USER_ID_CURRENT
  ): Observable<ConsignmentTracking> {
    const url = this.occEndpoints.buildUrl('consignmentTracking', {
      urlParams: {
        userId,
        orderCode,
        consignmentCode,
      },
    });
    return this.http.get<ConsignmentTracking>(url).pipe(this.converter.pipeable(CONSIGNMENT_TRACKING_NORMALIZER));
  }

  public cancel(
    userId: string,
    orderCode: string,
    cancelRequestInput: CancellationRequestEntryInputList
  ): Observable<{}> {
    const url = this.occEndpoints.buildUrl('cancelOrder', {
      urlParams: {
        userId,
        orderId: orderCode,
      },
    });
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http.post(url, cancelRequestInput, { headers }).pipe(catchError((error: any) => throwError(error)));
  }

  public createReturnRequest(
    userId: string,
    returnRequestInput: ReturnRequestEntryInputList
  ): Observable<ReturnRequest> {
    const url = this.occEndpoints.buildUrl('returnOrder', {
      urlParams: {
        userId,
      },
    });
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    returnRequestInput = this.converter.convert(returnRequestInput, ORDER_RETURN_REQUEST_INPUT_SERIALIZER);

    return this.http.post(url, returnRequestInput, { headers }).pipe(
      catchError((error: any) => throwError(error)),
      this.converter.pipeable(ORDER_RETURN_REQUEST_NORMALIZER)
    );
  }

  public loadReturnRequestList(
    userId: string,
    pageSize?: number,
    currentPage?: number,
    sort?: string
  ): Observable<ReturnRequestList> {
    const params = {};
    if (pageSize) {
      params['pageSize'] = pageSize.toString();
    }
    if (currentPage) {
      params['currentPage'] = currentPage.toString();
    }
    if (sort) {
      params['sort'] = sort.toString();
    }

    const url = this.occEndpoints.buildUrl('orderReturns', { urlParams: { userId }, queryParams: { params } });

    return this.http.get<ReturnRequestList>(url).pipe(this.converter.pipeable(ORDER_RETURNS_NORMALIZER));
  }

  public loadReturnRequestDetail(userId: string, returnRequestCode: string): Observable<ReturnRequest> {
    const customerNumber = this.customerNumber ? this.customerNumber : KN_BR_CUSTOMER_CURRENT;

    const url = this.occEndpoints.buildUrl('orderReturnDetail', {
      urlParams: {
        customerId: customerNumber,
        returnRequestCode: returnRequestCode,
      },
    });

    return this.http.get<ReturnRequest>(url).pipe(this.converter.pipeable(ORDER_RETURN_REQUEST_NORMALIZER));
  }

  public cancelReturnRequest(
    userId: string,
    returnRequestCode: string,
    returnRequestModification: ReturnRequestModification
  ): Observable<{}> {
    const url = this.occEndpoints.buildUrl('cancelReturn', {
      urlParams: {
        userId,
        returnRequestCode,
      },
    });
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    return this.http
      .patch(url, returnRequestModification, { headers })
      .pipe(catchError((error: any) => throwError(error)));
  }
}
