import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { RoutingService, UserService, WindowRef } from '@spartacus/core';
import { UserAccountService } from '@spartacus/user/account/core';
import * as _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { KnBrConfirmationDialogService } from 'src/app/common/kn-br-confirm-dialog/kn-br-confirm-dialog.service';

import { KnBrCartQuoteSummaryService } from '../../../services/kn-br-cart-quote.service';
import { KnBrCartContextService } from '../../../store/kn-br-cart-context/kn-br-cart-context.service';
import { KnBrCustomerContextService } from '../../../store/kn-br-customer-context/kn-br-customer-context.service';
import { KnBrDraftOrdersService } from '../../../store/kn-br-draft-orders/kn-br-draft-orders.service';
import { CustomerData } from './../../../models/customer.model';
import { KnBrDraftOrder } from './../../../models/kn-br-draft-order.model';

@Component({
  selector: 'kn-br-draft-search-result',
  templateUrl: './kn-br-draft-search-result.component.html',
  styleUrls: ['./kn-br-draft-search-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnBrDraftSearchResultComponent implements OnInit, OnDestroy {
  draftSearchResult$: Observable<any> = this.knBrDraftSearchService.list$;
  isLoader$: Observable<boolean> = this.knBrDraftSearchService.loader$;
  paginate$: Observable<any> = this.knBrDraftSearchService.paginate$;
  searchCriteria: any;
  subscription: Subscription;
  dateFormat$: Observable<any> = this.currentUserService.get().pipe(
    filter((user: any) => user && !!user.dateFormat),
    map((user: any) => user.dateFormat)
  );
  copyAction$: Observable<any> = this.currentUserService.get().pipe(
    filter((user: any) => user),
    map((user: any) => user.copyAction)
  );
  enableCheckboxFlag = false;
  allChecked = false;
  selectedOrderCodes: string[] = [];
  orderCodes: string[] = [];
  constructor(
    protected currentUserService: UserAccountService,
    public knBrDraftSearchService: KnBrDraftOrdersService,
    protected routeService: RoutingService,
    protected confirmationDialogService: KnBrConfirmationDialogService,
    protected winRef: WindowRef,
    protected kBrCartContextService: KnBrCartContextService,
    protected knBrCustomerContextService: KnBrCustomerContextService,
    protected knBrCartQuoteSummaryService: KnBrCartQuoteSummaryService,
    protected cdr: ChangeDetectorRef
  ) {}
  ngOnInit(): void {
    this.subscription = this.knBrDraftSearchService.searchCriteria$.subscribe((response) => {
      this.searchCriteria = _.cloneDeep(response);
    });
    this.knBrCartQuoteSummaryService.setDraftOrderListSelectedProducts([]);
    this.knBrCartQuoteSummaryService.setDownloadFlag(false);
    this.subscription.add(
      this.knBrDraftSearchService.list$.subscribe((orders) => {
        if (orders) {
          this.orderCodes = orders.map((order) => order.code);
          if (this.allChecked) {
            this.selectedOrderCodes = _.cloneDeep(this.orderCodes);
            this.knBrCartQuoteSummaryService.setDraftOrderListSelectedProducts(this.selectedOrderCodes);
          }
        }
      })
    );
    this.subscription.add(
      this.knBrCartQuoteSummaryService.loadDownloadFlag$().subscribe((flag) => {
        this.enableCheckboxFlag = flag;
        if (!flag) {
          this.knBrCartQuoteSummaryService.setDraftOrderListSelectedProducts([]);
          this.allChecked = false;
        }
        this.cdr.markForCheck();
      })
    );
    this.subscription.add(
      this.knBrCartQuoteSummaryService.loadDraftOrderListSelectedProducts$().subscribe((selectedOrderCodes) => {
        this.selectedOrderCodes = selectedOrderCodes;
        this.cdr.markForCheck();
      })
    );
  }
  copyDraftContent(draftOrder: KnBrDraftOrder) {
    this.showDialog(draftOrder);
  }
  loadMore(currentPage: number) {
    if (this.searchCriteria) {
      this.searchCriteria.currentPage = currentPage + 1;
    }
    this.knBrDraftSearchService.search(this.searchCriteria);
  }
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  enableCheckbox() {
    this.enableCheckboxFlag = true;
  }
  cancelDownload() {
    this.enableCheckboxFlag = false;
    this.selectedOrderCodes = [];
    this.allChecked = false;
  }
  changeCheckBox(event) {
    event.stopPropagation();
    event.preventDefault();
    event.currentTarget.checked ? this.addOrderCode(event.target.value) : this.removeOrderCode(event.target.value);
  }
  isChecked(code: string): boolean {
    if (this.selectedOrderCodes) {
      return this.selectedOrderCodes.indexOf(code) > -1;
    }
    return false;
  }
  downloadPDF() {
    this.knBrCartQuoteSummaryService.downloadDraftOrder(
      this.selectedOrderCodes.toString(),
      this.selectedOrderCodes.length > 1
    );
    this.cancelDownload();
  }
  selectAll(event) {
    this.allChecked = event.currentTarget.checked ? true : false;
    this.selectedOrderCodes = event.currentTarget.checked ? _.cloneDeep(this.orderCodes) : [];
    this.knBrCartQuoteSummaryService.setDraftOrderListSelectedProducts(this.selectedOrderCodes);
  }
  showDialog(draftOrder) {
    const params = {
      code: draftOrder.code,
      name: draftOrder.name,
      isQuote: false,
    };
    // this.confirmationDialogService
    //   .confirm('customer.context.changeCustomerLabel', 'customer.context.changeCustomerMessage')
    //   .then((confirmed) => {
    //     if (confirmed) {
    //       this.winRef.localStorage.setItem('copyObject', JSON.stringify(params));
    //       this.routeService.go({ cxRoute: 'home' }, null);
    //     } else {
    //       this.knBrCustomerContextService.set({
    //         customerNumber: draftOrder.accountNumber,
    //         customerName: draftOrder.accountName,
    //       });
    //       this.redirectToCopy(params);
    //     }
    //   })
    //   .catch(() => { });
    this.redirectToCopy(params);
  }
  redirectToDetail(event, cart) {
    if (
      event.target &&
      event.target.classList &&
      !event.target.classList.contains('copy-action') &&
      !event.target.classList.contains('fa-copy')
    ) {
      event.stopPropagation();
      event.preventDefault();

      const customerContext: CustomerData = {
        customerNumber: cart.accountNumber,
        customerName: cart.accountName,
        telephone: cart.accountPhone,
      };

      this.knBrCustomerContextService.set(customerContext);
    //  this.kBrCartContextService.set(cart.code);
      this.routeService.go({ cxRoute: 'cartDetails', params: { code: cart.code } });
    }
  }
  redirectToCopy(params) {
    const payload = {
      referenceCartId: params.code,
      name: params.name,
    };
    this.knBrDraftSearchService.copy(payload);
  }
  addOrderCode(code) {
    if (this.selectedOrderCodes && this.selectedOrderCodes.length) {
      this.selectedOrderCodes.push(code);
    } else {
      this.selectedOrderCodes = [code];
    }
    this.allChecked = this.selectedOrderCodes.length === this.orderCodes.length;

    this.knBrCartQuoteSummaryService.setDraftOrderListSelectedProducts(this.selectedOrderCodes);
  }
  removeOrderCode(code) {
    this.selectedOrderCodes.splice(this.selectedOrderCodes.indexOf(code), 1);
    this.allChecked = this.selectedOrderCodes.length === this.orderCodes.length;
    this.knBrCartQuoteSummaryService.setDraftOrderListSelectedProducts(this.selectedOrderCodes);
  }
}
