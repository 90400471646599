import {
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomerSelectionComponent } from '@spartacus/asm/components';
import { AsmConfig, AsmService, CustomerSearchPage } from '@spartacus/asm/core';
import { User } from '@spartacus/core';
import { Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'cx-customer-selection',
  templateUrl: './kn-br-customer-selection.component.html',
  styleUrls: ['./kn-br-customer-selection.component.scss'],
  encapsulation: ViewEncapsulation.None,
  // tslint:disable-next-line:no-host-metadata-property
  host: {
    '(document:click)': 'onDocumentClick($event)',
  },
})
export class KnBrCustomerSelectionComponent extends CustomerSelectionComponent implements OnInit, OnDestroy {
  constructor(protected fb: UntypedFormBuilder, protected asmService: AsmService, protected config: AsmConfig) {
    super(fb, asmService, config);
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  onSubmit(): void {
    if (this.customerSelectionForm.valid && !!this.selectedCustomer) {
      this.submitEvent.emit({ customerId: this.selectedCustomer.uid });
    } else {
      this.customerSelectionForm.markAllAsTouched();
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
