import { createFeatureSelector, createSelector } from '@ngrx/store';

import { KN_BR_COMMENT_QUOTES_FEATURE, KnBrQuotesCommentsState } from './kn-br-quotes-comments.state';

export const getQuoteListState = createFeatureSelector<KnBrQuotesCommentsState>(KN_BR_COMMENT_QUOTES_FEATURE);

export const getQuotesComments = createSelector(
  getQuoteListState,
  (state: KnBrQuotesCommentsState) => state.quoteComments
);
