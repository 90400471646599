import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
// TODO:Spartacus - UserActions - Following actions 'ForgotPasswordEmailRequestAction', 'ResetPasswordAction', 'EmailActions', 'UpdatePasswordAction', 'UserDetailsAction' were removed. Logic was moved to '@spartacus/user'.
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { OrderActions } from '@spartacus/order/core';
import { ProductActions } from '@spartacus/core'

@Injectable()
export class KnBrSpinnerEffects {
  @Effect({ dispatch: false })
  orderDetailNotify$: Observable<any> = this.actions$.pipe(
    ofType(OrderActions.LOAD_ORDER_DETAILS),
    map((action: any) => action.payload),
    map((data) => this.knBrCommonServive.setSpinner(true))
  );

  @Effect({ dispatch: false })
  orderDetailSucessNotify$: Observable<any> = this.actions$.pipe(
    ofType(OrderActions.LOAD_ORDER_DETAILS_SUCCESS),
    map((action: any) => action.payload),
    map((data) => this.knBrCommonServive.setSpinner(false))
  );

  @Effect({ dispatch: false })
  orderDetailFailureNotify$: Observable<any> = this.actions$.pipe(
    ofType(OrderActions.LOAD_ORDER_DETAILS_FAIL),
    map((action: any) => action.payload?.error),
    map((error: any) => this.knBrCommonServive.setSpinner(false))
  );

  @Effect({ dispatch: false })
  returnOrderNotify$: Observable<any> = this.actions$.pipe(
    ofType(OrderActions.LOAD_ORDER_RETURN_REQUEST),
    map((action: any) => action.payload),
    map((data) => this.knBrCommonServive.setSpinner(true))
  );

  @Effect({ dispatch: false })
  returnOrderSucessNotify$: Observable<any> = this.actions$.pipe(
    ofType(OrderActions.LOAD_ORDER_RETURN_REQUEST_SUCCESS),
    map((action: any) => action.payload),
    map((data) => this.knBrCommonServive.setSpinner(false))
  );

  @Effect({ dispatch: false })
  returnOrderFailureNotify$: Observable<any> = this.actions$.pipe(
    ofType(OrderActions.LOAD_ORDER_RETURN_REQUEST_FAIL),
    map((action: any) => action.payload?.error),
    map((error: any) => this.knBrCommonServive.setSpinner(false))
  );

  @Effect({ dispatch: false })
  productSearch$: Observable<any> = this.actions$.pipe(
    ofType(ProductActions.SEARCH_PRODUCTS),
    map((action: any) => action),
    tap(action => {
      //This is required to skip showing spinner on search box typing.
      if (action.payload.queryText.indexOf(':') > -1) {
        this.knBrCommonServive.setSpinner(true)
      }
    })
  );

  @Effect({ dispatch: false })
  productSearchSuccess$: Observable<any> = this.actions$.pipe(
    ofType(ProductActions.SEARCH_PRODUCTS_SUCCESS, ProductActions.SEARCH_PRODUCTS_FAIL),
    map((action: any) => action.payload)
  );


  constructor(private actions$: Actions, private knBrCommonServive: KnBrCommonService) { }
}
