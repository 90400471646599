import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CheckoutAdapter } from '@spartacus/checkout/base/core';
import { CheckoutState } from '@spartacus/checkout/base/root';
import {
  ConverterService,
  InterceptorUtil,
  Occ,
  OccEndpointsService,
  OCC_USER_ID_ANONYMOUS,
  USE_CLIENT_TOKEN,
} from '@spartacus/core';
import { Order, ORDER_NORMALIZER } from '@spartacus/order/root';
import { Observable } from 'rxjs';
import { KnBrCustomerContextService } from '../kn-br-customer-context/kn-br-customer-context.service';

// To be changed to a more optimised params after ticket: C3PO-1076
const FULL_PARAMS = 'fields=FULL';
const CHECKOUT_PARAMS = 'deliveryAddress(FULL),deliveryMode,paymentInfo(FULL)';
const ORDERS_ENDPOINT = '/orders';
const CARTS_ENDPOINT = '/carts/';
@Injectable()
export class KnBrOccCheckoutAdapter implements CheckoutAdapter {
  customerId: string;
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService,
    private customerContextService: KnBrCustomerContextService
  ) {
    this.customerContextService.get$.subscribe((response) => {
      this.customerId = response && response.customerNumber ? response.customerNumber : null;
    });
  }

  protected getEndpoint(userId: string, subEndpoint: string): string {
    return this.occEndpoints.buildUrl('knbr/customer/' + this.customerId + subEndpoint);
  }

  public placeOrder(userId: string, cartId: string, termsChecked = true): Observable<Order> {
    const url = this.getEndpoint(userId, ORDERS_ENDPOINT);
    const params = new HttpParams({
      fromString: `cartId=${cartId}&termsChecked=${termsChecked}&${FULL_PARAMS}`,
    });

    let headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    if (userId === OCC_USER_ID_ANONYMOUS) {
      headers = InterceptorUtil.createHeader(USE_CLIENT_TOKEN, true, headers);
    }

    return this.http.post<Occ.Order>(url, {}, { headers, params }).pipe(this.converter.pipeable(ORDER_NORMALIZER));
  }

  getCheckoutDetails(userId: string, cartId: string): Observable<CheckoutState> {
    const url = this.getEndpoint(userId, CARTS_ENDPOINT) + cartId;
    const params = new HttpParams({
      fromString: `fields=${CHECKOUT_PARAMS}`,
    });
    return this.http.get<CheckoutState>(url, { params });
  }

  clearCheckoutDeliveryAddress(userId: string, cartId: string): Observable<any> {
    const url = `${this.getEndpoint(userId, CARTS_ENDPOINT)}${cartId}/addresses/delivery`;
    return this.http.delete<any>(url);
  }

  clearCheckoutDeliveryMode(userId: string, cartId: string): Observable<any> {
    const url = `${this.getEndpoint(userId, CARTS_ENDPOINT)}${cartId}/deliverymode`;
    return this.http.delete<any>(url);
  }
}
