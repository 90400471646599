import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Breadcrumb } from '@spartacus/core';
import { FacetList, FacetService, ICON_TYPE } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';

@Component({
  selector: 'kn-br-active-facet',
  templateUrl: './kn-br-active-facet.component.html',
  styleUrls: ['./kn-br-active-facet.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class KnBrActiveFacetComponent {
  /** Active facets which are applied to the product results. */
  facetList$: Observable<FacetList> = this.facetService.facetList$;
  /** Configurable icon which is used for the active facet close button */
  @Input() closeIcon = ICON_TYPE.CLOSE;
  constructor(protected facetService: FacetService, private knBrCommonService: KnBrCommonService,) { }
  getLinkParams(facet: Breadcrumb) {
    return this.facetService.getLinkParams(facet.removeQuery?.query?.value);
  }

  removeFacets(activeFacets: any) {
    activeFacets.forEach(activeFacet => {
      return this.facetService.getLinkParams(activeFacet.removeQuery?.query?.value);
    });
  }
  /**
   * The focus key is used to persist the focus on the facet when the DOM is being
   * recreated. We only apply the focus key for the given _active_ facet when there
   * the original facets is not available. This happens for non multi-valued facets.
   *
   * With this approach, the we keep the focus, either at the facet list or on the
   * active facets.
   */
  getFocusKey(facetList: FacetList, facet: Breadcrumb) {
    return facetList.facets?.find((f) => f.values?.find((val) => val.name === facet.facetValueName))
      ? ''
      : facet.facetValueName;
  }
  linkClick() {
    this.knBrCommonService.setSpinner(true)
  }
}
