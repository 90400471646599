<div class="col-lg-12 filter-section p-0">
  <button #trigger class="btn btn-action btn-block dialog-trigger knbr-filter-btn" (click)="launch()">
    <cx-icon [type]="iconTypes.FILTER"></cx-icon>
    <span class="d-none d-sm-inline"> {{ 'productList.filterBy.label' | cxTranslate }}</span>
  </button>
  <kn-br-active-facet></kn-br-active-facet>
  <kn-br-facet-list
    *ngIf="isOpen$ | async"
    [isDialog]="hasTrigger"
    (closeList)="close()"
    [class.active]="isActive$ | async"
    [class.dialog]="hasTrigger"
  ></kn-br-facet-list>
</div>
