<div class="col-md-12 float-left customersearch-forms login-forms p-0 customer-home-forms mb-4">
  <form [formGroup]="draftSearchForm" (ngSubmit)="submitForm()">
    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0">
      <input
        type="text"
        class="form-control w-100"
        [placeholder]="'cartSearchForm.label.draftOrderName' | cxTranslate"
        formControlName="draftOrderName"
      />
      <span class="highlight"></span>
      <label class="text-muted">{{ 'cartSearchForm.label.draftOrderName' | cxTranslate }}</label>
    </div>

    <div class="form-group mt-3 col-lg-3 pl-0 float-left mb-3 p-res-0">
      <input
        type="text"
        class="form-control w-100"
        [placeholder]="'cartSearchForm.label.accountNumber' | cxTranslate"
        formControlName="accountNumber"
      />
      <span class="highlight"></span>
      <label class="text-muted">{{ 'cartSearchForm.label.accountNumber' | cxTranslate }}</label>
    </div>

    <div class="form-group mt-3 col-lg-3 pl-0 float-left mb-3 p-res-0">
      <input
        type="text"
        class="form-control w-100"
        [placeholder]="'cartSearchForm.label.accountName' | cxTranslate"
        formControlName="accountName"
      />
      <span class="highlight"></span>
      <label class="text-muted">{{ 'cartSearchForm.label.accountName' | cxTranslate }}</label>
    </div>

    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0">
      <input
        type="text"
        class="form-control w-100"
        [placeholder]="'cartSearchForm.label.customerPO' | cxTranslate"
        formControlName="purchaseOrderNumber"
      />
      <span class="highlight"></span>
      <label class="text-muted">{{ 'cartSearchForm.label.customerPO' | cxTranslate }}</label>
    </div>

    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0">
      <input
        type="text"
        class="form-control w-100"
        [placeholder]="'cartSearchForm.label.jobNameNumber' | cxTranslate"
        formControlName="jobName"
      />
      <span class="highlight"></span>
      <label class="text-muted">{{ 'cartSearchForm.label.jobNameNumber' | cxTranslate }}</label>
    </div>

    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0">
      <ng-select
        class=""
        formControlName="status"
        bindLabel="Text"
        bindValue="Key"
        [items]="status$ | async"
        [placeholder]="'cartSearchForm.label.status' | cxTranslate"
        [searchable]="false"
        [clearable]="false"
        bindLabel="Text"
        bindValue="Key"
      >
      </ng-select>
      <span class="highlight"></span>
      <label class="text-muted">{{ 'cartSearchForm.label.status' | cxTranslate }}</label>
    </div>

    <div class="col-lg-12 p-0 float-left">
      <div class="col-lg-2 pl-0 p-res-0 float-left">
        <button
          type="submit"
          [disabled]="isSearchDisabled$ | async"
          class="w-100 btn btn-primary mt-1 mt-sm-0"
        >
          {{ 'button.search' | cxTranslate }}
        </button>
      </div>
      <div class="col-lg-2 pl-0 p-res-0 float-left">
        <button type="button" (click)="resetForm()" class="w-100 btn btn-outline-primary mt-1 mt-sm-0">
          {{ 'button.reset' | cxTranslate }}
        </button>
      </div>
      <!-- <div class="col-lg-2 p-0 p-res-0 float-right mt-1 mt-sm-0">
        <cx-page-slot position="KnBrDraftOrderListButtonSlot"></cx-page-slot>
      </div> -->
    </div>
  </form>
</div>
