import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Subscription, zip } from 'rxjs';
import { KnBrCustomerContextService } from '../../../../app/store/kn-br-customer-context/kn-br-customer-context.service';
import { CustomerData } from '../../../../app/models/customer.model';
import { KN_BR_CUSTOMER_CURRENT } from '../../../../../src/constants/api.endpoints.constant';
import { KnBrCommonService } from '../../../../app/services/kn-br-common.service';
import { Statuses } from '../../../models/kn-br-draft-order.model';
import { KnBrOrderReturnService } from '../../../store/kn-br-order-return/kn-br-order-return.service';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { KnBrDateHelper } from 'src/app/shared/kn-br-date.helper';
import { ActivatedRoute } from '@angular/router';
import { isEqual } from 'lodash';

@Component({
  selector: 'knbr-kn-br-return-order-search',
  templateUrl: './kn-br-return-order-search.component.html',
  styleUrls: ['./kn-br-return-order-search.component.scss'],
})
export class KnBrReturnOrderSearchComponent implements OnInit {
  returnOrdersSearchForm: UntypedFormGroup;
  customerContextData$: Observable<CustomerData> = this.knBrCustomerContextService.get$;
  selectedCustomer: CustomerData;
  orderStatusList$: Observable<Statuses[]> = this.knBrCommonService.loadReturnOrderStatusList$();
  brandSalesShipToList$: Observable<Statuses[]> = this.knBrCommonService.loadBrandSalesShipToList$();
  isSearchDisabled$: Observable<boolean> = this.knBrOrderReturnService.isSearchDisabled$;
  currentPage = 0;
  todayDate = () => {
    const today = new Date();
    return ({ year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate() });
  };
  maxStartDate = () => this.todayDate();
  minEndDate = () => this.todayDate();
  fromDate: any;
  toDate: any;
  hasDates = false;
  pBrand = [];
  pStatus = [];
  pShipTo = [];
  state$: Observable<unknown>;
  retrievalPending = true;
  combinedCallSubscription: Subscription;
  subscription: Subscription = new Subscription();

  constructor(
    private fb: UntypedFormBuilder,
    private knBrCustomerContextService: KnBrCustomerContextService,
    private knBrCommonService: KnBrCommonService,
    private knBrOrderReturnService: KnBrOrderReturnService,
    private knBrDateHelper: KnBrDateHelper,
    protected activatedRoute: ActivatedRoute,

  ) {}

  ngOnInit(): void {
    this.state$ = this.activatedRoute.paramMap
    .pipe(map(() => window.history.state));
    this.subscription.add(
      this.customerContextData$.subscribe((value) => {
        if (value) {
          this.selectedCustomer = value;
        }
        const customerNumber = value?.customerNumber ? value?.customerNumber : KN_BR_CUSTOMER_CURRENT;
        this.knBrCommonService.getReturnOrderStatusList(customerNumber);
        this.knBrCommonService.getBrandSalesShipToList(customerNumber);
        this.initForm();
      })
    );

    this.state$.subscribe((data:any) => {
      console.log("logging the data", data)
      if(data && data.displayPersistedValues) {
        // this.submitForm("persist");
      } else {
        this.submitForm()
      }
    })

  }

  initForm() {
    const persistedData = JSON.parse(sessionStorage.getItem("returnOrderSearch"));
    let flagValue = false;
    this.state$.subscribe((data:any) => {
      if(data && data.displayPersistedValues) {
        flagValue = true;
      } else {
        flagValue = false;
      }
    });
    this.returnOrdersSearchForm = this.fb.group({
      rma: (flagValue && persistedData?.rma) ? persistedData.rma : null,
      accountNumber: [{
        value: this.selectedCustomer && this.selectedCustomer.customerNumber ? this.selectedCustomer.customerNumber : null,
        disabled: this.selectedCustomer && this.selectedCustomer.customerNumber ? true : false,
      }],
      accountName: [{
        value: this.selectedCustomer && this.selectedCustomer.customerName ? this.selectedCustomer.customerName : null,
        disabled: this.selectedCustomer && this.selectedCustomer.customerName ? true : false,
      }],
      purchaseOrderNumber: (flagValue && persistedData?.purchaseOrderNumber) ? persistedData.purchaseOrderNumber : null,
      status: null,
      productNumber: (flagValue && persistedData?.productNumber) ? persistedData.productNumber : null,
      productName: (flagValue && persistedData?.productName) ? persistedData.productName : null,
      brand: null,
      shipTo: null,
      currentPage: this.currentPage,
      pageSize: 50,
      startDate: null,
      endDate: null,
      _startDate: (flagValue && persistedData?.startDate) ? this.knBrCommonService.formatDate(this.knBrDateHelper.getDate(persistedData?.startDate)) : null,
      _endDate: (flagValue && persistedData?.endDate) ? this.knBrCommonService.formatDate(this.knBrDateHelper.getDate(persistedData?.endDate)) : null
    });
    this.setDateValidators();

      this.state$.subscribe((data:any) => {
      if(data && data.displayPersistedValues && this.retrievalPending) {
        if(persistedData) {
          const sessionStorageCusNo = sessionStorage.getItem("sscustomerNumber");
          if(this.selectedCustomer.customerNumber != sessionStorageCusNo && this.retrievalPending) {
            this.retrievalPending = false;
            this.combinedCallSubscription = zip(this.orderStatusList$, this.brandSalesShipToList$).subscribe((res:any)=> {
              const combinedData = {
                statusData: res[0],
                bresp: res[1],
              }
              const {statusData, bresp } = combinedData;
              if(persistedData.brand) {
                persistedData.brand = persistedData.brand.filter(eachBrand => this.containsObject(eachBrand, bresp.brand));
                this.pBrand = persistedData.brand
              }
              if(persistedData.shipTo) {
                persistedData.shipTo = persistedData.shipTo.filter(eachShipTo => this.containsObject(eachShipTo, bresp.shipTo));
                this.pShipTo = persistedData.shipTo;
              }
              if(persistedData.status) {
                persistedData.status = persistedData.status.filter(eachStatus => this.containsObject(eachStatus, statusData));
                this.pStatus = persistedData.status;
              }
              this.submitForm('persist');
            });

          } else if(this.retrievalPending) {
            this.retrievalPending = false
            this.pBrand = persistedData.brand || [];
            this.pStatus = persistedData.status || [];
            this.pShipTo = persistedData.shipTo || [];
            this.submitForm("newpersist")
          }

        }
      }
    })

  }

  setDateValidators() {
    const startDate = this.returnOrdersSearchForm.get('_startDate');
    const enddate = this.returnOrdersSearchForm.get('_endDate');

    startDate.valueChanges.pipe(distinctUntilChanged()).subscribe(fromDate => {
      if (fromDate && enddate.value === null) {
        enddate.setValidators([Validators.required]);
      } else {
        enddate.setValidators(null);
      }
      enddate.updateValueAndValidity();

      if (enddate.value && startDate.value) {
        const { year: y2, month: m2, day: d2 } = enddate.value;
        const e = new Date(y2, m2 - 1, d2);

        const { year: y1, month: m1, day: d1 } = startDate.value;
        const s = new Date(y1, m1 - 1, d1);

        if (s > e) {
          enddate.setValue(null)
        }
      }
    });
    enddate.valueChanges.pipe(distinctUntilChanged()).subscribe(toDate => {
      if (toDate) {
        startDate.setValidators([Validators.required]);
      } else {
        startDate.setValidators(null);
      }
      startDate.updateValueAndValidity();
    });
  }

  submitForm(shouldRetain?) {
    if (this.returnOrdersSearchForm.valid) {
      this.returnOrdersSearchForm.patchValue({
        startDate: this.knBrDateHelper.formatDate(this.returnOrdersSearchForm.value._startDate),
        endDate: this.knBrDateHelper.formatDate(this.returnOrdersSearchForm.value._endDate)
      });
      const { _startDate, _endDate, ...payload } = this.returnOrdersSearchForm.getRawValue();
      const updatedPayload = {...payload};
      const updatedSessionStorage = {...payload};

      if(shouldRetain) {
        updatedSessionStorage.brand = this.pBrand;
        updatedSessionStorage.status = this.pStatus;
        updatedSessionStorage.shipTo = this.pShipTo;
        updatedPayload.brand = (this.pBrand && this.pBrand.length) ? this.pBrand.map(eachbrand => eachbrand.value).join(",") : '';
        updatedPayload.status = (this.pStatus && this.pStatus.length) ? this.pStatus.map(eachStatus => eachStatus.Key).join(",") : '';
        updatedPayload.shipTo = (this.pShipTo && this.pShipTo.length) ? this.pShipTo.map(eachStatshipTous => eachStatshipTous.value).join(",") : ''
      } else {
        updatedPayload.brand = (payload.brand && payload.brand.length) ? payload.brand.map(eachbrand => eachbrand.value).join(",") : '';
        updatedPayload.status = (payload.status && payload.status.length) ? payload.status.map(eachStatus => eachStatus.Key).join(",") : '';
        updatedPayload.shipTo = (payload.shipTo &&  payload.shipTo.length) ? payload.shipTo.map(eachShipTo => eachShipTo.value).join(",") : '';
      }
      window.sessionStorage.setItem("returnOrderSearch",JSON.stringify(updatedSessionStorage));
      window.sessionStorage.setItem("sscustomerNumber", this.selectedCustomer?.customerNumber)
      this.knBrOrderReturnService.returnOrderListSearch(updatedPayload);
    }
  }

  onDateChange(value, param?) {
    this.knBrCommonService.onDateChange(value, param);
  }
  containsObject(obj, list) {
    var i;
    for (i = 0; i < list.length; i++) {
        if (isEqual(list[i], obj)) {
            return true;
        }
    }

    return false;
}

  resetForm() {
    this.returnOrdersSearchForm.reset();
    sessionStorage.removeItem('returnOrderSearch');
    this.initForm();
    this.knBrCommonService.fromDate = null;
    this.knBrCommonService.toDate = null;
    this.submitForm();
    this.clearDateInfo();
  }

  clearDateInfo() {
    this.knBrCommonService.clearDateInfo();
  }

  ngOnDestroy() {
    if(this.combinedCallSubscription) {
      this.combinedCallSubscription.unsubscribe();
    }
    this.clearDateInfo();
    this.subscription?.unsubscribe();
  }
}
