import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { KnBrMessageDialogComponent } from './kn-br-message-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class KnBrMessageDialogService {
  modalRef!: NgbModalRef;
  constructor(private modalService: NgbModal) {}

  public open(
    title: string,
    message: string,
    btnOkText: string = 'button.ok',
    dialogSize: 'sm' | 'lg' = 'sm',
    innerHtml?: string
  ): Promise<boolean> {
    this.modalRef = this.modalService.open(KnBrMessageDialogComponent, { size: dialogSize, centered: true });
    this.modalRef.componentInstance.title = title;
    this.modalRef.componentInstance.message = message;
    this.modalRef.componentInstance.btnOkText = btnOkText;
    this.modalRef.componentInstance.innerHtml = innerHtml;
    return this.modalRef.result;
  }

  getModalRef() {
    return this.modalRef;
  }

}
