

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService, ConverterService } from '@spartacus/core';
import { OccOrderHistoryAdapter } from '@spartacus/order/occ';


@Injectable({
  providedIn: 'root',
})
export  class KnBrOrderHistoryAdapter extends OccOrderHistoryAdapter {
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService
  ) {
    super(http, occEndpoints, converter)
  }

  
}
