import { Component, OnDestroy } from '@angular/core';
import { OccConfig, RoutingService, SiteContextParamsService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { KnBrCustomerContextService } from '../../../store/kn-br-customer-context/kn-br-customer-context.service';
import { KnBrCustomerService } from '../../../store/kn-br-customer/kn-br-customer.service';
import { CustomerData } from './../../../models/customer.model';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import { isEmpty } from 'lodash';

@Component({
  selector: 'knbr-customer-search-result',
  templateUrl: './kn-br-customer-search-result.component.html',
  styleUrls: ['./kn-br-customer-search-result.component.scss'],
})

export class KnBrCustomerSearchResultComponent implements OnDestroy {
  customerSearchSubscription: any;
  customerResult$: Observable<CustomerData[]> = this.customerSearchService.list$;
  isLoading$: Observable<boolean> = this.customerSearchService.loading$;
  routedFrom: string;
  copyObject: any;
  constructor(
    private customerSearchService: KnBrCustomerService,
    private routingService: RoutingService,
    private customerContextService: KnBrCustomerContextService,
    protected config: OccConfig,
    protected siteContextParamsService: SiteContextParamsService,
    private knbrCommonService: KnBrCommonService
  ) {}
  storeInContext(customer: CustomerData) {
    this.customerContextService.set(customer);
    const indexOfSite =  this.config.context.country.indexOf(customer.country.toLowerCase())
    if(indexOfSite > -1) {
      const site = this.config.context.baseSite[indexOfSite];
      const currency = this.config.context.currency[indexOfSite]
      this.siteContextParamsService.setValue('baseSite', site);
      this.siteContextParamsService.setValue('currency',currency);
      window.location.href = `/${site}/${currency}`;
    } else {
      this.routingService.go({ cxRoute: 'home' });
    }
  }

  formatState(state) {
    return this.knbrCommonService.formatState(state);
  }

  ngOnDestroy(): void {
    this.knbrCommonService.customersData$.subscribe(res => {
      if (isEmpty(res)) {
        this.knbrCommonService.getCustomersData();
      }
    })
  }
}
