import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { FormBuilder, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CsAgentAuthService } from '@spartacus/asm/root';
import { CheckoutPlaceOrderComponent } from '@spartacus/checkout/base/components';
import {
  CheckoutReplenishmentFormService,
  CheckoutScheduledReplenishmentPlaceOrderComponent,
} from '@spartacus/checkout/scheduled-replenishment/components';
// import { CheckoutFacade } from "@spartacus/checkout/root";
import { RoutingService } from '@spartacus/core';
import { LaunchDialogService } from '@spartacus/storefront';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { OrderFacade, ScheduledReplenishmentOrderFacade } from '@spartacus/order/root';

@Component({
  selector: 'cx-place-order',
  templateUrl: './kn-br-place-order.component.html',
  styleUrls: ['./kn-br-place-order.component.scss'],
})
export class KnBrPlaceOrderComponent
  extends CheckoutScheduledReplenishmentPlaceOrderComponent
  implements OnInit, OnDestroy
{
  checkoutSubmitForm: UntypedFormGroup = this.fb.group({
    termsAndConditions: [true, Validators.requiredTrue],
  });
  subscriprion = new Subscription();
  disablePlaceOrder = false;
  isASMLoggedIn$: Observable<boolean> = this.csAgentAuthService.isCustomerSupportAgentLoggedIn();
  constructor(
    protected checkoutService: OrderFacade,
    protected routingService: RoutingService,
    protected fb: FormBuilder,
    protected launchDialogService: LaunchDialogService,
    protected vcr: ViewContainerRef,
    protected checkoutReplenishmentFormService: CheckoutReplenishmentFormService,
    protected cdr: ChangeDetectorRef,
    protected scheduledReplenishmentOrderFacade: ScheduledReplenishmentOrderFacade,
    protected csAgentAuthService: CsAgentAuthService
  ) {
    // tslint:disable-next-line: max-line-length
    super(
      checkoutService,
      routingService,
      fb,
      launchDialogService,
      vcr,
      checkoutReplenishmentFormService,
      scheduledReplenishmentOrderFacade
    );
  }

  ngOnInit(): void {
    // super.ngOnInit();
    // this.subscriprion.add(
    //   combineLatest([
    //     this.orderFacade.getOrderDetails(),
    //     // this.orderFacade.getPlaceOrderLoading(),
    //     // this.orderFacade.getPlaceOrderSuccess(),
    //     // this.orderFacade.getPlaceOrderError(),
    //   ]).subscribe(([orderLoading, orderSuccess, orderError]) => {
    //     if (orderLoading) {
    //       this.disablePlaceOrder = true;
    //       this.cdr.markForCheck();
    //     }
    //     if (orderError) {
    //       this.disablePlaceOrder = false;
    //       this.cdr.markForCheck();
    //     }

    //     if (orderSuccess) {
    //       this.disablePlaceOrder = false;
    //       this.cdr.markForCheck();
    //     }
    //   })
    // );

    this.orderFacade
      .getOrderDetails()
      .pipe()
      .subscribe(
        (order) => {
          this.disablePlaceOrder = false;
          this.cdr.markForCheck();
        },
        (err) => {
          this.disablePlaceOrder = false;
          this.cdr.markForCheck();
        }
      );
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.subscriprion.unsubscribe();
  }
}
