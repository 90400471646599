import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { I18nModule, RoutingModule, UrlModule } from '@spartacus/core';
import { FormErrorsModule, MediaModule } from '@spartacus/storefront';

import { KnBrDirectiveModule } from '../../../../shared/directive/kn-br-directive.module';
import { KnBrDraftOrderItemComponent } from './kn-br-draft-order-item.component';

@NgModule({
  declarations: [KnBrDraftOrderItemComponent],
  imports: [
    CommonModule,
    RouterModule,
    UrlModule,
    RoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MediaModule,
    NgbModule,
    I18nModule,
    KnBrDirectiveModule,
    FormErrorsModule,
  ],
  exports: [KnBrDraftOrderItemComponent],
})
export class KnBrDraftOrderItemModule {}
