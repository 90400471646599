import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigModule, I18nModule, RoutingConfig } from '@spartacus/core';
import { KnBrInvoiceDetailsItemsModule } from './kn-br-invoice-list-detail-items/kn-br-invoice-list-detail-items.module';
import { KnBrInvoiceDetailsOverviewModule } from './kn-br-invoice-list-detail-overview/kn-br-invoice-list-detail-overview.module';

@NgModule({
  declarations: [],
  imports: [
    I18nModule,
    CommonModule,
    KnBrInvoiceDetailsItemsModule,
    KnBrInvoiceDetailsOverviewModule,
    ConfigModule.withConfig({
      routing: {
        routes: {
          invoiceDetail: {
            paths: ['customer-360/invoice-list/invoice'],
          },
        },
      },
    } as RoutingConfig),
  ]
})
export class KnBrInvoiceListDetailModule { }
