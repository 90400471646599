import { KnBrFactSheetActions, KnBrFactSheetActionTypes } from './kn-br-fact-sheet.action';
import { KnBrFactSheetState } from './kn-br-fact-sheet.state';

export const initialState: KnBrFactSheetState = {
  factSheetData: undefined,
  error: undefined,
  isLoading: false,
  pagination: undefined
};

export function KnBrFactSheetReducer(state = initialState, action: KnBrFactSheetActions): KnBrFactSheetState {
  switch (action.type) {
    case KnBrFactSheetActionTypes.KnBrFactSheetDetailsAction:
      return { ...state, isLoading: true };
    case KnBrFactSheetActionTypes.KnBrFactSheetDetailsSuccessAction:
      return {
        ...state,
        factSheetData: priceSheetItems(state, action),
        pagination: action.payload?.pagination,
        isLoading: false,
      };
    case KnBrFactSheetActionTypes.KnBrFactSheetDetailsFailureAction:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
}

function priceSheetItems(state, action) {
  if (action.payload?.pagination?.currentPage > 0 && state.factSheetData.priceSheetItems) {
    return {
      ...action.payload,
      priceSheetItems: [...state.factSheetData?.priceSheetItems, ...action.payload?.priceSheetItems],
    };
  } else {
   return { ...action.payload }
  }
}
