import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { MediaModule } from '@spartacus/storefront';
import { KnBrInvoiceListDetailItemsComponent } from './kn-br-invoice-list-detail-items.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [KnBrInvoiceListDetailItemsComponent],
  imports: [
    CommonModule,
    I18nModule,
    MediaModule,
    NgbModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CarlisleInvoiceDetailsBodyComponent: {
          component: KnBrInvoiceListDetailItemsComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class KnBrInvoiceDetailsItemsModule {}
