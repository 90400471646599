import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KnBrReturnOrderSearchComponent } from './kn-br-return-order-search/kn-br-return-order-search.component';
import { KnBrReturnOrderSearchResultComponent } from './kn-br-return-order-search-result/kn-br-return-order-search-result.component';
import { AuthGuard, ConfigModule, I18nModule, RoutingConfig } from '@spartacus/core';
import { KnBrReturnOrderSearchResultModule } from './kn-br-return-order-search-result/kn-br-return-order-search-result.module';
import { KnBrReturnOrderSearchModule } from './kn-br-return-order-search/kn-br-return-order-search.module';
import { CmsPageGuard, PageLayoutComponent } from '@spartacus/storefront';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    I18nModule,
    KnBrReturnOrderSearchResultModule,
    KnBrReturnOrderSearchModule,
    RouterModule.forChild([
      {
        data: { pageLabel: 'customer-360/returns', cxRoute: 'returnOrders' },
        path: null,
        canActivate: [AuthGuard, CmsPageGuard],
        component: PageLayoutComponent,
      },
    ]),
    ConfigModule.withConfig({
      routing: {
        routes: {
          returnOrders: {
            paths: ['customer-360/returns'],
          },
        },
      },
    } as RoutingConfig),
  ],
})
export class KnBrReturnOrderListModule {}
