import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { CustomerData } from '../models/customer.model';
import { Statuses } from '../models/kn-br-quote.model';
import { KnBrCommonService } from '../services/kn-br-common.service';
import { KnBrCustomerContextService } from '../store/kn-br-customer-context/kn-br-customer-context.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { KN_BR_CUSTOMER_CURRENT } from 'src/constants/api.endpoints.constant';
import { KnBrDateHelper } from '../shared/kn-br-date.helper';
import { KnBrCustomerService } from '../store/kn-br-customer/kn-br-customer.service';

@Component({
  selector: 'knbr-kn-br-shipping',
  templateUrl: './kn-br-shipping.component.html',
  styleUrls: ['./kn-br-shipping.component.scss']
})

export class KnBrShippingComponent implements OnInit, OnDestroy {
  shipmentSearchForm: UntypedFormGroup;
  shipmentStatusList$: Observable<Statuses[]> = this.knBrCommonService.loadShippingStatusList$();
  brandSalesShipToList$: Observable<Statuses[]> = this.knBrCommonService.loadBrandSalesShipToList$();
  shipmentTypeList$: Observable<Statuses[]> = this.knBrCommonService.loadShipmentTypeList$();
  shipmentPointList$: Observable<Statuses[]> = this.knBrCommonService.loadShipmentPointList$();
  customerContextData$: Observable<CustomerData> = this.knBrCustomerContextService.get$;
  customerResult$: Observable<CustomerData[]> = this.customerSearchService.list$;
  customersDataList$: Observable<any> = this.knBrCommonService.loadCustomersData$();

  selectedCustomer: CustomerData;
  currentPage = 0;
  fromDate: any;
  toDate: any;
  customerNumber = '';
  hasContext = false;
  subscription = new Subscription();
  accountNumber = [];
  maxDates: { year: any; month: any; day: any; };
  minDates: { year: number; month: any; day: any; };
  mindateTo: any;
  maxdatefrom: any;
  todayDate = () => {
    const today = new Date();
    return ({ year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate() });
  };
  maxfromDate = () => this.todayDate();
  maxtoDate = () => this.todayDate();
  mintoDate = () => this.todayDate();
  brandList = [];
  customersList = [];

  constructor(protected fb: UntypedFormBuilder,
    protected knBrCustomerContextService: KnBrCustomerContextService,
    protected knBrCommonService: KnBrCommonService,
    protected activatedRoute: ActivatedRoute,
    private knBrDateHelper: KnBrDateHelper,
    private customerSearchService: KnBrCustomerService)
    {}

  ngOnInit(): void {
    this.subscription.add(
      this.customerContextData$.subscribe((value) => {
        if (value) {
          this.hasContext = true;
          this.selectedCustomer = value;
          this.customerNumber = this.selectedCustomer.customerNumber ? this.selectedCustomer.customerNumber : null;
        } else {
          this.getCustomers();
        }
        this.knBrCommonService.getShipmentStatusList(KN_BR_CUSTOMER_CURRENT);
        this.knBrCommonService.getShipmentTypeList(KN_BR_CUSTOMER_CURRENT);
        this.knBrCommonService.getShipmentPointList(KN_BR_CUSTOMER_CURRENT);
        this.knBrCommonService.getBrandSalesShipToList(KN_BR_CUSTOMER_CURRENT);
        this.initForm();
      })
    )

     this.subscription.add(
       this.brandSalesShipToList$.subscribe((res: any) => {
         this.brandList = [];
         if (res && res.brand) {
           res.brand.forEach(item => this.brandList.push(item.value));
           this.shipmentSearchForm.patchValue({
             brand: this.brandList.join(','),
           });
             this.submitForm();
         }
       })
     )
  }

  getCustomers() {
    this.subscription = this.knBrCommonService.customersData$.subscribe(res => {
      if (res === null) {
        this.knBrCommonService.getCustomersData();
      }
    })
  };

  initForm() {
    this.shipmentSearchForm = this.fb.group({
      salesOrderNumber: null,
      accountNumber: [{
        value: this.selectedCustomer && this.selectedCustomer.customerNumber ? this.knBrCommonService.combineCustomerData(this.selectedCustomer) : null,
        disabled: this.selectedCustomer ? true : false
      }],
      purchaseOrderNumber: null,
      shippingType: null,
      shippingStatus: null,
      product: null,
      currentPage: this.currentPage,
      productName: null,
      fromDate: null,
      toDate: null,
      _fromDate: null,
      _toDate: null,
      brand: null,
      shipToCity: null,
      salesOffice: null,
      salesGroup: null,
      shippingPoint: null,
      jobName: null,
      pageSize: 50,
    });
    this.setDateValidators();
  }

  createContext(value) {
    return value.customerNumber + ' - ' + value.customerName + ' - ' + value.city;
  };

  setDateValidators() {
    const fromDate = this.shipmentSearchForm.get('_fromDate');
    const toDate = this.shipmentSearchForm.get('_toDate');

    fromDate.valueChanges.pipe(distinctUntilChanged()).subscribe(fromDate => {
      if (fromDate && toDate.value === null) {
        toDate.setValidators([Validators.required]);
      } else {
        toDate.setValidators(null);
      }
      toDate.updateValueAndValidity();

      if (toDate?.value && fromDate?.value) {
        const { year: y2, month: m2, day: d2 } = toDate.value;
        const e = new Date(y2, m2 - 1, d2);

        const { year: y1, month: m1, day: d1 } = fromDate.value;
        const s = new Date(y1, m1 - 1, d1);

        if (s > e) {
          toDate.setValue(null)
        }
      }
    });
    toDate.valueChanges.pipe(distinctUntilChanged()).subscribe(toDate => {
      if (toDate) {
        fromDate.setValidators([Validators.required]);
      } else {
        fromDate.setValidators(null);
      }
      fromDate.updateValueAndValidity();
    });
  }

  submitForm() {
    if (this.shipmentSearchForm.valid) {
      this.shipmentSearchForm.patchValue({
        fromDate: this.knBrDateHelper.shipformatDate(this.shipmentSearchForm.value._fromDate),
        toDate: this.knBrDateHelper.shipformatDate(this.shipmentSearchForm.value._toDate)

      });
      const { _fromDate, _toDate, ...payload } = this.shipmentSearchForm.getRawValue();
      const updatedPayload = {...payload};
      const updatedSessionStorage = {...payload};
      if(this.selectedCustomer?.customerNumber){
        updatedPayload.accountNumber=this.selectedCustomer?.customerNumber
      }
      else{
        updatedPayload.accountNumber = Array.isArray(payload.accountNumber) ? payload.accountNumber.map(eachacc => eachacc.Key).join(",") : window.sessionStorage.getItem('customerLists');
      }
      updatedPayload.shippingType = (payload.shippingType && payload.shippingType.length) ? payload.shippingType.map(eachShippingType => eachShippingType.value).join(",") : null;
      updatedPayload.shippingStatus = (payload.shippingStatus && payload.shippingStatus.length) ? payload.shippingStatus.map(eachShippingStatus => eachShippingStatus.value).join(","): null;
      updatedPayload.brand = Array.isArray(payload.brand) ? payload.brand.map(eachBrand => eachBrand.value).join(",") : this.brandList;
      updatedPayload.shipToCity = (payload.shipToCity && payload.shipToCity.length) ? payload.shipToCity.map(eachShipTo => eachShipTo.value).join(",") :null;
      updatedPayload.salesOffice = (payload.salesOffice &&  payload.salesOffice.length) ? payload.salesOffice.map(eachSalesOffice => eachSalesOffice.value).join(",") : null;
      updatedPayload.salesGroup = (payload.salesGroup &&  payload.salesGroup.length) ? payload.salesGroup.map(eachSalesGroup => eachSalesGroup.value).join(",") : null;
      updatedPayload.shippingPoint = this.trimmingShippingPoint(payload);
      updatedSessionStorage.accountNumber = updatedPayload.accountNumber;
      updatedSessionStorage.shippingType = updatedPayload.shippingType;
      updatedSessionStorage.shippingStatus = updatedPayload.shippingStatus;
      updatedSessionStorage.brand = updatedPayload.brand;
      updatedSessionStorage.shipToCity = updatedPayload.shipToCity;
      updatedSessionStorage.salesOffice = updatedPayload.salesOffice;
      updatedSessionStorage.salesGroup = updatedPayload.salesGroup;
      updatedSessionStorage.shippingPoint = updatedPayload.shippingPoint;
      window.sessionStorage.setItem('customerList', updatedPayload.accountNumber);
      window.sessionStorage.setItem("shipmentSearch",JSON.stringify(updatedSessionStorage));
      this.knBrCommonService.getShippingHistory(updatedPayload);
    }
  }

  trimmingShippingPoint(payload) {
    const input = (payload.shippingPoint && payload.shippingPoint.length) ? payload.shippingPoint.map(eachShippingPoint => eachShippingPoint.value).join(',') : null;
    const trimmed = input?.replace(/\[|\]/g, "").split(',').map(num => num.trim());
    return trimmed?.join(",");
  }

  onDateChange(value, param?) {
    this.knBrCommonService.onDateChange(value, param);
    if (param === 'fDate') {
      this.mindateTo=value;
      this.maxDates= { year: value.year + 1 , month: value.month, day: value.day};
    }
    if(param === 'tDate'){
      this.maxdatefrom=value;
        this.minDates= { year: value.year -1 ,month: value.month, day: value.day};
    }
  }

  resetForm() {
    this.shipmentSearchForm.reset();
    this.minDates = null;
    this.maxDates = null;
    this.mindateTo = null;
    this.maxdatefrom = null;
    sessionStorage.removeItem('shipmentSearch');
    this.initForm();
    this.submitForm();
    this.clearDateInfo();
  }

  clearDateInfo() {
    this.knBrCommonService.clearDateInfo();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.clearDateInfo();
    sessionStorage.removeItem('shipmentSearch');
    sessionStorage.removeItem('customerLists');
  }
}
