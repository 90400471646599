import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslationService } from '@spartacus/core';
import { OrderOverviewComponent } from '@spartacus/order/components';
import { Card } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { KnBrAddress } from '../models/kn-br-order.model';

@Component({
  selector: 'cx-order-overview',
  templateUrl: './kn-br-order-overview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnBrOrderOverviewComponent extends OrderOverviewComponent {
  constructor(protected translation: TranslationService) {
    super(translation);
  }

  getAddressCardContent(deliveryAddress: KnBrAddress): Observable<Card> {
    return this.translation.translate('addressCard.shipTo').pipe(
      filter(() => Boolean(deliveryAddress)),
      map((textTitle) => ({
        title: textTitle,
        textBold: deliveryAddress.firstName + (deliveryAddress.lastName ? ' ' + deliveryAddress.lastName : ''),
        text: [
          (deliveryAddress.houseNumber ? deliveryAddress.houseNumber + ', ' : '') + deliveryAddress.line1,
          deliveryAddress.line2,
          deliveryAddress.town + ', ' + deliveryAddress.region.name,
          deliveryAddress.country.name + ', ' + deliveryAddress.postalCode,
          this.formatPhone(deliveryAddress.phone),
        ],
      }))
    );
  }

  formatPhone(value) {
    const trim = value?.substring(2);
    const cleaned = ('' + trim).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    } else {
      const removeSpaces = value?.replace(/\s/g, '');
      const trim = removeSpaces?.substring(1);
      const match = trim?.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
      }
    }
  }

  getTotalItems(totalItems: string): Observable<Card> {
    return this.translation.translate('checkoutOrderConfirmation.totalItems').pipe(
      map((textTitle) => ({
        title: textTitle,
        text: [totalItems],
      }))
    );
  }

  getTotalQuantity(totalUnitCount: string): Observable<Card> {
    return this.translation.translate('checkoutOrderConfirmation.totalQuantity').pipe(
      map((textTitle) => ({
        title: textTitle,
        text: [totalUnitCount],
      }))
    );
  }

  getOrderTotal(totalPrice: string): Observable<Card> {
    return this.translation.translate('checkoutOrderConfirmation.orderTotal').pipe(
      map((textTitle) => ({
        title: textTitle,
        text: [totalPrice],
      }))
    );
  }
}
