import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, queueScheduler } from 'rxjs';
import { observeOn } from 'rxjs/operators';

import { CustomerData, CustomerSearchForm } from '../../models/customer.model';
import { CustomerSearch, CustomerSearchReset } from './kn-br-customer.action';
import { getCustomerLoader, getCustomers, getSearchDisabled } from './kn-br-customer.selector';
import { StateWithCustomer } from './kn-br-customer.state';

@Injectable({
  providedIn: 'root',
})
export class KnBrCustomerService {
  constructor(private store: Store<StateWithCustomer>) {}

  list$: Observable<CustomerData[]> = this.store.pipe(select(getCustomers), observeOn(queueScheduler));

  loading$: Observable<boolean> = this.store.pipe(select(getCustomerLoader), observeOn(queueScheduler));

  searchDisabled$: Observable<boolean> = this.store.pipe(select(getSearchDisabled), observeOn(queueScheduler));

  search(customerSearchForm: CustomerSearchForm) {
    this.store.dispatch(new CustomerSearch(customerSearchForm));
  }

  reset() {
    this.store.dispatch(new CustomerSearchReset(null));
  }
}
