<ng-container *ngIf="product$ | async as product">
  <ng-container>
    <ngb-accordion #acc="ngbAccordion" [activeIds]="openedPanels" class="pdp-accordion-styles" [closeOthers]="false"
      (panelChange)="showHide($event, acc)">
      <ngb-panel class="w-100 float-left mb-2 text-muted font-size-18" id="panel1" class="pr-detail">
        <ng-template ngbPanelTitle="">
          <div class="d-flex justify-content-between">
            <div class="d-flex">Product details</div>
            <div class="d-flex"><i class="fas ml-3 mt-1"
                [ngClass]="this.show && this.openedPanels.includes('panel1') ? 'fa-chevron-up' : 'fa-chevron-down'"
                [class]="'arrow-styles'"></i></div>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="pd-container">
            <div class="d-flex col-12 item">
              <div class="col-6">{{ 'productList.label.hazmat' | cxTranslate }}:</div>
              <div class="col-6 font-text-600 font-sm-size">{{ product?.hazmat }}</div>
            </div>
            <div class="d-flex col-12 item">
              <div class="col-6">{{ 'productList.label.packageUnit' | cxTranslate }}:</div>
              <div class="col-6 font-text-600 font-sm-size">{{ product?.packageUnit }}</div>
            </div>
            <div class="d-flex col-12 item">
              <div class="col-6">{{ 'productList.label.packageWeight' | cxTranslate }}:</div>
              <div class="col-6 font-text-600 font-sm-size">{{ product?.packageWeight }}</div>
            </div>
            <div class="d-flex col-12 item">
              <div class="col-6">{{ 'productList.label.packageQty' | cxTranslate }}:</div>
              <div class="col-6 font-text-600 font-sm-size">{{ product?.packageQty }}</div>
            </div>
            <div class="d-flex col-12 item">
              <div class="col-6">{{ 'productList.label.moq' | cxTranslate }}:</div>
              <div class="col-6 font-text-600 font-sm-size">{{ product?.moq }}</div>
            </div>
            <div class="d-flex col-12 item">
              <div class="col-6">{{ 'productList.label.palletQty' | cxTranslate }}:</div>
              <div class="col-6 font-text-600 font-sm-size">{{ product?.palletQty }}</div>
            </div>
            <div *ngIf="product?.stockingLocation" class="d-flex col-6 item">
              <div class="col-6">{{ 'productList.label.stockingLocation' | cxTranslate }}:</div>
              <div class="col-6 font-text-600 font-sm-size pl-2">{{ product?.stockingLocation }}</div>
            </div>
          </div>
        </ng-template>
      </ngb-panel>
      <ngb-panel class="w-100 float-left mb-2 text-muted font-size-18" id="panel2" *ngIf="shouldProductandTechnicalDocumentationDisplay()">
        <ng-template ngbPanelTitle="">
          <div class="d-flex justify-content-between">
            <div class="d-flex" id="technicaldocsection">Product and Technical Documentation</div>
            <div class="d-flex"><i class="fas ml-3 mt-1"
                [ngClass]="this.show && this.openedPanels.includes('panel2') ? 'fa-chevron-up' : 'fa-chevron-down'"
                [class]="'arrow-styles'"></i></div>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <div *ngIf="product?.assetName || product?.assetNameEs || product?.assetNameFr" class="font-sm-size mb-2">
            <p class="mb-1 ml-3">Product Datasheet</p>
            <div class="d-flex col-12 pr-0 pl-0">
              <div class="col-3">
                <div *ngIf="product?.assetName"><a [href]="product?.assetUrl" target="_blank">{{product?.assetName}}</a><i aria-hidden="true" class="fas fa-download pr-2"></i></div>
              </div>
              <div class="col-3">
                <div *ngIf="product?.assetNameEs"><a [href]="product?.assetUrlEs" target="_blank">{{product?.assetNameEs}}</a><i aria-hidden="true" class="fas fa-download pr-2"></i></div>
              </div>
              <div class="col-3">
                <div *ngIf="product?.assetNameFr"><a [href]="product?.assetUrlFr" target="_blank">{{product?.assetNameFr}}</a><i aria-hidden="true" class="fas fa-download pr-2"></i></div>
              </div>
            </div>
          </div>
          <div *ngIf="product?.safetyAssetName || product?.safetyAssetNameEs || product?.safetyAssetNameFr" class="font-sm-size mb-2">
            <p class="mb-1 ml-3">Safety Datasheet</p>
            <div class="d-flex col-12 pr-0 pl-0">
              <div class="col-3">
                <div *ngIf="product?.safetyAssetName"><a [href]="product?.safetyAssetUrl" target="_blank">{{product?.safetyAssetName}}</a><i aria-hidden="true" class="fas fa-download pr-2"></i></div>
              </div>
              <div class="col-3">
                <div *ngIf="product?.safetyAssetNameEs"><a [href]="product?.safetyAssetUrlEs" target="_blank">{{product?.safetyAssetNameEs}}</a><i aria-hidden="true" class="fas fa-download pr-2"></i></div>
              </div>
              <div class="col-3">
                <div *ngIf="product?.safetyAssetNameFr"><a [href]="product?.safetyAssetUrlFr" target="_blank">{{product?.safetyAssetNameFr}}</a><i aria-hidden="true" class="fas fa-download pr-2"></i></div>
              </div>
            </div>
          </div>
          <div *ngIf="product?.productSellSheetName || product?.productSellSheetNameEs || product?.productSellSheetNameFr" class="font-sm-size mb-2">
            <p class="mb-1 ml-3">Product Selling Datasheet</p>
            <div class="d-flex col-12 pr-0 pl-0">
              <div class="col-3 mb-2">
                <div *ngIf="product?.productSellSheetName"><a [href]="product?.productSellSheetUrl" target="_blank">{{product?.productSellSheetName}}</a><i aria-hidden="true" class="fas fa-download pr-2"></i></div>
              </div>
              <div class="col-3 mb-2">
                <div *ngIf="product?.productSellSheetNameEs"><a [href]="product?.productSellSheetUrlEs" target="_blank">{{product?.productSellSheetNameEs}}</a><i aria-hidden="true" class="fas fa-download pr-2"></i></div>
              </div>
              <div class="col-3 mb-2">
                <div *ngIf="product?.productSellSheetNameFr"><a [href]="product?.productSellSheetUrlFr" target="_blank">{{product?.productSellSheetNameFr}}</a><i aria-hidden="true" class="fas fa-download pr-2"></i></div>
              </div>
            </div>
          </div>
        </ng-template>
      </ngb-panel>
      <ngb-panel class="w-100 float-left mb-2 text-muted font-size-18" id="panel3" *ngIf="product.featuresAndBenefits">
        <ng-template ngbPanelTitle="">
          <div class="d-flex justify-content-between">
            <div class="d-flex">Features and Benefits</div>
            <div class="d-flex"><i class="fas ml-3 mt-1"
                [ngClass]="this.show && this.openedPanels.includes('panel3') ? 'fa-chevron-up' : 'fa-chevron-down'"
                [class]="'arrow-styles'"></i></div>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="font-sm-size ml-3" [innerHTML]="product.featuresAndBenefits">
          </div>
        </ng-template>
      </ngb-panel>
      <ngb-panel class="w-100 float-left mb-2 text-muted font-size-18" id="panel4" *ngIf="product.applications">
        <ng-template ngbPanelTitle="">
          <div class="d-flex justify-content-between">
            <div class="d-flex">Application</div>
            <div class="d-flex"><i class="fas ml-3 mt-1"
                [ngClass]="this.show && this.openedPanels.includes('panel4')  ? 'fa-chevron-up' : 'fa-chevron-down'"
                [class]="'arrow-styles'"></i></div>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <div>
            <div class="font-sm-size ml-3" [innerHTML]="product.applications">
            </div>
          </div>
        </ng-template>
      </ngb-panel>
      <ngb-panel class="w-100 float-left mb-2 text-muted font-size-18" id="panel5" *ngIf="product.panelCharacteristics">
        <ng-template ngbPanelTitle="">
          <div class="d-flex justify-content-between">
            <div class="d-flex">Panel Characteristics</div>
            <div class="d-flex"><i class="fas ml-3 mt-1"
                [ngClass]="this.show && this.openedPanels.includes('panel5')  ? 'fa-chevron-up' : 'fa-chevron-down'"
                [class]="'arrow-styles'"></i></div>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="font-sm-size ml-3" [innerHTML]="product.panelCharacteristics">
          </div>
        </ng-template>
      </ngb-panel>
      <ngb-panel class="w-100 float-left mb-2 text-muted font-size-18" id="panel6" *ngIf="product.potentialLeedCredits">
        <ng-template ngbPanelTitle="">
          <div class="d-flex justify-content-between">
            <div class="d-flex">Potential LEED Credits</div>
            <div class="d-flex"><i class="fas ml-3 mt-1"
                [ngClass]="this.show && this.openedPanels.includes('panel6')  ? 'fa-chevron-up' : 'fa-chevron-down'"
                [class]="'arrow-styles'"></i></div>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="font-sm-size ml-3" [innerHTML]="product.potentialLeedCredits">
          </div>
        </ng-template>
      </ngb-panel>
      <ngb-panel class="w-100 float-left mb-2 text-muted font-size-18" id="panel7" *ngIf="product.codesCompliances">
        <ng-template ngbPanelTitle="">
          <div class="d-flex justify-content-between">
            <div class="d-flex">Codes & Compliance</div>
            <div class="d-flex"><i class="fas ml-3 mt-1"
                [ngClass]="this.show && this.openedPanels.includes('panel7')  ? 'fa-chevron-up' : 'fa-chevron-down'"
                [class]="'arrow-styles'"></i></div>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <div class="font-sm-size ml-3" [innerHTML]="product.codesCompliances">
          </div>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </ng-container>

</ng-container>
