import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { I18nModule } from '@spartacus/core';
import { KnBrCaseUpdateDialogComponent } from './kn-br-case-update-dialog.component';
import { SpinnerModule } from '@spartacus/storefront';


@NgModule({
    declarations: [KnBrCaseUpdateDialogComponent],
    imports: [
      CommonModule, FormsModule, I18nModule, ReactiveFormsModule, SpinnerModule
    ]
})
export class KnBrCaseUpdateDialogModule {}
