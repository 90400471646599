<div *ngIf="content" class="col-md-12 p-0 float-left border shadow-sm address-card">
  <div *ngIf="content.header && !editMode" class="card-header">
    {{ content.header }}
  </div>
  <div class="col-md-12 pb-3 float-left" [class.pt-3]="!content.header" [class.pt-2]="content.header">
    <h4 *ngIf="editMode" class="col-md-12 p-0 pb-2 address-delete-msg text-info">
      {{ content.deleteMsg }}
    </h4>
    <div *ngIf="content.textBold" class="col-md-12 p-0 address-header">
      {{ content.textBold }}
    </div>
    <div class="col-md-12 p-0 address-items">
      <div *ngFor="let line of content.text">
        <div class="text-muted address-item">{{ line }}</div>
      </div>
    </div>
    <!-- Edit Mode Actions -->
    <div *ngIf="editMode" class="col-md-12 p-0 mt-3 float-left">
      <div class="col-md-6 float-left">
        <button class="btn btn-block btn-outline-primary btn-sm" (click)="cancelEdit()">
          {{ 'common.cancel' | cxTranslate }}
        </button>
      </div>
      <div class="col-md-6 float-left">
        <button class="btn btn-block btn-primary btn-sm" (click)="delete()">
          {{ 'common.delete' | cxTranslate }}
        </button>
      </div>
    </div>
    <!-- Actions -->
    <div *ngIf="content.actions && !editMode" class="col-md-12 p-0 d-flex justify-content-end">
      <div *ngFor="let action of content.actions">
        <div [ngSwitch]="action.event">
          <a
            *ngSwitchCase="'delete'"
            class="address-card-link pl-2"
            (click)="delete()"
            (keydown.enter)="delete()"
            tabindex="0"
            >{{ action.name }}</a
          >
          <a
            *ngSwitchCase="'default'"
            class="address-card-link pl-2"
            (click)="setDefault()"
            (keydown.enter)="setDefault()"
            tabindex="0"
            >{{ action.name }}</a
          >
          <a
            *ngSwitchCase="'edit'"
            class="address-card-link pl-2"
            (click)="edit()"
            (keydown.enter)="edit()"
            tabindex="0"
            >{{ action.name }}</a
          >
          <a *ngSwitchDefault href="{{ action.link }}" class="address-card-link pl-2" tabindex="0">{{ action.name }}</a>
        </div>
      </div>
    </div>
  </div>
</div>
