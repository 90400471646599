<div *ngIf="address && !isSoldTo" class="col-12 float-left mt-2 p-0">
  <label class="color-font font-text-600 mb-1">{{ this.addressLabel }}</label>
  <p class="float-left text-muted mb-2">
    <span class="w-100 float-left">{{ address.firstName }}</span>
    <span class="w-100 float-left">{{ address.street1 ? address.street1 : address.line1 ? address.line1 : address.street }}</span>
    <span class="w-100 float-left">{{ address.city }} {{ address.region?.isocode }} {{ address.postalCode }}</span>
    <span class="w-100 float-left">{{ address.country ? address.country : address.country?.isocode }}</span>
  </p>
</div>

<div *ngIf="address && isSoldTo" class="col-12 float-left mt-2 p-0">
  <label class="color-font font-text-600 mb-1">{{ this.addressLabel }}</label>
  <p class="float-left text-muted mb-2">
    <span class="w-100 float-left">{{ address.customerName }}</span>
    <span class="w-100 float-left">{{ address.street }}</span>
    <span class="w-100 float-left">{{ address.city }} {{ formatState(address.state) }} {{ address.postalCode }}</span>
    <span class="w-100 float-left">{{ address.country }}</span>
  </p>
</div>
