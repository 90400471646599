
import { ReturnRequestList } from '@spartacus/order/root';
import * as OrderActions  from './kn-br-order-return-request.action';

export interface KnBrReturnRequestList extends ReturnRequestList{
  error?: Error;
  isLoading?: boolean;
}

export const initialState: KnBrReturnRequestList = {
  returnRequests: [],
  pagination: {},
  sorts: [],
  error: undefined,
  isLoading: false,
};

export function knBrOrderReturnRequestReducer(
  state = initialState,
  action: OrderActions.OrderReturnRequestAction
): KnBrReturnRequestList {
  switch (action.type) {
    case OrderActions.LOAD_ORDER_RETURN_REQUEST_LIST_SUCCESS: {
      return action.payload ? action.payload : initialState;
    }
    case OrderActions.LOAD_ORDER_RETURN_REQUEST_LIST_FAIL:
    case OrderActions.LOAD_ORDER_RETURN_REQUEST_FAIL:
      return { ...state, error: action.payload, isLoading: false};
  }

  return state;
}
