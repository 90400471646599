<button
  type="button"
  class="mt-1 mt-sm-0 btn"
  [class.btn-primary]="(isProductSelected$ | async)?.length > 0"
  [class.btn-outline-primary]="(isProductSelected$ | async)?.length == 0"
  (click)="delete()"
  *ngIf="!isDisabled()"
  [attr.disabled]="(isProductSelected$ | async)?.length === 0 ? true : null"
>
  <i class="far fa-trash-alt"></i>
</button>
