import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { CmsService, ContentSlotData, RoutingService, UserService } from '@spartacus/core';
// import { ModalService } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { KnBrQuoteEntry } from '../../../../models/kn-br-quote.model';
import { KnBrDateHelper } from '../../../../shared/kn-br-date.helper';
import { KnBrQuoteEntryService } from '../../../../store/kn-br-quote-entry/kn-br-quote-entry.service';
import { KnBrQuotesService } from '../../../../store/kn-br-quotes/kn-br-quotes.service';
import { KnBrQuoteCommentsPopupComponent } from './../../kn-br-quote-comments-popup/kn-br-quote-comments-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserAccountService } from '@spartacus/user/account/core';
import { CustomerData } from 'src/app/models/customer.model';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import { KnBrCommonPopUpComponent } from 'src/app/kn-br-customer360/kn-br-common-pop-up/kn-br-common-pop-up.component';

@Component({
  selector: 'kn-br-quote-item',
  templateUrl: './kn-br-quote-item.component.html',
  styleUrls: ['./kn-br-quote-item.component.scss'],
  providers: [KnBrDateHelper],
})
export class KnBrQuoteItemComponent implements OnInit, OnDestroy {
  @Input() entry: any;
  @Input() isPartial: any;
  @Input() knbrOrderType: any;
  @Input() status: any;
  reqShipDate = new UntypedFormControl('');
  quantity = new UntypedFormControl('');
  priceAdjusted = new UntypedFormControl('');
  subscription: Subscription;
  modalRef: any;
  components: ContentSlotData;

  selectedProductsCodes: string[];
  subscriptionSelectedProduct: Subscription;
  dateFormat$: Observable<any> = this.currentUserService.get().pipe(
    filter((user: any) => user && !!user.dateFormat),
    map((user: any) => user.dateFormat)
  );
  netPriceUnit: any;
  totalPrice: any;
  isZtdOrZqtd: boolean;
  salesText = [];
  materialText = [];
  customerContextData$: Observable<CustomerData> = this.knBrCustomerContextService.get$;
  selectedCustomer: CustomerData;

  constructor(
    protected currentUserService: UserAccountService,
    protected modalService: NgbModal,
    protected knBrQuoteEntryService: KnBrQuoteEntryService,
    protected knBrQuoteService: KnBrQuotesService,
    protected cdr: ChangeDetectorRef,
    protected cmsService: CmsService,
    protected knBrDateHelper: KnBrDateHelper,
    protected knBrCommonService: KnBrCommonService,
    protected routingService: RoutingService,
    protected knBrCustomerContextService: KnBrCustomerContextService
  ) {
    this.subscriptionSelectedProduct = this.knBrQuoteService
      .loadSelectedProducts$()
      .subscribe((selectedProductsCodes) => {
        this.selectedProductsCodes = selectedProductsCodes;
      });
  }

  ngOnInit(): void {
    if (this.entry) {
      this.isZtdOrZqtd = this.knbrOrderType === 'ZTD' || this.knbrOrderType === 'ZQTD';
      this.netPriceUnit = this.isZtdOrZqtd ? '-' : (this.entry?.knbrTotalPrice?.formatedNetPrice + this.entry?.knbrTotalPrice?.conditionUnit);
      this.totalPrice = this.isZtdOrZqtd ? '-' : this.entry.totalPrice?.formattedValue;
      this.reqShipDate.setValue(this.knBrDateHelper.getDate(this.entry.requestedShipDate as string));
      this.quantity.setValue(this.entry.quantity?.toLocaleString());
      this.priceAdjusted.setValue(this.entry.knbrTotalPrice?.priceAdjusted?.value?.toFixed(2));
      this.subscription = this.knBrQuoteEntryService.get$.subscribe((response: KnBrQuoteEntry) => {
        if (response && response.entryNumber === this.entry.entryNumber && response.product) {
          this.entry = response;
          this.reqShipDate.setValue(this.knBrDateHelper.getDate(this.entry.requestedShipDate as string));
          this.quantity.setValue(this.entry.quantity?.toLocaleString());
          this.priceAdjusted.setValue(this.entry.knbrTotalPrice?.priceAdjusted?.value?.toFixed(2));
          this.cdr.markForCheck();
          this.reqShipDate.enable();
          this.quantity.enable();
          this.priceAdjusted.enable();
        }
      });
    }

    this.subscription.add(
      this.customerContextData$.subscribe((value) => {
        if (value) {
          this.selectedCustomer = value;
        }
      })
    );
  }

  update(event) {
    if (
      (this.reqShipDate.valid && this.reqShipDate.dirty) ||
      (this.quantity.valid && this.quantity?.dirty) ||
      (this.priceAdjusted.valid && this.priceAdjusted.dirty)
    ) {
      const entryObj: KnBrQuoteEntry = {
        quantity: this.knBrCommonService.getQuantityBasedOnMoq(this.quantity.value, this.entry.minimumOrderQuantity),
        knbrTotalPrice: {
          priceAdjusted: {
            value: this.priceAdjusted.value,
          },
        },
        requestedShipDate: '',
        entryNumber: this.entry.entryNumber,
      };
      if (this.reqShipDate.value) {
        entryObj.requestedShipDate = this.knBrDateHelper.formatDate(this.reqShipDate.value);
      }
      this.knBrQuoteEntryService.update(entryObj);
      this.reqShipDate.disable();
      this.quantity.disable();
      this.priceAdjusted.disable();
    }
  }

  splitSalesText(str) {
    if (str !== undefined) {
      const replaceAmpersand = str?.replaceAll('<(>&<)>', '&');
      this.salesText = replaceAmpersand?.split('|');
    }
  }

  splitMaterialText(str) {
    if (str !== undefined) {
      const replaceAmpersand = str?.replaceAll('<(>&<)>', '&');
      this.materialText = replaceAmpersand?.split('|');
    }
  }

  openComment(entry?: KnBrQuoteEntry) {
    let modalInstance: any;
    this.modalRef = this.modalService.open(KnBrQuoteCommentsPopupComponent, {
      centered: true,
      size: 'lg',
    });
    modalInstance = this.modalRef.componentInstance;
    modalInstance.entryNumber = entry.entryNumber;
    modalInstance.editable = this.isDisabled();
  }

  isChecked(code: any): boolean {
    if (this.selectedProductsCodes) {
      return this.selectedProductsCodes.indexOf(code) > -1;
    }
    return false;
  }
  changeCheckBox(event) {
    event.stopPropagation();
    const selectedProductCodes = event.currentTarget.checked ? this.addProductCode() : this.removeProductCode();
    this.knBrQuoteService.setSelectedProducts(selectedProductCodes);
  }

  private addProductCode() {
    if (this.selectedProductsCodes && this.selectedProductsCodes.length) {
      this.selectedProductsCodes.push(this.entry.entryNumber as unknown as string);
    } else {
      this.selectedProductsCodes = [this.entry.entryNumber as unknown as string];
    }
    return this.selectedProductsCodes;
  }
  private removeProductCode() {
    this.selectedProductsCodes.splice(
      this.selectedProductsCodes.indexOf(this.entry.entryNumber as unknown as string),
      1
    );
    return this.selectedProductsCodes;
  }

  isDisabled() {
    this.cmsService
      .getContentSlot('KnBrQuoteHeaderSlot')
      .subscribe((value) => (this.components = value))
      .unsubscribe();
    if (
      (this.components &&
        this.components.components &&
        this.components.components.length &&
        this.components.components[0].flexType === 'KnBrQuoteHeaderComponentReadOnly') ||
      !this.isEditable()
    ) {
      this.reqShipDate.disable();
      this.quantity.disable();
      this.priceAdjusted.disable();
      return true;
    }
    return false;
  }

  isEditable() {
    let editable = true;
    this.knBrQuoteService.get$.subscribe((value) => (editable = value.editable)).unsubscribe();
    return editable;
  }

  navigateToOrderDetails(order) {
    const customerContext: CustomerData = {
      customerNumber: this.selectedCustomer?.customerNumber,
      customerName: this.selectedCustomer?.customerName,
    };
    this.knBrCustomerContextService.set(customerContext);
    this.routingService.go({ cxRoute: 'orderDetails', params: { code: order } });
  }

  openPopup(data, materialNum) {
    let modalInstance: any;
    this.modalRef = this.modalService.open(KnBrCommonPopUpComponent, { centered: true, size: 'md' });
    modalInstance = this.modalRef.componentInstance;
    modalInstance.header = "Order Reference | Cust PO List"
    modalInstance.data = data
    modalInstance.materialNum = materialNum;
    modalInstance.image = true;
  };

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.subscriptionSelectedProduct) {
      this.subscriptionSelectedProduct.unsubscribe();
    }
  }
}
