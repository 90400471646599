import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { FacetModule, IconModule, KeyboardFocusModule } from '@spartacus/storefront';

import { KnBrFacetModule } from './../kn-br-facet/kn-br-facet.module';
import { KnBrFacetListComponent } from './kn-br-facet-list.component';

@NgModule({
  declarations: [KnBrFacetListComponent],
  imports: [CommonModule, I18nModule, IconModule, FacetModule, KeyboardFocusModule, KnBrFacetModule],
  exports: [KnBrFacetListComponent],
})
export class KnBrFacetListModule {}
