import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { B2BUser, UserService } from '@spartacus/core';
import {
  CurrentItemService,
  CurrentUserService,
  ItemService,
  UserFormComponent,
  UserItemService,
} from '@spartacus/organization/administration/components';
import { B2BUserService, OrgUnitService } from '@spartacus/organization/administration/core';
import { UserProfileFacade } from '@spartacus/user/profile/root';
import { Observable } from 'rxjs';
import { Statuses } from 'src/app/models/kn-br-quote.model';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'cx-org-user-form',
  templateUrl: './kn-br-user-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  // tslint:disable-next-line: no-host-metadata-property
  host: { class: 'content-wrapper' },
  providers: [
    {
      provide: ItemService,
      useExisting: UserItemService,
    },
    {
      provide: CurrentItemService,
      useExisting: CurrentUserService,
    },
  ],
})
export class KnBrUserFormComponent extends UserFormComponent implements OnInit {
  jobFunctionList$: Observable<Statuses[]> = this.knBrCommonService.loadJobFunctionList$();

  constructor(
    protected itemService: ItemService<B2BUser>,
    protected unitService: OrgUnitService,
    protected userProfileFacade: UserProfileFacade,
    protected b2bUserService: B2BUserService,
    protected knBrCommonService: KnBrCommonService
  ) {
    super(itemService, unitService, userProfileFacade, b2bUserService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.knBrCommonService.getJobFunctionList();
  }
}
