import { Directive, OnDestroy, OnInit } from '@angular/core';
import { GlobalMessageType } from '@spartacus/core';
import { BaseItem, ItemService, MessageService } from '@spartacus/organization/administration/components';
import { filter } from 'rxjs/operators';

@Directive({
  selector: '[cxOrgItemExists]',
})
export class ItemExistsDirective<T = BaseItem> implements OnInit, OnDestroy {
  protected subscription;

  constructor(protected itemService: ItemService<T>, protected messageService: MessageService) {}

  ngOnInit() {
    this.subscription = this.itemService.error$
      .pipe(filter((error) => error))
      .subscribe(() => this.handleErrorMessage());
  }

  protected handleErrorMessage() {
    this.messageService.add({
      message: {
        key: 'organization.notification.notExist',
      },
      type: GlobalMessageType.MSG_TYPE_ERROR,
    });
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
