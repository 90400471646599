import { NgModule } from '@angular/core';
import { NavigationEvent, ProductDetailsPageEvent } from '@spartacus/storefront';
import { provideConfig, LoginEvent, LogoutEvent } from '@spartacus/core';
import { BaseTmsModule, TmsConfig } from '@spartacus/tracking/tms/core';
import { GtmModule } from '@spartacus/tracking/tms/gtm';
import { environment } from 'src/environments/environment';
import { MarkettingCenterLinkDownlaodEvent, QuickorderSearchSuccess } from 'src/app/store/custome-spartacus-events';
import { KnBrGtmCollectorService } from '../services/kn-br-gtm-collector.service';
import { CartAddEntrySuccessEvent, CartRemoveEntrySuccessEvent } from '@spartacus/cart/base/root';

@NgModule({
  declarations: [],
  imports: [BaseTmsModule.forRoot(), GtmModule],
  providers: [
    provideConfig({
      tagManager: {
        gtm: {
          collector: KnBrGtmCollectorService,
          gtmId: environment.gtmId,
          events: [
            NavigationEvent,
            ProductDetailsPageEvent,
            LoginEvent,
            LogoutEvent,
            CartAddEntrySuccessEvent,
            CartRemoveEntrySuccessEvent,
            MarkettingCenterLinkDownlaodEvent,
            QuickorderSearchSuccess,
          ],
        },
      },
    } as TmsConfig),
  ],
})
export class TagManagementFeatureModule {}
