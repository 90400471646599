import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingPage2TemplateComponent } from './landing-page2-template/landing-page2-template.component';
import { IconModule, PageSlotModule } from '@spartacus/storefront';



@NgModule({
  declarations: [LandingPage2TemplateComponent],
  imports: [CommonModule, PageSlotModule,  IconModule,],
  exports: [LandingPage2TemplateComponent],
})
export class KnBrTemplateModule {}
