<ng-sidebar-container (onBackdropClicked)="_onBackdropClicked()">
  <ng-sidebar *ngIf="isNotLoginPage$ | async" [opened]="menuState$ | async" [mode]="'over'" [closeOnClickOutside]="true"
    [closeOnClickBackdrop]="true" (onClosed)="_onClosed()" (onOpened)="_onOpened()" [showBackdrop]="true"
    class="kn-br-sidebar">
    <cx-page-slot position="TopHeaderSlot"></cx-page-slot>
  </ng-sidebar>
  <div ng-sidebar-content>
    <kn-br-outlet></kn-br-outlet>
    <cx-storefront [ngClass]="{ 'asm-storefront': isLoggedInASM }"></cx-storefront>
  </div>
</ng-sidebar-container>

<ng-container *ngIf="{spinnerActive:commonService.showSpinner$ | async} as $">
  <cx-spinner *ngIf="$.spinnerActive" class="cx-dialog-popover-center-backdrop"></cx-spinner>
</ng-container>

<ng-template cxOutletRef="KnBrLandingPage2Template">
 <knbr-landing-page2-template></knbr-landing-page2-template>
</ng-template>
