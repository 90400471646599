import { createFeatureSelector, createSelector } from '@ngrx/store';

import { KN_BR_QUOTE_ENTRY_FEATURE, KnBrQuoteEntryState } from './kn-br-quote-entry.state';

export const getQuoteEntryState = createFeatureSelector<KnBrQuoteEntryState>(KN_BR_QUOTE_ENTRY_FEATURE);
export const getQuoteEntry = createSelector(getQuoteEntryState, (state: KnBrQuoteEntryState) => state.entry);

export const getQuoteEntrySaveDisabled = createSelector(
  getQuoteEntryState,
  (state: KnBrQuoteEntryState) => state.isSaveDisabled
);

export const getQuoteQuickEntryLoader = createSelector(
  getQuoteEntryState,
  (state: KnBrQuoteEntryState) => state.isLoading
);

export const getQuoteEntryLoading = createSelector(
  getQuoteEntryState,
  (state: KnBrQuoteEntryState) => state.showAddQuoteEntryLoading
);
