<ng-container *ngIf="orderReturnData$ | async as order">
  <div class="col-md-12 p-0 float-right d-flex justify-content-end">
    <button class="btn back-btn p-1 font-lg-size" (click)="goBack()">
      <i class="fas fa-level-down-alt fa-rotate-90" aria-hidden="true"></i>
    </button>
  </div>
  <div class="col-md-12 float-left px-0 my-5">
    <div class="col-md-12 float-left px-0">
      <div class="col-md-3 float-left">
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrReturnOrderDetails.label.returnNumber' | cxTranslate }}
        </label>
        <p class="mb-2 text-muted">{{ order.rma | removeLeadingZeros }}</p>

        <label class="color-font font-text-600 mb-1">
          {{ 'knbrReturnOrderDetails.label.accountNumber' | cxTranslate }}
        </label>
        <p class="mb-2 text-muted">{{ order.accountNumber | removeLeadingZeros }}</p>

        <kn-br-common-address [address]="order.soldTo" [addressLabel]="'Sold-to-Address'" [isSoldTo]="true"></kn-br-common-address>

        <label class="color-font font-text-600 mb-1">
          {{ 'knbrReturnOrderDetails.label.accountPhone' | cxTranslate }}
        </label>
        <p class="text-muted mb-2">{{ this.formatPhone(order.deliveryAddress?.phone) }}</p>
      </div>

      <div class="col-md-3 float-left">
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrReturnOrderDetails.label.returnCreateDate' | cxTranslate }}
        </label>
        <p class="mb-2 text-muted">{{ order.returnRequestDate }}</p>

        <label class="color-font font-text-600 mb-1">
          {{ 'knbrReturnOrderDetails.label.returnReason' | cxTranslate }}
        </label>
        <p class="mb-2 text-muted">{{ order.refundReason }}</p>

        <kn-br-common-address [address]="order.deliveryAddress" [addressLabel]="'Ship-to-Address'"></kn-br-common-address>
      </div>

      <div class="col-md-3 float-left">
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrReturnOrderDetails.label.orderNumber' | cxTranslate }}
        </label>
        <p class="mb-2 text-muted">{{ order.order.code | removeLeadingZeros }}</p>

        <label class="color-font font-text-600 mb-1">
          {{ 'knbrReturnOrderDetails.label.overallStatus' | cxTranslate }}
        </label>
        <p class="mb-2 text-muted">{{ order.statusDisplay }}</p>

        <label class="color-font font-text-600 mb-1">
          {{ 'knbrReturnOrderDetails.label.returnNote' | cxTranslate }}
        </label>
        <p hidden>{{ this.splitReturnNotes(order.notes) }}</p>
        <div *ngFor="let note of returnNotes; let i = index">
          <p class="mb-0 text-muted" *ngIf="i !== this.returnIdx">{{ note }}</p>
          <p class="mb-0 text-muted" *ngIf="i === this.returnIdx"><br>{{ note }}</p>
        </div>
      </div>

      <div class="col-md-3 float-left">
        <label class="color-font font-text-600 mb-1">
          {{ 'knbrReturnOrderDetails.label.PO' | cxTranslate }}
        </label>
        <p class="mb-2 text-muted">{{ order.purchaseOrderNumber }}</p>

        <label class="color-font font-text-600 mb-1">
          {{ 'knbrReturnOrderDetails.label.creditMemoNumber' | cxTranslate }}
        </label>
        <p class="mb-2 text-muted">{{ order.creditMemoNumber ? order.creditMemoNumber : '-' }}</p>

        <div class="col-md-12 float-left p-0">
          <label class="color-font font-text-600 mb-1">
            {{ 'knbrReturnOrderDetails.heading.attachments' | cxTranslate }}
          </label>
          <p class="float-left mb-2" *ngIf="order.carlisleSaleDocs && order?.carlisleSaleDocs?.length > 0">
            <a (click)="downloadPdf(doc)" class=" cursor-pointer pt-2 w-100 float-left doc-link"
            *ngFor="let doc of order.carlisleSaleDocs">{{ doc?.documentName }}</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</ng-container>
