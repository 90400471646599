import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { KnBrCommonService } from '../../../services/kn-br-common.service';
import { CustomerData } from 'src/app/models/customer.model';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import { KN_BR_CUSTOMER_CURRENT } from '../../../../constants/api.endpoints.constant';
import { Statuses } from '../../../models/kn-br-quote.model';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'knbr-kn-br-cancel-dialog',
  templateUrl: './kn-br-cancel-dialog.component.html',
  styleUrls: ['./kn-br-cancel-dialog.component.scss'],
})
export class KnBrCancelDialogComponent implements OnInit {
  customerContextData$: Observable<CustomerData> = this.knBrCustomerContextService.get$;
  cancelOrderStatusList$: Observable<Statuses[]> = this.knBrCommonService.loadCancelOrderStatusList$();
  orderReasonForm: UntypedFormGroup;

  constructor(
    private activeModal: NgbActiveModal,
    private knBrCustomerContextService: KnBrCustomerContextService,
    private knBrCommonService: KnBrCommonService,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.knBrCommonService.getCancelOrderStatusList();
    this.initForm();
    // this.customerContextData$.subscribe((value) => {
    //   const customerNumber = value?.customerNumber ? value?.customerNumber : KN_BR_CUSTOMER_CURRENT;
    //   this.knBrCommonService.getCancelOrderStatusList(customerNumber);
    //   this.initForm();
    // });
  }

  initForm() {
    this.orderReasonForm = this.fb.group({
      reason: [{ value: '' }],
    });

    this.cancelOrderStatusList$.subscribe((status) => {
      if (status) {
        this.orderReasonForm.patchValue({ reason: status[0].Key });
      }
    });
  }

  cancelOrder() {
    this.activeModal.close(this.orderReasonForm.value.reason);
  }

  dismiss() {
    this.activeModal.dismiss();
  }
}
