import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnInit, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalMessageService, GlobalMessageType, OccEndpointsService } from '@spartacus/core';
import { LaunchDialogService } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { CustomerData } from 'src/app/models/customer.model';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import { KNBR_CASE_CREATE_UPLOAD_FILE, KNBR_CASE_UPDATE, KN_BR_CUSTOMER_CURRENT } from 'src/constants/api.endpoints.constant';

@Component({
  selector: 'kn-br-case-update-dialog',
  templateUrl: './kn-br-case-update-dialog.component.html',
  styleUrls: ['./kn-br-case-update-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None //
})
export class KnBrCaseUpdateDialogComponent implements OnInit {

  @Input() ticketInfo: any;

  customerContextData$: Observable<CustomerData> = this.knBrCustomerContextService.get$;
  submitted: boolean = false;
  caseUpdateForm: UntypedFormGroup;
  hasImage: any;
  showSpinner: any;
  customerId: any;
  uploadedFileObjects = [];
  comment: any;
  subscription = new Subscription();
  disableUpdateButton = false;

  constructor(
    private knBrCustomerContextService: KnBrCustomerContextService,
    private fb: UntypedFormBuilder,
    protected modalService: NgbActiveModal,
    protected http: HttpClient,
    protected globalMessageService: GlobalMessageService,
    protected cdr: ChangeDetectorRef,
    protected launchDialogService: LaunchDialogService,
    protected vcr: ViewContainerRef,
    private endPointService: OccEndpointsService,
  ) {}

  ngOnInit(): void {
    this.knBrCustomerContextService.getActiveCustomerNumber().subscribe((response) => {
      this.customerId = response ? response : KN_BR_CUSTOMER_CURRENT;
    });
    this.initForm();
  }

  initForm() {
    this.caseUpdateForm = this.fb.group({
      updateComment: [null, [Validators.maxLength(512)]],
      fileInput: [''],
    });
  }

  onFileSelected(event) {
    if(event.target.files.length) {
      this.showSpinner = true;
    }
    this.cdr.detectChanges();
    const fileUploadUrl = this.endPointService.buildUrl(KNBR_CASE_CREATE_UPLOAD_FILE(this.customerId), {
      queryParams: {
        svcCaseGuid: this.ticketInfo.svcCaseGuid
      }
    });
    console.log("logging files", event.target.files);
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file)
    this.subscription.add(this.http.post(fileUploadUrl, formData).subscribe((response:any) => {
      console.log("logging the fileupload response", response);
      if(response && response.fileReferenceId){
        const fileSizeInBytes = file.size;
        const fileSizeInKB = fileSizeInBytes / 1024;
        const fileObj = {...response, fileSize: fileSizeInKB.toFixed(2)}
        this.uploadedFileObjects.push(fileObj);
        this.showSpinner = false;
        this.cdr.detectChanges();
      } else {
        this.showSpinner = false;
        this.globalMessageService.add('Something went wrong, please check the file type', GlobalMessageType.MSG_TYPE_ERROR);
        this.caseUpdateForm.controls['fileInput'].reset();
      }
    }, (error)=> {
      this.showSpinner = false;
      this.globalMessageService.add('Something went wrong, please check the file type', GlobalMessageType.MSG_TYPE_ERROR);
      this.caseUpdateForm.controls['fileInput'].reset();
    }));
  }



  removeSelectedFile(fileId) {
    this.uploadedFileObjects = this.uploadedFileObjects.filter(eachObj => eachObj.fileReferenceId != fileId);
    this.cdr.detectChanges();
  }

  resetTheDialogForm() {
    this.caseUpdateForm.reset();
    this.uploadedFileObjects = [];
  }

  handleUpdateClick($event) {
    this.disableUpdateButton = true;
    const payload = this.caseUpdateForm.getRawValue();
    const updatedPayload = this.getupdatedPayload(payload);
    const caseUpdateUrl = this.endPointService.buildUrl(KNBR_CASE_UPDATE(this.customerId));
    this.subscription.add(this.http.post(caseUpdateUrl, updatedPayload).subscribe((response: any) => {
      if(response && response.updated) {
      this.globalMessageService.add('Case Updated successfully', GlobalMessageType.MSG_TYPE_CONFIRMATION);
      this.dismissModal('update');
      } else {
      this.globalMessageService.add('Case not updated due to system issue. Try again later.', GlobalMessageType.MSG_TYPE_ERROR);
      }
    }, (err) => {
      this.globalMessageService.add('Case not updated due to system issue. Try again later.', GlobalMessageType.MSG_TYPE_ERROR);
    }));
  }

  getupdatedPayload(payload) {
    const modifiedPayload = {...payload}
    modifiedPayload.ticketID = this.ticketInfo.ticketId;
    modifiedPayload.svcCaseNumber = this.ticketInfo.svcCaseNumber;
    modifiedPayload.comment = payload.updateComment;
    modifiedPayload.fileReferenceId = this.uploadedFileObjects.map(obj => obj.fileReferenceId);
    modifiedPayload.fileNames = this.uploadedFileObjects.map(obj => obj.fileName);
    delete modifiedPayload.fileInput;
    delete modifiedPayload.updateComment;
    return modifiedPayload;
  }

  dismissModal(reason?: any): void {
    this.modalService.close(reason);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
