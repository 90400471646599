import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import { Component, ComponentRef, OnInit, ViewContainerRef, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { OccConfig, RoutingService } from '@spartacus/core';
import { LaunchDialogService, LAUNCH_CALLER } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { KnBrOrderReturnRequestService } from '../../../store/kn-br-order-return-request/kn-br-order-return-request.service';

@Component({
  selector: 'knbr-kn-br-return-order-details-overview',
  templateUrl: './kn-br-return-order-details-overview.component.html',
  styleUrls: ['./kn-br-return-order-details-overview.component.scss'],
})
export class KnBrReturnOrderDetailsOverviewComponent implements OnInit, OnDestroy {
  orderReturnData$ = this.returnRequestService.getOrderReturnRequest();
  returnCode: string;
  showSpinner: void | Observable<ComponentRef<any>>;
  subscription = new Subscription();
  loader$: Observable<boolean> = this.knBrCommonService.loadSpinner$();

  orderReturnLoading = true;
  orderReturnSuccess = false;
  returnNotes = [];
  returnIdx = 0;

  constructor(
    protected returnRequestService: KnBrOrderReturnRequestService,
    protected routingService: RoutingService,
    protected launchDialogService: LaunchDialogService,
    protected vcr: ViewContainerRef,
    protected knBrCommonService: KnBrCommonService,
    public cd: ChangeDetectorRef,
    private config: OccConfig
  ) {
    this.routingService
      .getRouterState()
      .subscribe((state) => {
        if (state && state.state && state.state.params && state.state.params.returnCode) {
          this.returnCode = state.state.params.returnCode;
          this.returnRequestService.loadOrderReturnRequestDetail(this.returnCode);
        }
      })
      .unsubscribe();
  }

  onSpinnerLoading(isLoading) {
    if (isLoading) {
      this.showSpinner = this.launchDialogService.launch(LAUNCH_CALLER.PLACE_ORDER_SPINNER, this.vcr);
    } else {
      if (this.showSpinner) {
        this.showSpinner
          .subscribe((component) => {
            this.launchDialogService.clear(LAUNCH_CALLER.PLACE_ORDER_SPINNER);
            component.destroy();
          })
          .unsubscribe();
      }
    }
  }

  ngOnInit(): void {
    this.subscription.add(
      this.loader$.subscribe((isLoading) => {
        this.onSpinnerLoading(isLoading);
      })
    );
    this.returnRequestService.getReturnRequestLoading().subscribe((loading) => {
      if (!loading) {
        this.returnRequestService.getReturnRequestSuccess().subscribe((data) => {
          this.orderReturnLoading = false;
          this.orderReturnSuccess = data;
          this.cd.detectChanges();
        });
      }
    });
  }

  downloadPdf(document) {
    const invoiceDetails = {
      documentName: document?.documentName,
      documentNumber: document?.documentNumber,
      documentReference: document?.documentReference
    };
    this.knBrCommonService.downloadSinglePDF(invoiceDetails);
  }

  formatPhone(value) {
    const trim = value?.substring(2);
    const cleaned = ('' + trim).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    } else {
      const removeSpaces = value?.replace(/\s/g, '');
      const trim = removeSpaces?.substring(1);
      const match = trim?.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
      }
    }
  }

  splitReturnNotes(notes) {
    if (notes !== undefined) {
      this.returnNotes = notes?.split(',');
      this.returnIdx = this.returnNotes?.findIndex(item => item.endsWith('to:'))
    }
  }

  ngOnDestroy() {
    this.launchDialogService.clear(LAUNCH_CALLER.PLACE_ORDER_SPINNER);
    this.returnRequestService.clearOrderReturnRequestDetail();
    this.subscription.unsubscribe();
  }

  goBack() {
    this.routingService.go({ cxRoute: 'returnOrders' }, { state: { displayPersistedValues: true }});
  }
}
