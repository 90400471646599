import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';

import { KNBR_RETURN_ORDER_SEARCH, KN_BR_CUSTOMER_CURRENT } from '../../../constants/api.endpoints.constant';
import { KnBrReturnOrderSearchForm } from '../../models/kn-br-order.model';
import { KnBrCustomerContextService } from '../kn-br-customer-context/kn-br-customer-context.service';

@Injectable({
  providedIn: 'root',
})
export class KnBrOrderReturnAdapter {
  customerNumber: string;
  constructor(
    private endPointService: OccEndpointsService,
    private httpClient: HttpClient,
    protected knBrCustomerContextService: KnBrCustomerContextService
  ) {
    this.knBrCustomerContextService.getActiveCustomerNumber().subscribe((response) => {
      this.customerNumber = response ? response : null;
    });
  }

  retrunOrdersSearch(returnOrderSearch: KnBrReturnOrderSearchForm) {
    const CUSTOMER_NUMBER = this.customerNumber ? this.customerNumber : KN_BR_CUSTOMER_CURRENT;
    return this.httpClient.get(
      this.endPointService.buildUrl(KNBR_RETURN_ORDER_SEARCH, {
        urlParams: {
          customerId: CUSTOMER_NUMBER,
        },
        queryParams: returnOrderSearch,
      })
    );
  }
}
