import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService, GlobalMessageService } from '@spartacus/core';
import { KNBR_CONTACT_COUNTRY, KNBR_CONTACT_STATE, KNBR_CONTACT_SUBMIT, KNBR_CONTACT_USER_INFO, KN_BR_CUSTOMER_CURRENT } from 'src/constants/api.endpoints.constant';
import { KnBrCustomerContextService } from '../store/kn-br-customer-context/kn-br-customer-context.service';
import { Observable } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class KnBrContactService  {
  customerId: string;
  constructor(
    private endPointService: OccEndpointsService,
    private httpClient: HttpClient,
    private globalMessageService: GlobalMessageService,
    protected knBrCustomerContextService: KnBrCustomerContextService
  ) {
    this.knBrCustomerContextService.getActiveCustomerNumber().subscribe((response) => {
      this.customerId = response ? response : KN_BR_CUSTOMER_CURRENT;
    });
  }

  getCustomerId() {
    return this.customerId;
  }

  getCurrentUserInfo(): Observable<any> {
    return this.httpClient.get(this.endPointService.buildUrl(KNBR_CONTACT_USER_INFO(this.customerId)))
  }

  getCountries(): Observable<any>  {
    return this.httpClient.get(this.endPointService.buildUrl(KNBR_CONTACT_COUNTRY(this.customerId)))
  }

  getStates(countryId: string): Observable<any>  {
    return this.httpClient.get(this.endPointService.buildUrl(KNBR_CONTACT_STATE(this.customerId, countryId)))
  }

  submitContactForm(data: any) {
    return this.httpClient.post(this.endPointService.buildUrl(KNBR_CONTACT_SUBMIT(this.customerId)), data)
  }


}
