import { KnBrOrderHistoryActions, KnBrOrderHistoryActionTypes } from './kn-br-order-history.action';
import { KnBrOrderHistoryState } from './kn-br-order-history.state';

export const initialState: KnBrOrderHistoryState = {
  orders: [],
  error: undefined,
  searchRequest: undefined,
  isLoading: false,
  pagination: undefined,
  sorts: undefined,
  isSearchDisabled: false,
  order: undefined,
  products: [],
  orderFormsSearchRequest: undefined,
  editOrder: undefined,
  editDisable: false,
  orderFormspagination: undefined,
  orderFormssorts: undefined,
  orderFormsLoading: false,
  orderFormsSearchDisabled: false,
};

export function knBrOrderHistoryReducer(state = initialState, action: KnBrOrderHistoryActions): KnBrOrderHistoryState {
  switch (action.type) {
    case KnBrOrderHistoryActionTypes.KnBrOrderHistorySearchAction:
      return { ...state, searchRequest: action.payload, isLoading: true, isSearchDisabled: true };
    case KnBrOrderHistoryActionTypes.KnBrOrderHistorySearchSuccessAction:
      return {
        ...state,
        orders: [...state.orders, ...action.payload?.orders],
        pagination: action.payload?.pagination,
        sorts: action.payload?.sorts,
        isLoading: false,
        isSearchDisabled: false,
      };
    case KnBrOrderHistoryActionTypes.KnBrOrderHistoryQuickSearchSuccessAction:
      return {
        ...state,
        orders: action.payload?.orders,
        pagination: action.payload?.pagination,
        sorts: action.payload?.sorts,
        isLoading: false,
        isSearchDisabled: false,
      };
    case KnBrOrderHistoryActionTypes.KnBrOrderHistorySearchFailureAction:
      return { ...state, error: action.payload, isLoading: false, isSearchDisabled: false };
    case KnBrOrderHistoryActionTypes.KnBrOrderHistorySearchResetAction:
      return { ...state, orders: [], pagination: null, sorts: null, isLoading: true };
    case KnBrOrderHistoryActionTypes.KnBrCreateReturnOrderAction:
      return { ...state, order: action.payload, isLoading: true };
    case KnBrOrderHistoryActionTypes.KnBrCreateReturnOrderSuccessAction:
      return { ...state, order: action.payload, isLoading: false };
    case KnBrOrderHistoryActionTypes.KnBrCreateReturnOrderFailureAction:
      return { ...state, error: action.payload, isLoading: false };
    case KnBrOrderHistoryActionTypes.KnBrOrderReOrderAction:
      return { ...state, order: action.payload };
    case KnBrOrderHistoryActionTypes.KnBrOrderReOrderCopySuccessAction:
      return { ...state, order: action.payload };
    case KnBrOrderHistoryActionTypes.KnBrOrderReOrderCopyFailureAction:
      return { ...state, error: action.payload };
    case KnBrOrderHistoryActionTypes.KnBrOrderCancelSuccessAction:
      return { ...state, orders: action.payload, isLoading: false };
    case KnBrOrderHistoryActionTypes.KnBrOrderCancelFailureAction:
      return { ...state, error: action.payload, isLoading: false };
    case KnBrOrderHistoryActionTypes.KnBrOrderFormsProductsSearchAction:
      return {
        ...state,
        orderFormsSearchRequest: action.payload,
        orderFormsLoading: true,
        orderFormsSearchDisabled: true,
      };
    case KnBrOrderHistoryActionTypes.KnBrOrderFormsProductsSearchSuccessAction:
      return {
        ...state,
        orderFormResponse: {
          products: action.payload?.products , rowindex: action.payload?.rowIndex
        },
        orderFormspagination: action.payload?.pagination,
        orderFormssorts: action.payload?.sorts,
        orderFormsLoading: false,
        orderFormsSearchDisabled: false,
      };
    case KnBrOrderHistoryActionTypes.KnBrOrderFormsProductsSearchFailureAction:
      return {
        ...state,
        error: action.payload,
        orderFormResponse: {products: []},
        orderFormspagination: null,
        orderFormssorts: null,
        orderFormsLoading: false,
        orderFormsSearchDisabled: false,
      };
    case KnBrOrderHistoryActionTypes.KnBrOrderFormsProductsResetAction:
      return { ...state, orderFormResponse: {products: []}, orderFormsLoading: false };
    case KnBrOrderHistoryActionTypes.KnBrEditOrderAction:
      return { ...state, editOrder: action.payload, isLoading: true, editDisable: true };
    case KnBrOrderHistoryActionTypes.KnBrEditOrderSuccessAction:
      return { ...state, order: action.payload, isLoading: false, editDisable: false };
    case KnBrOrderHistoryActionTypes.KnBrEditOrderFailureAction:
      return { ...state, error: action.payload, isLoading: false, editDisable: false };
    default:
      return state;
  }
}
