<ng-container>
  <div class="logout-carlisle">
    <div class="justify-content-center" class="carlisle-logo-container">
      <img [src]="'assets/images/Carlisle-logo.png'" class="carlisle-customer" alt="Carlisle-logo" />
    </div>

    <div class="logout-description">
      Thank you for visiting the Customer Success portal<br/>
      For security reasons, we recommend you close this current tab or login.
    </div> 

    <button type="button" class="w-25 btn btn-primary" (click)="routeToLogin()">Login</button>
  </div>
</ng-container>