import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { I18nModule } from '@spartacus/core';
import { KnBrCommonAddressComponent } from './kn-br-common-address.component';


@NgModule({
  declarations: [KnBrCommonAddressComponent],
  exports: [KnBrCommonAddressComponent],
  imports: [
    CommonModule,
    I18nModule
  ]
})
export class KnBrCommonAddressModule { }
