import { NgModule } from '@angular/core';
import { ConfigModule } from '@spartacus/core';
import { LayoutConfig } from '@spartacus/storefront';

@NgModule({
  declarations: [],
  imports: [
    ConfigModule.withConfig({
      layoutSlots: {
        SSOPageTemplate: {
          slots: ['BodyContent', 'SiteTitle'],
        },
      },
    } as LayoutConfig),
  ],
})
export class SloanSSOLayoutConfigModule {}
