import { CustomerData } from '../../models/customer.model';
import { CustomerSearchForm } from './../../models/customer.model';

export const KN_BR_SEARCH_CUSTOMER_FEATURE = 'kn-br-customer-search';

export interface KnBrCustomerState {
  customers?: CustomerData[];
  error?: Error;
  searchRequest?: CustomerSearchForm;
  isLoading?: boolean;
  searchDisabled?: boolean;
}

export interface StateWithCustomer {
  [KN_BR_SEARCH_CUSTOMER_FEATURE]: KnBrCustomerState;
}
