import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthGuard, ConfigModule, RoutingConfig } from '@spartacus/core';
import { CmsPageGuard, PageLayoutComponent } from '@spartacus/storefront';

import { KnBrCustomerSearchModule } from './kn-br-customer-search/kn-br-customer-search.module';
import { KnBrCustomerContextGuard } from '../shared/guards/kn-br-customer-context.guard';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    KnBrCustomerSearchModule,
    RouterModule.forChild([
      {
        data: { pageLabel: 'bravaCustomerHomepage', cxRoute: 'home' },
        path: null,
        canActivate: [AuthGuard, CmsPageGuard,KnBrCustomerContextGuard],
        component: PageLayoutComponent,
      },
    ]),
    ConfigModule.withConfig({
      routing: {
        routes: {
          home: {
            paths: ['']
          },
        },
      },
    } as RoutingConfig),
  ],
})
export class KnBrHomeModule {}
