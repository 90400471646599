import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SpinnerModule } from '@spartacus/storefront';

import { KnBrProductQuoteReferenceComponent } from './kn-br-product-quote-reference.component';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';

@NgModule({
  declarations: [KnBrProductQuoteReferenceComponent],
  imports: [
    CommonModule,
    FormsModule,
    SpinnerModule,
    I18nModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrQuoteReferenceComponent: {
          component: KnBrProductQuoteReferenceComponent,
        },
      },
    } as CmsConfig),
  ],
  exports: [KnBrProductQuoteReferenceComponent],
})
export class KnBrProductQuoteReferenceModule {}
