<div class="rounded w-100 float-left">
  <div class="w-100 customersearch-title">
    {{ 'customer.label.heading' | cxTranslate }}
  </div>
</div>
<form [formGroup]="customerSearchForm" (ngSubmit)="submitForm()">
  <div class="col-md-12 float-left customersearch-forms mt-3 login-forms p-0 customer-home-forms">
    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0">
      <input
        type="text"
        formControlName="customerName"
        class="form-control w-100 pl-3"
        [placeholder]="'customer.label.name' | cxTranslate"
        type="text"
      />
      <span class="highlight"></span>
      <label class="text-muted">{{ 'customer.label.name' | cxTranslate }}</label>
    </div>
    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0">
      <input
        type="text"
        class="form-control w-100"
        formControlName="customerNumber"
        [placeholder]="'customer.label.number' | cxTranslate"
        type="text"
      />
      <span class="highlight"></span>
      <label class="text-muted">{{ 'customer.label.number' | cxTranslate }}</label>
    </div>
    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0">
      <input
        type="text"
        class="form-control w-100"
        [placeholder]="'customer.label.city' | cxTranslate"
        formControlName="city"
      />
      <span class="highlight"></span>
      <label class="text-muted">{{ 'customer.label.city' | cxTranslate }}</label>
    </div>
    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0">
      <input
        type="text"
        class="form-control w-100"
        [placeholder]="'customer.label.zipCode' | cxTranslate"
        formControlName="postalCode"
      />
      <span class="highlight"></span>
      <label class="text-muted">{{ 'customer.label.zipCode' | cxTranslate }}</label>
    </div>
    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0 country-form">
      <ng-select
        [items]="this.countryList"
        bindLabel="name"
        bindValue="isocode"
        [loading]="isCountryLoading"
        (change)="countrySelected($event)"
        formControlName="countryCode"
        [placeholder]="'customer.label.country' | cxTranslate"
      >
      </ng-select>
      <span class="highlight"></span>
      <label class="text-muted">{{ 'customer.label.country' | cxTranslate }}</label>
    </div>
    <!-- <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0 country-form">
      <select name="stateCode" id="stateCode" class="selectState" formControlName="stateCode">
        <option value="" disabled="true" [selected]="true">State</option>
        <option *ngFor="let state of objectKeys(states).sort()" [value]="state">{{ states[state] }}</option>
      </select>
      <span class="highlight"></span>
      <label class="text-muted">{{ 'customer.label.state' | cxTranslate }}</label>
    </div> -->

    <div class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0 country-form">
      <ng-select
        [items]="this.stateList"
        bindLabel="name"
        bindValue="isocode"
        formControlName="stateCode"
        [placeholder]="'customer.label.state' | cxTranslate"
      >
      </ng-select>
      <span class="highlight"></span>
      <label class="text-muted">{{ 'customer.label.state' | cxTranslate }}</label>
    </div>

    <div class="col-lg-12 p-0 float-left">
      <div class="col-lg-2 pl-0 p-res-0 float-left">
        <button
          type="submit"
          [disabled]="isSearchDisabled$ | async"
          class="w-100 mt-1 mt-sm-0 btn btn-primary"
        >
          {{ 'button.search' | cxTranslate }}
        </button>
      </div>
      <div class="col-lg-2 pl-0 p-res-0 float-left">
        <button type="button" (click)="resetForm()" class="w-100 mt-1 mt-sm-0 btn btn-outline-primary">
          {{ 'button.reset' | cxTranslate }}
        </button>
      </div>
    </div>
  </div>
</form>
