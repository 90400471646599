<ng-container *ngIf="product$ | async as product">
  <ng-template [cxOutlet]="outlets.PRICE" [cxOutletContext]="{ product: product }">
    <table>
      <tr>
        <td>{{ 'productList.label.brand' | cxTranslate }}:</td>
        <td class="font-text-600 font-sm-size">{{ product.brandName }}</td>
      </tr>
      <tr>
        <td>{{ 'productList.label.material' | cxTranslate }}:</td>
        <td class="font-text-600 font-sm-size">{{ product.code }}</td>
      </tr>
      <tr>
        <td>{{ 'productList.label.listPrice' | cxTranslate }}:</td>
        <td class="font-text-600 font-sm-size">{{ (product?.callForPricing | lowercase) === 'yes' ? product?.pricingText : product?.knbrPrice?.formatedListPrice }}</td>
      </tr>
      <tr>
        <td>{{ 'productList.label.netPrice' | cxTranslate }}:</td>
        <td class="font-text-600 font-sm-size">{{  (product?.callForPricing | lowercase) === 'yes' ? product?.pricingText : product?.knbrPrice?.formatedNetPrice }}</td>
      </tr>
    </table>
  </ng-template>

  <div class="row mb-4 pt-1 pl-2">
    <div class="col-md-12 attachement" *ngIf="shouldProductandTechnicalDocumentationDisplay()">
      <u (click)="navigateTo('panel2')" href="">View Product and Technical Documentation &nbsp;<i class="fa fa-angle-right pt-1"></i></u>
    </div>
  </div>
</ng-container>
