import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  ConverterService,
  Occ,
  OccEndpointsService,
  OccRequestsOptimizerService,
  Product,
  PRODUCT_NORMALIZER,
  ProductAdapter,
  RoutingService,
  ScopedDataWithUrl,
  ScopedProductData,
} from '@spartacus/core';
import { Observable } from 'rxjs';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';

import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';

@Injectable()
export class KnBrOccProductAdapter implements ProductAdapter {
  customerId: string;
  salesOrg: string;
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService,
    protected requestsOptimizer: OccRequestsOptimizerService,
    private customerContextService: KnBrCustomerContextService,
    private route: ActivatedRoute,
    private routingService: RoutingService
  ) {
    this.customerContextService.get$.subscribe((response) => {
      this.customerId = response && response.customerNumber ? response.customerNumber : null;
    });
    this.getSalesOrg().subscribe((res) => {
      this.salesOrg = res;
    });
  }

  load(productCode: string, scope?: string): Observable<Product> {
    return this.http.get(this.getEndpoint(productCode, scope)).pipe(this.converter.pipeable(PRODUCT_NORMALIZER));
  }

  loadMany(products: ScopedProductData[]): ScopedProductData[] {
    const scopedDataWithUrls: ScopedDataWithUrl[] = products.map((model) => ({
      scopedData: model,

      url: this.getEndpoint(model.code, model.scope),
    }));

    return this.requestsOptimizer.scopedDataLoad<Occ.Product>(scopedDataWithUrls).map(
      (scopedProduct) =>
        ({
          ...scopedProduct,
          data$: scopedProduct.data$.pipe(this.converter.pipeable(PRODUCT_NORMALIZER)),
        } as ScopedProductData)
    );
  }

  protected getEndpoint(code: string, scope?: string): string {
    if (this.salesOrg) {
      return this.occEndpoints.buildUrl('product', {
        urlParams: {
          productCode: code,
          salesOrg: this.salesOrg,
          customerId: this.customerId,
        },
        scope,
      });
    }
    return this.occEndpoints.buildUrl('product', {
      urlParams: {
        productCode: code,
        customerId: this.customerId,
      },
      scope,
    });
  }

  protected getSalesOrg(): Observable<any> {
    return this.routingService.getRouterState().pipe(map((state) => state.state.params['salesOrg']));
  }
}
