import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { CarouselModule, MediaModule, OutletModule, StarRatingModule } from '@spartacus/storefront';

import { KnBrProductImagesComponent } from './kn-br-product-images/kn-br-product-images.component';
import { KnBrProductIntroComponent } from './kn-br-product-intro/kn-br-product-intro.component';
import { KnBrProductSummaryComponent } from './kn-br-product-summary/kn-br-product-summary.component';
import { KnBrProductInfoDetailComponent } from './kn-br-product-info-detail/kn-br-product-info-detail.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [KnBrProductIntroComponent, KnBrProductImagesComponent, KnBrProductSummaryComponent, KnBrProductInfoDetailComponent],
    imports: [
        CommonModule,
        I18nModule,
        StarRatingModule,
        RouterModule,
        NgbModule,
        MediaModule,
        OutletModule,
        CarouselModule,
        ConfigModule.withConfig({
            cmsComponents: {
                KnBrProductIntroComponent: {
                    component: KnBrProductIntroComponent,
                },
                KnBrProductImagesComponent: {
                    component: KnBrProductImagesComponent,
                },
                KnBrProductSummaryComponent: {
                    component: KnBrProductSummaryComponent,
                },
                KnBrProductDetailTabsComponent: {
                  component: KnBrProductInfoDetailComponent,
                }
            },
        } as CmsConfig),
    ],
    exports: [KnBrProductIntroComponent, KnBrProductImagesComponent, KnBrProductSummaryComponent, KnBrProductInfoDetailComponent]
})
export class KnBrProductDetailModule {}
