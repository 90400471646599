import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { GlobalMessageService, OccConfig, RoutingService, TranslationService } from '@spartacus/core';
import * as _ from 'lodash';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { B2bDocuments } from 'src/app/models/kn-br-invoice.model';
import { KnBrInvoiceService } from 'src/app/store/kn-br-invoice/kn-br-invoice.service';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import { CustomerData } from 'src/app/models/customer.model';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';

@Component({
  selector: 'app-kn-br-invoice-list-seach-result',
  templateUrl: './kn-br-invoice-list-seach-result.component.html',
  styleUrls: ['./kn-br-invoice-list-seach-result.component.scss'],
})
export class KnBrInvoiceListSeachResultComponent implements OnInit, OnDestroy {
  invoiceResult$: Observable<B2bDocuments[]> = this.knBrInvoiceService.list$;
  isInvoiceInit$: Observable<boolean> = this.knBrInvoiceService.isInvoiceInit$();
  isLoader$: Observable<boolean> = this.knBrInvoiceService.loader$;
  paginate$: Observable<any> = this.knBrInvoiceService.paginate$;
  sorts$: Observable<any> = this.knBrInvoiceService.sorts$;
  searchCriteria: any;
  sortType: string;
  subscription: Subscription;
  downloadProgress = false;
  invoiceList: [];
  documentNumbers = [];
  startDownload = false;


  constructor(
    public knBrInvoiceService: KnBrInvoiceService,
    protected translation: TranslationService,
    protected config: OccConfig,
    protected globalMessageService?: GlobalMessageService,
    protected cd?: ChangeDetectorRef,
    protected knBrCommonService?: KnBrCommonService,
    protected routeService?: RoutingService,
    protected knBrCustomerContextService?: KnBrCustomerContextService,
  ) { }

  ngOnInit(): void {
    this.subscription = this.knBrInvoiceService.searchCriteria$.subscribe((response) => {
      this.searchCriteria = _.cloneDeep(response);
    });
    this.subscription.add(
      this.paginate$.subscribe((paginate: any) => {
        if (paginate) {
          if (paginate.currentPage === 0) {
            this.documentNumbers = []
          }
          this.sortType = paginate.sort;
        }
      })
    );
    this.subscription.add(
      this.invoiceResult$.subscribe((res: any) => {
        this.downloadProgress = true;
        if (res.length === 0) {
          this.downloadProgress = false;
          this.cd.detectChanges();
        } else {
          this.downloadProgress = false;
          this.cd.detectChanges();
        }
        this.invoiceList = res.filter(item => {
          return item.amount !== 0;
        })
      })
    );
  }

  getSortLabels(): Observable<{ byDate: string; byDocType: string; byDocAmount: string; byDocStatus: string }> {
    return combineLatest([
      this.translation.translate('invoiceResult.sorting.byDate'),
      this.translation.translate('invoiceResult.sorting.byDocType'),
      this.translation.translate('invoiceResult.sorting.byDocAmount'),
      this.translation.translate('invoiceResult.sorting.byDocStatus'),
    ]).pipe(
      map(([textByDate, textByDocType, textByDocAmount, textByDocStatus]) => {
        return {
          byDate: textByDate,
          byDocType: textByDocType,
          byDocAmount: textByDocAmount,
          byDocStatus: textByDocStatus,
        };
      })
    );
  }

  changeSortCode(sortCode: string): void {
    if (this.searchCriteria) {
      this.searchCriteria.currentPage = 0;
      this.searchCriteria.sort = sortCode;
    }
    this.sortType = sortCode;
    this.knBrInvoiceService.search(this.searchCriteria);
  }

  loadMore(currentPage: number) {
    if (this.searchCriteria) {
      this.searchCriteria.currentPage = currentPage + 1;
      this.searchCriteria.sort = this.sortType;
    }
    this.knBrInvoiceService.search(this.searchCriteria);
  }

  downloadPdfOrNavigateToDetails(isPdf, invoice?) {
    if (isPdf) {
      let invoiceDetails
      if (invoice) {
        invoiceDetails = {
          documentName: invoice.documentType?.name,
          documentNumber: invoice.documentNumber,
          documentReference: 'V3'
        };
      } else {
        const documentRefArray = Array(this.documentNumbers.length).fill("V3");
      invoiceDetails = {
          documentNumber: this.documentNumbers.join(","),
          documentReference: documentRefArray.join(",")
        };
      }
      if (invoice) {
        this.knBrInvoiceService.downloadSinglePdf(invoiceDetails);
      } else {
        this.knBrInvoiceService.downloadPdf(invoiceDetails);
      }
    } else {
      const customerContext: CustomerData = {
        customerNumber: invoice.accountNumber,
        customerName: invoice.accountName,
        city: invoice.city
      };
      //this.knBrCustomerContextService.set(customerContext);
      const invoiceName = invoice.documentType.name;
      const invoiceNumber = invoice.documentNumber;
      const documentTypeCode = invoice.documentType.code;
      this.routeService.go({ cxRoute: 'invoiceDetail'}, { queryParams: {invoiceName: invoiceName, invoiceNumber: invoiceNumber, documentTypeCode: documentTypeCode} });
    }
  }

  selectAll(userChecked: boolean) {
    if (userChecked) {
      this.documentNumbers = [];
      this.invoiceList.forEach((eachInvoice:any) => {
        this.documentNumbers.push(eachInvoice.documentNumber);
      })
    } else {
      this.documentNumbers = [];
    }
  }

  select(invoice, isChecked:boolean) {
    const invoiceDocNumber = invoice.documentNumber;
    if(isChecked) {
      this.documentNumbers.push(invoiceDocNumber);
    }else {
      let index = this.documentNumbers.indexOf(invoiceDocNumber);
      this.documentNumbers.splice(index, 1);
    }

  }

  downloadInvoice() {
    this.downloadProgress = true;
      this.knBrInvoiceService.invoiceXlsDownalodUrl({ ...this.searchCriteria, pageSize: '100000',})
        .pipe(take(1))
        .subscribe((response) => {
          this.downloadProgress = false;
          this.cd.detectChanges();
          const downloadLink = document.createElement('a');
          downloadLink.href = URL.createObjectURL(new Blob([response.body], { type: response.body.type }));
          downloadLink.download = 'invoice.xlsx';
          downloadLink.click();
      });
  }

  invoiceDetail(invoice) {
    const invoiceName = invoice.documentType.name;
    const invoiceNumber = invoice.documentNumber;
    this.routeService.go({ cxRoute: 'invoiceDetail'}, { queryParams: {invoiceName: invoiceName, invoiceNumber: invoiceNumber} });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
