<ng-container #container
  ><ng-container *ngIf="viewMode === ViewModes.Grid">

    <div
      infiniteScroll
      [fromRoot]="true"
      [infiniteScrollDistance]="5"
      [infiniteScrollThrottle]="50"
      [infiniteScrollDisabled]="isMaxProducts || isLastPage || isEmpty"
      (scrolled)="scrollPage(model?.pagination?.currentPage + 1)"
    >
      <kn-br-product-grid-item *ngFor="let product of model?.products" [product]="product" [editable]="editable"></kn-br-product-grid-item>
      <div class="row">
        <!-- <div class="col-6 mt-3 pl-4">
          <span class="font-text-600 color-font" [innerHTML]="getPageTitle(model) | async"></span>
        </div> -->
        <div class="col-6">
          <div [className]="!isLastPage && model?.pagination?.currentPage > 0 ? 'cx-double-btn-container grid-btn-padding' : 'cx-single-btn-container grid-btn-padding'">
            <div class="bg-white mt-3 ml-3">
                <div *ngIf="isMaxProducts && !isLastPage" (click)="loadNextPage(model?.pagination?.currentPage + 1)" class="btn btn-loadmore mt-0 mr-3">
                  {{ 'productList.showMoreBtn' | cxTranslate }}
                </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="appendProducts && !hasQueryParams()" class="cx-spinner show-more-spinner d-flex align-items-center justify-content-center float-left w-100">
        <cx-spinner></cx-spinner>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="viewMode === ViewModes.List">
    <div
      infiniteScroll
      [fromRoot]="true"
      [infiniteScrollDistance]="5"
      [infiniteScrollThrottle]="50"
      [infiniteScrollDisabled]="isMaxProducts || isLastPage || isEmpty"
      (scrolled)="scrollPage(model?.pagination?.currentPage + 1)"
    >

      <div class="customersearch-list-table float-left col-lg-12 pr-0 p-res-0">
        <div class="customersearch-list-titles product-res-titles border-top float-left w-100 p-3">
          <div class="col-md-4 text-center padding-res-0 float-left font-text-600">
            {{ 'productList.label.name' | cxTranslate }}
          </div>
          <div class="col-md-1 text-center float-left font-text-600">{{ 'productList.label.id' | cxTranslate }}</div>
          <div class="col-md-2 text-center float-left font-text-600">
            {{ 'productList.label.netPrice' | cxTranslate }}
          </div>
          <div class="col-md-2 text-center float-left font-text-600">
            {{ 'productList.label.listPrice' | cxTranslate }}
          </div>
          <div class="col-md-1 text-center float-left font-text-600">{{ 'productList.label.uom' | cxTranslate }}</div>
          <div class="col-md-1 text-center float-left font-text-600">{{ 'productList.label.moq' | cxTranslate }}</div>
          <div class="col-md-1 text-center float-left font-text-600">{{ 'productList.label.brand' | cxTranslate }}</div>
        </div>
        <kn-br-product-list-item
          *ngFor="let product of model?.products"
          [product]="product"
          [editable]="editable"
          class="res-product-grid"
        ></kn-br-product-list-item>
        <div class="float-left w-100 d-flex bg-white justify-content-center mt-3">
          <div
            [className]="
              !isLastPage && model?.pagination?.currentPage > 0 ? 'cx-double-btn-container' : 'cx-single-btn-container'
            "
          >
            <div
              *ngIf="isMaxProducts && !isLastPage"
              (click)="loadNextPage(model?.pagination?.currentPage + 1)"
              class="btn btn-loadmore mt-0 mr-3"
            >
              {{ 'productList.showMoreBtn' | cxTranslate }}
            </div>
          </div>
        </div>
        <div
          *ngIf="appendProducts"
          class="cx-spinner show-more-spinner d-flex align-items-center justify-content-center float-left w-100"
        >
          <cx-spinner></cx-spinner>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
