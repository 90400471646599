import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { GlobalMessageService, GlobalMessageType } from '@spartacus/core';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as _ from 'lodash';
import {
  KnBrDuesAmountLoadSeccess,
  KnBrInvoiceAccountSummaryFailure,
  KnBrInvoiceAccountSummarySuccess,
  KnBrInvoiceActions,
  KnBrInvoiceActionTypes,
  KnBrInvoiceQuickSearchSuccess,
  KnBrInvoiceSearchFailure,
  KnBrInvoiceSearchSuccess,
} from './kn-br-invoice.action';
import { KnBrInvoiceAdapter } from './kn-br-invoice.adapter';

@Injectable()
export class KnBrInvoiceEffects {
  @Effect()
  invoiceList$ = this.actions$.pipe(
    ofType(KnBrInvoiceActionTypes.KnBrInvoiceSearchAction),
    mergeMap((action) =>
      this.invoiceAdapter.search(action.payload).pipe(
        map((data: any) => {
          return action.payload?.isWidget
            ? new KnBrInvoiceQuickSearchSuccess(data)
            : new KnBrInvoiceSearchSuccess(data);
        }),
        catchError((error: Error) => {
          this.messageService.add({ key: 'knBrMessages.technicalIssue' }, GlobalMessageType.MSG_TYPE_ERROR);
          return of(new KnBrInvoiceSearchFailure(error));
        })
      )
    )
  );

  @Effect()
  dueAmount$ = this.actions$.pipe(
    ofType(KnBrInvoiceActionTypes.KnBrInvoiceDuesAmountLoadAction),
    mergeMap((action) =>
      this.invoiceAdapter.duesAmount().pipe(
        map((data: any) => {
          return new KnBrDuesAmountLoadSeccess(data)
        }),
        catchError((error: Error) => {
          this.messageService.add({ key: 'knBrMessages.technicalIssue' }, GlobalMessageType.MSG_TYPE_ERROR);
          return of(new KnBrInvoiceSearchFailure(error));
        })
      )
    )
  );

  @Effect({ dispatch: false })
  InvoiceListFailure$ = this.actions$.pipe(
    ofType(KnBrInvoiceActionTypes.KnBrInvoiceSearchFailureAction),
    map((action) => {
      this.messageService.add({ key: 'knBrMessages.technicalIssue' }, GlobalMessageType.MSG_TYPE_ERROR);
    })
  );

  @Effect()
  accountSummary$ = this.actions$.pipe(
    ofType(KnBrInvoiceActionTypes.KnBrInvoiceAccountSummaryAction),
    mergeMap((action) =>
      this.invoiceAdapter.invoiceAccountSummary().pipe(
        map((data: any) => {
          return new KnBrInvoiceAccountSummarySuccess(data);
        }),
        catchError((error: Error) => {
          this.messageService.add({ key: 'knBrMessages.technicalIssue' }, GlobalMessageType.MSG_TYPE_ERROR);
          return of(new KnBrInvoiceAccountSummaryFailure(error));
        })
      )
    )
  );

  constructor(
    private actions$: Actions<KnBrInvoiceActions>,
    private invoiceAdapter: KnBrInvoiceAdapter,
    private messageService: GlobalMessageService
  ) {}
}
