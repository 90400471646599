<div class="col-lg-12 p-0 invoice-list" *ngIf="invoiceResult$ | async as invoiceResult">
  <ng-container *ngIf="sorts$ | async as sorts">
    <div class="row d-flex justify-content-end" *ngIf="paginate$ | async as paginate">
      <div class="col-sm-12 col-md-6 col-lg-5 justify-content-start mt-3" >
        <kn-br-browsing [paginate]="paginate" [recordsShown]="invoiceResult.length"
          recordType="{{ 'label.invoices' | cxTranslate }}">
        </kn-br-browsing>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-7 d-flex justify-content-end">
        <div class="mt-3 pt-1 sortBy">Sort By</div>
        <cx-sorting class="w-40" [sortOptions]="sorts" [sortLabels]="getSortLabels() | async"
          (sortListEvent)="changeSortCode($event)" [selectedOption]="sortType"
          placeholder="{{ 'invoiceResult.sortByMostRecent' | cxTranslate }}">
        </cx-sorting>
        <button *ngIf="documentNumbers.length" type="button" class="w-60 btn btn-outline-primary mr-3" (click)="downloadPdfOrNavigateToDetails(true)">
          <i class="fas fa-download" aria-hidden="true"></i>
          {{ 'invoiceResult.header.downloadPDF' | cxTranslate }} &nbsp;
        </button>
        <button type="button" class="w-60 btn btn-primary" [disabled]="downloadProgress" (click)="downloadInvoice()">
          {{ 'invoiceResult.header.downloadStatement' | cxTranslate }} &nbsp; <i class="fas fa-download pr-2" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  </ng-container>
  <div class="row counter-wrapper" *ngIf="invoiceResult && invoiceResult.length">
    <div class="customersearch-list-table float-left col-lg-12 mt-3">
      <div class="customersearch-list-titles border-top float-left w-100 pl-2 pr-2 pt-3 pb-3 d-none d-sm-none d-lg-block">
        <div class="col-md-1 float-left">
          <p></p>
        </div>
        <div class="col-md-1 float-left font-text-400 pl-0 font-md-size">
          {{ 'invoiceResult.header.documentType' | cxTranslate }}
        </div>
        <div class="col-md-2 float-left font-text-400 pl-0 font-md-size">
          {{ 'invoiceResult.header.purchaseOrder' | cxTranslate }}
        </div>
        <div class="col-md-3 float-left font-text-400 pl-0 font-md-size">
          {{ 'invoiceResult.header.jobName' | cxTranslate }}
        </div>
        <div class="col-md-1 float-left font-text-400 pl-0 font-md-size">
          {{ 'invoiceResult.header.document' | cxTranslate }}
        </div>
        <div class="col-md-1 float-left font-text-400 pl-0 font-md-size">
          {{ 'invoiceResult.header.documentDate' | cxTranslate }}
        </div>
        <div class="col-md-1 float-left font-text-400 pl-0 font-md-size">
          {{ 'invoiceResult.header.amount' | cxTranslate }}
        </div>
        <div class="col-md-1 float-left font-text-400 pl-0 font-md-size">
          {{ 'invoiceResult.header.dueDate' | cxTranslate }}
        </div>
        <div class="col-md-1 float-left font-text-400 pl-0 font-md-size">
          {{ 'invoiceResult.header.status' | cxTranslate }}
        </div>
      </div>

      <div class="bg-white customersearch-list-titles table-content border-top float-left w-100 p-1 font-weight-normal align-items-center" *ngFor="let invoice of this.invoiceList">
        <div class="col-md-1 float-left pl-0">
          <div class="form-check-inline mr-0">
            <input type="checkbox" class="form-check-input" (change)="select(invoice, $event.target.checked)" [checked]="this.documentNumbers.includes(invoice.documentNumber)"/>
          </div>
          <button (click)="downloadPdfOrNavigateToDetails(true, invoice)" type="button" class="btn font-lg-size pl-0">
            <i class="fas fa-download downloadIcon" aria-hidden="true"></i>
          </button>
        </div>
        <div class="col-md-1 float-left pl-0 mt-3">
          <span>{{ invoice.documentType?.name }}</span>
        </div>
        <div class="col-md-2 float-left title-wrap pl-0 mt-3 font-md-size color-font" (click)="downloadPdfOrNavigateToDetails(false, invoice)">
          {{ invoice.purchaseOrder }}
        </div>
        <div class="col-md-3 float-left title-wrap pl-0 mt-3 font-md-size color-font" (click)="downloadPdfOrNavigateToDetails(false, invoice)">
          {{ invoice.jobName ? invoice.jobName: '-' }}
        </div>
        <div class="col-md-1 float-left title-wrap pl-0 mt-3 pb-1 font-md-size color-font right-align" (click)="downloadPdfOrNavigateToDetails(false, invoice)">
          <a href="javascript:void(0)" class="cx-link"> {{ invoice.documentNumber | removeLeadingZeros }}</a>
        </div>
        <div class="col-md-1 float-left title-wrap pl-0 mt-3 font-md-size color-font" (click)="downloadPdfOrNavigateToDetails(false, invoice)">
          {{ invoice.invoiceDate }}
        </div>
        <div class="col-md-1 float-left title-wrap pl-0 mt-3 font-md-size color-font right-align" (click)="downloadPdfOrNavigateToDetails(false, invoice)">
          {{ invoice.formattedAmount }}
        </div>
        <div class="col-md-1 float-left title-wrap pl-0 mt-3 font-md-size color-font" (click)="downloadPdfOrNavigateToDetails(false, invoice)">
          {{ invoice.knbrDueDate }}
        </div>
        <div class="col-md-1 float-left title-wrap pl-0 mt-3 font-md-size color-font" (click)="downloadPdfOrNavigateToDetails(false, invoice)">
          {{ invoice.status | titlecase }}
        </div>
      </div>
    </div>
    <div class="col-lg-12 d-flex">
      <ng-container *ngIf="paginate$ | async as paginate">
        <div class="col-lg-6 justify-content-start mt-3" >
          <kn-br-browsing [paginate]="paginate" [recordsShown]="invoiceResult.length" recordType="{{ 'label.invoices' | cxTranslate }}"></kn-br-browsing>
        </div>
        <div class="col-lg-6 w-100 mt-3">
          <div *ngIf="paginate.currentPage < paginate.totalPages - 1 && !(isLoader$ | async)"
            (click)="loadMore(paginate.currentPage)" class="btn btn-block btn-action align-btn show-more-product font-size border-0 float-left w-auto mt-0 mr-3 btn-loadmore">
            {{ 'invoiceResult.showMore' | cxTranslate }}
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="alert-info p-3 rounded mt-3" *ngIf="invoiceResult && invoiceResult.length < 1 && !(isLoader$ | async) && !(isInvoiceInit$ | async)">
    {{ 'invoiceResult.nodataMsg' | cxTranslate }}
  </div>
</div>
<!-- <ng-container class="cx-spinner" *ngIf="downloadProgress">
  <cx-spinner class="cx-dialog-popover-center-backdrop"></cx-spinner>
</ng-container> -->
