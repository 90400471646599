import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable, queueScheduler } from 'rxjs';
import { observeOn } from 'rxjs/operators';
import { KnBrFactSheetDetail } from './kn-br-fact-sheet.action';
import { getFacSheetDataLoader, getFactSheetData, getFactSheetPaginate } from './kn-br-fact-sheet.selector';
import { StateWithKnBrFactSheet } from './kn-br-fact-sheet.state';
import { knBrFactSheet } from 'src/app/models/kn-br-fact-sheet.model';

@Injectable({
  providedIn: 'root',
})
export class KnBrFactSheetService {
  constructor(private store: Store<StateWithKnBrFactSheet>) {}
  loading$: Observable<boolean> = this.store.pipe(select(getFacSheetDataLoader), observeOn(queueScheduler));
  factSheetData$: Observable<any> = this.store.pipe(select(getFactSheetData), observeOn(queueScheduler));
  paginate$: Observable<any> = this.store.pipe(select(getFactSheetPaginate), observeOn(queueScheduler));
  brandName$: BehaviorSubject<any> = new BehaviorSubject(null);

  getFactSheetData(payload: knBrFactSheet) {
    this.store.dispatch(new KnBrFactSheetDetail(payload));
  }

  setBrandName(value) {
    this.brandName$.next(value);
  }
}
