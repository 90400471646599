import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, Observable, queueScheduler } from 'rxjs';
import { observeOn } from 'rxjs/operators';
import { StateWithKnBrInvoice } from './kn-br-invoice.state';
import { B2bDocuments, KnBrInvoiceDueAmount, KnBrInvoiceSearchForm } from 'src/app/models/kn-br-invoice.model';
import {
  KnBrDuesAmountLoad,
  KnBrInvoiceAccountSummary,
  KnBrInvoiceSearch,
  KnBrInvoiceSearchReset,
} from './kn-br-invoice.action';
import {
  getDuesAmount,
  getInvoice,
  getInvoiceAccountSummaryState,
  getInvoiceLoader,
  getInvoicePaginate,
  getInvoiceSearchCriteria,
  getInvoiceSearchDisabled,
  getInvoiceSorts,
} from './kn-br-invoice.selector';
import { OccEndpointsService } from '@spartacus/core';
import { KN_BR_CUSTOMER_CURRENT, KNBR_INVOICE_SEARCH_XLS_DOWNALOD } from 'src/constants/api.endpoints.constant';
import { KnBrCustomerContextService } from '../kn-br-customer-context/kn-br-customer-context.service';
import { HttpClient } from '@angular/common/http';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';

@Injectable({
  providedIn: 'root',
})
export class KnBrInvoiceService {
  customerNumber: string;
  constructor(
    private store: Store<StateWithKnBrInvoice>,
    private endPointService: OccEndpointsService,
    protected knBrCustomerContextService: KnBrCustomerContextService,
    private httpClient: HttpClient,
    private knBrCommonService: KnBrCommonService) {
    this.knBrCustomerContextService.getActiveCustomerNumber().subscribe((response) => {
      this.customerNumber = response ? response : null;
    });
  }

  invoiceinit$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  list$: Observable<B2bDocuments[]> = this.store.pipe(select(getInvoice), observeOn(queueScheduler));

  duesAmount$: Observable<KnBrInvoiceDueAmount[]> = this.store.pipe(select(getDuesAmount), observeOn(queueScheduler));

  loader$: Observable<boolean> = this.store.pipe(select(getInvoiceLoader), observeOn(queueScheduler));

  paginate$: Observable<any> = this.store.pipe(select(getInvoicePaginate), observeOn(queueScheduler));

  sorts$: Observable<any> = this.store.pipe(select(getInvoiceSorts), observeOn(queueScheduler));

  searchCriteria$: Observable<any> = this.store.pipe(select(getInvoiceSearchCriteria), observeOn(queueScheduler));

  accountSummary$: Observable<any> = this.store.pipe(select(getInvoiceAccountSummaryState), observeOn(queueScheduler));

  isSearchDisabled$: Observable<boolean> = this.store.pipe(select(getInvoiceSearchDisabled), observeOn(queueScheduler));

  setInvoiceInit(isInit: boolean) {
    this.invoiceinit$.next(isInit);
  }

  isInvoiceInit$(): Observable<boolean> {
    return this.invoiceinit$.asObservable();
  }

  search(searchForm: KnBrInvoiceSearchForm) {
    if ((searchForm && searchForm.currentPage === 0) || !searchForm) {
      this.reset();
    }
    this.store.dispatch(new KnBrInvoiceSearch(searchForm));
  }

  accountSummary() {
    this.store.dispatch(new KnBrInvoiceAccountSummary(null));
  }

  reset() {
    this.store.dispatch(new KnBrInvoiceSearchReset(null));
  }

  loadDues() {
    this.store.dispatch(new KnBrDuesAmountLoad(null));
  }

  invoiceXlsDownalodUrl(invoiceSearch: KnBrInvoiceSearchForm) {
    const CUSTOMER_NUMBER = this.customerNumber ? this.customerNumber : KN_BR_CUSTOMER_CURRENT;

   return this.httpClient.get<Blob>(this.endPointService.buildUrl(KNBR_INVOICE_SEARCH_XLS_DOWNALOD(CUSTOMER_NUMBER), { queryParams: invoiceSearch }), { observe: 'response', responseType: 'blob' as 'json'});

  }

  downloadPdf(invoice) {
    this.knBrCommonService.downloadPDF(invoice);
  }

  downloadSinglePdf(invoiceDetails) {
    this.knBrCommonService.downloadSinglePDF(invoiceDetails);
  }
}
