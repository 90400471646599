import { Injectable } from '@angular/core';
import { Converter, Occ } from '@spartacus/core';
import { KnBrB2BUser } from '../models/kn-br-b2b-user.model';

@Injectable()
export class KnBrB2BUserSerializer implements Converter<KnBrB2BUser, Occ.B2BUser> {
  convert(source: KnBrB2BUser, target?: Occ.B2BUser): Occ.B2BUser {
    if (target === undefined) {
      target = { ...(source as any) };
    }
    delete (target as KnBrB2BUser).isAssignedToApprovers;
    if (target.active === false) {
      target.roles = [];
    }
    (target as any).contactNumber = source.knbrMobileNumber;
    return target;
  }
}
