<ng-container *ngIf="state$ | async as state">
  <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" [closeOthers]="false">
    <ngb-panel [title]="facet.name" class="p-0">
      <ng-container [ngSwitch]="facet.name">
        <ng-container *ngSwitchCase="'Flush Volume'">
          <ng-template ngbPanelContent>
            <a *ngFor="let value of facet.values" #facetValue routerLink="./" [queryParams]="getLinkParams(value)"
              class="value flushVolume" [class.selected]="value.selected" [cxFocus]="value.name"
              (keydown.space)="openLink($event)" (click)="linkClick()">
              <span>
                <span class="label">{{ value.name }}</span>
                <!-- <span class="count">{{ value.count }}</span> -->
              </span>
            </a>
          </ng-template>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <ng-template ngbPanelContent>
            <a *ngFor="let value of facet.values | slice: 0:state.topVisible" #facetValue routerLink="./"
              [queryParams]="getLinkParams(value)" class="value" [class.selected]="value.selected"
              [cxFocus]="value.name" (keydown.space)="openLink($event)" (click)="linkClick()">
              <span>
                <i *ngIf="facet.name === 'Finish'" class="fas fa-circle fa-lg pb-2 pt-2 pr-1" [ngClass]="{
                    'finish-graphite': value.name === 'Graphite',
                    'finish-brushed-nickel': value.name === 'Brushed Nickel',
                    'finish-brushed-stainless': value.name === 'Brushed Stainless',
                    'finish-polished-brass': value.name === 'Polished Brass',
                    'finish-polished-chrome': value.name === 'Polished Chrome',
                    'finish-rough-brass': value.name === 'Rough Brass',
                    'finish-satin-brass': value.name === 'Satin Brass',
                    'finish-gun-metal': value.name === 'Gun Metal',
                    'finish-matte-black': value.name === 'Matte Black',
                    'finish-matte-white': value.name === 'Matte White',
                    'finish-off-white': value.name === 'Off White',
                    'finish-polished-white': value.name === 'Polished White',
                    'finish-white': value.name === 'White'
                  }"></i>
                <span class="label">
                  {{
                  value.name === 'true' || value.name === 'false'
                  ? value.name === 'true'
                  ? 'Yes'
                  : 'No'
                  : value.name
                  }}
                </span>
                <span class="count">{{ value.count }}</span>
              </span>
            </a>

            <div class="show-more">
              <a *ngFor="let value of facet.values | slice: state.topVisible:state.maxVisible" #facetValue
                routerLink="./" [queryParams]="getLinkParams(value)" class="value" [class.selected]="value.selected"
                [cxFocus]="value.name" (keydown.space)="openLink($event)">
                <span>
                  <span class="label">{{ value.name }}</span>
                  <span class="count">{{ value.count }}</span>
                </span>
              </a>

              <button *ngIf="state.maxVisible > state.topVisible" (click)="decreaseVisibleValues()"
                class="cx-action-link text-decoration-none font-text-600 font-size-17 pt-2" cxFocus="moreorless">
                {{ 'productList.showLess' | cxTranslate }}
              </button>

              <button *ngIf="state.maxVisible > 0 && state.maxVisible < facet.values.length"
                (click)="increaseVisibleValues()"
                class="cx-action-link text-decoration-none font-text-600 font-size-17 pt-2" cxFocus="moreorless">
                {{ 'productList.showMore' | cxTranslate }}
              </button>
            </div>
          </ng-template>
        </ng-container>
      </ng-container>
    </ngb-panel>
  </ngb-accordion>
</ng-container>
