import { createFeatureSelector, createSelector } from '@ngrx/store';

import { KN_BR_SEARCH_DRAFT_ORDERS_FEATURE, KnBrDraftOrdersState } from './kn-br-draft-orders.state';

export const getDraftOrderListState = createFeatureSelector<KnBrDraftOrdersState>(KN_BR_SEARCH_DRAFT_ORDERS_FEATURE);

export const getDraftOrders = createSelector(getDraftOrderListState, (state: KnBrDraftOrdersState) => state.orders);

export const getDraftOrdersLoader = createSelector(
  getDraftOrderListState,
  (state: KnBrDraftOrdersState) => state.isLoading
);

export const getDraftOrdersPaginate = createSelector(
  getDraftOrderListState,
  (state: KnBrDraftOrdersState) => state.pagination
);

export const getDraftOrderSearchCriteria = createSelector(
  getDraftOrderListState,
  (state: KnBrDraftOrdersState) => state.searchRequest
);

export const getDraftSearchDisabled = createSelector(
  getDraftOrderListState,
  (state: KnBrDraftOrdersState) => state.isSearchDisabled
);

export const getDraftCreateDisabled = createSelector(
  getDraftOrderListState,
  (state: KnBrDraftOrdersState) => state.isCreateDisabled
);

export const getDraftOrderDetailStatuses = createSelector(
  getDraftOrderListState,
  (state: KnBrDraftOrdersState) => state.statuses
);

export const getDraftOrdersSpinner = createSelector(
  getDraftOrderListState,
  (state: KnBrDraftOrdersState) => state.showSpinner
);

export const getDraftOrderCheckOutDisable = createSelector(
  getDraftOrderListState,
  (state: KnBrDraftOrdersState) => state.checkoutDisabled
);

export const getDraftOrderWarningMessage = createSelector(
  getDraftOrderListState,
  (state: KnBrDraftOrdersState) => state.warningMessage
);

export const getResetForm = createSelector(getDraftOrderListState, (state: KnBrDraftOrdersState) => state.resetForm);
