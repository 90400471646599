import { AfterViewInit, Component } from '@angular/core';
import { Event, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { CsAgentAuthService } from "@spartacus/asm/root";
import { CmsService, PageMetaService } from '@spartacus/core';
import { map } from 'rxjs/operators';
import { KnBrSidebarService } from './services/kn-br-sidebar.service';
import { KnBrCommonService } from './services/kn-br-common.service';
import { environment } from 'src/environments/environment';


declare const ga: any;
@Component({
  selector: 'kn-br-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements AfterViewInit {
  title = 'brava-customer';
  pageTitle: any;
  Url: any;
  isLoggedInASM = false;
  baseURL = environment.BASEURL;
  readonly isNotLoginPage$ = this.cmsService
    .getCurrentPage()
    .pipe(map((page) => page && page.template !== 'KnBrLoginPageTemplate'));
  readonly menuState$ = this.sidebarService.loadMenuState$();
  constructor(
    private cmsService: CmsService,
    private sidebarService: KnBrSidebarService,
    private router: Router,
    protected csAgentAuthService: CsAgentAuthService,
    protected pageMetaService: PageMetaService,
    protected commonService: KnBrCommonService
  ) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        this.sidebarService.changeMenuState(false);
      } else if (event instanceof NavigationEnd) {
        let element = document.querySelectorAll('div.ng-sidebar__content')[0];
        setTimeout(() => {
          element.scrollTop = 0;
          element.scrollIntoView({ behavior: 'smooth' });
        }, 0);
      }
    });
    this.csAgentAuthService.isCustomerSupportAgentLoggedIn().subscribe((res) => {
      this.isLoggedInASM = res;
    });

    this.loadQualtrics();
    if (this.baseURL.includes('p1')) {
      this.loadHotJar();
    };
  }

  ngAfterViewInit() {
    this.initGoogleAnalyticsPageView();
  }

  private initGoogleAnalyticsPageView() {
    const interval = setInterval(() => {
      if ((window as any).ga && (window as any).ga.getAll) {
        const ga = (window as any).ga;
        const tracker = ga.getAll()[0];
        let count = 0;
        this.router.events.subscribe((event) => {
          if (event instanceof NavigationEnd) {
            count = 0;
            this.Url = event.urlAfterRedirects;
          }
        });
        this.pageMetaService.getMeta().subscribe((meta) => {
          count = count + 1;
          this.pageTitle = meta.title;
          if (this.pageTitle) {
            tracker.set('title', this.pageTitle);
          } else {
            tracker.set('title', document.title);
          }

          if (count === 1) {
            tracker.set('page', this.Url);
            tracker.send('pageview');
          }
        });
        clearInterval(interval);
      }
    }, 50);
  }

    // <!--BEGIN QUALTRICS WEBSITE FEEDBACK SNIPPET-->
    loadQualtrics() {
      const script = `(function(){var g=function(e,h,f,g){
        this.get=function(a){for(var a=a+"=",c=document.cookie.split(";"),b=0,e=c.length;b<e;b++){for(var d=c[b];" "==d.charAt(0);)d=d.substring(1,d.length);if(0==d.indexOf(a))return d.substring(a.length,d.length)}return null};
        this.set=function(a,c){var b="",b=new Date;b.setTime(b.getTime()+6048E5);b="; expires="+b.toGMTString();document.cookie=a+"="+c+b+"; path=/; "};
        this.check=function(){var a=this.get(f);if(a)a=a.split(":");else if(100!=e)"v"==h&&(e=Math.random()>=e/100?0:100),a=[h,e,0],this.set(f,a.join(":"));else return!0;var c=a[1];if(100==c)return!0;switch(a[0]){case "v":return!1;case "r":return c=a[2]%Math.floor(100/c),a[2]++,this.set(f,a.join(":")),!c}return!0};
        this.go=function(){if(this.check()){var a=document.createElement("script");a.type="text/javascript";a.src=g;document.body&&document.body.appendChild(a)}};
        this.start=function(){var t=this;"complete"!==document.readyState?window.addEventListener?window.addEventListener("load",function(){t.go()},!1):window.attachEvent&&window.attachEvent("onload",function(){t.go()}):t.go()};};
        try{(new g(100,"r","QSI_S_ZN_eYjkkocKcMAYoaG","https://zneyjkkockcmayoag-ccm.siteintercept.qualtrics.com/SIE/?Q_ZID=ZN_eYjkkocKcMAYoaG")).start()}catch(i){}})();`;
      const scripttag = document.createElement("script");
      scripttag.type = `text/javascript`;
      scripttag.text = script;
      scripttag.id = 'ZN_eYjkkocKcMAYoaG';
      document.getElementsByTagName("head")[0].appendChild(scripttag);
    };
    // <!--END WEBSITE FEEDBACK SNIPPET-->

    // <!-- Hotjar Tracking Code for CSP External -->
    loadHotJar() {
      const script = `(function(h,o,t,j,a,r){ h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; h._hjSettings={hjid:5291809,hjsv:6}; a=o.getElementsByTagName('head')[0]; r=o.createElement('script');r.async=1; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r); })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=')`;
      const scripttag = document.createElement("script");
      scripttag.type = `text/javascript`;
      scripttag.text = script;
      document.getElementsByTagName("head")[0].appendChild(scripttag);
    };

  _onBackdropClicked() {
    this.sidebarService.changeMenuState(false);
  }
  _onClosed() {
    this.sidebarService.changeMenuState(false);
  }
  _onOpened() {
    this.sidebarService.changeMenuState(true);
  }
}
