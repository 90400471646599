<section class="col-md-12 p-0 login-wrapper d-flex justify-content-center align-items-center"
  *ngIf="{isUserLoggedIn: auth.isUserLoggedIn() | async, loginInProgress:loginInProgress} as $">
  <div class="container fluid p-0">
    <form (ngSubmit)="checkIsValidUser()" [formGroup]="form">
      <div class="col-md-12 float-left knbr-login-forms mt-2 d-flex justify-content-center p-0">
        <div class="col-lg-4 col-sm-6 login-forms p-3">
          <div class="form-group">
            <i class="fas fa-user fa-lg" aria-hidden="true"></i>
            <input type="text" required class="form-control w-100" formControlName="userId"
              placeholder="{{ 'loginForm.emailAddress.placeholder' | cxTranslate }}" type="email"
              [disabled]="$.loginInProgress" />
            <span class="highlight"></span>
            <!-- <label>{{ 'loginForm.emailAddress.label' | cxTranslate }}</label> -->
            <cx-form-errors [control]="form.get('userId')"></cx-form-errors>
          </div>

          <div class="form-group m-0">
            <i _ngcontent-lsq-c299="" aria-hidden="true" class="fas fa-lock pr-1 fa-lg"></i>
            <input type="text" required class="form-control w-100"
              placeholder="{{ 'loginForm.password.placeholder' | cxTranslate }}" formControlName="password"
              type="password" [disabled]="$.loginInProgress" />
            <span class="highlight"></span>
            <!-- <label>{{ 'loginForm.password.label' | cxTranslate }}</label> -->
            <cx-form-errors [control]="form.get('password')"></cx-form-errors>
          </div>
          <div class="login-btns d-flex col-md-12 mt-3 pl-0 pr-0 p-res-0">
            <button type="submit" class="btn btn-primary btn-lg col-md-12 m-res-0 loader-button"
              [disabled]="$.loginInProgress|| $.isUserLoggedIn">
              <ng-container *ngIf="loginInProgress || $.isUserLoggedIn">
                <div class=" custom-loader">
                </div>
              </ng-container>
              <ng-container *ngIf="!loginInProgress && !$.isUserLoggedIn">
                {{ 'loginForm.signIn' | cxTranslate }}
              </ng-container>
            </button>
            <button type="button" class=" mt-2 ml-2 btn btn-outline-primary btn-lg btn-block  m-res-0 loader-button"
              (click)="redirectToSSO()">
              {{ 'loginForm.signInWithSSO' | cxTranslate }}
            </button>
          </div>
          <div class="remember-me forget-pass col-md-12 float-left mt-3 d-flex justify-content-center p-0">
            <a [routerLink]="{ cxRoute: 'forgotPassword' } | cxUrl" class="font-color-knbr-secondary">
              <i class="fas fa-lock pr-1" aria-hidden="true"></i>{{ 'loginForm.forgotPassword' | cxTranslate }}</a>
          </div>
        </div>
      </div>
    </form>
  </div>
</section>
