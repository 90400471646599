import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CartSharedModule } from '@spartacus/cart/base/components';
import { OrderConfirmationGuard } from '@spartacus/order/components';
import { CmsConfig, ConfigModule, FeaturesConfigModule, I18nModule } from '@spartacus/core';
import { CardModule, FormErrorsModule, PromotionsModule, PwaModule } from '@spartacus/storefront';
import { KnBrOrderOverviewModule } from '../kn-br-order-overview/kn-br-order-overview.module';
import { KnBrOrderConfirmationOverviewComponent } from './kn-br-order-confirmation-overview/kn-br-order-confirmation-overview.component';

@NgModule({
  imports: [
    CommonModule,
    CartSharedModule,
    CardModule,
    PwaModule,
    PromotionsModule,
    I18nModule,
    ReactiveFormsModule,
    FeaturesConfigModule,
    FormErrorsModule,
    KnBrOrderOverviewModule,
    ConfigModule.withConfig({
      cmsComponents: {
        OrderConfirmationOverviewComponent: {
          component: KnBrOrderConfirmationOverviewComponent,
          guards: [OrderConfirmationGuard],
        },
      },
    } as CmsConfig),
  ],
  declarations: [KnBrOrderConfirmationOverviewComponent],
  exports: [KnBrOrderConfirmationOverviewComponent],
})
export class KnBrOrderConfirmationModule {}
