import { Action } from '@ngrx/store';


export enum KnBrSalesOrgActionTypes {
  KnBrSalesOrgAddAction = '[SalesOrg] Add'
}


/**
 * Action to add sales org mapped product
 */
export class KnBrSalesOrgAdd implements Action {
    readonly type = KnBrSalesOrgActionTypes.KnBrSalesOrgAddAction;
    constructor(public payload: any) {}
  }

export type KnBrSalesOrgActions =  KnBrSalesOrgAdd