import { AfterViewInit, ChangeDetectionStrategy, Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { CmsProductCarouselComponent, Product, ProductLoadingService, ProductScope, ProductService } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { KnBrProductSalesOrgService, SALESORG } from 'src/app/services/kn-br-product-sales-org.service';
import { KnBrProductService } from 'src/app/services/kn-br-product.sevice';
import { KnBrSalesOrgAdd } from 'src/app/store/kn-br-salesorg/kn-br-salesorg.action';
import { StateWithKnBrSalesOrgs } from 'src/app/store/kn-br-salesorg/kn-br-salesorg.state';

export interface KnBrCmsProductCarouselComponent extends CmsProductCarouselComponent {
  salesOrgs?: string;
}

@Component({
  selector: 'cx-product-carousel',
  templateUrl: './kn-br-product-carousel.component.html',
  styleUrls: ['./kn-br-product-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders:[
    {
      provide: ProductService,
      useClass: KnBrProductService
    }
  ]
})
export class KnBrProductCarouselComponent implements AfterViewInit {
  protected readonly PRODUCT_SCOPE = ProductScope.LIST;
  salesOrg: SALESORG = {};
  private componentData$: Observable<KnBrCmsProductCarouselComponent> = this.componentData.data$.pipe(filter(Boolean));

  /**
   * returns an Obervable string for the title.
   */
  title$: Observable<string> = this.componentData$.pipe(map((data) => data.title));

  /**
   * Obervable that holds an Array of Observables. This is done, so that
   * the component UI could consider to lazy load the UI components when they're
   * in the viewpoint.
   */
  items$: Observable<Observable<Product>[]> = this.componentData$.pipe(
    map((data) => {
      const productCodes = data.productCodes ? data.productCodes.trim().split(' ') : [];
      const salesOrgs = data.salesOrgs ? data.salesOrgs.trim().split(' ') : [];
      const salesMappedProduct = {};
      productCodes.forEach((k, i) => {
        salesMappedProduct[`${k}-${salesOrgs[i]}`] = salesOrgs[i];
      });
      this.store.dispatch(new KnBrSalesOrgAdd(salesMappedProduct));
      return {productCodes, salesOrgs};
    }),
    //@ts-ignore
    map(({productCodes, salesOrgs}) => productCodes.map((code, index) => this.productService.get(code,salesOrgs[index], this.PRODUCT_SCOPE)))
  );

  constructor(
    protected componentData: CmsComponentData<KnBrCmsProductCarouselComponent>,
    protected productService: ProductService,
    private store: Store<StateWithKnBrSalesOrgs>,
    private _sanitizer: DomSanitizer,
    protected knBrProductSalesOrgService: KnBrProductSalesOrgService,
  ) {}

  ngAfterViewInit(): void {
    window.dispatchEvent(new Event('resize'));
  }

  getSalesOrg(salesOrg: string, type: string) {
    const _salesOrg = this.knBrProductSalesOrgService.getSalesOrgDetails(salesOrg);
    if (_salesOrg) {
      return _salesOrg[type];
    }
    return "";
  }

  getSVGImage(salesOrg) {
    const image = this.getSalesOrg(salesOrg, 'imgUrl')
    return image ? this._sanitizer.bypassSecurityTrustHtml(`${image}`) : '';
  }
}
