import { KnBrCancellationRequestEntryInputList, KnBrOrderFormsSearchRequest } from './../../models/kn-br-order.model';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, queueScheduler, BehaviorSubject } from 'rxjs';
import { observeOn } from 'rxjs/operators';
import { StateWithKnBrOrderHistory } from './kn-br-order-history.state';
import { KnBrOrderHistory, KnBrOrderHistorySearchForm } from 'src/app/models/kn-br-order.model';
import {
  KnBrOrderHistorySearch,
  KnBrOrderHistorySearchReset,
  KnBrCreateReturnOrder,
  KnBrOrderReOrder,
  KnBrOrderCancel,
  KnBrOrderFormsProductsSearch,
  KnBrEditOrder,
  KnBrOrderFormsProductsReset,
} from './kn-br-order-history.action';
import {
  getEditOrderDisable,
  getOrdeFormsSearchCriteria,
  getOrdeFormsSearchDisabled,
  getOrderFormsLoader,
  getOrderFormsPaginate,
  getOrderFormsSorts,
  getOrderHistory,
  getOrderHistoryLoader,
  getOrderHistoryPaginate,
  getOrderHistorySearchCriteria,
  getOrderHistorySearchDisabled,
  getOrderHistorySorts,
  getProductsOrderForms,
} from './kn-br-order-history.selector';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';

@Injectable({
  providedIn: 'root',
})
export class KnBrOrderHistoryService {
  constructor(
    private knBrCommonService: KnBrCommonService,
    private store: Store<StateWithKnBrOrderHistory>) {}

  list$: Observable<KnBrOrderHistory[]> = this.store.pipe(select(getOrderHistory), observeOn(queueScheduler));

  loader$: Observable<boolean> = this.store.pipe(select(getOrderHistoryLoader), observeOn(queueScheduler));

  paginate$: Observable<any> = this.store.pipe(select(getOrderHistoryPaginate), observeOn(queueScheduler));

  sorts$: Observable<any> = this.store.pipe(select(getOrderHistorySorts), observeOn(queueScheduler));

  searchCriteria$: Observable<any> = this.store.pipe(select(getOrderHistorySearchCriteria), observeOn(queueScheduler));

  orderFormsProducts$: Observable<any> = this.store.pipe(select(getProductsOrderForms), observeOn(queueScheduler));

  editDisable$: Observable<boolean> = this.store.pipe(select(getEditOrderDisable), observeOn(queueScheduler));

  orderFormsloader$: Observable<boolean> = this.store.pipe(select(getOrderFormsLoader), observeOn(queueScheduler));

  orderFormspaginate$: Observable<any> = this.store.pipe(select(getOrderFormsPaginate), observeOn(queueScheduler));

  orderFormssorts$: Observable<any> = this.store.pipe(select(getOrderFormsSorts), observeOn(queueScheduler));

  orderFormsSearchCriteria$: Observable<any> = this.store.pipe(
    select(getOrdeFormsSearchCriteria),
    observeOn(queueScheduler)
  );

  orderFormsSearchDisabled$: Observable<any> = this.store.pipe(
    select(getOrdeFormsSearchDisabled),
    observeOn(queueScheduler)
  );

  isEditOrder$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  saveEditOrder$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  isOrderFormm$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  createOrderFormData$: BehaviorSubject<string> = new BehaviorSubject(null);

  isSearchDisabled$: Observable<boolean> = this.store.pipe(
    select(getOrderHistorySearchDisabled),
    observeOn(queueScheduler)
  );

  search(searchForm: KnBrOrderHistorySearchForm) {
    if ((searchForm && searchForm.currentPage === 0) || !searchForm) {
      this.reset();
    }
    this.store.dispatch(new KnBrOrderHistorySearch(searchForm));
  }

  reset() {
    this.store.dispatch(new KnBrOrderHistorySearchReset(null));
  }

  createReturnOrder(returnObj: KnBrCancellationRequestEntryInputList) {
    this.store.dispatch(new KnBrCreateReturnOrder(returnObj));
  }
  reorder(payload) {
    this.store.dispatch(new KnBrOrderReOrder(payload));
  }

  cancelOrder(paramsJson: any) {
    this.store.dispatch(new KnBrOrderCancel(paramsJson));
  }

  setOrderEditable(isEditable: boolean) {
    this.isEditOrder$.next(isEditable);
  }

  isEditOrderable$(): Observable<boolean> {
    return this.isEditOrder$.asObservable();
  }

  getOrderFormProducts(payload: KnBrOrderFormsSearchRequest) {
    this.store.dispatch(new KnBrOrderFormsProductsSearch(payload));
  }

  editOrder(payload) {
    this.store.dispatch(new KnBrEditOrder(payload));
  }

  setSaveEditOrderRequest(editorder: boolean) {
    this.saveEditOrder$.next(editorder);
  }

  loadSaveEditOrderRequest$(): Observable<boolean> {
    return this.saveEditOrder$.asObservable();
  }

  resetOrderFormsData() {
    this.store.dispatch(new KnBrOrderFormsProductsReset(null));
  }

  setOrderForm(isOrderForm: boolean) {
    this.isOrderFormm$.next(isOrderForm);
  }

  getOrderForm(): Observable<boolean> {
    return this.isOrderFormm$.asObservable();
  }

  createOrderFormData(productIds: string) {
    this.createOrderFormData$.next(productIds);
  }

  getCreateOrderFormData(): Observable<string> {
    return this.createOrderFormData$.asObservable();
  }

  downloadPDF(doc) {
    this.knBrCommonService.downloadSinglePDF(doc);
  }
}
