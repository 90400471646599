<div class="how-section1 mySlides fadespot" *ngIf="data$ | async as data" [attr.data-visiblae]="data.visible">
    <h2 *ngIf="data.imageMapHTML && data.visible === 'true'">Product Spotlight</h2>
  <div class="row" *ngIf="data.visible === 'true'" [ngClass]="{'noShow': data.visible === 'false'}" 
    (click)="data.urlLink ? navSpotlightPlp(data.urlLink) : null" [style.cursor]="data.urlLink ? 'pointer' : null">
      <div class="col-md-6 how-img" *ngIf="data.media">
          <img [src]="getImgUrl(data.media.url)" class=" img-fluid" alt=""/>
      </div>
      <div class="col-md-6 p-text">
          <p [innerHTML]="data.imageMapHTML"></p>
      </div>
  </div>
</div>