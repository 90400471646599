import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';

import { KnBrCartContextService } from '../kn-br-cart-context/kn-br-cart-context.service';
import {
  KnBrQuoteAddUpdateContext,
  KnBrQuoteContextActions,
  KnBrQuoteContextActionTypes,
  KnBrQuoteLoadSuccessContext,
  KnBrQuoteRemoveContext,
  KnBrQuoteResetContext,
} from './kn-br-quote-context.actions';
import { KnBrQuoteContextAdapter } from './kn-br-quote-context.adapter';

@Injectable()
export class KnBrQuoteContextEffects {
  @Effect()
  loadQuoteContext$ = this.actions$.pipe(
    ofType(KnBrQuoteContextActionTypes.KnBrQuoteContextLoadAction),
    map((x) => {
      const context = this.knBrQuoteContextAdapter.get();
      return new KnBrQuoteLoadSuccessContext(context);
    })
  );
  @Effect({ dispatch: false })
  addUpdateQuoteContext$ = this.actions$.pipe(
    ofType(KnBrQuoteContextActionTypes.KnBrQuoteContextAddUpdateAction),
    map((action: KnBrQuoteAddUpdateContext) => action.payload),
    map((quoteCode: string) => {
      this.knBrCartContextService.delete();
      this.knBrQuoteContextAdapter.set(quoteCode);
    })
  );
  @Effect({ dispatch: false })
  removeQuoteContext$ = this.actions$.pipe(
    ofType(KnBrQuoteContextActionTypes.KnBrQuoteContextRemoveAction),
    map((action: KnBrQuoteRemoveContext) => action.payload),
    map((quoteCode: any) => {
      this.knBrQuoteContextAdapter.remove();
    })
  );
  @Effect({ dispatch: false })
  resetQuoteContext$ = this.actions$.pipe(
    ofType(KnBrQuoteContextActionTypes.KnBrQuoteContextResetAction),
    map((action: KnBrQuoteResetContext) => action.payload),
    map((quoteCode: any) => {
      this.knBrQuoteContextAdapter.remove();
    })
  );

  constructor(
    private actions$: Actions<KnBrQuoteContextActions>,
    private knBrQuoteContextAdapter: KnBrQuoteContextAdapter,
    private knBrCartContextService: KnBrCartContextService
  ) {}
}
