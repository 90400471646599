import { Component, ComponentRef, OnInit, ViewContainerRef, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { TranslationService } from '@spartacus/core';
import { LaunchDialogService, LAUNCH_CALLER } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { KnBrInvoiceAccountSummaryObject } from 'src/app/models/kn-br-invoice-account-summary.model';
import { KnBrInvoiceService } from 'src/app/store/kn-br-invoice/kn-br-invoice.service';
import { KnBrInvoiceDueAmount } from 'src/app/models/kn-br-invoice.model';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';

@Component({
  selector: 'app-kn-br-invoice-account-summary',
  templateUrl: './kn-br-invoice-account-summary.component.html',
  styleUrls: ['./kn-br-invoice-account-summary.component.scss'],
})
export class KnBrInvoiceAccountSummaryComponent implements OnInit, OnDestroy {
  accountSummaryResult$: Observable<KnBrInvoiceAccountSummaryObject> = this.knBrInvoiceService.accountSummary$;
  dueList$: Observable<KnBrInvoiceDueAmount[]> = this.knBrInvoiceService.duesAmount$;
  duelist = [];
  loader$: Observable<boolean> = this.knBrInvoiceService.loader$;
  showSpinner: void | Observable<ComponentRef<any>>;
  subscription = new Subscription();
  totalBalance: any;

  constructor(
    public knBrInvoiceService: KnBrInvoiceService,
    protected translation: TranslationService,
    protected launchDialogService: LaunchDialogService,
    protected vcr: ViewContainerRef,
    protected cd: ChangeDetectorRef,
    public knbrCommonService: KnBrCommonService
  ) {}

  ngOnInit(): void {
    this.getAccountSummary();
    this.subscription.add(
      this.loader$.subscribe((isLoading) => {
        this.onSpinnerLoading(isLoading);
      })
    );

    this.knBrInvoiceService.loadDues();
    this.subscription.add(
      this.dueList$.subscribe((response: any) => {
        if (response) {
          const list = [...response];
          this.duelist = list.slice(0, -1);
          this.totalBalance = response.filter(item => item.name == 'totalBalance');
          this.cd.markForCheck();
        }
      })
    );
  }


  search(data: string) {
    const params = {lowerLimit: '-1' , upperLimit: '0', status: 'open' ,currentPage:0, filterBy:'DOCUMENT_TYPE'}
    if(data === 'CURRENT') {
      params.lowerLimit = 'CURRENT'
    }else if(data.indexOf('+') > -1) {
      params.lowerLimit = data;
      params.upperLimit = '-1';
    } else  if(data.indexOf('-') > -1) {
      const _data = data.split('-');
      params.lowerLimit = _data[1];
      params.upperLimit = _data[0];
    }
    this.knBrInvoiceService.search(params);
  }

  formatValue(val) {
    const toNumber = Number(val);
    if (Number(val)) {
      return toNumber.toLocaleString();
    } else {
      return val;
    }
  }

  formatState(state) {
    return this.knbrCommonService.formatState(state);
  }

  onSpinnerLoading(isLoading) {
    if (isLoading) {
      this.showSpinner = this.launchDialogService.launch(LAUNCH_CALLER.PLACE_ORDER_SPINNER, this.vcr);
    } else {
      if (this.showSpinner) {
        this.showSpinner
          .subscribe((component) => {
            this.launchDialogService.clear(LAUNCH_CALLER.PLACE_ORDER_SPINNER);
            component.destroy();
          })
          .unsubscribe();
      }
    }
  }

  formatPhone(value) {
    const trim = value?.substring(2);
    const cleaned = ('' + trim).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    } else {
      const removeSpaces = value?.replace(/\s/g, '');
      const trim = removeSpaces?.substring(1);
      const match = trim?.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
      }
    }
  }

  formatCountry(country) {
    if (country === 'US') {
      return 'United States'
    } else if (country === 'CA') {
      return 'Canada'
    } else {
      return country;
    }
  }

  getAccountSummary() {
    this.knBrInvoiceService.accountSummary();
  }


  ngOnDestroy() {
    this.launchDialogService.clear(LAUNCH_CALLER.PLACE_ORDER_SPINNER);
    this.subscription.unsubscribe();
  }
}
