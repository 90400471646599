<ng-container *ngIf="model$ | async as model">
  <ng-container *ngIf="viewMode$ | async as viewMode">
    <div class="col-lg-12 product-action-section float-right login-forms d-flex align-items-center justify-content-end mb-3 p-0">
      <div class="float-left col-lg-6 text-left m-res-bottom m-res-top p-0">
        <span class="font-text-600 color-font"  [innerHTML]="getPageTitle(model) | async"></span>
      </div>
      <div class="col-lg-6 p-0">
        <div class="col-lg-12 pl-3 res-actions float-right p-0 d-flex justify-content-end">
          <div class="col-lg-7 form-group mt-0 pl-0 pr-2 float-left mb-0 d-flex align-items-center m-res-bottom justify-content-end p-res-0">
            <label class="text-muted position-static float-left mr-2 mb-0 sort-by sort-label-width">{{
              'label.sortBy' | cxTranslate}}
            </label>

           <cx-sorting *ngIf="model?.sorts.length>0"
              [sortOptions]="model.sorts"
              [sortLabels]="getLabels(model.sorts)"
              (sortListEvent)="sortList($event)"
              [selectedOption]="model.pagination.sort"
              placeholder="{{ 'productList.sortByRelevance' | cxTranslate }}"
            >
            </cx-sorting>

          </div>
          <div *ngIf="viewMode$ | async as viewMode" class="d-none d-xl-block">
            <ng-template #gridViewTip>{{ 'toolTip.listView' | cxTranslate }}</ng-template>
            <ng-template #listViewTip>{{ 'toolTip.gridView' | cxTranslate }}</ng-template>
            <div class="float-left" *ngIf="viewMode === ViewModes.Grid">
              <a
                [ngbTooltip]="gridViewTip"
                class="grid-icon p-2 rounded float-left d-flex align-items-center justify-content-center cursor-pointer"
                (click)="setViewMode(ViewModes.Grid)"
              >
                <i class="fas fa-list" aria-hidden="true"></i>
              </a>
            </div>
            <div class="float-left" *ngIf="viewMode === ViewModes.List">
              <a
                [ngbTooltip]="listViewTip"
                class="grid-icon p-2 rounded float-left d-flex align-items-center justify-content-center cursor-pointer"
                (click)="setViewMode(ViewModes.List)"
              >
                <i class="fas fa-th" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
