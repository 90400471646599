import { KnBrOccConfig } from '../config/kn-br-occ.config';

export const knBrCustomer360EndpointsConfig: KnBrOccConfig = {
  backend: {
    occ: {
      endpoints: {
        userForgotPassword: 'forgottenpasswordtoken',
        orderDetail: 'knbr/customer/${customerId}/orders/${orderId}?fields=FULL,entries(product(images(FULL),showInCatalog))',
        orderReturnDetail:
          'knbr/customer/${customerId}/orderReturns/${returnRequestCode}?fields=BASIC,returnEntries(BASIC,refundAmount(formattedValue),orderEntry(basePrice(formattedValue),product(name,code,baseOptions,images(DEFAULT,galleryIndex)))),deliveryCost(formattedValue),totalPrice(formattedValue),subTotal(formattedValue),deliveryAddress(FULL)',
        orderList: 'knbr/customer/${customerId}/orders',
        createReturnOrder: 'knbr/customer/${customerId}/orderReturns',
        reorder: 'knbr/customer/${customerId}/cartFromOrder',
        knbrCancelOrder: 'knbr/customer/${customerId}/orders/${code}/cancellation',
        orderFormProductSearch:
          'knbr/customer/${customerId}/orderforms?fields=products(code,name,summary,price(FULL),images(DEFAULT),stock(FULL),averageRating,variantOptions,uom,minOrderQuantity),sorts(FULL)',
        editOrder: 'knbr/customer/${customerId}/orders/${code}/editOrder',
      },
    },
  },
};
