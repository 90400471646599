import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { I18nModule, UrlModule } from '@spartacus/core';
import { IconModule, KeyboardFocusModule } from '@spartacus/storefront';

import { KnBrFacetComponent } from './kn-br-facet.component';

@NgModule({
  declarations: [KnBrFacetComponent],
  imports: [CommonModule, NgbModule, RouterModule, UrlModule, I18nModule, IconModule, KeyboardFocusModule],
  exports: [KnBrFacetComponent],
})
export class KnBrFacetModule {}
