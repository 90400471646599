import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router'; 
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { CarouselModule, MediaModule } from '@spartacus/storefront';

import { KnBrProductCarouselComponent } from './kn-br-product-carousel.component';

@NgModule({
  declarations: [KnBrProductCarouselComponent],
  imports: [
    CommonModule,
    CarouselModule,
    MediaModule,
    RouterModule,
    UrlModule,
    CommonModule,
    I18nModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ProductCarouselComponent: {
          component: KnBrProductCarouselComponent,
        },
      },
    } as CmsConfig),
  ],
  providers: [],
})
export class KnBrProductCarouselModule {}
