import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { RoutingService } from '@spartacus/core';
import { Subscription } from 'rxjs';

import { KnBrProductSelectService } from '../../../services/kn-br-product-select.service';
import { KnBrProductSalesOrgService, SALESORG } from 'src/app/services/kn-br-product-sales-org.service';
import { DomSanitizer } from '@angular/platform-browser';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';

@Component({
  selector: 'kn-br-product-grid-item',
  templateUrl: './kn-br-product-grid-item.component.html',
  styleUrls: ['./kn-br-product-grid-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class KnBrProductGridItemComponent implements OnDestroy, OnChanges {
  @Input() product: any;
  @Input() editable: boolean;
  salesOrg: SALESORG = {};
  selectedProductsCodes: string[];
  subscription: Subscription;
  constructor(
    protected productSelectService: KnBrProductSelectService,
    protected routingService: RoutingService,
    protected knBrProductSalesOrgService: KnBrProductSalesOrgService,
    private _sanitizer: DomSanitizer,
    private knBrCommonService: KnBrCommonService
  ) {
    this.subscription = this.productSelectService.loadSelectedProducts$().subscribe((selectedProductsCodes) => {
      this.selectedProductsCodes = selectedProductsCodes;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getSalesOrg(this.product.salesOrg);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  getSalesOrg(salesOrg: string) {
    const _salesOrg = this.knBrProductSalesOrgService.getSalesOrgDetails(salesOrg);
    if (_salesOrg) {
      this.salesOrg = _salesOrg;
    }
  }

  getSVGImage(image) {
    return image ? this._sanitizer.bypassSecurityTrustHtml(`${image}`) : '';
  }

  isChecked(code: string): boolean {
    if (this.selectedProductsCodes) {
      return this.selectedProductsCodes.indexOf(code) > -1;
    }
    return false;
  }
  changeCheckBox(event) {
    event.stopPropagation();
    const selectedProductCodes = event.currentTarget.checked ? this.addProductCode() : this.removeProductCode();
    this.productSelectService.setSelectedProducts(selectedProductCodes);
  }
  goToDetail(event) {
    if (event.target && event.target.classList && !event.target.classList.contains('custom-control-input')) {
      event.stopPropagation();
      event.preventDefault();
      this.knBrCommonService.setSpinner(true);
      location.assign(this.routingService.getFullUrl({ cxRoute: 'product', params: this.product }))
    }
  }
  addProductCode() {
    if (this.selectedProductsCodes && this.selectedProductsCodes.length) {
      this.selectedProductsCodes.push(this.product.code);
    } else {
      this.selectedProductsCodes = [this.product.code];
    }
    return this.selectedProductsCodes;
  }
  removeProductCode() {
    this.selectedProductsCodes.splice(this.selectedProductsCodes.indexOf(this.product.code), 1);
    return this.selectedProductsCodes;
  }
}
