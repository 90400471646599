import { KnBrAddRequestEntry } from './../../models/kn-br-order.model';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { GlobalMessageService, GlobalMessageType, Product } from '@spartacus/core';
import { CurrentProductService } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { KnBrCommonService } from '../../services/kn-br-common.service';
import { KnBrProductSelectService } from '../../services/kn-br-product-select.service';
import { KnBrCartContextService } from '../../store/kn-br-cart-context/kn-br-cart-context.service';
import { KnBrQuoteContextService } from '../../store/kn-br-quote-context/kn-br-quote-context.service';
import { KnBrQuoteEntryService } from '../../store/kn-br-quote-entry/kn-br-quote-entry.service';
import { KnBrQuotesService } from '../../store/kn-br-quotes/kn-br-quotes.service';
import { KnBrSelectQuoteComponent } from './kn-br-select-quote/kn-br-select-quote.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { OrderEntry } from '@spartacus/cart/base/root';

@Component({
  selector: 'kn-br-add-to-quote',
  templateUrl: './kn-br-add-to-quote.component.html',
  styleUrls: ['./kn-br-add-to-quote.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class KnBrAddToQuoteComponent implements OnInit, OnDestroy {
  @Input() type = 'PDP';
  @Input() productCode: string;
  @Input() isOrderable = true;
  /**
   * As long as we do not support #5026, we require product input, as we need
   *  a reference to the product model to fetch the stock data.
   */
  @Input() product: Product;
  maxQuantity: number;
  modalRef: NgbModalRef;
  hasStock = false;
  quantity = 1;
  increment = false;
  cartEntry$: Observable<OrderEntry>;
  subscription = new Subscription();
  addToCartForm = new UntypedFormGroup({
    quantity: new UntypedFormControl(1),
  });
  cartContext: string;
  quoteContext: string;
  selectedProductCodes: string[];
  isSaveDisabled$: Observable<boolean> = this.knBrQuoteEntryService.isSaveDisabled$;
  editable = false;
  @Input() productList: KnBrAddRequestEntry[] = [];
  quoteCode;
  constructor(
    protected modalService: NgbModal,
    protected currentProductService: CurrentProductService,
    protected cd: ChangeDetectorRef,
    protected knBrCartContextService: KnBrCartContextService,
    protected knBrProductService: KnBrProductSelectService,
    protected messageService: GlobalMessageService,
    protected knBrQuoteContextService: KnBrQuoteContextService,
    protected knBrQuoteEntryService: KnBrQuoteEntryService,
    protected knBrCommonService: KnBrCommonService,
    protected knBrQuotesService: KnBrQuotesService
  ) {}
  ngOnInit() {
    this.subscription.add(
      this.knBrCartContextService.get$.subscribe((data) => {
        this.cartContext = data;
        this.cd.markForCheck();
        this.editable = false;
        if (data) {
          this.isEditable();
        } else {
          this.editable = true;
        }
      })
    );
    this.subscription.add(
      this.knBrQuoteContextService.get$.subscribe((data) => {
        this.quoteContext = data;

        this.cd.markForCheck();
        this.editable = false;
        if (data) {
          this.isEditable();
        } else {
          this.editable = true;
        }
      })
    );
    this.subscription.add(
      this.knBrProductService.loadSelectedProducts$().subscribe((selectedProductCodes) => {
        this.selectedProductCodes = selectedProductCodes;
        this.cd.markForCheck();
      })
    );

    if (this.product) {
      this.productCode = this.product.code;
      this.setStockInfo(this.product);
      this.cd.markForCheck();
    } else if (this.productCode) {
      // force hasStock and quantity for the time being, as we do not have more info:
      this.quantity = 1;
      this.hasStock = true;
      this.cd.markForCheck();
    } else {
      this.subscription.add(
        this.currentProductService
          .getProduct()
          .pipe(filter(Boolean))
          .subscribe((product: Product) => {
            this.productCode = product.code;
            this.setStockInfo(product);
            this.cd.markForCheck();
          })
      );
      this.subscription.add(
        this.knBrCommonService
          .loadPDPQuantity()
          .pipe(filter(Boolean))
          .subscribe((quantity: number) => {
            this.quantity = quantity;
            this.cd.markForCheck();
          })
      );
    }
  }
  setStockInfo(product: Product): void {
    this.quantity = 1;
    this.hasStock = product.stock && product.stock.stockLevelStatus !== 'outOfStock';
    if (this.hasStock && product.stock.stockLevel) {
      this.maxQuantity = product.stock.stockLevel;
    }
  }
  updateCount(value: number): void {
    this.quantity = value;
  }
  addToQuote() {
    this.knBrProductService.setSelectedProducts([]);
    if (!this.productCode) {
      return;
    }
    if (!this.quoteContext) {
      this.openModal();
    } else {
      const quoteEntry = {
        quoteId: this.quoteContext,
        code: this.productCode,
        quantity: this.quantity,
      };
      this.knBrQuoteEntryService.add(quoteEntry);
    }
  }
  addMultipleProducts() {
    if (this.selectedProductCodes && this.selectedProductCodes.length) {
      this.productCode = this.selectedProductCodes.toString();
      this.addToQuote();
    } else {
      this.messageService.add({ raw: 'Please select product(s).' }, GlobalMessageType.MSG_TYPE_WARNING);
    }
  }
  openModal() {
    let modalInstance: any;
    this.modalRef = this.modalService.open(KnBrSelectQuoteComponent, {
      centered: true,
      size: 'lg',
    });
    modalInstance = this.modalRef.componentInstance;
    modalInstance.productCode = this.productCode;
    modalInstance.quantity = this.quantity;
    modalInstance.productList = this.productList;
  }
  isEditable() {
    this.editable = false;
    this.subscription.add(
      this.knBrQuotesService.get$.subscribe((quote) => {
        if (quote && Object.keys(quote).length && quote?.code && this.quoteContext) {
          this.quoteCode = quote?.code;
          this.editable = quote.editable;
        }
        this.cd.markForCheck();
      })
    );
  }

  addProductsToQuote() {
    if (!this.quoteContext) {
      this.openModal();
    } else if (this.productList && this.productList.length > 0) {
      const addEntriesReq = { request: this.productList, quoteId: this.quoteCode };
      this.knBrQuoteEntryService.addEntries(addEntriesReq);
    }
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
