<label class="searchbox" [class.dirty]="!!searchInput.value" (click)="checkContext($event)">
  <input
    #searchInput
    [placeholder]="'searchBox.placeholder' | cxTranslate"
    aria-label="search"
    (focus)="open()"
    (click)="open()"
    (input)="search(searchInput.value)"
    (blur)="closeme();close($event)"
    (keydown.escape)="closeme();close($event)"
    (keydown.enter)="
    closeme();close($event, true); launchSearchResult($event, searchInput.value); updateChosenWord(searchInput.value)
    "
    (keydown.arrowup)="focusPreviousChild($event)"
    (keydown.arrowdown)="focusNextChild($event)"
    value="{{ chosenWord }}"
    [disabled]="!customerContext"
  />

  <cx-icon
    [type]="iconTypes.RESET"
    aria-label="reset"
    (mousedown)="clear(searchInput); clearIcon()"
    (keydown.enter)="clear(searchInput); clearIcon()"
    class="reset header-icon-color"
    tabindex="0"
  ></cx-icon>

  <cx-icon
    [type]="iconTypes.SEARCH"
    aria-label="search"
    class="search header-icon-color"
    (mousedown)="avoidReopen($event)"
    (keydown.enter)="avoidReopen($event)"
    tabindex="0"
  ></cx-icon>
</label>

<div *ngIf="isOpen && results$ | async as result" class="results" [ngClass]="{ 'active': isactive }" (click)="close($event, true)">
  <div *ngIf="result.message" class="message" [innerHTML]="result.message"></div>

  <div class="suggestions">
    <a
      *ngFor="let suggestion of result.suggestions"
      [innerHTML]="suggestion | cxHighlight: searchInput.value"
      [routerLink]="
        {
          cxRoute: 'search',
          params: { query: suggestion }
        } | cxUrl
      "
      (keydown.arrowup)="focusPreviousChild($event)"
      (keydown.arrowdown)="focusNextChild($event)"
      (keydown.enter)="close($event, true)"
      (keydown.escape)="close($event, true)"
      (blur)="close($event)"
      (mousedown)="preventDefault($event)"
      (click)="
        dispatchSuggestionEvent({
          freeText: searchInput.value,
          selectedSuggestion: suggestion,
          searchSuggestions: result.suggestions
        });
        updateChosenWord(suggestion)
      "
    >
    </a>
  </div>

  <div class="products" *ngIf="result.products">
    <a
      *ngFor="let product of result.products"
      [routerLink]="
        {
          cxRoute: 'product',
          params: product
        } | cxUrl
      "
      [class.has-media]="config.displayProductImages"
      (keydown.arrowup)="focusPreviousChild($event)"
      (keydown.arrowdown)="focusNextChild($event)"
      (keydown.enter)="close($event, true)"
      (keydown.escape)="close($event, true)"
      (blur)="close($event)"
      (mousedown)="preventDefault($event)"
      (click)="
        dispatchProductEvent({
          freeText: searchInput.value,
          productCode: product.code
        })
      "
    >
      <cx-media
        *ngIf="config.displayProductImages"
        [container]="product.images?.PRIMARY"
        format="thumbnail"
        [alt]="product.summary"
      ></cx-media>
      <h4 class="name" [innerHTML]="product.nameHtml"></h4>
      <span class="price">{{ product.price?.formattedValue }}</span>
    </a>
  </div>
</div>
