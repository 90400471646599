import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  AuthService,
  GlobalMessageService,
  WindowRef,
  AuthStorageService,
  UserIdService,
  StateWithClientAuth,
  AuthActions,
  AuthToken,
  OCC_USER_ID_CURRENT,
} from '@spartacus/core';
import { LoginFormComponent, LoginFormComponentService } from '@spartacus/user/account/components';
import { KnBrAuthRedirectService } from '../services/kn-br-auth-redirect.service';

@Component({
  selector: 'knbr-sso',
  templateUrl: './knbr-sso.component.html',
})
export class KnbrSsoComponent extends LoginFormComponent implements OnInit {
  constructor(
    protected auth: AuthService,
    protected globalMessageService: GlobalMessageService,
    protected fb: FormBuilder,
    protected winRef: WindowRef,
    protected activatedRoute: ActivatedRoute,
    protected knBrAuthRedirectService: KnBrAuthRedirectService,
    protected authStorageService: AuthStorageService,
    protected userIdService: UserIdService,
    protected loginFormComponentService: LoginFormComponentService,
    protected store: Store<StateWithClientAuth>
  ) {
    super(loginFormComponentService);
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (
        params &&
        params.access_token &&
        params.token_type &&
        params.refresh_token &&
        params.expires_in &&
        params.scope
      ) {
        const date = new Date();
        date.setSeconds(date.getSeconds() + +atob(params.expires_in));
        const token = {
          access_token: atob(params.access_token),
          refresh_token: atob(params.refresh_token),
          expires_at: date.toJSON(),
          granted_scopes: new Array(atob(params.scope)),
          access_token_stored_at: '',
          token_type: atob(params.token_type),
        };
        this.authStorageService.setToken(token as AuthToken);

        this.userIdService.setUserId(OCC_USER_ID_CURRENT);
        this.store.dispatch(new AuthActions.Login());
        this.knBrAuthRedirectService.redirect();
      }
    });
  }
}
