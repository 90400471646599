<div class="w-auto float-left p-0 m-res-bottom m-res-bottom" *ngIf="!isDisabled()">
  <button
    type="button"
    class="mt-1 mt-sm-0 btn btn-outline-primary"
    [class.btn-primary]="(isProductSelected$ | async)?.length > 0"
    [class.btn-outline-primary]="(isProductSelected$ | async)?.length == 0"
    (click)="delete()"
    [attr.disabled]="(isProductSelected$ | async)?.length === 0 ? true : null"
  >
    <i class="far fa-trash-alt"></i>
  </button>
</div>
