import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { KnBrQuotesCommentsEffects } from './kn-br-quotes-comments.effects';
import { knBrQuotesCommentsReducer } from './kn-br-quotes-comments.reducer';
import { KN_BR_COMMENT_QUOTES_FEATURE } from './kn-br-quotes-comments.state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(KN_BR_COMMENT_QUOTES_FEATURE, knBrQuotesCommentsReducer),
    EffectsModule.forFeature([KnBrQuotesCommentsEffects]),
  ],
})
export class KnBrQuotesCommentsStateModule {}
