import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { RoutingService, TranslationService } from '@spartacus/core';
import { KnBrReturnOrderSearchResultComponent } from '../../kn-br-return-order-list/kn-br-return-order-search-result/kn-br-return-order-search-result.component';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import { KnBrOrderReturnService } from 'src/app/store/kn-br-order-return/kn-br-order-return.service';
import { Observable } from 'rxjs/internal/Observable';
import { CustomerData } from 'src/app/models/customer.model';
import { KnBrDateHelper } from 'src/app/shared/kn-br-date.helper';
import { WIDGET_PAGESIZE } from 'src/constants/pagination.constant';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';

@Component({
  selector: 'knbr-kn-br-quick-return-order',
  templateUrl: './kn-br-quick-return-order.component.html',
  styleUrls: ['./kn-br-quick-return-order.component.scss'],
})
export class KnBrQuickReturnOrderComponent extends KnBrReturnOrderSearchResultComponent implements OnInit, OnDestroy {
  returnOrdersSearchForm: UntypedFormGroup;
  customerContextData$: Observable<CustomerData> = this.knBrCustomerContextService.get$;
  isSearchDisabled$: Observable<boolean> = this.knBrOrderReturnService.isSearchDisabled$;
  currentPage = 0;
  todayDate = () => {
    const today = new Date();
    return ({ year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate() });
  };
  maxStartDate = () => this.todayDate();
  maxEndDate = () => this.todayDate();
  constructor(
    public knBrOrderReturnService: KnBrOrderReturnService,
    protected routeService: RoutingService,
    protected knBrCustomerContextService: KnBrCustomerContextService,
    protected translation: TranslationService,
    private fb: UntypedFormBuilder,
    private knBrDateHelper: KnBrDateHelper,
    protected knBrCommonService: KnBrCommonService
  ) {
    super(knBrOrderReturnService, routeService, knBrCustomerContextService, translation, knBrCommonService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.customerContextData$.subscribe((value) => {
      this.initForm(value);
    });
    this.submitForm();
  }

  submitForm() {
    const formValues = this.returnOrdersSearchForm.getRawValue();
    if (this.returnOrdersSearchForm.controls.startDate.value) {
      formValues.startDate = this.knBrDateHelper.formatDate(this.returnOrdersSearchForm.controls.startDate.value);
    }
    if (this.returnOrdersSearchForm.controls.endDate.value) {
      formValues.endDate = this.knBrDateHelper.formatDate(this.returnOrdersSearchForm.controls.endDate.value);
    }

    this.knBrOrderReturnService.returnOrderListSearch(formValues);
  }

  initForm(customerData) {
    this.returnOrdersSearchForm = this.fb.group({
      accountNumber: customerData ? customerData.customerNumber : null,
      accountName: customerData ? customerData.customerName : null,
      startDate: null,
      endDate: null,
      currentPage: this.currentPage,
      pageSize: WIDGET_PAGESIZE,
      isWidget: true,
    });
  }

  pageChange(page: number): void {
    if (this.searchCriteria) {
      this.searchCriteria.currentPage = page;
    }
    this.knBrOrderReturnService.returnOrderListSearch(this.searchCriteria);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
