import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { ListNavigationModule, SpinnerModule } from '@spartacus/storefront';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { KnBrInvoiceListSeachResultComponent } from './kn-br-invoice-list-seach-result.component';
import { KnBrCustomPipesModule } from 'src/app/shared/pipe/kn-br-custom-pipes/kn-br-custom-pipes.module';
import { KnBrBrowsingModule } from 'src/app/common/kn-br-browsing/kn-br-browsing.module';

@NgModule({
  declarations: [KnBrInvoiceListSeachResultComponent],
  imports: [
    CommonModule,
    SpinnerModule,
    RouterModule,
    NgSelectModule,
    ListNavigationModule,
    I18nModule,
    KnBrBrowsingModule,
    KnBrCustomPipesModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrInvoiceSearchResultComponent: {
          component: KnBrInvoiceListSeachResultComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class KnBrInvoiceListSearchResultModule {}
