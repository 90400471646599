<div class="col-lg-3 pr-0 float-left mb-3 p-res-0 product-res-grid" (click)="goToDetail($event)">
  <div class="col-lg-12 border-top border-left border-right border-bottom p-2 float-left cursor-pointer block-height">

    <div class="col-lg-12 p-0 d-flex product-image justify-content-center mb-3">
      <cx-media [container]="product?.images?.PRIMARY" [format]="'thumbnail'" [alt]="product?.summary"></cx-media>
    </div>
    <div class="col-lg-12 p-0 d-flex product-name mb-2 justify-content-center">
     <div [innerHTML]="getSVGImage(salesOrg.imgUrl)"  class="brand-img"></div>
    </div>

    <div class="col-lg-12 mt-3 product-name mb-2 p-0 text-align-center">
      <span class="color-font font-size font-weight700"> {{product?.name}} </span><br>
      <span class="color-font font-size">{{ 'productList.label.id' | cxTranslate }}: {{ product?.code }}</span><br>
      <span class="color-font font-size">{{ 'productList.label.moq' | cxTranslate }}: {{ product?.moq }}</span><br>
      <span class="color-font font-size">{{ 'productList.label.listPrice' | cxTranslate }}: {{ (product?.callForPricing | lowercase) === 'yes' ? product?.pricingText : product?.price?.formattedValue }}</span><br>
      <span class="color-font font-size">{{ 'productList.label.netPrice' | cxTranslate }}: {{ (product?.callForPricing | lowercase) === 'yes' ? product?.pricingText : product?.price?.formatedNetPrice }}</span><br>
      <span class="color-font font-size">{{ 'productList.label.brand' | cxTranslate }}: {{salesOrg?.name}}</span>
    </div>
  </div>
</div>
