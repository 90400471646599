import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Country, OccSiteAdapter, Region } from '@spartacus/core';
import { Observable } from 'rxjs';

import { KnBrCustomerService } from '../../../store/kn-br-customer/kn-br-customer.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'knbr-customer-header',
  templateUrl: './kn-br-customer-header.component.html',
  styleUrls: ['./kn-br-customer-header.component.scss'],
  providers: [OccSiteAdapter],
})
export class KnBrCustomerHeaderComponent implements OnInit {
  countries$: Observable<Country[]>;
  isCountryLoading = false;
  regions$: Observable<Region[]>;
  isRegionLoading = false;
  customerSearchForm: UntypedFormGroup;
  isSearchDisabled$: Observable<boolean> = this.customerSearchService.searchDisabled$;
  countryList: any[];
  stateList: any[];
  objectKeys = Object.keys;

  constructor(
    private occSiteAdapter: OccSiteAdapter,
    private fb: UntypedFormBuilder,
    private customerSearchService: KnBrCustomerService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.resetForm();
    this.getCountries();
  }

  initForm() {
    this.customerSearchForm = this.fb.group({
      customerName: null,
      customerNumber: null,
      countryCode: null,
      stateCode: null,
      city: null,
      postalCode: null,
    });
  }

  getCountries() {
    this.isCountryLoading = true;
    this.occSiteAdapter.loadCountries().subscribe(res => {
      this.countryList = res.filter(item => item.isocode === 'US' || item.isocode === 'CA');
      this.isCountryLoading = false;
    })
  }

  countrySelected(country: Country) {
    if (country && country.isocode) {
      this.customerSearchForm.patchValue({
        stateCode: null,
      });
      this.occSiteAdapter.loadRegions(country.isocode).pipe(tap(() => (this.isRegionLoading = false))).subscribe(res => {
        if (res) {
          if (country && country.isocode === 'US') {
            this.stateList = res.filter(item => item.isocode.startsWith('US-'));
          } else {
            this.stateList = res.filter(item => item.isocode.startsWith('CA-'));
          }
        }
      })
    }
  }

  submitForm() {
    this.customerSearchService.search(this.customerSearchForm.value);
  }

  resetForm() {
    this.customerSearchForm.reset();
    this.customerSearchService.reset();
    this.submitForm();
  }
}
