import { Action } from '@ngrx/store';

import { CustomerData, CustomerSearchForm } from './../../models/customer.model';

export enum KnBrCustomerActionTypes {
  CustomerSearchAction = '[Customer] Search',
  CustomerSearchSuccessAction = '[Customer] Search Success',
  CustomerSearchFailureAction = '[Customer] Search Failure',
  CustomerSearchResetAction = '[Customer] Search Reset',
}

/**
 * Action to Search Customer
 */
export class CustomerSearch implements Action {
  readonly type = KnBrCustomerActionTypes.CustomerSearchAction;
  constructor(public payload: CustomerSearchForm) {}
}

/**
 * Action dispatched on successful Customer Serach
 */
export class CustomerSearchSuccess implements Action {
  readonly type = KnBrCustomerActionTypes.CustomerSearchSuccessAction;
  constructor(public payload: CustomerData[]) {}
}

/**
 * Action dispatched on successful Customer Serach
 */
export class CustomerSearchReset implements Action {
  readonly type = KnBrCustomerActionTypes.CustomerSearchResetAction;
  constructor(public payload: CustomerData[]) {}
}
/**
 * Action dispatched on failure Customer Serach
 */
export class CustomerSearchFailure implements Action {
  readonly type = KnBrCustomerActionTypes.CustomerSearchFailureAction;
  constructor(public payload: Error) {}
}

export type CustomerSearchActions =
  | CustomerSearch
  | CustomerSearchSuccess
  | CustomerSearchFailure
  | CustomerSearchReset;
