import { KnBrAcknowledgeModule } from './kn-br-acknowledge/kn-br-acknowledge.module';
import { KnBrReturnOrderModule } from './kn-br-return-order/kn-br-return-order.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { knBrCustomer360EndpointsConfig } from './kn-br-customer360-endpoints.config';
import { ADDRESS_NORMALIZER, ADDRESS_SERIALIZER, ConfigModule } from '@spartacus/core';
import { ORDER_NORMALIZER, ORDER_RETURN_REQUEST_NORMALIZER } from '@spartacus/order/root';
import { KnBrOrderHistoryModule } from './kn-br-order-history/kn-br-order-history.module';
import { KnBrOrderDetailModule } from './kn-br-order-detail/kn-br-order-detail.module';
import { KnBrOrderNormalizer } from '../converter/kn-br-order.converter';
import { KnBrReturnOrderListModule } from './kn-br-return-order-list/kn-br-return-order-list.module';
import { KnBrInvoiceListModule } from './kn-br-invoice-list/kn-br-invoice-list.module';
import { KnBrReturnOrderDetailModule } from './kn-br-return-order-detail/kn-br-return-order-detail.module';
import { KnBrReturnOrderNormalizer } from '../converter/kn-br-return-order.converter';
import { KnBrAddressNormalizer } from '../converter/kn-br-address.converter';
import { KnBrAddressSerializer } from '../serializer/kn-br-address.serializer';
import { KnBrAddressBookModule } from './kn-br-address-book/kn-br-address-book.module';
import { KnBrFactSheetModule } from './kn-br-fact-sheet/kn-br-fact-sheet.module';
import { KnBrCustomer360HomeModule } from './kn-br-customer360-home/kn-br-customer360-home.module';
import { KnBrCommomPopUpModule } from './kn-br-common-pop-up/kn-br-commom-pop-up.module';
import { KnBrInvoiceListDetailModule } from './kn-br-invoice-list-detail/kn-br-invoice-list-detail.module';

@NgModule({
  imports: [
    CommonModule,
    KnBrOrderHistoryModule,
    KnBrOrderDetailModule,
    KnBrReturnOrderModule,
    KnBrAcknowledgeModule,
    KnBrInvoiceListModule,
    KnBrReturnOrderListModule,
    KnBrReturnOrderDetailModule,
    KnBrAddressBookModule,
    KnBrFactSheetModule,
    KnBrCustomer360HomeModule,
    KnBrCommomPopUpModule,
    KnBrInvoiceListDetailModule,
    ConfigModule.withConfig(knBrCustomer360EndpointsConfig),
  ],
  providers: [
    {
      provide: ORDER_NORMALIZER,
      useClass: KnBrOrderNormalizer,
      multi: true,
    },
    {
      provide: ORDER_RETURN_REQUEST_NORMALIZER,
      useClass: KnBrReturnOrderNormalizer,
      multi: true,
    },
    {
      provide: ADDRESS_NORMALIZER,
      useClass: KnBrAddressNormalizer,
      multi: true,
    },
    {
      provide: ADDRESS_SERIALIZER,
      useClass: KnBrAddressSerializer,
      multi: true,
    },
  ],
  declarations: [],
})
export class KnBrCustomer360Module {}
