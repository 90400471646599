import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { I18nModule } from '@spartacus/core';
import { CardModule } from '@spartacus/storefront';
import { KnBrOrderOverviewComponent } from './kn-br-order-overview.component';

@NgModule({
  imports: [CommonModule, I18nModule, CardModule],
  declarations: [KnBrOrderOverviewComponent],
  exports: [KnBrOrderOverviewComponent],
})
export class KnBrOrderOverviewModule {}
