import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, ConfigModule } from '@spartacus/core';
import { KnBrCartSharedModule } from 'src/app/kn-br-cart-shared/kn-br-cart-shared.module';
import { KnBrCheckoutOrderSummaryComponent } from './kn-br-checkout-order-summary.component';

@NgModule({
    imports: [
        CommonModule,
        KnBrCartSharedModule,
        ConfigModule.withConfig({
            cmsComponents: {
                CheckoutOrderSummary: {
                    component: KnBrCheckoutOrderSummaryComponent,
                },
            },
        } as CmsConfig),
    ],
    declarations: [KnBrCheckoutOrderSummaryComponent],
    exports: [KnBrCheckoutOrderSummaryComponent]
})
export class KnBrCheckoutOrderSummaryModule {}
