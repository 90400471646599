import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ResetPasswordComponent, ResetPasswordComponentService } from '@spartacus/user/profile/components';

@Component({
  selector: 'knbr-kn-br-reset-password',
  templateUrl: './kn-br-reset-password.component.html',
  styleUrls: ['./kn-br-reset-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnBrResetPasswordComponent extends ResetPasswordComponent {
  constructor(protected service: ResetPasswordComponentService) {
    super(service);
  }
}
