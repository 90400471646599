import { KnBrAddRequestEntry } from './../../models/kn-br-order.model';
import { KnBrQuoteEntry } from '../../models/kn-br-quote.model';

export const KN_BR_QUOTE_ENTRY_FEATURE = 'kn-br-quote-entry';

export interface KnBrQuoteEntryState {
  entry?: KnBrQuoteEntry;
  error?: Error;
  isSaveDisabled?: boolean;
  entries?: KnBrAddRequestEntry[];
  showAddQuoteEntryLoading?: boolean;
  isLoading?: boolean;
}

export interface StateWithQuoteEntry {
  [KN_BR_QUOTE_ENTRY_FEATURE]: KnBrQuoteEntryState;
}
