// import { KnBrOrderHistoryActions, KnBrOrderHistoryActionTypes } from './kn-br-order-history.action';
// import { KnBrOrderHistoryState } from './kn-br-order-history.state';

import { KnBrInvoiceActions, KnBrInvoiceActionTypes } from './kn-br-invoice.action';
import { KnBrInvoiceState } from './kn-br-invoice.state';

export const initialState: KnBrInvoiceState = {
  b2bDocuments: [],
  error: undefined,
  searchRequest: undefined,
  isLoading: false,
  pagination: undefined,
  sorts: undefined,
  isSearchDisabled: false,
  accountSummary: undefined,
  duesAmount: []
};

export function knBrInvoiceReducer(state = initialState, action: KnBrInvoiceActions): KnBrInvoiceState {
  switch (action.type) {
    case KnBrInvoiceActionTypes.KnBrInvoiceDuesAmountLoadAction:
      return { ...state, isLoading: true };
    case KnBrInvoiceActionTypes.KnBrInvoiceDuesAmountSuccessAction:
        return { ...state, isLoading: false, duesAmount: [...action.payload] };
    case KnBrInvoiceActionTypes.KnBrInvoiceSearchAction:
      return { ...state, searchRequest: action.payload, isLoading: true, isSearchDisabled: true };
    case KnBrInvoiceActionTypes.KnBrInvoiceSearchSuccessAction:
      return {
        ...state,
        b2bDocuments: [...state.b2bDocuments, ...action.payload?.b2bDocuments],
        pagination: action.payload?.pagination,
        sorts: action.payload?.sorts,
        isLoading: false,
        isSearchDisabled: false,
      };
    case KnBrInvoiceActionTypes.KnBrInvoiceQuickSearchSuccessAction:
      return {
        ...state,
        b2bDocuments: action.payload?.b2bDocuments,
        pagination: action.payload?.pagination,
        sorts: action.payload?.sorts,
        isLoading: false,
        isSearchDisabled: false,
      };
    case KnBrInvoiceActionTypes.KnBrInvoiceSearchFailureAction:
      return { ...state, error: action.payload, isLoading: false, isSearchDisabled: false };
    case KnBrInvoiceActionTypes.KnBrInvoiceSearchResetAction:
      return { ...state, b2bDocuments: [], pagination: null, sorts: null, isLoading: true };
    case KnBrInvoiceActionTypes.KnBrInvoiceAccountSummarySuccessAction:
      return { ...state, accountSummary: action.payload };
    default:
      return state;
  }
}
