import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { GenericLinkModule, IconModule, NavigationModule } from '@spartacus/storefront';

import { KnBrCategoryNavigationComponent } from './kn-br-category-navigation.component';
import { KnBrResponsiveCategoryNavigationComponent } from './kn-br-responsive-category-navigation/kn-br-responsive-category-navigation.component';

@NgModule({
    declarations: [KnBrCategoryNavigationComponent, KnBrResponsiveCategoryNavigationComponent],
    imports: [
        CommonModule,
        RouterModule,
        IconModule,
        GenericLinkModule,
        I18nModule,
        NavigationModule,
        UrlModule,
        ConfigModule.withConfig({
            cmsComponents: {
                CategoryNavigationComponent: {
                    component: KnBrCategoryNavigationComponent,
                },
            },
        } as CmsConfig),
    ],
    exports: [KnBrCategoryNavigationComponent, KnBrResponsiveCategoryNavigationComponent]
})
export class KnBrCategoryNavigationModule {}
