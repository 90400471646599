import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { RoutingService } from '@spartacus/core';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import { Subscription } from 'rxjs';
import { includes } from 'lodash';

@Component({
  selector: 'knbr-kn-br-invoice-list-detail-overview',
  templateUrl: './kn-br-invoice-list-detail-overview.component.html',
  styleUrls: ['./kn-br-invoice-list-detail-overview.component.scss']
})
export class KnBrInvoiceListDetailOverviewComponent implements OnInit, OnDestroy {
  invoiceData;
  termsOfPayment = [];
  invoiceName: string;
  private customerNumber: string;
  subscription = new Subscription();
  showRemittanceAddress = false;

  constructor(
    protected cdr: ChangeDetectorRef,
    protected routingService: RoutingService,
    public knBrCommonService: KnBrCommonService,
    protected knBrCustomerContextService: KnBrCustomerContextService,
  ) {
    this.knBrCustomerContextService.getActiveCustomerNumber().subscribe((response) => {
      this.customerNumber = response ? response : null;
    });
  }

  ngOnInit() {
    this.routingService
      .getRouterState()
      .subscribe((state) => {
        if (state && state.state && state.state.queryParams && state.state.queryParams) {
          this.invoiceName = state.state.queryParams.invoiceName;
          const invoiceNumber = state.state.queryParams.invoiceNumber;
          this.knBrCommonService.getInvoiceDetails(this.customerNumber, invoiceNumber);
        }

        const docTypeCodes = ['F2', 'ZF2', 'L2', 'S2'];
        if (includes(docTypeCodes, state.state.queryParams.documentTypeCode)) {
          this.showRemittanceAddress = true;
          sessionStorage.setItem('showRemittanceAddress', 'true');
        } else {
          this.showRemittanceAddress = false;
          sessionStorage.setItem('showRemittanceAddress', 'false');
        }
      })
      .unsubscribe();

    this.subscription.add(
      this.knBrCommonService.invoiceData$.subscribe((res) => {
        if (res) {
          this.invoiceData = res;
          this.cdr.detectChanges();
        }
      })
    )
  }

  formatDescription(data) {
    if (data !== undefined) {
      return this.termsOfPayment = data?.split('|');
    }
  }

  goBack() {
    this.routingService.go({ cxRoute: 'invoiceList' }, { state: { displayPersistedValues: true }});
  }

  title(invoiceName) {
    if (invoiceName === 'Invoice') {
      return invoiceName + ' Details'
    } else {
      return invoiceName;
    }
  }

  toFixed(value) {
    if (value !== null) {
      return this.knBrCommonService.toFixedAndThousandSeparator(value);
    }
  }

  separator(value) {
    if (value != "null") {
      return this.knBrCommonService.separator(value);
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.knBrCommonService.invoiceData$.next(null)
    }
  }
}
