import { KnBrCartContextService } from './../kn-br-cart-context/kn-br-cart-context.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OccEndpointsService } from '@spartacus/core';

import { KnBrDraftOrderComments } from '../../models/kn-br-draft-order.model';
import { KnBrCustomerContextService } from '../kn-br-customer-context/kn-br-customer-context.service';
import { KnBrQuoteContextService } from '../kn-br-quote-context/kn-br-quote-context.service';
import {
  KN_BR_DRAFT_ORDER_GET_ADD_COMMENTS,
  KN_BR_DRAFT_ORDER_UPDATE_DELETE_COMMENTS,
} from 'src/constants/api.endpoints.constant';

@Injectable({
  providedIn: 'root',
})
export class KnBrDraftOrdersCommentsAdapter {
  private customerNumber: string;
  cartId: string;
  constructor(
    private endPointService: OccEndpointsService,
    private httpClient: HttpClient,
    protected knBrCustomerContextService: KnBrCustomerContextService,
    protected knBrCartContextService: KnBrCartContextService
  ) {
    this.knBrCustomerContextService.getActiveCustomerNumber().subscribe((response) => {
      this.customerNumber = response ? response : null;
    });
    this.knBrCartContextService.get$.subscribe((response) => {
      this.cartId = response ? response : null;
    });
  }

  get(entryNumber: number) {
    return this.httpClient.get(
      this.endPointService.buildUrl(KN_BR_DRAFT_ORDER_GET_ADD_COMMENTS, {
        urlParams: {
          customerId: this.customerNumber,
          cartId: this.cartId,
          entryNumber,
        },
      })
    );
  }

  create(payload) {
    return this.httpClient.post(
      this.endPointService.buildUrl(
        KN_BR_DRAFT_ORDER_GET_ADD_COMMENTS,
        {
          urlParams: {
            customerId: this.customerNumber,
            cartId: this.cartId,
            entryNumber: payload.entryNumber,
          },
        },
        payload
      ),
      null
    );
  }

  update(payload) {
    return this.httpClient.patch(
      this.endPointService.buildUrl(
        KN_BR_DRAFT_ORDER_UPDATE_DELETE_COMMENTS,
        {
          urlParams: {
            customerId: this.customerNumber,
            cartId: this.cartId,
            entryNumber: payload.entryNumber,
            commentId: payload.code,
          },
        },
        payload
      ),
      null
    );
  }
  delete(payload) {
    return this.httpClient.delete(
      this.endPointService.buildUrl(KN_BR_DRAFT_ORDER_UPDATE_DELETE_COMMENTS, {
        urlParams: {
          customerId: this.customerNumber,
          cartId: this.cartId,
          entryNumber: payload.entryNumber,
          commentId: payload.code,
        },
      })
    );
  }
}
