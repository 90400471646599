import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KnBrPlaceOrderComponent } from './kn-br-place-order.component';
import { RouterModule } from '@angular/router';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        UrlModule,
        I18nModule,
        ReactiveFormsModule,
        ConfigModule.withConfig({
            cmsComponents: {
                CheckoutPlaceOrder: {
                    component: KnBrPlaceOrderComponent,
                },
            },
        } as CmsConfig),
    ],
    declarations: [KnBrPlaceOrderComponent],
    exports: [KnBrPlaceOrderComponent]
})
export class KnBrPlaceOrderModule {}
