import { FormErrorsModule } from '@spartacus/storefront';
import { I18nModule, ConfigModule } from '@spartacus/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KnBrAsmMainUiComponent } from './kn-br-asm-main-ui.component';
import { KnBrAsmToggleUiComponent } from './kn-br-asm-toggle-ui/kn-br-asm-toggle-ui.component';
import { KnBrCSAgentLoginFormComponent } from './kn-br-csagent-login-form/kn-br-csagent-login-form.component';
import { KnBrCustomerEmulationComponent } from './kn-br-customer-emulation/kn-br-customer-emulation.component';
import { KnBrCustomerSelectionComponent } from './kn-br-customer-selection/kn-br-customer-selection.component';
import { FormatTimerPipe } from './kn-br-asm-session-timer/kn-br-format-timer.pipe';
import { KnBrAsmSessionTimerComponent } from './kn-br-asm-session-timer/kn-br-asm-session-timer.component';
import { knBrDefaultAsmLayoutConfig } from './kn-br-asm-layout-config';

@NgModule({
    declarations: [
        KnBrAsmMainUiComponent,
        KnBrCSAgentLoginFormComponent,
        KnBrCustomerSelectionComponent,
        FormatTimerPipe,
        KnBrCustomerEmulationComponent,
        KnBrAsmToggleUiComponent,
        KnBrAsmSessionTimerComponent,
    ],
    imports: [CommonModule, ReactiveFormsModule, I18nModule, FormErrorsModule]
})
export class KnBrAsmModule {}
