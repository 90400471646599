<ng-container>
  <div class="col-lg-12 float-left p-3 border border-bottom-0 bg-light-gray">
    <div class="w-100">
      <div class="w-100 customersearch-title">
        {{ 'orderHistorySearchForm.label.quickOrderLookup' | cxTranslate }}
      </div>
    </div>
  </div>
  <div class="col-lg-12 float-left p-3 border">
    <form class="customersearch-forms login-forms p-0 w-100" [formGroup]="orderHistorySearchForm"
      (ngSubmit)="submitForm()">
      <div class="col-12 p-0 float-left">
        <div class="form-group mt-3 col-6 pl-0 float-left mb-3 p-res-0">
          <input type="text" class="form-control w-100"
            [placeholder]="'orderHistorySearchForm.label.salesOrderNumber' | cxTranslate"
            formControlName="salesOrderNumber" />
          <span class="highlight"></span>
          <label class="text-muted">{{ 'orderHistorySearchForm.label.salesOrderNumber' | cxTranslate }}</label>
        </div>

        <div class="form-group mt-3 col-6 pl-0 float-left mb-3 p-res-0">
          <input type="text" class="form-control w-100"
            [placeholder]="'orderHistorySearchForm.label.purchaseOrderNumber' | cxTranslate"
            formControlName="purchaseOrderNumber" />
          <span class="highlight"></span>
          <label class="text-muted">{{ 'orderHistorySearchForm.label.purchaseOrderNumber' | cxTranslate }}</label>
        </div>
      </div>

      <div class="col-12 p-0 float-left">
        <div class="col-6 pl-0 form-group float-left valid-to mb-3">
          <div class="input-group">
            <input class="form-control w-100 dateControls"
              [placeholder]="'invoiceSearchForm.label.fromFirstDeliveryDate' | cxTranslate" name="validFrom"
              ngbDatepicker #validFrom="ngbDatepicker" formControlName="startDate"  [maxDate]="maxStartDate()" [readonly]="true"/>
            <span class="highlight"></span>
            <label class="text-muted">{{ 'orderHistorySearchForm.label.fromFirstDeliveryDate' | cxTranslate }}</label>
            <div class="input-group-append">
              <button class="btn btn calendar p-0 h-auto w-auto" container="body" (click)="validFrom.toggle()"
                type="button">
                <i class="far fa-calendar-alt color-font"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="col-6 pl-0 form-group float-left valid-to mb-3">
          <div class="input-group">
            <input class="form-control w-100 dateControls"
              [placeholder]="'invoiceSearchForm.label.toFirstDeliveryDate' | cxTranslate" name="validTo"
              [minDate]="orderHistorySearchForm.get('startDate').value" ngbDatepicker #validTo="ngbDatepicker"
              formControlName="endDate" [maxDate]="maxEndDate()" [readonly]="true"/>
            <span class="highlight"></span>
            <label class="text-muted">{{ 'orderHistorySearchForm.label.toFirstDeliveryDate' | cxTranslate }}</label>
            <div class="input-group-append">
              <button class="btn btn calendar p-0 h-auto w-auto" container="body" (click)="validTo.toggle()"
                type="button">
                <i class="far fa-calendar-alt color-font"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 p-0 float-left">
        <div class="form-group mt-3 col-6 pl-0 float-left mb-3 p-res-0">
          <input type="text" class="form-control w-100"
            [placeholder]="'orderHistorySearchForm.label.jobName' | cxTranslate" formControlName="jobName" />
          <span class="highlight"></span>
          <label class="text-muted">{{ 'orderHistorySearchForm.label.jobName' | cxTranslate }}</label>
        </div>
      </div>

      <div class="col-12 pl-0 float-left">
        <button type="submit" [disabled]="isSearchDisabled$ | async" class="btn btn-primary col-4">
          {{ 'button.search' | cxTranslate }}
        </button>
      </div>
    </form>
    <div class="col-lg-12 customer-search bg-white p-0 float-left"
      *ngIf="orderHistoryResult$ | async as orderHistoryResult">
      <div class="customersearch-list-table mt-3 float-left col-lg-12 p-0"
        *ngIf="orderHistoryResult && orderHistoryResult.length && !(isLoader$ | async)">
        <div class="customersearch-list-titles border-top border-bottom float-left w-100 px-2 py-3 d-none d-sm-none d-lg-block pr-4">
          <div class="col-md-2 padding-res-0 float-left font-text-600 pl-0 font-sm-size">
            {{ 'orderHistoryResult.widgetHeader.orderNumber' | cxTranslate }}
          </div>
          <div class="col-md-2 padding-res-0 float-left font-text-600 pl-2 font-sm-size">
            {{ 'orderHistoryResult.widgetHeader.jobName' | cxTranslate }}
          </div>
          <div class="col-md-2 float-left font-text-600 pl-0 font-sm-size">
            {{ 'orderHistoryResult.widgetHeader.purchaseOrderNumber' | cxTranslate }}
          </div>
          <div class="col-md-3 float-left font-text-600 pl-0 font-sm-size">
            {{ 'orderHistoryResult.header.customer' | cxTranslate }}
          </div>
          <div class="col-md-2 float-left font-text-600 pl-0 font-sm-size">
            {{ 'orderHistoryResult.widgetHeader.firstDeliveryDate ' | cxTranslate }}
          </div>
          <div class="col-md-1 float-left font-text-600 pl-2 font-sm-size">
            {{ 'orderHistoryResult.header.status' | cxTranslate }}
          </div>
        </div>
        <div class="bg-white customersearch-list-titles table-content border-bottom float-left w-100 p-2 mr-4 font-weight-normal d-flex align-items-center pr-4"
          *ngFor="let order of orderHistoryResult" (click)=" order.deliveryBlock !=='Processing' && redirectToDetail(order)" [ngClass]="{'cursor-not-allowed': order.deliveryBlock ==='Processing'}">
          <div class="col-md-2 text-align-right title-wrap pl-0 font-sm-size color-font">
            <span class="res-titles"> {{ 'orderHistoryResult.widgetHeader.orderNumber' | cxTranslate }}:</span>
            {{ order.sapOrderNumber ? (order.sapOrderNumber | removeLeadingZeros) : (order.code | removeLeadingZeros) }}
          </div>
          <div class="col-md-2 float-left title-wrap pl-2 font-sm-size color-font">
            <span class="res-titles"> {{ 'orderHistoryResult.widgetHeader.jobName' | cxTranslate }}:</span>
            {{ order.jobName ? order.jobName : '-' }}
          </div>
          <div class="col-md-2 float-left title-wrap pl-0 font-sm-size color-font">
            <span class="res-titles">{{ 'orderHistoryResult.widgetHeader.purchaseOrderNumber' | cxTranslate }}:</span>{{
            order.purchaseOrderNumber }}
          </div>

          <div class="col-md-3 float-left title-wrap pl-0 font-sm-size color-font">
            <span class="res-titles"> {{ 'orderHistoryResult.header.customer' | cxTranslate }}:</span>
            {{ order.accountNumber | removeLeadingZeros }} - {{ order.accountName }}
          </div>
          <div class="col-md-2 float-left title-wrap pl-0 font-sm-size color-font">
            <span class="res-titles"> {{ 'orderHistoryResult.widgetHeader.firstDeliveryDate' | cxTranslate}}</span>
            {{ order.requestedDeliveryDate }}
          </div>
          <div class="col-md-1 float-left title-wrap pl-2 font-sm-size color-font">
            <span class="res-titles"> {{ 'orderHistoryResult.header.status' | cxTranslate }}:</span>
            {{ order.statusDisplay }}
          </div>
        </div>
      </div>
      <div class="alert-info p-3 rounded mt-3"
        *ngIf="orderHistoryResult && orderHistoryResult.length < 1 && !(isLoader$ | async)">
        {{ 'orderHistoryResult.nodataMsg' | cxTranslate }}
      </div>
      <ng-container *ngIf="paginate$ | async as pagination">
        <div *ngIf="pagination.totalPages > 1" class="col-lg-12 p-0 float-left mt-3">
          <cx-pagination [pagination]="pagination" (viewPageEvent)="pageChange($event)"></cx-pagination>
        </div>
      </ng-container>

      <div class="col-lg-12 p-0 float-left mt-4"
        *ngIf="orderHistoryResult && orderHistoryResult.length && !(isLoader$ | async)">
        <a [routerLink]="{ cxRoute: 'orders' } | cxUrl">{{ 'orderHistoryResult.detailedSearch' | cxTranslate }}</a>
      </div>

      <div *ngIf="(isLoader$ | async) || !(orderHistoryResult$ | async)">
        <cx-spinner></cx-spinner>
      </div>
    </div>
  </div>
</ng-container>
