import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KnBrLogoutComponent } from './kn-br-logout.component';
import { Routes } from '@angular/router';


const staticRoutes: Routes = [{
  path: '/logout',
  component: KnBrLogoutComponent
}];

@NgModule({
  declarations: [KnBrLogoutComponent],
  imports: [CommonModule],
  exports: [KnBrLogoutComponent]
})

export class KnBrLogoutModule { }
