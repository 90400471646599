import { KnBrOrderReturnModule } from './kn-br-order-return/kn-br-order-return.module';
import { ConfigModule, RoutingConfig } from '@spartacus/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    KnBrOrderReturnModule,
    ConfigModule.withConfig({
      routing: {
        routes: {
          orderReturn: {
            paths: ['customer-360/order/return/:orderCode/:returnType'],
            paramsMapping: { orderCode: 'code', returnType: 'returnType' },
          },
        },
      },
    } as RoutingConfig),
  ],
})
export class KnBrReturnOrderModule {}
