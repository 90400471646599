<cx-carousel class="carousel-title" [items]="items$ | async" [title]="title$ | async" [template]="carouselItem" itemWidth="285px">
</cx-carousel>

<ng-template #carouselItem let-item="item">
  <a tabindex="0" [routerLink]="{ cxRoute: 'product', params: item } | cxUrl" class="kn-br-product-carousel">
    <cx-media [container]="item.images?.PRIMARY"></cx-media>
    <div class="col-lg-12 p-0 d-flex product-name mb-2 justify-content-center">
      <div [innerHTML]="getSVGImage(item.salesOrg)"  class="brand-img"></div>
     </div>
    <div class="mt-3">
      <p class="mb-1 font-size-17 font-text-600">{{ item.code }}</p>
      <p class="mb-1 text-muted item-details-height">
        {{ item.name }}
        <span class="code-number">
          {{ 'knbrOrderDetails.productCarousel.codeNumber' | cxTranslate }}: {{item.code}}
        </span>

        <span class="color-font font-size"
        >{{ 'productList.label.brand' | cxTranslate }}:
        {{getSalesOrg(item.salesOrg, 'name')}}</span>
      </p>
    </div>
  </a>
</ng-template>
