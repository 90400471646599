import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CmsNavigationComponent, OccConfig } from '@spartacus/core';
import { CmsComponentData, NavigationNode } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { KnBrNavigationService } from './kn-br-navigation.service';

@Component({
  selector: 'kn-br-footer',
  templateUrl: './kn-br-footer.component.html',
  styleUrls: ['./kn-br-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnBrFooterComponent implements OnInit {
  componentData$: Observable<CmsNavigationComponent> = this.componentData.data$;
  node$: Observable<NavigationNode> = this.service.getNavigationNode(this.componentData.data$);
  styleClass$: Observable<string> = this.componentData.data$.pipe(map((d) => d?.styleClass));
  constructor(
    protected componentData: CmsComponentData<CmsNavigationComponent>,
    protected service: KnBrNavigationService,
    private config?: OccConfig
  ) {}
  ngOnInit(): void {}
  getUrl(node: any) {
    return node.title === 'Terms and Conditions of Sales' ? window.location.origin + node.url : node.url;
  }
}
