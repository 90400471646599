import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  PRODUCT_SEARCH_PAGE_NORMALIZER,
  ProductAdapter,
  ProductReferencesAdapter,
  ProductReviewsAdapter,
  ConfigModule,
  RoutingConfig,
  ProductSearchAdapter,
  PRODUCT_NORMALIZER,
} from '@spartacus/core';

import { KnBrOccProductReviewsAdapter } from '../services/kn-br-occ-product-reviews.adapter';
import { KnBrOccProductAdapter } from '../services/kn-br-occ-product.adapter';
import { KnProductSearchPageNormalizer } from '../shared/converters/kn-br-product-search-page.normalizer';
import { KnBrOccProductReferencesAdapter } from './../services/kn-br-occ-product-references.adapter';
import { KnBrFacetNavigationModule } from './kn-br-facet-navigation/kn-br-facet-navigation.module';
import { KnBrProductActionBarModule } from './kn-br-product-action-bar/kn-br-product-action-bar.module';
import { KnBrProductDetailModule } from './kn-br-product-detail/kn-br-product-detail.module';
import { KnBrProductListModule } from './kn-br-product-list/kn-br-product-list.module';
import { knBrProductEndpointsConfig, knBrProductViewConfig } from './kn-br-product.config';
import { KnBrProductQuoteReferenceModule } from './kn-br-product-quote-reference/kn-br-product-quote-reference.module';
import { KnBrOccProductSearchAdapter } from '../services/kn-br-product-search.service';
import { ProductPrettyNameNormalizer } from '../shared/converters/kn-br-product-name.normalizer';
import { ProductFacetService } from '@spartacus/storefront';
import { KnBrProductFacetService } from '../services/kn-br-product-facet.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    KnBrProductActionBarModule,
    KnBrFacetNavigationModule,
    KnBrProductListModule,
    KnBrProductDetailModule,
    KnBrProductQuoteReferenceModule,
    ConfigModule.withConfig(knBrProductEndpointsConfig),
    ConfigModule.withConfig(knBrProductViewConfig),
    ConfigModule.withConfig({
      routing: {
        routes: {
          category: {
            paths: ['c/:categoryCode', 'category/:categoryCode'],
            paramsMapping: { categoryCode: 'code' },
          },
          product: {
            paths: ['product/:productCode/salesOrg/:salesOrg/:prettyName', 'product/:productCode/salesOrg/:salesOrg/:name'],
          },
        },
      },
    } as RoutingConfig),
  ],
  providers: [
    {
      provide: ProductFacetService,
      useClass: KnBrProductFacetService
    },
    {
      provide: ProductAdapter,
      useClass: KnBrOccProductAdapter,
    },
    {
      provide: ProductReferencesAdapter,
      useClass: KnBrOccProductReferencesAdapter,
    },
    {
      provide: ProductReviewsAdapter,
      useClass: KnBrOccProductReviewsAdapter,
    },
    {
      provide: ProductSearchAdapter,
      useClass: KnBrOccProductSearchAdapter,
    },
    {
      provide: PRODUCT_SEARCH_PAGE_NORMALIZER,
      useClass: KnProductSearchPageNormalizer,
      multi: true,
    },
    {
      provide: PRODUCT_NORMALIZER,
      useExisting: ProductPrettyNameNormalizer,
      multi: true,
    },
  ],
})
export class KnBrProductModule {}
