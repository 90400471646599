import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ICON_TYPE } from '@spartacus/storefront';

@Component({
  selector: 'knbr-landing-page2-template',
  templateUrl: './landing-page2-template.component.html',
  styleUrls: ['./landing-page2-template.component.scss'],
})
export class LandingPage2TemplateComponent implements OnInit, AfterViewInit {
  slideIndex = 1;
  totalSlides = 0;
  dots = [];
  previousIcon = ICON_TYPE.CARET_LEFT;
  nextIcon = ICON_TYPE.CARET_RIGHT;
  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    const slides = document.getElementsByClassName('mySlides');
    if (slides.length) {
      //@ts-ignore
      for (let slide of slides) {
        if (slide.getAttribute('data-visiblae') === 'false') {
          slide.parentElement.removeChild(slide);
        }
      }
      //@ts-ignore
      for (let slide of slides) {
        if (slide.innerHTML.indexOf('Product Spotlight') > -1) {
          this.dots.push('slide');
        }
      }
    }
    if (this.dots.length) {
      this.totalSlides = this.dots.length;
      this.showSlide(1);
      this.cdr.detectChanges();
    }
  }

  plusSlides(n: number) {
    this.showSlide((this.slideIndex += n));
  }

  showSlide(n: number) {
    let i;
    let slides = document.getElementsByClassName('mySlides');
    let dots = document.getElementsByClassName('dot');
    if (n > slides.length) {
      this.slideIndex = 1;
    }
    if (n < 1) {
      this.slideIndex = slides.length;
    }
    for (i = 0; i < slides.length; i++) {
      //@ts-ignore
      slides[i].style.display = 'none';
    }
    for (i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace(' active', '');
    }
    if (slides.length) {
      //@ts-ignore
      slides[this.slideIndex - 1].style.display = 'block';
      //@ts-ignore
      slides[this.slideIndex - 1].style.opacity = '1';
    }
    if (dots.length) {
      dots[this.slideIndex - 1].className += ' active';
    }
  }

  currentSlide(n) {
    this.showSlide((this.slideIndex = n));
  }
}
