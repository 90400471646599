import { KnBrSalesOrgActions, KnBrSalesOrgActionTypes } from "./kn-br-salesorg.action";
import { KnBrSalesOrgState } from "./kn-br-salesorg.state";

export const initialState: KnBrSalesOrgState  = {

}


export function knBrSalesOrgReducer(state = initialState, action:KnBrSalesOrgActions): KnBrSalesOrgState {
    switch(action.type) {
        case KnBrSalesOrgActionTypes.KnBrSalesOrgAddAction:
            return {...state, ...action.payload}
        default:
            return state;
    }
}