import { Injectable } from '@angular/core';

import { CxEvent, WindowRef, EventService } from '@spartacus/core';
import { TmsCollectorConfig, WindowObject } from '@spartacus/tracking/tms/core';
import { GtmCollectorService } from '@spartacus/tracking/tms/gtm';
import { MarkettingCenterLinkDownlaodEvent, QuickorderSearchSuccess } from 'src/app/store/custome-spartacus-events';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { KnBrCommonService } from './kn-br-common.service';

@Injectable({ providedIn: 'root' })
export class KnBrGtmCollectorService extends GtmCollectorService {
  constructor(protected winRef: WindowRef, private userService: UserAccountFacade, private events: EventService, private knBrCommonService: KnBrCommonService) {
    super(winRef);
  }

  pushEvent<T extends CxEvent>(config: TmsCollectorConfig, windowObject: WindowObject, event: T | any): void {
    const dataLayerProperty = config.dataLayerProperty ?? 'dataLayer';
    const eventName = (event.constructor as typeof CxEvent).type;
    if (eventName === 'LoginEvent' || eventName === 'NavigationEvent') {
      const usersubscription = this.userService.get().subscribe((user: any) => {
        if (user && user.uid) {
          windowObject[dataLayerProperty].push({
            visitorRoleType: user.roles && user.roles.length > 0 ? user.roles[0] : 'B2B Rep',
            userPK: user.uid,
            event: 'visitorLogged',
          });
          usersubscription.unsubscribe();
        }
      });
    }
    if (eventName === 'LoginEvent') {
      this.knBrCommonService.getUserPk().subscribe(res => {
        if (res) {
          windowObject[dataLayerProperty].push({userPk: res, 'event': 'Login'});
        }
      })
    }
    if (eventName === 'QuickorderSearchSuccess') {
      this.events.get(QuickorderSearchSuccess).subscribe((event) => {
        windowObject[dataLayerProperty].push(event);
      });
    }
    if (eventName === 'markettingCenterLinkDownlaodEvent') {
      this.events.get(MarkettingCenterLinkDownlaodEvent).subscribe((event) => {
        windowObject[dataLayerProperty].push(event);
      });
    }
    windowObject[dataLayerProperty].push(event);
  }
}
