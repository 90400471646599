import { createFeatureSelector, createSelector } from '@ngrx/store';

import { KNBR_FACT_SHEET_FEATURE, KnBrFactSheetState } from './kn-br-fact-sheet.state';

export const getFactSheetDataState = createFeatureSelector<KnBrFactSheetState>(KNBR_FACT_SHEET_FEATURE);

export const getFactSheetData = createSelector(
  getFactSheetDataState,
  (state: KnBrFactSheetState) => state.factSheetData
);

export const getFacSheetDataLoader = createSelector(
  getFactSheetDataState,
  (state: KnBrFactSheetState) => state.isLoading
);

export const getFactSheetPaginate = createSelector(
  getFactSheetDataState,
  (state: KnBrFactSheetState) => state.pagination
);
