import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { RoutingService, TranslationService } from '@spartacus/core';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import { KnBrOrderHistoryService } from 'src/app/store/kn-br-order-history/kn-br-order-history.service';
import * as _ from 'lodash';
import { CustomerData } from 'src/app/models/customer.model';
import { KnBrOrderHistory } from 'src/app/models/kn-br-order.model';
import { map } from 'rxjs/operators';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';

@Component({
  selector: 'knbr-kn-br-order-history-search-result',
  templateUrl: './kn-br-order-history-search-result.component.html',
  styleUrls: ['./kn-br-order-history-search-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnBrOrderHistorySearchResultComponent implements OnInit, OnDestroy {
  orderHistoryResult$: Observable<KnBrOrderHistory[]> = this.knBrOrderHistoryService.list$;
  isLoader$: Observable<boolean> = this.knBrOrderHistoryService.loader$;
  paginate$: Observable<any> = this.knBrOrderHistoryService.paginate$;
  sorts$: Observable<any> = this.knBrOrderHistoryService.sorts$;
  searchCriteria: any;
  sortType: string;
  subscription: Subscription;
  downloadProgress = false;
  paginate: any = {};
  modalRef: any;

  constructor(
    public knBrOrderHistoryService: KnBrOrderHistoryService,
    protected routeService: RoutingService,
    protected knBrCustomerContextService: KnBrCustomerContextService,
    protected knBrCommonService: KnBrCommonService,
    protected translation: TranslationService
  ) { }

  ngOnInit(): void {
    this.subscription = this.knBrOrderHistoryService.searchCriteria$.subscribe((response) => {
      this.searchCriteria = _.cloneDeep(response);
    });
    this.subscription.add(
      this.paginate$.subscribe((paginate: any) => {
        if (paginate) {
          this.sortType = paginate.sort;
        }
      })
    );

    this.subscription.add(this.knBrOrderHistoryService.loader$.subscribe({
      next: response => {
        this.knBrCommonService.setSpinner(response)
      },
      error: error => { }
    }))
  }

  getSortLabels(): Observable<{ byDate: string; byOrderNumber: string; byPONumber: string; byStatus: string }> {
    return combineLatest([
      this.translation.translate('sorting.date'),
      this.translation.translate('sorting.orderNumber'),
      this.translation.translate('sorting.poNumber'),
      this.translation.translate('sorting.status'),
    ]).pipe(
      map(([textByDate, textByOrderNumber, textByPoNumber, textByStatus]) => {
        return {
          byDate: textByDate,
          byOrderNumber: textByOrderNumber,
          byPONumber: textByPoNumber,
          byStatus: textByStatus,
        };
      })
    );
  }

  changeSortCode(sortCode: string): void {
    if (this.searchCriteria) {
      this.searchCriteria.currentPage = 0;
      this.searchCriteria.sort = sortCode;
    }
    this.sortType = sortCode;
    this.knBrOrderHistoryService.search(this.searchCriteria);
  }

  loadMore(currentPage: number) {
    if (this.searchCriteria) {
      this.searchCriteria.currentPage = currentPage + 1;
      this.searchCriteria.sort = this.sortType;
    }
    this.knBrOrderHistoryService.search(this.searchCriteria);
  }

  redirectToDetail(order: KnBrOrderHistory) {
    const customerContext: CustomerData = {
      customerNumber: order.accountNumber,
      customerName: order.accountName,
      telephone: order.accountPhone,
    };
    this.knBrCustomerContextService.set(customerContext);
    this.routeService.go({ cxRoute: 'orderDetails', params: order });
  }

  downloadOrders(param1, param2, downloadFlag, fileName) {
    if (downloadFlag) {
      const data = {
        param1: param1,
        param2: param2,
        searchCriteria: this.searchCriteria,
        startDownload: true,
        fileName: fileName
      }
      this.knBrCommonService.downloadItems(data)
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
