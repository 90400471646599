import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { IconModule, ListNavigationModule } from '@spartacus/storefront';

import { KnBrAddToCartModule } from '../kn-br-add-to-cart/kn-br-add-to-cart.module';
import { KnBrAddToQuoteModule } from '../kn-br-add-to-quote/kn-br-add-to-quote.module';
import { KnBrProductActionBarComponent } from './kn-br-product-action-bar.component';

@NgModule({
    declarations: [KnBrProductActionBarComponent],
    imports: [
        CommonModule,
        ListNavigationModule,
        UrlModule,
        I18nModule,
        KnBrAddToQuoteModule,
        KnBrAddToCartModule,
        IconModule,
        NgbTooltipModule,
        ConfigModule.withConfig({
            cmsComponents: {
                KnBrProductActionBarComponent: {
                    component: KnBrProductActionBarComponent,
                },
            },
        } as CmsConfig),
    ],
    exports: [KnBrProductActionBarComponent]
})
export class KnBrProductActionBarModule {}
