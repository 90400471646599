import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { KnBrDraftOrdersEffects } from './kn-br-draft-orders.effects';
import { knBrDraftOrderReducer } from './kn-br-draft-orders.reducer';
import { KN_BR_SEARCH_DRAFT_ORDERS_FEATURE } from './kn-br-draft-orders.state';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(KN_BR_SEARCH_DRAFT_ORDERS_FEATURE, knBrDraftOrderReducer),
    EffectsModule.forFeature([KnBrDraftOrdersEffects]),
  ],
})
export class KnBrDraftOrdersStateModule {}
