import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CmsConfig, ConfigModule, FeaturesConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import {
  defaultViewConfig,
  IconModule,
  ItemCounterModule,
  ListNavigationModule,
  MediaModule,
  SpinnerModule,
  StarRatingModule,
  ViewConfig,
} from '@spartacus/storefront';
import { ProductVariantsModule } from '@spartacus/product/variants';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { KnBrAddToCartModule } from '../kn-br-add-to-cart/kn-br-add-to-cart.module';
import { KnBrAddToQuoteModule } from '../kn-br-add-to-quote/kn-br-add-to-quote.module';
import { KnBrCustomerContextGuard } from './../../shared/guards/kn-br-customer-context.guard';
import { KnBrProductGridItemComponent } from './kn-br-product-grid-item/kn-br-product-grid-item.component';
import { KnBrProductListItemComponent } from './kn-br-product-list-item/kn-br-product-list-item.component';
import { KnBrProductListComponent } from './kn-br-product-list.component';
import { KnBrProductScrollComponent } from './kn-br-product-scroll/kn-br-product-scroll.component';
import { KnBrSearchBoxModule } from './kn-br-search-box/kn-br-search-box.module';
import { AddToCartModule } from '@spartacus/cart/base/components/add-to-cart';

@NgModule({
  declarations: [
    KnBrProductListComponent,
    KnBrProductGridItemComponent,
    KnBrProductListItemComponent,
    KnBrProductScrollComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MediaModule,
    AddToCartModule,
    ItemCounterModule,
    ListNavigationModule,
    UrlModule,
    I18nModule,
    StarRatingModule,
    IconModule,
    SpinnerModule,
    InfiniteScrollModule,
    // ViewConfigModule,
    ProductVariantsModule,
    FeaturesConfigModule,
    KnBrAddToQuoteModule,
    KnBrAddToCartModule,
    NgbTooltipModule,
    KnBrSearchBoxModule,
    ConfigModule.withConfig({
      cmsComponents: {
        CMSProductListComponent: {
          component: KnBrProductListComponent,
          guards: [KnBrCustomerContextGuard],
        },
        ProductGridComponent: {
          component: KnBrProductListComponent,
          guards: [KnBrCustomerContextGuard],
        },
        SearchResultsListComponent: {
          component: KnBrProductListComponent,
          guards: [KnBrCustomerContextGuard],
        },
      },
    } as CmsConfig),
    ConfigModule.withConfig(defaultViewConfig as ViewConfig),
  ],
  exports: [
    KnBrProductListComponent,
    KnBrProductGridItemComponent,
    KnBrProductListItemComponent,
    KnBrProductScrollComponent,
  ],
})
export class KnBrProductListModule {}
