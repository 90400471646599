import { Location } from '@angular/common';
import { AfterContentChecked, ChangeDetectionStrategy, Component } from '@angular/core';
import { EventService, TranslationService, WindowRef } from '@spartacus/core';
import { CurrentProductService, ProductDetailOutlets, ProductIntroComponent } from '@spartacus/storefront';

@Component({
  selector: 'kn-br-product-intro',
  templateUrl: './kn-br-product-intro.component.html',
  styleUrls: ['./kn-br-product-intro.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KnBrProductIntroComponent extends ProductIntroComponent {
  outlets = ProductDetailOutlets;
  attachements = ["Product Data Sheet.pdf", "Safety Data Sheet.pdf", "Product Data Sheet_FR.pdf"];
  constructor(
    protected currentProductService: CurrentProductService,
    protected knBrTranslationService: TranslationService,
    protected winRef: WindowRef,
    protected location: Location,
    protected eventService: EventService
  ) {
    super(currentProductService, knBrTranslationService, winRef, eventService);
  }
  back() {
    this.location.back();
  }
}
