
export const KN_BR_SALES_ORG_FEATURE = 'kn-br-sale-orgs';

export interface KnBrSalesOrgState {
    [key: string|number]: string;
}

export interface StateWithKnBrSalesOrgs {
  [KN_BR_SALES_ORG_FEATURE]: KnBrSalesOrgState;
}
