<ng-container *ngIf="componentData$ | async as data">
  <div class="row">
    <div class="col-md-8 color-knbr-footer p-3 float-left">
      <div class="container-fluid">
        <div class="knbr-footerTop">
          <div class="row">
            <ng-container *ngIf="node$ | async as node">
              <div class="footer-left flex-wrap col-xs-12 col-sm-12 col-md-12 footer-p-5 footer-nav">
                <ng-container *ngFor="let child of node?.children">
                  <ng-container *ngTemplateOutlet="nav; context: { node: child, depth: 0 }"> </ng-container>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4 color-knbr-footer p-3 float-left">
    </div>
</div>
  <ng-template #nav let-node="node" let-depth="depth">
    <div class="footer-colums">
      <ul class="list-unstyled m-0 p-0">
        <cx-generic-link
          *ngIf="node.url && (!node.children || node.children?.length === 0)"
          [url]="getUrl(node)"
          [target]="node.target"
          [class]="'w-100 p-1 float-left color-white font-size-14 hover-link'"
        >
          {{ node.title }}
        </cx-generic-link>
        <ng-container *ngIf="node?.children?.length > 0">
          <li class="w-100" *ngFor="let innerNode of node?.children">
            <cx-generic-link
              *ngIf="innerNode.url"
              [url]="innerNode.url"
              [target]="innerNode.target"
              [class]="'w-100 p-1 float-left color-white font-size-14 hover-link'"
            >
              {{ innerNode.title }}
            </cx-generic-link>
          </li>
        </ng-container>
      </ul>
    </div>
  </ng-template>

</ng-container>
