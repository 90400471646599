import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ConverterService,
  OccEndpointsService,
  PRODUCT_SEARCH_PAGE_NORMALIZER,
  PRODUCT_SUGGESTION_NORMALIZER,
  ProductSearchAdapter,
  ProductSearchPage,
  SearchConfig,
  Suggestion,
} from '@spartacus/core';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

import { KnBrCustomerContextService } from '../store/kn-br-customer-context/kn-br-customer-context.service';

const DEFAULT_SEARCH_CONFIG: SearchConfig = {
  pageSize: 24,
};

@Injectable()
export class KnBrOccProductSearchAdapter implements ProductSearchAdapter {
  customerId: string;
  constructor(
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService,
    protected customerContextService: KnBrCustomerContextService
  ) {
    this.customerContextService.get$.subscribe((response) => {
      this.customerId = response && response.customerNumber ? response.customerNumber : null;
    });
  }

  search(query: string, searchConfig: SearchConfig = DEFAULT_SEARCH_CONFIG): Observable<ProductSearchPage> {
    const localSearchConfig = _.cloneDeep(searchConfig);
    localSearchConfig.pageSize = DEFAULT_SEARCH_CONFIG.pageSize;
    return this.http
      .get(this.getSearchEndpoint(query, localSearchConfig))
      .pipe(this.converter.pipeable(PRODUCT_SEARCH_PAGE_NORMALIZER));
  }

  loadSuggestions(term: string, pageSize: number = 3): Observable<Suggestion[]> {
    return this.http
      .get(this.getSuggestionEndpoint(term, pageSize.toString()))
      .pipe(pluck('suggestions'), this.converter.pipeableMany(PRODUCT_SUGGESTION_NORMALIZER));
  }

  protected getSearchEndpoint(query: string, searchConfig: SearchConfig): string {
    return this.occEndpoints.buildUrl('productSearch', {
      urlParams: { customerId: this.customerId },
      queryParams: {
        query,
        pageSize: searchConfig.pageSize,
        currentPage: searchConfig.currentPage,
        sort: searchConfig.sort,
      },
    });
  }

  protected getSuggestionEndpoint(term: string, max: string): string {
    return this.occEndpoints.buildUrl('productSuggestions', {
      urlParams: { customerId: this.customerId },
      queryParams: { term, max },
    });
  }
}
