import { Component, OnInit } from '@angular/core';
import { RoutingService } from '@spartacus/core';

@Component({
  selector: 'kn-br-logout',
  templateUrl: './kn-br-logout.component.html',
  styleUrls: ['./kn-br-logout.component.scss']
})
export class KnBrLogoutComponent implements OnInit {

  constructor(protected routeService: RoutingService) { }

  ngOnInit(): void {}

  routeToLogin() {
    this.routeService.goByUrl('/login');
  }
}
