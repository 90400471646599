import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CmsConfig, ConfigModule, I18nModule } from '@spartacus/core';
import { IconModule } from '@spartacus/storefront';

import { KnBrActiveFacetModule } from '../kn-br-active-facet/kn-br-active-facet.module';
import { KnBrFacetListModule } from './../kn-br-facet-list/kn-br-facet-list.module';
import { KnBrFacetNavigationComponent } from './kn-br-facet-navigation.component';

@NgModule({
  declarations: [KnBrFacetNavigationComponent],
  imports: [
    CommonModule,
    KnBrActiveFacetModule,
    KnBrFacetListModule,
    IconModule,
    I18nModule,
    ConfigModule.withConfig({
      cmsComponents: {
        ProductRefinementComponent: {
          component: KnBrFacetNavigationComponent,
        },
      },
    } as CmsConfig),
  ],
  exports: [KnBrFacetNavigationComponent],
})
export class KnBrFacetNavigationModule {}
