import { Injectable } from '@angular/core';
import { RoutingService, ProductSearchPage, ActivatedRouterStateSnapshot, PageType } from '@spartacus/core';
import { ProductListComponentService, ProductFacetService } from '@spartacus/storefront';

/**
 * Provides access to all the facets and active facets for the Product Listing Page.
 */
@Injectable({
  providedIn: 'root',
})
export class KnBrProductFacetService extends ProductFacetService {
  constructor(protected routing: RoutingService, protected productListComponentService: ProductListComponentService) {
    super(routing, productListComponentService);
  }

  protected filterForPage(state: ActivatedRouterStateSnapshot, page: ProductSearchPage): boolean {
    if (!page.currentQuery?.query?.value) {
      return false;
    }
    if (state.context.type === PageType.CATEGORY_PAGE) {
      return page.currentQuery.query.value.indexOf(`allCategories:${state.context.id}`) > -1;
    }

    if (state.context.type === PageType.CONTENT_PAGE && state.context.id === 'search') {
      return page.freeTextSearch === state.params.query.split(':')[0] || state.params.query === 'active-brands';
    }
    return false;
  }
}
