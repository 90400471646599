<ng-container *ngIf="quoteReference; else showLoader">
  <ng-container *ngIf="quoteReference && quoteReference.length; else noReference">
    <div class="text-center">
      <div class="alert-info p-3 rounded w-100">
        <!-- Hidden as part of https://carlisleccmsite.atlassian.net/browse/SSP-882 -->

        <!-- <span class="font-text-bold" *ngIf="quoteContext">{{
          'productDetails.quoteReference.quoteContextMsg' | cxTranslate
        }}</span>
        <span class="font-text-bold" *ngIf="cartContext">{{
          'productDetails.quoteReference.cartContextMsg' | cxTranslate
        }}</span>
        <span class="font-text-bold" *ngIf="!quoteContext && !cartContext">{{
          'productDetails.quoteReference.noContext' | cxTranslate
        }}</span> -->
      </div>
    </div>
    <!-- <div class="reference-lbl font-size color-font pl-4">
      {{ 'quoteReference.selectMessage1' | cxTranslate: { code: productCode } }} <br />
      {{ 'quoteReference.selectMessage2' | cxTranslate }}
    </div> -->
    <div class="product-main-grid col-md-12 p-0 d-flex quote-ref-modal" *ngIf="quoteReference">
      <div class="col-lg-12 product-list-view p-0">
        <div class="row counter-wrapper pr-3">
          <div class="customersearch-list-table float-left col-lg-12 mt-3 knbr-quote-reference pr-0">
            <div class="customersearch-list-titles d-flex align-items-center border-top float-left col-lg-12 p-2">
              <div
                *ngIf="!quoteContext"
                class="col-md-1 text-center padding-res-0 float-left font-text-600 pl-0 font-sm-size"
              >
                &nbsp;
              </div>
              <div class="col-md-2 text-center padding-res-0 float-left font-text-600 pl-0 font-sm-size">
                {{ 'quoteReference.label.quoteNumber' | cxTranslate }}
              </div>
              <div class="col-md-2 text-center float-left font-text-600 pl-0 font-sm-size">
                {{ 'quoteCommon.label.jobNameNumber' | cxTranslate }}
              </div>
              <div class="col-md-1 text-center float-left font-text-600 pl-0 font-sm-size">
                {{ 'quoteReference.label.totalQty' | cxTranslate }}
              </div>
              <!-- <div class="col-md-1 text-center float-left font-text-600 pl-0 font-sm-size">
                {{ 'quoteReference.label.orderQty' | cxTranslate }}
              </div> -->
              <div class="col-md-2 text-center float-left font-text-600 pl-0 font-sm-size">
                {{ 'quoteReference.label.referencedQty' | cxTranslate }}
              </div>
              <div class="col-md-2 text-center float-left font-text-600 pl-0 font-sm-size">
                {{ 'quoteReference.label.availableQty' | cxTranslate }}
              </div>
              <div class="col-md-2 text-center float-left font-text-600 pl-0 font-sm-size">
                {{ 'quoteReference.label.price' | cxTranslate }}
              </div>
            </div>

            <div
              class="
                bg-white
                customersearch-list-titles
                table-content
                border-top
                float-left
                w-100
                p-2
                font-weight-normal
                d-flex
                align-items-center
              "
              *ngFor="let reference of quoteReference"
            >
              <div
                *ngIf="!quoteContext"
                class="col-lg-1 pl-0 reference-checkbox float-left d-flex justify-content-start p-2"
              >
                <div class="form-check">
                  <input
                    class="form-check-input"
                    [value]="reference"
                    [checked]="
                      reference.knbrQuoteReference.quoteRefNumber ===
                      selectedQuoteReference?.knbrQuoteReference?.quoteRefNumber
                    "
                    type="radio"
                    name="exampleRadios"
                    id="exampleRadios1"
                    (change)="quoteReferenceChange(reference)"
                  />
                </div>
              </div>

              <div class="col-md-2 text-center float-left title-wrap pl-0 font-sm-size color-font">
                <span class="res-titles"> {{ 'quoteReference.label.quoteNumber' | cxTranslate }}</span>
                {{ reference?.knbrQuoteReference?.quoteRefNumber }}
              </div>
              <div class="col-md-2 text-center float-left title-wrap product-name pl-0 font-size color-font">
                <span class="res-titles"> {{ 'quoteCommon.header.jobNameNumber' | cxTranslate }}</span
                >{{ reference?.knbrQuoteReference?.jobName }}
              </div>
              <div class="col-md-1 text-center float-left title-wrap pl-0 font-size color-font">
                <span class="res-titles"> {{ 'quoteReference.label.totalQty' | cxTranslate }}</span>
                {{ reference?.quantity }}
              </div>
              <!-- <div class="col-md-1 text-center float-left title-wrap pl-0 font-size color-font">
                <span class="res-titles"> {{ 'quoteReference.label.orderQty' | cxTranslate }}</span>
                {{ reference?.orderedQty }}
              </div> -->
              <div class="col-md-2 text-center float-left title-wrap pl-0 font-size color-font">
                <span class="res-titles"> {{ 'quoteReference.label.referencedQty' | cxTranslate }}</span>
                {{ reference?.referencedQty }}
              </div>
              <div class="col-md-2 text-center float-left title-wrap pl-0 font-size color-font">
                <span class="res-titles">{{ 'quoteReference.label.availableQty' | cxTranslate }}</span>
                {{ reference?.knbrQuoteReference?.remainingQuantity }}
              </div>
              <div class="col-md-2 text-center float-left title-wrap pl-0 font-size color-font">
                <span class="res-titles"> {{ 'quoteReference.label.price' | cxTranslate }}</span>
                {{ reference?.knbrQuoteReference?.refQuotePriceAdjusted }}
              </div>
            </div>
          </div>
        </div>
        <div class="pl-4">
          <ng-container *ngIf="selectedQuoteReference">
            <div class="alert alert-warning rounded h-auto p-3 font-sm-size w-100 my-3" *ngIf="showWarningMessage">
              <i class="fas fa-exclamation-triangle mr-2" aria-hidden="true"></i>
              {{
                'knBrMessages.quoteReferenceWarningMessage'
                  | cxTranslate
                    : {
                        reqQty: quantity,
                        availQty: selectedQuoteReference.knbrQuoteReference.remainingQuantity
                      }
              }}
            </div>
            <!--<span
              class="font-size color-font col-lg-12 p-0 d-flex align-items-center justify-content-center float-left"
            >
              {{ cartEntry.knbrQuoteReference?.quoteRefNumber }}
            </span>
             <span class="font-size color-font col-lg-12 p-0 align-items-center justify-content-center float-left">
              {{ cartEntry.knbrQuoteReference?.refQuotePriceAdjusted }} x 1Item
            </span> -->
            <div class="w-100">
              <button
                type="button"
                (click)="deleteQuoteReference()"
                class="
                  btn
                  color-knbr-primary color-white
                  pl-4
                  pr-4
                  pb-1
                  pt-1
                  font-lg-size
                  w-50
                  hover-link
                  m-res-bottom
                  float-right
                "
              >
                {{ 'productDetails.quoteReference.removeQtoueReference' | cxTranslate }}
              </button>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-template #noReference>
  <!-- Hidden as part of https://carlisleccmsite.atlassian.net/browse/SSP-882 -->

  <!-- <div class="text-center">
    <div class="alert-info p-3 rounded w-100">
      {{ 'productDetails.quoteReference.referenceNotFound' | cxTranslate }}
    </div>
  </div> -->
</ng-template>
<ng-template #showLoader>
  <cx-spinner></cx-spinner>
</ng-template>
