import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ComponentRef, OnDestroy, OnInit, ViewContainerRef } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalMessageService, OccEndpointsService, RoutingService } from '@spartacus/core';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { Observable } from 'rxjs';
import { KnBrCaseUpdateDialogComponent } from 'src/app/kn-br-case-update-dialog/kn-br-case-update-dialog.component';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import { RemoveLeadingZerosPipe } from 'src/app/shared/pipe/kn-br-custom-pipes/remove-leading-zeros.pipe';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import { KnBrQuotesService } from 'src/app/store/kn-br-quotes/kn-br-quotes.service';
import { KNBR_CASE_LIST } from 'src/constants/api.endpoints.constant';
@Component({
  selector: 'kn-br-case-listing-result',
  templateUrl: './kn-br-case-listing-result.component.html',
  styleUrls: ['./kn-br-case-listing-result.component.scss'],
  providers: [RemoveLeadingZerosPipe],
})
export class KnBrCaseListingResultComponent implements OnInit, OnDestroy {
  caseUpdateModalRef: any;
  searchCriteria: any;

  public formGroup: FormGroup;

  customerId: string;
  helperForm: UntypedFormGroup;
  submitted: boolean;
  showSpinner: void | Observable<ComponentRef<any>>;
  ticketsData = [];
  paginate: any;
  apiCalled = false;
  initialPayload = {
    currentPage: 0,
    pageSize: 50,
  };
  vieworManageHeadingToolTipText = `To view an existing case, click "View" under Action. To add a comment or file to an In Progress case click “Update” under Action`;


  constructor(
    protected fb: UntypedFormBuilder,
    protected knBrQuoteService: KnBrQuotesService,
    protected knBrCommonService: KnBrCommonService,
    protected knBrCustomerContextService: KnBrCustomerContextService,
    protected removeLeadingZerosPipe: RemoveLeadingZerosPipe,
    protected activatedRoute: ActivatedRoute,
    protected http: HttpClient,
    private endPointService: OccEndpointsService,
    protected cdr: ChangeDetectorRef,
    protected globalMessageService: GlobalMessageService,
    protected launchDialogService: LaunchDialogService,
    protected vcr: ViewContainerRef,
    protected modalService: NgbModal,
    protected routeService: RoutingService,

  ) {}

  ngOnInit(): void {
    this.getCaseListingDetails(this.initialPayload);
    this.knBrCustomerContextService.getActiveCustomerNumber().subscribe((response) => {
      this.customerId = response ? response : 'current';
    });
    this.initForm();
  }

  initForm() {
    this.helperForm = this.fb.group({
      caseOptionToCreate: [null, Validators.required],
      salesOrInvoiceNumber: [null, Validators.required],
    });
  }

  submitForm() {
    this.submitted = true;
    console.log('form submission getting called');
  }

  onSpinnerLoading(isLoading) {
    if (isLoading) {
      this.showSpinner = this.launchDialogService.launch(LAUNCH_CALLER.PLACE_ORDER_SPINNER, this.vcr);
    } else {
      if (this.showSpinner) {
        this.showSpinner
          .subscribe((component) => {
            this.launchDialogService.clear(LAUNCH_CALLER.PLACE_ORDER_SPINNER);
            component.destroy();
          })
          .unsubscribe();
      }
    }
  }

  getCaseListingDetails(payload?: any) {
    this.onSpinnerLoading(true);
    const caseListingUrl = this.endPointService.buildUrl(KNBR_CASE_LIST(this.customerId), {
      queryParams: payload,
    });
    this.http.get(caseListingUrl).subscribe((response: any) => {
      console.log('logging the case list response', response);
      this.ticketsData.push(...response.tickets);
      this.paginate = response.pagination;
      this.searchCriteria = response.pagination;
      this.apiCalled = true;
      this.onSpinnerLoading(false);
      this.cdr.detectChanges();
    });
  }

  loadMore(currentPage: number) {
    this.searchCriteria.currentPage = currentPage + 1;
    this.getCaseListingDetails(this.searchCriteria);
  }

  handleCaseUpdateClick(ticketRow) {
    let caseUpdateModalRef = this.modalService.open(KnBrCaseUpdateDialogComponent, {});
    caseUpdateModalRef.componentInstance.ticketInfo = ticketRow;
    caseUpdateModalRef.result.then((comment) => {}).catch((error) => {});
  }

  resetTheForm() {
    this.helperForm.reset();
    this.submitted = false;
  }

  handleViewCaseClick(ticketData) {
    const url = `my-account/case-detail/${ticketData.svcCaseNumber}?ticketID=${ticketData.ticketId}`
    this.routeService.goByUrl(url);
  }

  ngOnDestroy(): void {
    this.ticketsData = [];
    this.paginate = {};
    this.searchCriteria = {};
    this.helperForm.reset();
  }
}
