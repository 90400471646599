
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  StateWithProduct,
  Product,
  ConverterService,
  OccEndpointsService,
  OccRequestsOptimizerService,
  PRODUCT_NORMALIZER,
} from '@spartacus/core';
import { Observable, of } from 'rxjs';
import { KnBrCustomerContextService } from '../store/kn-br-customer-context/kn-br-customer-context.service';
import { getSalesOrg } from '../store/kn-br-salesorg/kn-br-salesorg.selector';
import { KnBrSalesOrgState, StateWithKnBrSalesOrgs } from '../store/kn-br-salesorg/kn-br-salesorg.state';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class KnBrProductService {
  customerId: string;
  salesOrg: KnBrSalesOrgState;
  constructor(
    protected store: Store<StateWithProduct | StateWithKnBrSalesOrgs>,
    protected http: HttpClient,
    protected occEndpoints: OccEndpointsService,
    protected converter: ConverterService,
    protected requestsOptimizer: OccRequestsOptimizerService,
    private customerContextService: KnBrCustomerContextService
  ) {
    this.customerContextService.get$.subscribe((response) => {
      this.customerId = response && response.customerNumber ? response.customerNumber : null;
    });
    this.store.pipe(select(getSalesOrg)).subscribe((salesOrg) => (this.salesOrg = salesOrg));
  }

  get(productCode: string, salesorg: string, scopes: string): Observable<Product | undefined> {
    return productCode ? this.load(productCode, salesorg, scopes) : of(undefined);
  }

  load(productCode: string, salesOrg: string, scope?: string): Observable<Product> {
    return this.http
      .get(this.getEndpoint(productCode, salesOrg, scope))
      .pipe(this.converter.pipeable(PRODUCT_NORMALIZER), map(product => ({...product, salesOrg})));
  }

  protected getEndpoint(code: string, salesorg: string, scope?: string): string {
    if (scope === 'list' && this.salesOrg && this.salesOrg[`${code}-${salesorg}`]) {
      scope = 'listWithSalesOrg';
      return this.occEndpoints.buildUrl('product', {
        urlParams: {
          productCode: code,
          salesOrg: this.salesOrg[`${code}-${salesorg}`],
          customerId: this.customerId,
        },
        scope,
      });
    }

    return this.occEndpoints.buildUrl('product', {
      urlParams: {
        productCode: code,
        customerId: this.customerId,
      },
      scope,
    });
  }
}
