<ng-container>
  <div class="col-md-12 p-0 pb-4 float-right d-flex justify-content-between">
    <h2 class="mt-2 font-text-600 titleName">{{ this.title(this.invoiceName) }}</h2>
    <button class="btn back-btn p-1 font-lg-size" (click)="goBack()">
      <i class="fas fa-level-down-alt fa-rotate-90" aria-hidden="true"></i>
    </button>
  </div>
  <div class="col-md-12 float-left pb-2 mt-2 mb-4 ">
    <div class="col-md-3 float-left mt-1 p-0">
      <label class="color-font font-text-600 mb-1">
        {{ 'knbrInvoiceDetails.label.documentNumberDate' | cxTranslate }}
      </label>
      <p class="text-muted mb-2">{{this.invoiceData?.documentNumber}}</p>

      <label class="color-font font-text-600 mb-1">
        {{ 'knbrInvoiceDetails.label.accountNumber' | cxTranslate }}
      </label>
      <p class="text-muted mb-2">{{this.invoiceData?.customerNumber}}</p>

      <kn-br-common-address [address]="this.invoiceData?.billToAddress" [isSoldTo]="true" [addressLabel]="'Bill To Address'"></kn-br-common-address>

      <label class="color-font font-text-600 mb-1">
        {{ 'knbrInvoiceDetails.label.brand' | cxTranslate }}
      </label>
      <p class="text-muted mb-2">{{this.invoiceData?.brandName}}</p>

      <label class="color-font font-text-600 mb-1">
        {{ 'knbrInvoiceDetails.label.termsOfDelivery' | cxTranslate }}
      </label>
      <p class="text-muted mb-2">{{this.invoiceData?.termsOfDelivery}} - {{this.invoiceData?.termsOfDeliveryDesc}}</p>

      <label class="color-font font-text-600 mb-1">
        {{ 'knbrInvoiceDetails.label.carrier' | cxTranslate }}
      </label>
      <p class="text-muted mb-2">{{this.invoiceData?.carrier}}</p>
    </div>

    <div class="col-md-3 float-left mt-1 p-0">
      <label class="color-font font-text-600 mb-1">
        {{ 'knbrInvoiceDetails.label.documentDate' | cxTranslate }}
      </label>
      <p class="text-muted mb-2">{{this.invoiceData?.invoiceDate}}</p>

      <label class="color-font font-text-600 mb-1">
        {{ 'knbrInvoiceDetails.label.purchaseOrderNum' | cxTranslate }}
      </label>
      <p class="text-muted mb-2">{{this.invoiceData?.salesOrderNumber}}</p>

      <kn-br-common-address [address]="this.invoiceData?.soldToAddress" [isSoldTo]="true" [addressLabel]="'Sold To Address'"></kn-br-common-address>

      <label class="color-font font-text-600 mb-1">
        {{ 'knbrInvoiceDetails.label.accountPhone' | cxTranslate }}
      </label>
      <p class="text-muted mb-2">{{this.invoiceData?.soldToAddress.phone ? this.invoiceData?.soldToAddress.phone : '-'}}</p>

      <label class="color-font font-text-600 mb-1">
        {{ 'knbrInvoiceDetails.label.netWeight' | cxTranslate }}
      </label>
      <p class="text-muted mb-2">{{this.toFixed(this.invoiceData?.netWeight)}} {{this.invoiceData?.netWeightUOM}}</p>

      <label class="color-font font-text-600 mb-1">
        {{ 'knbrInvoiceDetails.label.truckProNum' | cxTranslate }}
      </label>
      <p class="text-muted mb-2">{{this.invoiceData?.truck}}</p>
    </div>

    <div class="col-md-3 float-left mt-1 p-0">
      <label class="color-font font-text-600 mb-1">
        {{ 'knbrInvoiceDetails.label.poNumber' | cxTranslate }}
      </label>
      <p class="text-muted mb-2">{{this.invoiceData?.salesOrderNumber}}</p>

      <label class="color-font font-text-600 mb-1">
        {{ 'knbrInvoiceDetails.label.orderCreationDate' | cxTranslate }}
      </label>
      <p class="text-muted mb-2">{{this.invoiceData?.poDate}}</p>

      <kn-br-common-address [address]="this.invoiceData?.shipToAddress" [isSoldTo]="true" [addressLabel]="'Ship To Address'"></kn-br-common-address>

      <label class="color-font font-text-600 mb-1">
        {{ 'knbrInvoiceDetails.label.volume' | cxTranslate }}
      </label>
      <p class="text-muted mb-2">{{this.toFixed(this.invoiceData?.volume)}} {{this.invoiceData?.volumeUOM}}</p>

      <label class="color-font font-text-600 mb-1">
        {{ 'knbrInvoiceDetails.label.currency' | cxTranslate }}
      </label>
      <p class="text-muted mb-2">{{this.invoiceData?.currencyISO}}</p>

      <label class="color-font font-text-600 mb-1">
        {{ 'knbrInvoiceDetails.label.termsOfPayment' | cxTranslate }}
      </label>
      <span class="text-muted mb-2">{{this.invoiceData?.termsOfPayment}}</span>
      <p class="text-muted mb-2" hidden>{{this.formatDescription(this.invoiceData?.termsOfPaymentDesc)}}</p>
      <div *ngFor="let note of termsOfPayment">
        <p class="mb-0 text-muted">{{ note }}</p>
      </div>
    </div>
<!--
    <div *ngIf="this.invoiceData?.packingListNo" class="col-12 float-left mt-1 p-0">
      <label class="color-font font-text-600 mb-1">
        {{ 'knbrInvoiceDetails.label.packingList' | cxTranslate }}
      </label>
      <p class="text-muted mb-2">{{this.invoiceData?.packingListNo}} / {{this.invoiceData?.packingListDate}}</p>
    </div>
    <div class="col-12 float-left mt-1 p-0">
      <label class="color-font font-text-600 mb-1">
        {{ 'knbrInvoiceDetails.label.salesOrderNum' | cxTranslate }}
      </label>
      <p class="text-muted mb-2">{{this.invoiceData?.salesOrderNumber}} / {{this.invoiceData?.salesOrderDate}}</p>
    </div> -->

    <div class="col-md-3 float-left mt-1 p-0">
      <label class="color-font font-text-600 mb-1">
        {{ 'knbrInvoiceDetails.label.poDate' | cxTranslate }}
      </label>
      <p class="text-muted mb-2">{{this.invoiceData?.poDate}}</p>

      <label class="color-font font-text-600 mb-1">
        {{ 'knbrInvoiceDetails.label.saleGroup' | cxTranslate }}
      </label>
      <p class="text-muted mb-2">{{this.invoiceData?.salesGroup}}</p>

      <kn-br-common-address [address]="this.invoiceData?.remittanceAddress" [isSoldTo]="true" [addressLabel]="'Remittances Address'"></kn-br-common-address>

      <label class="color-font font-text-600 mb-1">
        {{ 'knbrInvoiceDetails.label.shipmentBlNum' | cxTranslate }}
      </label>
      <p class="text-muted mb-2">{{this.invoiceData?.shipment}}</p>

      <label class="color-font font-text-600 mb-1">
        {{ 'knbrInvoiceDetails.label.shippingPoint' | cxTranslate }}
      </label>
      <p class="text-muted mb-2">{{this.invoiceData?.shippingPoint}}</p>

      <div *ngIf="this.invoiceData?.discounts && this.invoiceData?.discounts?.length > 0" class="col-md-12 float-left mt-1 p-0">
        <div *ngFor="let item of this.invoiceData?.discounts; let i = index">
          <label class="color-font font-text-600 mb-1">
            {{ 'knbrInvoiceDetails.label.discountAmount' | cxTranslate }} {{i + 1}}
          </label>
          <p class="text-muted mb-2">{{this.separator(item.value)}}</p>
        </div>
      </div>
    </div>
  </div>
</ng-container>
