import { NgModule } from '@angular/core';
import { provideConfig, ConfigModule } from '@spartacus/core';
import { defaultCmsContentProviders, layoutConfig, mediaConfig } from '@spartacus/storefront';
import { translationChunksConfig } from '@spartacus/assets';
import { environment } from 'src/environments/environment';

@NgModule({
  imports:[
    ConfigModule.withConfig({
      pagination:{
        startLabel:`«`,
        endLabel:`»`,
        previousLabel:`‹`,
        nextLabel:`›`,
        rangeCount:3,
        addNext:true,
        addEnd:true,
        addPrevious:true,
        addStart:true,
        addLast: false,
        addFirst:false
      }
    }),
  ],
  providers: [
    provideConfig(layoutConfig),
    provideConfig(mediaConfig),
    ...defaultCmsContentProviders,
    provideConfig({
      backend: {
        occ: {
          baseUrl: environment.BASEURL,
          prefix: environment.PREFIX,
        },
      },
      context: {
        currency: ['USD','CAD'],
        language: ['en', 'fr'],
        baseSite: environment.BASE_SITE,
        country: environment.COUNTRY,
        urlParameters: ['baseSite','currency']
      },
      features: {
        level: '*',
      },
      pwa: {
        enabled: true,
        addToHomeScreen: true,
      },
      i18n: {
        chunks: translationChunksConfig,
      },
      routing: {
        protected: true,
        routes: {
          privacyPolicy: {
            paths: ['privacy-policy'],
            protected: false,
          },
          termsOfUse: {
            paths: ['terms-of-use'],
            protected: false,
          },
          contactUs: {
            paths: ['contact-us'],
            protected: false,
          },
        },
      },
      smartEdit: {
        storefrontPreviewRoute: 'cx-preview',
        allowOrigin: environment.ORIGIN,
      },
    }),
  ],
})
export class SpartacusConfigurationModule {}
