import { KnBrAddRequestEntry } from './../../models/kn-br-order.model';
import { Statuses } from 'src/app/models/kn-br-quote.model';
import { DraftSearchForm } from '../../models/draft-order.model';
import { KnBrDraftOrderEntry } from '../../models/kn-br-draft-order.model';

export const KN_BR_SEARCH_DRAFT_ORDERS_FEATURE = 'kn-br-draft-orders';

export interface KnBrDraftOrdersState {
  orders?: any;
  pagination?: any;
  error?: Error;
  searchRequest?: DraftSearchForm;
  isLoading?: boolean;
  order?: any;
  entry?: KnBrDraftOrderEntry;
  isSearchDisabled: boolean;
  isCreateDisabled: boolean;
  statuses: Statuses[];
  quickentry: KnBrAddRequestEntry[];
  resetForm: boolean;
  showSpinner?: boolean;
  checkoutDisabled?: boolean;
  warningMessage?: string;
}

export interface StateWithDraftOrder {
  [KN_BR_SEARCH_DRAFT_ORDERS_FEATURE]: KnBrDraftOrdersState;
}
