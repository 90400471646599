import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  CmsConfig,
  ConfigModule,
  I18nConfig,
  I18nModule,
  NotAuthGuard,
  provideConfig,
  UrlModule,
} from '@spartacus/core';
import {
  FormErrorsModule,
  PageComponentModule,
  PageLayoutModule,
  PageSlotModule,
  SpinnerModule,
} from '@spartacus/storefront';
import { LoginModule } from '@spartacus/user/account/components';
import { userProfileTranslationChunksConfig, userProfileTranslations } from '@spartacus/user/profile/assets';
import { USER_SIGN_UP_SERIALIZER } from '@spartacus/user/profile/core';
import { KnBrUserSignUpSerializer } from '../serializer/kn-br-user-signup.serializer';
import { knBrUserRegisterEndpointsConfig } from './kn-br-user-register-endpoints.config';
import { KnBrUserRegisterComponent } from './kn-br-user-register.component';

@NgModule({
    imports: [
        CommonModule,
        LoginModule,
        ReactiveFormsModule,
        NgSelectModule,
        RouterModule,
        UrlModule,
        I18nModule,
        SpinnerModule,
        FormErrorsModule,
        PageComponentModule,
        PageLayoutModule,
        PageSlotModule,
        ConfigModule.withConfig({
            cmsComponents: {
                RegisterCustomerComponent: {
                    component: KnBrUserRegisterComponent,
                    guards: [NotAuthGuard],
                },
            },
        } as CmsConfig),
        ConfigModule.withConfig(knBrUserRegisterEndpointsConfig),
    ],
    providers: [
        {
            provide: USER_SIGN_UP_SERIALIZER,
            useClass: KnBrUserSignUpSerializer,
            multi: true,
        },
        provideConfig({
            i18n: {
                resources: userProfileTranslations,
                chunks: userProfileTranslationChunksConfig,
            },
        } as I18nConfig),
    ],
    declarations: [KnBrUserRegisterComponent],
    exports: [KnBrUserRegisterComponent]
})
export class KnBrUserRegisterModule {}
