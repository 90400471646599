<form (ngSubmit)="submitForm()" class="cx-place-order-form form-check" [formGroup]="checkoutSubmitForm">
  <div class="form-group">
    <label>
      <input formControlName="termsAndConditions" class="scaled-input form-check-input" type="checkbox" />
      <span class="form-check-label">
        {{ 'checkoutReview.sloanConfirmThatRead' | cxTranslate }}
        <a [routerLink]="{ cxRoute: 'termsOfUse' } | cxUrl" class="cx-tc-link" target="_blank">
          {{ 'checkoutReview.termsAndConditions' | cxTranslate }}
        </a>
      </span>
    </label>
  </div>

  <button
    type="submit"
    class="btn btn-primary btn-block"
    [disabled]="
      termsAndConditionInvalid || (daysOfWeekNotChecked$ | async) || disablePlaceOrder || (isASMLoggedIn$ | async)
    "
  >
    {{ 'checkoutReview.placeOrder' | cxTranslate }}
  </button>
</form>
