import { KnBrQuoteEntryAction, KnBrQuoteEntryActionTypes } from './kn-br-quote-entry.actions';
import { KnBrQuoteEntryState } from './kn-br-quote-entry.state';

export const initialState: KnBrQuoteEntryState = {
  entry: undefined,
  error: undefined,
  isSaveDisabled: false,
  showAddQuoteEntryLoading: false,
  isLoading: false,
};

export function knBrQuoteEntryReducer(state = initialState, action: KnBrQuoteEntryAction): KnBrQuoteEntryState {
  switch (action.type) {
    case KnBrQuoteEntryActionTypes.KnBrQuoteAddEntryAction:
      return { ...state, entry: action.payload, isSaveDisabled: true, showAddQuoteEntryLoading: true };
    case KnBrQuoteEntryActionTypes.KnBrQuoteAddEntrySuccessAction:
      return { ...state, entry: action.payload, isSaveDisabled: false, showAddQuoteEntryLoading: false };
    case KnBrQuoteEntryActionTypes.KnBrQuoteAddEntryFailureAction:
      return { ...state, error: action.error, isSaveDisabled: false, showAddQuoteEntryLoading: false };
    case KnBrQuoteEntryActionTypes.KnBrQuoteRemoveEntryAction:
      return { ...state, entry: action.payload, showAddQuoteEntryLoading: true };
    case KnBrQuoteEntryActionTypes.KnBrQuoteRemoveEntrySuccessAction:
      return { ...state, entry: action.payload, showAddQuoteEntryLoading: false };
    case KnBrQuoteEntryActionTypes.KnBrQuoteRemoveEntryFailureAction:
      return { ...state, error: action.error, showAddQuoteEntryLoading: true };
    case KnBrQuoteEntryActionTypes.KnBrQuoteUpdateEntryAction:
      return { ...state, entry: action.payload };
    case KnBrQuoteEntryActionTypes.KnBrQuoteUpdateEntrySuccessAction:
      return { ...state, entry: action.payload };
    case KnBrQuoteEntryActionTypes.KnBrQuoteUpdateEntryFailureAction:
      return { ...state, error: action.error };
    case KnBrQuoteEntryActionTypes.KnBrQuoteLoadEntryAction:
      return { ...state, entry: action.payload };
    case KnBrQuoteEntryActionTypes.KnBrQuoteLoadEntrySuccessAction:
      return { ...state, entry: action.payload };
    case KnBrQuoteEntryActionTypes.KnBrQuoteLoadEntryFailureAction:
      return { ...state, error: action.error };
    case KnBrQuoteEntryActionTypes.KnBrQuoteAddEntriesAction:
      return { ...state, entries: action.payload, isSaveDisabled: true, isLoading: true };
    case KnBrQuoteEntryActionTypes.KnBrQuoteAddEntriesSuccessAction:
      return { ...state, entries: action.payload, isSaveDisabled: false, isLoading: false };
    case KnBrQuoteEntryActionTypes.KnBrQuoteAddEntriesFailureAction:
      return { ...state, error: action.payload, isSaveDisabled: false, isLoading: false };
    default:
      return state;
  }
}
