import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { AuthGuard, CmsConfig, ConfigModule, I18nModule, RoutingConfig, UrlModule } from '@spartacus/core';
import { CmsPageGuard, FormErrorsModule, PageComponentModule, PageLayoutComponent, PageLayoutModule, PageSlotModule } from '@spartacus/storefront';
import { KnBrCaseUpdateDialogComponent } from '../kn-br-case-update-dialog/kn-br-case-update-dialog.component';
import { KnBrCustomPipesModule } from "../shared/pipe/kn-br-custom-pipes/kn-br-custom-pipes.module";
import { KnBrCaseListingHeaderComponent } from './kn-br-case-listing-header/kn-br-case-listing-header.component';
import { KnBrCaseListingResultComponent } from './kn-br-case-result/kn-br-case-listing-result.component';
import { KnBrCaseUpdateDialogModule } from '../kn-br-case-update-dialog/kn-br-case-update-dialog.module';
import { KnBrCustomerContextGuard } from '../shared/guards/kn-br-customer-context.guard';


@NgModule({
    declarations: [KnBrCaseListingHeaderComponent, KnBrCaseListingResultComponent],
    exports: [KnBrCaseListingHeaderComponent, KnBrCaseListingResultComponent],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        KnBrCaseUpdateDialogModule,
        UrlModule,
        I18nModule,
        FormErrorsModule,
        NgSelectModule,
        NgbTooltipModule,
        NgbModule,
        PageComponentModule,
        PageLayoutModule,
        PageSlotModule,
        ConfigModule.withConfig({
            cmsComponents: {
                CarlisleCaseListHeaderComponent: {
                    component: KnBrCaseListingHeaderComponent,
                },
                CarlisleCaseListResultsComponent: {
                    component: KnBrCaseListingResultComponent
                }
            },
        } as CmsConfig),
        RouterModule.forChild([
          {
            data: { pageLabel: '/my-account/customer-care', cxRoute: 'caseList' },
            path: null,
            canActivate: [AuthGuard, CmsPageGuard, KnBrCustomerContextGuard],
            component: PageLayoutComponent,
          },
        ]),
        ConfigModule.withConfig({
          routing: {
            routes: {
              caseList: {
                paths: ['my-account/customer-care'],
              },
            },
          },
        } as RoutingConfig),

        KnBrCustomPipesModule
    ]
})
export class KnBrCaseListingModule {}
