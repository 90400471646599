import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CheckoutDeliveryAddressFacade } from '@spartacus/checkout/base/root';
import { GlobalMessageService, TranslationService, UserAddressService, UserService } from '@spartacus/core';
import { AddressFormComponent, LaunchDialogService } from '@spartacus/storefront';
import { Subscription } from 'rxjs';

@Component({
  selector: 'knbr-address-form',
  templateUrl: './kn-br-address-form.component.html',
  styleUrls: ['./kn-br-address-form.component.scss'],
})
export class KnBrAddressFormComponent extends AddressFormComponent implements OnInit, OnDestroy {
  defaultCountrySelected = 'US';
  addressForm: UntypedFormGroup = this.fb.group({
    country: this.fb.group({
      isocode: [this.defaultCountrySelected, Validators.required],
    }),
    firstName: ['', Validators.required],
    bldg: [''],
    houseNumber: [''],
    line1: ['', Validators.required],
    line2: [''],
    town: ['', Validators.required],
    region: this.fb.group({
      isocode: [null, Validators.required],
    }),
    postalCode: ['', Validators.required],
    phone: [''],
    defaultAddress: [false],
    email: [''],
    fax: [''],
    visibleInAddressBook: [true],
  });
  isCheckOut = false;
  submitAddressSubscribe: Subscription;
  constructor(
    protected fb: UntypedFormBuilder,
    // protected checkoutDeliveryService: CheckoutDeliveryFacade,
    protected userService: UserService,
    protected userAddressService: UserAddressService,
    protected globalMessageService: GlobalMessageService,
    // protected modalService: ModalService,
    protected translation: TranslationService,
    protected launchDialogService: LaunchDialogService,
    public activeModal?: NgbActiveModal
  ) {
    super(fb, userService, userAddressService, globalMessageService, translation, launchDialogService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.selectedCountry$.next(this.defaultCountrySelected);
    this.submitAddressSubscribe = this.submitAddress.asObservable().subscribe((res) => {
      if (this.activeModal) {
        const addrobj = {
          address: this.addressForm.value,
          isOneTime: false,
        };
        this.activeModal.close(addrobj);
      }
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.submitAddressSubscribe) {
      this.submitAddressSubscribe.unsubscribe();
    }
  }

  oneTimeUse() {
    if (this.addressForm.valid) {
      const addrobj = {
        address: this.addressForm.value,
        isOneTime: true,
      };
      this.activeModal.close(addrobj);
    } else {
      this.addressForm.markAllAsTouched();
    }
  }
}
