import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KnBrSearchBoxComponent } from './kn-br-search-box.component';
import { RouterModule } from '@angular/router';
import { UrlModule, I18nModule, CmsConfig, provideDefaultConfig } from '@spartacus/core';
import { MediaModule, IconModule, SearchBoxModule } from '@spartacus/storefront';
import { HighlightPipe } from './highlight.pipe';

@NgModule({
  declarations: [KnBrSearchBoxComponent, HighlightPipe],
  imports: [CommonModule, RouterModule, MediaModule, IconModule, UrlModule, I18nModule, SearchBoxModule],
  providers: [
    provideDefaultConfig({
      cmsComponents: {
        SearchBoxComponent: {
          component: KnBrSearchBoxComponent,
        },
      },
    } as CmsConfig),
  ],
  exports: [KnBrSearchBoxComponent, HighlightPipe],
})
export class KnBrSearchBoxModule {}
