import { CommonModule } from '@angular/common';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { CmsConfig, ConfigModule, I18nModule, UrlModule } from '@spartacus/core';
import { KnBrInvoiceListSearchComponent } from './kn-br-invoice-list-search.component';

@NgModule({
  declarations: [KnBrInvoiceListSearchComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UrlModule,
    I18nModule,
    NgSelectModule,
    NgbTooltipModule,
    NgbModule,
    ConfigModule.withConfig({
      cmsComponents: {
        KnBrInvoiceSearchComponent: {
          component: KnBrInvoiceListSearchComponent,
        },
      },
    } as CmsConfig),
  ],
})
export class KnBrInvoiceListSearchModule {}
