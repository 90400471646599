import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { OccConfig, TranslationService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { CustomerData } from 'src/app/models/customer.model';
import { KnBrDateHelper } from 'src/app/shared/kn-br-date.helper';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
import { KnBrInvoiceService } from 'src/app/store/kn-br-invoice/kn-br-invoice.service';
import { WIDGET_PAGESIZE } from 'src/constants/pagination.constant';
import { KnBrInvoiceListSeachResultComponent } from '../../kn-br-invoice-list/kn-br-invoice-list-seach-result/kn-br-invoice-list-seach-result.component';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';

@Component({
  selector: 'knbr-kn-br-quick-invoice',
  templateUrl: './kn-br-quick-invoice.component.html',
  styleUrls: ['./kn-br-quick-invoice.component.scss'],
})
export class KnBrQuickInvoiceComponent extends KnBrInvoiceListSeachResultComponent implements OnInit, OnDestroy {
  invoiceSearchForm: UntypedFormGroup;
  selectedCustomer: CustomerData = null;
  customerContextData$: Observable<CustomerData> = this.knBrCustomerContextService.get$;
  currentPage = 0;
  isSearchDisabled$: Observable<boolean> = this.knBrInvoiceService.isSearchDisabled$;
  todayDate = () => {
    const today = new Date();
    return ({ year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate() });
  };
  maxStartDate = () => this.todayDate();
  maxEndDate = () => this.todayDate();
  constructor(
    public knBrInvoiceService: KnBrInvoiceService,
    protected translation: TranslationService,
    protected knBrDateHelper: KnBrDateHelper,
    protected fb: UntypedFormBuilder,
    protected knBrCustomerContextService: KnBrCustomerContextService,
    protected config: OccConfig,
    public knBrCommonService: KnBrCommonService
  ) {
    super(knBrInvoiceService, translation, config);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.customerContextData$.subscribe((value) => {
      if (value) {
        this.selectedCustomer = value;
      }
      this.initForm();
    });
    this.submitForm();
  }

  initForm() {
    this.invoiceSearchForm = this.fb.group({
      startDate: null,
      endDate: null,
      currentPage: this.currentPage,
      pageSize: WIDGET_PAGESIZE,
      isWidget: true,
    });
  }

  submitForm() {
    const formValues = this.invoiceSearchForm.getRawValue();
    if (this.invoiceSearchForm.controls.startDate.value) {
      formValues.startDate = this.knBrDateHelper.formatDate(this.invoiceSearchForm.controls.startDate.value);
    }
    if (this.invoiceSearchForm.controls.endDate.value) {
      formValues.endDate = this.knBrDateHelper.formatDate(this.invoiceSearchForm.controls.endDate.value);
    }
    this.knBrInvoiceService.search(formValues);
  }

  pageChange(page: number): void {
    if (this.searchCriteria) {
      this.searchCriteria.currentPage = page;
    }
    this.knBrInvoiceService.search(this.searchCriteria);
  }

  downloadFile(invoice) {
    const invoiceDetails = {
      documentName: invoice?.documentType?.name,
      documentNumber: invoice?.documentNumber,
      documentReference: 'V3'
    };

    this.knBrCommonService.downloadSinglePDF(invoiceDetails);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
