<div class="col-lg-12 p-0" *ngIf="ticketsData">

  <div class="row counter-wrapper mr-5 ml-5" *ngIf="ticketsData && ticketsData.length ">
    <div class="label-heading ml-4 mb-3 heading-tooltip">
      View or Manage Existing Cases
      <span ngbTooltip="{{vieworManageHeadingToolTipText}}" triggers="hover" placement="right">
      <img src="assets/images/info_icon.svg" class="pb-1">
      </span>
    </div>
    <div class="customersearch-list-table float-left col-lg-12 mt-3">
      <div class="customersearch-list-titles border-top float-left w-100 pl-2 pr-2 pt-3 pb-3 d-none d-sm-none d-lg-block">
        <div class="col-md-1 padding-res-0 float-left font-text-600 pl-1 font-sm-size">Case ID</div>
        <div class="col-md-4 padding-res-0 float-left font-text-600 pl-0 font-sm-size">Case Description</div>
        <div class="col-md-2 padding-res-0 float-left font-text-600 pl-0 font-sm-size">Case Type</div>
        <div class="col-md-1 float-left font-text-600 pl-0 font-sm-size">Created On</div>
        <div class="col-md-1 float-left font-text-600 pl-0 pr-0 font-sm-size">Last Updated</div>
        <div class="col-md-1 float-left status font-text-600 pl-0 font-sm-size">Status</div>
        <div class="col-md-2 float-left font-text-600 action-padding pl-4 pr-0 font-sm-size">Action</div>
      </div>

      <div class="col-lg-12 bg-white customersearch-list-titles table-content border-top float-left w-100 p-2 font-weight-normal d-flex align-items-center" *ngFor="let eachTicket of ticketsData">
        <div class="col-md-1 float-left title-wrap pl-1 font-sm-size color-font font-text-400">
          <a href="javascript:void(0)" (click)="handleViewCaseClick(eachTicket)" class="cx-link">{{ eachTicket.svcCaseNumber }}</a>
        </div>
        <div class="col-md-4 float-left title-wrap pl-0 font-sm-size color-font">
          {{ eachTicket.caseDescription }}
        </div>
        <div class="col-md-2 float-left title-wrap pl-0 font-sm-size color-font">
          {{eachTicket.caseType }}
        </div>
        <div class="col-md-1 float-left title-wrap pl-0 font-sm-size color-font">
          {{ eachTicket.createdOn }}
        </div>
        <div class="col-md-1 float-left title-wrap pl-0 font-sm-size color-font">
          {{ eachTicket.lastUpdated }}
        </div>
        <div class="col-md-1 status-padding title-wrap pl-0 font-sm-size color-font">
          {{ eachTicket.status }}
        </div>
        <div class="action col-md-2 title-wrap pl-0 font-sm-size color-font">
          <a href="javascript:void(0)" (click)="handleViewCaseClick(eachTicket)" class="cx-link">View</a>
          <ng-container *ngIf="eachTicket.status !== 'Closed'" > &nbsp;&nbsp;&nbsp; | &nbsp;&nbsp;&nbsp;</ng-container>
          <a href="javascript:void(0)" class="cx-link" *ngIf="eachTicket.status !== 'Closed'" (click)="handleCaseUpdateClick(eachTicket)">Update</a>
        </div>
      </div>
    </div>
    <ng-container *ngIf="paginate">
      <div class="col-lg-12 d-flex">
        <div class="col-6 px-0">
          <ng-container *ngIf="paginate">
            <div class="p-2 mt-2 font-text-400 font-md-size text-muted">
              Showing {{ticketsData.length}} out of {{paginate.totalResults}} Tickets
            </div>
          </ng-container>
        </div>
        <div class="col-6">
          <div class=" w-100 mt-3 mb-3">
            <div *ngIf="paginate.currentPage < paginate.totalPages - 1 && apiCalled"
              (click)="loadMore(paginate.currentPage)" class="btn btn-primary mt-0 mr-3">
              Show More
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="alert-info p-3 rounded mt-3"
    *ngIf="ticketsData && ticketsData.length < 1 && (apiCalled)">
      No tickets available
  </div>
</div>
