<div class="case-create-styles">
  <div class="mx-5 mb-3 d-flex flex-column">
    <div class="mr-3 font-size-18 font-text-bold">Case Information</div>
    <p class="font-sm-size">Please complete the form below to create a case. All fields marked with an <i class="fas fa-xs fa-asterisk text-danger"></i> are required</p>

    <div class="d-flex pr-2">
      <div class="mr-4">
        <ng-container *ngIf="orderUrlObject?.orderNumber">
          <div class="font-sm-size font-text-bold">Sales Order#</div>
          <div class="font-sm-size">{{this.orderNumber}}</div>
        </ng-container>
        <ng-container *ngIf="invoiceUrlObject?.documentNumber">
          <div class="d-flex">
            <div >
              <div class="font-sm-size font-text-bold">Document No#</div>
              <div class="font-sm-size">{{invoiceUrlObject?.documentNumber}}</div>
            </div>
            <div class="ml-4" *ngIf="orderNumber">
              <div class="font-sm-size font-text-bold">Sales Order#</div>
              <div class="font-sm-size">{{this.orderNumber}}</div>
            </div>
          </div>
        </ng-container>
      </div>
      <div>
        <div class=" font-sm-size font-text-bold" *ngIf="jobName">Job Name</div>
          <div class="font-sm-size">{{this.jobName}}</div>
      </div>
    </div>
    <div class="login-forms">
      <hr class="mt-3 col-lg-9 float-left">
    </div>

    <div [formGroup]="helperForm">
      <ng-container *ngIf="caseFromInvoice === false">
        <div class="login-forms col-lg-12">
          <div class="form-group col-lg-3 pl-0 float-left mb-3 p-res-0 mt-3">
            <ng-select class="mb-1" formControlName="orderBaseDocumentNumber" [items]="invoicesForTheOrder" [placeholder]="'Select Value'"
              [searchable]="false" [clearable]="false" bindLabel="name" bindValue="value" (change)='onInvoiceSelectionChange($event)'>
            </ng-select>
            <label class="text-muted">Invoice Selection<i class="fas fa-xs fa-asterisk text-danger pl-1"></i></label>
            <p class="warning mb-0">Note: Changing the Invoice# Selection, will reset the form</p>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="(caseFromInvoice === false && helperForm.get('orderBaseDocumentNumber')?.value) || (caseFromInvoice === true)">
        <div class="login-forms col-lg-12">
          <h4 class="font-sm-size font-text-bold">Select Case Type</h4>
          <div class="form-group col-lg-3 pl-0 float-left mb-3 p-res-0 mt-3">
            <ng-select class="mb-1" formControlName="proxyCaseType" [items]="caseTypesData" [placeholder]="'Select Value'"
              [searchable]="false" [clearable]="false" bindLabel="name" bindValue="value" (change)='onCaseTypeChange($event)'>
            </ng-select>
            <label class="text-muted">Select Subject/Case Type<i class="fas fa-xs fa-asterisk text-danger pl-1"></i></label>
            <p class="warning mb-0">Note: Changing the Case Type, will reset the form</p>
          </div>
        </div>
      </ng-container>
    </div>

    <ng-container [formGroup]="caseCreationForm" >
      <div class="login-forms col-lg-12">
        <div *ngIf="showIssueType" class="form-group col-lg-3 pl-0 float-left mb-3 p-res-0 mt-3">
          <ng-select class="mb-1" formControlName="issueType" [items]="issueTypesData" [placeholder]="'Select Value'"
            [searchable]="false" [clearable]="false" bindLabel="name" bindValue="value" (change)='onIssueTypeChange($event)'>
          </ng-select>
          <span class="highlight"></span>
          <label class="text-muted">Case Reason<i class="fas fa-xs fa-asterisk text-danger pl-1"></i></label>
          <div *ngIf="submitted && caseCreationForm.get('issueType') && caseCreationForm.get('issueType').errors">
            <small class="text-danger">
              Issue Type is required
            </small>
          </div>
        </div>
        <div *ngIf="showIssueSubType" class="form-group col-lg-3 pl-0 float-left mb-3 p-res-0 mt-3">
          <ng-select class="mb-1" formControlName="issueSubType" [items]="issueSubTypesData" [placeholder]="'Select Value'"
            [searchable]="false" [clearable]="false" bindLabel="name" bindValue="value" (change)='onSubIssueTypeChange($event)'>
          </ng-select>
          <span class="highlight"></span>
          <label class="text-muted">Specific Reason for Case<i class="fas fa-xs fa-asterisk text-danger pl-1"></i></label>
          <div *ngIf="submitted && caseCreationForm.get('issueSubType').touched && caseCreationForm.get('issueSubType').errors">
            <small class="text-danger">
              Issue Sub Type is required
            </small>
          </div>
        </div>
      </div>

      <div *ngIf="showIncidentDate || showCaseDesc" class="font-sm-size font-text-bold">Case Details</div>
      <div *ngIf="showIncidentDate" class="col-12 login-forms customersearch-forms">
        <div class="form-group mt-3 col-lg-3 pl-0 float-left mb-2 p-res-0 valid-to">
          <div class="input-group">
            <input class="form-control w-100 mb-0" [placeholder]="'Date of Incident'"
              name="incidentDate" ngbDatepicker #incidentDate="ngbDatepicker" formControlName="incidentDate" [readonly]="true"
              [maxDate]="maxStartDate()" />
            <span class="highlight"></span>
            <label class="text-muted">Date of Event</label>
             <div class="input-group-append">
              <button class="btn btn calendar p-0 h-auto w-auto" container="body" (click)="incidentDate.toggle()"
                type="button">
                <i class="far fa-calendar-alt color-font header-icon-color"></i>
              </button>
              </div>
          </div>
        </div>
      </div>

      <div *ngIf="showCaseDesc" class="col-md-12 float-left customersearch-forms login-forms p-0 customer-home-forms">
        <div class="form-group mt-3 col-lg-6 pl-0 float-left mb-3 p-res-0 custom-height">
          <textarea type="text" class="form-control w-100" placeholder="Enter Description" formControlName="caseDescription"></textarea>
          <span class="highlight"></span>
          <label class="text-muted">Case Description<i class="fas fa-xs fa-asterisk text-danger pl-1"></i></label>
          <div *ngIf="caseCreationForm.get('caseDescription') && caseCreationForm.get('caseDescription').errors?.maxlength">
            <small class="text-danger">
              Can't be more than 512 characters
            </small>
          </div>
        </div>
      </div>

      <!-- Additional Details section goes here (only one type) -->
      <ng-container >
        <div>
          <div *ngIf="showAdditionalDetails" class="font-sm-size font-text-bold">Issue Details</div>
          <div class="col-lg-12 login-forms">
            <div *ngIf="showShipmentNumber" class="form-group col-lg-3 pl-0 float-left mb-3 p-res-0 mt-3">
              <span class="highlight"></span>
              <label class="text-muted">Shipment#</label>
              <ng-select class="mb-1" formControlName="shipmentNumber" [items]="shipmentResponseData" [placeholder]="'Select Value'"
                [searchable]="true" [clearable]="true" [addTag]="addCustomOption" bindLabel="name" bindValue="value" (change)='onSubIssueTypeChange($event)'>
              </ng-select>
            </div>
          </div>

          <h4 *ngIf="showMultiSelectProducts" class="font-sm-size font-text-bold">Issue Details</h4>
          <div class="col-md-12 col-12 customersearch-forms login-forms p-0 customer-home-forms" *ngIf="showMultiSelectProducts">
            <div class="form-group mt-3 col-lg-6 pl-0 float-left mb-3 p-res-0 multi-select-styles">
              <ng-select  [items]="this.productsData" [clearSearchOnAdd]="true"
              [closeOnSelect]="false" labelForId="state" [multiple]="true"  [placeholder]="'Product'"
              clearAllText="Clear" formControlName="multiSelectProducts" [bindLabel]="'labelDescription'" [ngModel]="multipleSelectedProducts" (change)="onMultiSelectProductChange($event)">
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <div class="row">
                  <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"    [ngModelOptions]="{standalone: true}" />
                {{item['labelDescription']}}
              </div>
              </ng-template>
              <ng-template ng-multi-label-tmp let-items="items">
                <div class="ng-value" *ngFor="let item of items | slice:0:1">
                  {{item['labelDescription']}}
                </div>
                <div class="ng-value" *ngIf="items.length > 1">
                  <span class="ng-value-label">{{items.length - 1}} more...</span>
                </div>
              </ng-template>
            </ng-select>

              <span class="highlight"></span>
              <label class="text-muted">Product<i class="fas fa-xs fa-asterisk text-danger pl-1" *ngIf="caseCreationForm.get('multiSelectProducts')?.validator"></i></label>
            </div>
          </div>

          <ng-container *ngIf="showMultiSelectProducts && multipleSelectedProducts.length">
            <div class="col-lg-12 float-left customersearch-forms login-forms p-0 customer-home-forms">
            <h4 class="font-sm-size col-12">Selected List of Products</h4>
              <div *ngFor="let item of multipleSelectedProducts">
                <div class="d-flex col-lg-9">
                  <div class="mt-2 col-lg-4 col-6">{{item.labelDescription}}</div>
                  <div class="form-group pl-0 float-left p-res-0 col-lg-4 col-4">
                    <input type="text" class="form-control w-100" placeholder="Enter Value" (input)="quantityChangeAfterMultiSelect($event, item)"/>
                    <span class="highlight"></span>
                    <label class="text-muted">Quantity Damage</label>
                  </div>
                  <div class="form-group col-lg-3 pl-0 float-left p-res-0">
                    <ng-select class="mb-1" [items]="alternateUomData[item.code]" [placeholder]="'Select Value'"
                      [searchable]="false" [clearable]="false" bindLabel="name" bindValue="value" (change)='uomChangeAfterMultiSelect($event, item)'>
                    </ng-select>
                    <span class="highlight"></span>
                    <label class="text-muted">UOM</label>
                  </div>
                  <div class="mt-2 col-lg-1 col-1 ml-3 removeIcon" (click)="removeTheSelectedItem(item.code)">
                    <img src="assets/images/close-icon-upload.svg" class="pb-1">
                  </div>
                </div>
              </div>
            </div>
          </ng-container>


          <div *ngIf="showProducts"  class="login-forms col-lg-12">
            <div class="form-group col-lg-3 pl-0 float-left mb-3 p-res-0 mt-3">
              <ng-select class="mb-1" formControlName="products" [items]="this.productsData"
                [placeholder]="'Select Value'" [searchable]="false" [clearable]="false"
                bindLabel="labelDescription" bindValue="code" (change)='onProductChange($event)'>
              </ng-select>
              <span class="highlight"></span>
              <label class="text-muted">Product<i class="fas fa-xs fa-asterisk text-danger pl-1" *ngIf="caseCreationForm.get('products')?.validator"></i></label>
            </div>
          </div>
          <!-- <div *ngIf="showDefectDesc" class="col-md-12 float-left customersearch-forms login-forms p-0 customer-home-forms">
            <div class="form-group mt-3 col-lg-6 pl-0 float-left mb-3 p-res-0 custom-height">
              <textarea type="text" class="form-control w-100" placeholder="Enter Description" formControlName="defectDescription"></textarea>
              <span class="highlight"></span>
              <label class="text-muted">Defect Description</label>
              <div *ngIf="caseCreationForm.get('defectDescription') && caseCreationForm.get('defectDescription').errors">
                <small class="text-danger">
                  Can't be more than 512 characters
                </small>
              </div>
            </div>
          </div> -->

          <div class="col-lg-6 float-left customersearch-forms login-forms p-0 customer-home-forms">
            <div *ngIf="showRequestedSku" class="form-group mt-3 col-lg-6 pl-0 float-left mb-3 p-res-0">
              <ng-select class="mb-1" formControlName="requestedSku" [items]="this.productsData"
              [placeholder]="'Select Value'" [searchable]="false" [clearable]="false"
              bindLabel="labelDescription" bindValue="code" (change)="handleRequestedSkuSelection($event)">
            </ng-select>
              <span class="highlight"></span>
              <label class="text-muted">Requested SKU<i class="fas fa-xs fa-asterisk text-danger pl-1"></i></label>
              <div *ngIf="submitted && caseCreationForm.get('requestedSku') && caseCreationForm.get('requestedSku').errors">
                <small class="text-danger">
                  Requested SKU is required
                </small>
              </div>
            </div>
            <div *ngIf="showDeliveredSku" class="form-group mt-3 col-lg-6 pl-0 float-left mb-3 p-res-0">
              <ng-select
              [items]="options$ | async"
              [searchable]="true"
              [clearable]="true"
              [typeahead]="caseCreationForm.get('deliveredSku')!.valueChanges"
              bindLabel="description"
              formControlName="deliveredSku"
              placeholder="Start typing to search"
              notFoundText="No results found"
              bindValue="code"
              (change)="handleSelectedDeliveredsku($event)"
            ></ng-select>
            <span class="highlight"></span>
            <label class="text-muted">Delivered SKU<i class="fas fa-xs fa-asterisk text-danger pl-1"></i></label>
              <div *ngIf="submitted && caseCreationForm.get('deliveredSku') && caseCreationForm.get('deliveredSku').errors">
                <small class="text-danger">
                  Delivered SKU is required
                </small>
              </div>
            </div>
          </div>
          <div class="col-lg-12 float-left customersearch-forms login-forms p-0 customer-home-forms">
            <div *ngIf="showBatchNumber" class="form-group mt-3 col-lg-3 pl-0 float-left mb-3 p-res-0">
              <input type="text" class="form-control w-100" placeholder="Enter Value" formControlName="batchNumber"/>
              <span class="highlight"></span>
              <label class="text-muted">Batch/Lot#</label>
            </div>
            <div *ngIf="showQuantity" class="form-group mt-3 col-lg-3 pl-0 float-left mb-3 p-res-0">
              <input type="text" class="form-control w-100" placeholder="Enter Value" formControlName="quantity" />
              <span class="highlight"></span>
              <label class="text-muted">{{getQuantityLabelName()}}</label>
            </div>
            <ng-container *ngIf="showQuantity">
              <div class="form-group mt-3 col-lg-2 pl-0 float-left p-res-0">
                <ng-select class="mb-1" [items]="alternateUomData[getSingleSelectedProductValue()]" [placeholder]="'Select Value'"
                  [searchable]="false" [clearable]="false" bindLabel="name" bindValue="value" formControlName="uom">
                </ng-select>
                <span class="highlight"></span>
                <label class="text-muted">UOM</label>
              </div>
            </ng-container>
            <!-- <div *ngIf="showQuantity" formControlName="uom"  class="mt-4 col-lg-2 pl-0 float-left">
              <label class="text-muted">{{selectedSingleProductuomValue}}</label>
            </div> -->
            <!-- <div *ngIf="showInvoiceNumber && !caseFromInvoice" class="form-group mt-3 col-lg-2 pl-0 float-left mb-3 p-res-0">
              <input type="text" class="form-control w-100" placeholder="Enter Value" formControlName="invoiceNumber" />
              <span class="highlight"></span>
              <label class="text-muted">Invoice #</label>
              <div *ngIf="caseCreationForm.get('invoiceNumber') && caseCreationForm.get('invoiceNumber').errors">
                <small class="text-danger">
                  Only Numbers are allowed
                </small>
              </div>
            </div> -->
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="showUploadFileSection">
        <h4 class="font-sm-size font-text-bold mb-3">Upload Documents</h4>
        <div class="col-lg-6 pl-0">
          <div [ngClass]="uploadedFileObjects.length > 0 ? 'border' : '' ">
            <div class="col-12 d-flex upload-background file-drop-area" [ngClass]="uploadedFileObjects.length === 0 ? 'border' : ''"
            (dragover)="onDragOver($event)"
            (dragleave)="onDragLeave($event)"
            (drop)="onDrop($event)"
            [class.drag-over]="isDragOver">
              <div>
                <input type="file" class="file-input" formControlName="fileInput"
                (change)="onFileSelected($event)" #fileUpload>
                <button class="btn btn-outline-primary m-2 upload-file-styles" (click)="fileUpload.click()">Choose files</button>
              </div>
              <div *ngIf="uploadedFileObjects.length === 0" class="font-sm-size d-flex"><span class="align-content-center">Select Files to Upload</span></div>
            </div>
            <div *ngIf="uploadedFileObjects.length" [ngClass]="uploadedFileObjects.length > 6 ? 'upload-container': ''">
              <div *ngFor="let eachFile of uploadedFileObjects" class="file-styles">
                <div class="col-12 d-flex ">
                  <div class="d-flex">
                    <div>
                      <img src="assets/images/document-icon.svg" class="pb-1 mr-4">
                    </div>
                    <div class="d-block">
                      <div class="font-sm-size">{{eachFile.fileName}}</div>
                      <div class="font-xs-size">{{eachFile.fileSize}} KB</div>
                    </div>
                  </div>
                  <div class="ml-auto" (click)="removeSelectedFile(eachFile.fileReferenceId)">
                    <img src="assets/images/close-icon-upload.svg" class="pb-1">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex">
            <div class="font-xs-size ml-auto"> Max file size 200 MB</div>
          </div>
        </div>
      </ng-container>

      </ng-container>
      <div class="col-12 pl-0 mt-3">
        <div class="col-lg-2 pl-0 p-res-0 float-left">
          <button type="button" class="w-100 mt-1 mt-sm-0 btn btn-outline-primary" (click)="goBack()">
            Cancel
          </button>
        </div>
        <div class="col-lg-2 pl-0 p-res-0 float-left">
          <button type="button" (click)="submitForm()" class="w-100 mt-1 mt-sm-0 btn btn-primary" [disabled]="!(helperForm.valid && caseCreationForm.valid)">
           Create Case
          </button>
        </div>
      </div>
  </div>
</div>
