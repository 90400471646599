import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProductSearchPage, ProductSearchService } from '@spartacus/core';
import { PageLayoutService, ProductListComponentService, ViewConfig, ViewModes } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';

import { ProductViewModeService } from './../../services/product-view-mode-service';
import { KnBrCommonService } from 'src/app/services/kn-br-common.service';
import { Router } from '@angular/router';

@Component({
  selector: 'kn-br-product-list',
  templateUrl: './kn-br-product-list.component.html',
  styleUrls: ['./kn-br-product-list.component.scss'],
})
export class KnBrProductListComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  isInfiniteScroll: boolean;
  model$: Observable<ProductSearchPage> = this.productListComponentService.model$;
  viewMode$ = this.viewModeService.getViewMode$();
  ViewModes = ViewModes;
  constructor(
    protected pageLayoutService: PageLayoutService,
    protected productListComponentService: ProductListComponentService,
    public scrollConfig: ViewConfig,
    protected viewModeService: ProductViewModeService,
    protected productSearchService: ProductSearchService,
    private knBrCommonService: KnBrCommonService,
    protected router: Router
  ) {
    if(router.url.includes('/search/')){
      this.knBrCommonService.setSpinner(true);
    }
  }
  ngOnInit(): void {
    this.isInfiniteScroll = this.scrollConfig.view.infiniteScroll.active;
    // this.isInfiniteScroll = true;
    this.viewModeService.setViewMode(ViewModes.Grid);
    this.model$.subscribe(() => this.knBrCommonService.setSpinner(false));
  }
  ngOnDestroy(): void {
    this.productSearchService.getResults();
    this.productSearchService.clearResults();
    this.subscription.unsubscribe();
  }
}
