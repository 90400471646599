import { KnBrCommonService } from './../../../services/kn-br-common.service';
import { Component, ComponentRef, OnInit, ViewContainerRef, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { GlobalMessageService, GlobalMessageType, OccEndpointsService, RoutingService } from '@spartacus/core';
import { LaunchDialogService, LAUNCH_CALLER } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
import { OrderHistoryFacade } from '@spartacus/order/root';
import { OrderDetailsService } from '../kn-br-order-details.service';
import { map } from 'rxjs/operators';
import { KnBrOrderHistoryService } from 'src/app/store/kn-br-order-history/kn-br-order-history.service';
import { KN_BR_CUSTOMER_CURRENT, KNBR_GET_INFO_TO_CREATE_CASE } from 'src/constants/api.endpoints.constant';
import { HttpClient } from '@angular/common/http';
import { KnBrCustomerContextService } from 'src/app/store/kn-br-customer-context/kn-br-customer-context.service';
@Component({
  selector: 'knbr-kn-br-order-details-overview',
  templateUrl: './kn-br-order-details-overview.component.html',
  styleUrls: ['./kn-br-order-details-overview.component.scss'],
})
export class KnBrOrderDetailsOverviewComponent implements OnInit, OnDestroy {
  order$: Observable<any>;
  loader$: Observable<boolean> = this.knBrCommonService.loadSpinner$();
  showSpinner: void | Observable<ComponentRef<any>>;
  subscription = new Subscription();
  orderDetailsLoadError: boolean = false;
  createCasetoolTipText = `If you need assistance with this order,please click 'Submit Case'`;
  customerId: any;
  constructor(
    protected orderDetailsService: OrderDetailsService,
    protected routingService: RoutingService,
    protected launchDialogService: LaunchDialogService,
    protected vcr: ViewContainerRef,
    protected userOrderService: OrderHistoryFacade,
    protected knBrCommonService: KnBrCommonService,
    private knBrOrderHistoryService: KnBrOrderHistoryService,
    protected cdr: ChangeDetectorRef,
    protected knBrCustomerContextService: KnBrCustomerContextService,
    protected http: HttpClient,
    protected globalMessageService: GlobalMessageService,
    private endPointService: OccEndpointsService,
  ) {}

  ngOnInit(): void {
    this.order$ = this.orderDetailsService.getOrderDetails();
    this.subscription.add(
      this.loader$.subscribe((isLoading) => {
        this.onSpinnerLoading(isLoading);
      })
    );
    this.order$
      .pipe(
        map((order) => {
          if (Object.keys(order).length === 0) {
            // TODO order details error notification
            // this.orderDetailsLoadError = true;
            //this.messageService.add({ key: 'knBrMessages.orderDetailsLoadFailed' }, GlobalMessageType.MSG_TYPE_ERROR);
          }
          return order;
        })
      )
      .subscribe(() => this.knBrCommonService.setSpinner(false));
    this.subscription.add(this.knBrCustomerContextService.getActiveCustomerNumber().subscribe((response) => {
      this.customerId = response ? response : KN_BR_CUSTOMER_CURRENT;
    }))
  }

  onSpinnerLoading(isLoading) {
    if (isLoading) {
      this.showSpinner = this.launchDialogService.launch(LAUNCH_CALLER.PLACE_ORDER_SPINNER, this.vcr);
    } else {
      if (this.showSpinner) {
        this.showSpinner
          .subscribe((component) => {
            this.launchDialogService.clear(LAUNCH_CALLER.PLACE_ORDER_SPINNER);
            component.destroy();
          })
          .unsubscribe();
      }
    }
  }

  formatPhone(value) {
    const trim = value?.substring(2);
    const cleaned = ('' + trim).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    } else {
      const removeSpaces = value?.replace(/\s/g, '');
      const trim = removeSpaces?.substring(1);
      const match = trim?.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
      }
    }
  }

  navigateToCaseCreation() {
    const orderNumber = this.orderDetailsService.orderNumber;
    const url = this.endPointService.buildUrl(KNBR_GET_INFO_TO_CREATE_CASE(this.customerId), {
      queryParams: {
        orderNumber
      }
    });
    this.subscription.add(this.http.get(url).subscribe((resp:any)=> {
      if(resp && resp.invoices && resp.invoices.length) {
        const urlValue = `my-account/case-creation?orderNumber=${orderNumber}`
        this.routingService.goByUrl(urlValue);
      } else {
        this.globalMessageService.add(
          'Case can not be created at this time. Please reach out directly to your Order Management Specialist for assistance.',
          GlobalMessageType.MSG_TYPE_ERROR
        );
      }
    }))
  }

  downloadPDF(document) {
    this.knBrOrderHistoryService.downloadPDF(document);
  }

  goBack() {
    this.routingService.go({ cxRoute: 'orders' }, { state: { displayPersistedValues: true }});
  }

  ngOnDestroy() {
    this.launchDialogService.clear(LAUNCH_CALLER.PLACE_ORDER_SPINNER);
  }
}
