<div class="price-book-up">
  <div class="modal-header d-flex align-items-center">
    <h4 class="modal-title">{{header}}</h4>
    <!-- <button type="button" class="close" aria-label="Close" (click)="dismissModal()">
      <span aria-hidden="true">&times;</span>
    </button> -->
  </div>
  <div class="modal-body float-left w-100 p-0 mt-3">
    <div class="col-md-12 float-left">
      <p>{{message}}</p>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="handleContinueClick()">Yes, Continue</button>
    <button type="button" class="btn btn-outline-primary" (click)="dismissModal()">Cancel</button>
  </div>

</div>
